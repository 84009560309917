// stylelint-disable selector-class-pattern, selector-nested-pattern
//
// メインコンテンツ（content-mainコンポーネント）の内容はCMSなどで管理されるHTMLを含んだり、
// ページ独自のスタイルを扱うことが多いので例外的に専用のルールを設ける。
//
// メインコンテンツのエレメントは基本的に自由である。
// ただし、クラス名は`c-`で開始してはいけない。もちろん 【`c-` + `コンポーネント名` + `__` + `エレメント名`】 という規則でクラス名をつけない。
// さらにそのルールに従って、メインコンテンツの中にコンポーネントを内包してはいけない。
//
// 🍔 マークは BurgerEditor専用の意味で、利用しない場合は削除する
//
.bge-contents, // 🍔
.c-content-main {
	// 🍔 全ブロック基本の余白
	$bgb-default-margin-bottom: 3em;
	// 🍔 背景色・枠線がある場合のパディング
	$bgb-opt-padding: 3rem;

	//
	// 基本スタイル
	//
	font-size: 1.6rem;
	letter-spacing: 0.08em;
	line-height: 2;
	overflow-wrap: break-all;
	word-break: break-all;

	@media (--xs) {
		font-size: calc(30 / 750 * 100vw); // 幅320pxのデバイスで16pt
	}

	@media (--sm-lte) {
		img {
			max-width: 100%;
		}
	}

	[lang='en'] & {
		word-break: normal;
		word-wrap: break-word;
		line-height: 1.7;
	}

	//
	// エレメント
	//
	// ☝ 以降のフォントサイズの指定単位は em を推奨
	//
	a {
		color: $secondary-color;

		figcaption {
			color: $darkest-color;
		}

		&:hover {
			text-decoration: none;
		}

		&:visited {
			color: $secondary-color-lighter10 !important; // stylelint-disable-line declaration-no-important
		}
	}

	p,
	table,
	ul,
	ol {
		a {
			&[href^='tel:'] {
				@media (--sm-gt) {
					color: $font-primary-color;
					pointer-events: none;
					text-decoration: none;
				}
			}

			&[target='_blank']::after {
				content: '';
				width: 13px;
				height: 13px;
				background: url("<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='13' height='13' viewBox='0 0 13 13' xml:space='preserve'><polygon points='10.5,13 0,13 0,2.5 1,2.5 1,12 10.5,12' fill='" + $primary-color + "'/><path d='M12,1v8H4V1H12 M13,0H3v10h10V0L13,0z' fill='" + $primary-color + "'/></svg>") no-repeat center center / 100% 100%;
				display: inline-block;
				margin: 0 5px;

				@media (--sm-lte) {
					width: calc(26 / 750 * 100vw);
					height: calc(26 / 750 * 100vw);
					margin: 0 calc(10 / 750 * 100vw);
				}
			}

			&[href$='pdf'],
			&[href*='.pdf#'],
			&[href$='ppt'],
			&[href$='pptx'],
			&[href$='doc'],
			&[href$='docx'],
			&[href$='xls'],
			&[href$='xlsx'],
			&[href$='zip'] {
				border-color: $font-primary-color;
				display: inline-block;
				font-feature-settings: normal;
				position: relative;

				&::after {
					background: url('/img/icon-pdf-color.png') no-repeat;
					background-size: 15px 20px;
					content: '';
					display: inline-block;
					height: 20px;
					width: 15px;
					margin: 0 8px;
					vertical-align: -4px;

					@media (--sm-lte) {
						height: calc(40 / 750 * 100vw);
						width: calc(30 / 750 * 100vw);
						background-size: 100% 100%;
						margin: 0 calc(10 / 750 * 100vw);
					}
				}
			}

			&[href$='ppt'],
			&[href$='pptx'] {
				&::after {
					background-image: url('/img/icon-ppt-color.png');
				}
			}

			&[href$='doc'],
			&[href$='docx'] {
				&::after {
					background-image: url('/img/icon-doc-color.png');
				}
			}

			&[href$='xls'],
			&[href$='xlsx'] {
				&::after {
					background-image: url('/img/icon-xls-color.png');
				}
			}

			&[href$='zip'] {
				&::after {
					background-image: url('/img/icon-zip-black.png');
				}
			}

			&.icon-external {
				&::after {
					content: '';
					width: 13px;
					height: 13px;
					background: url("<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='13' height='13' viewBox='0 0 13 13' xml:space='preserve'><polygon points='10.5,13 0,13 0,2.5 1,2.5 1,12 10.5,12' fill='" + $primary-color + "'/><path d='M12,1v8H4V1H12 M13,0H3v10h10V0L13,0z' fill='" + $primary-color + "'/></svg>") no-repeat center center / 100% 100%;
					display: inline-block;
					margin: 0 5px;

					@media (--sm-lte) {
						width: calc(26 / 750 * 100vw);
						height: calc(26 / 750 * 100vw);
						margin: 0 calc(10 / 750 * 100vw);
					}
				}
			}
		}
	}

	ul,
	ol {
		li {
			a {
				&[href$='pdf'],
				&[href$='ppt'],
				&[href$='pptx'],
				&[href$='doc'],
				&[href$='docx'],
				&[href$='xls'],
				&[href$='xlsx'],
				&[href$='zip'] {
					// padding: 4px 0 4px 32px;

					// @media (--sm-lte) {
					// 	padding: calc(8 / 750 * 100vw) 0 calc(8 / 750 * 100vw) calc(64 / 750 * 100vw);
					// }

					&::before {
						// margin-top: 0;
					}
				}

				& + br {
					& + a {
						margin-top: 8px;
					}
				}
			}

			& + li {
				margin-top: 8px;
			}
		}
	}

	h2 {
		position: relative;
		font-size: 2.8rem;
		font-weight: normal;
		line-height: 1.6;
		margin: 1em 0 25px;
		padding: 0 0 16px;
		color: $font-primary-color;

		@media (--sm-lte) {
			font-size: calc(48 / 750 * 100vw);
		}

		[lang='en'] & {
			@media (--sm-gt) {
				font-size: 2.6rem;
			}

			@media (--sm-lte) {
				padding: 0 0 10px;
			}
		}

		&::before,
		&::after {
			content: '';
			position: absolute;
			display: block;
			height: 2px;
			bottom: 0;
			left: 0;
		}

		&::before {
			background: $primary-color-lighter;
			width: 100%;
		}

		&::after {
			background: $secondary-color;
			width: 120px;
		}

		&:first-child {
			margin-top: 0;
		}

		// タイトルの英字
		.title-en {
			color: $gray-color12;
			font-size: 1.4rem;

			@media (--sm-lte) {
				display: block;
				font-size: calc(28 / 750 * 100vw);
				line-height: 1.4;
			}
		}
	}

	.roman-title {
		h2 {
			&::first-letter {
				font-family: $font-family-secondary;
			}
		}
	}

	.flag-title {
		&.bgb-text-image2 {
			border-bottom: 1px solid $gray-color03;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			font-weight: bold;
			padding: 0 0 16px;

			@media (--sm-lte) {
				padding: 0 0 calc(20 / 750 * 100vw);
			}

			& > div {
				float: none;

				&:first-child {
					width: 62px;
					height: 41px;
					position: static;
					flex: 0 0 auto;
					margin-right: 25px;
					padding: 0;

					@media (--sm-lte) {
						width: calc(93 / 750 * 100vw) !important; // stylelint-disable-line declaration-no-important
						height: calc(61.5 / 750 * 100vw);
						margin-right: calc(30 / 750 * 100vw);
						margin-bottom: 0;
					}
				}

				&:last-child {
					padding: 0 !important; // stylelint-disable-line declaration-no-important

					h2 {
						padding: 0;
						margin: 0;

						&::before,
						&::after {
							content: none;
						}
					}
				}
			}
		}

		h2 {
			display: flex;
			justify-content: flex-start;
			align-items: center;
			font-weight: bold;

			@media (--sm-lte) {
				padding: 0 0 calc(20 / 750 * 100vw);
			}

			&::before {
				width: 62px;
				height: 41px;
				position: static;
				flex: 0 0 auto;
				margin-right: 25px;

				@media (--sm-lte) {
					width: calc(93 / 750 * 100vw);
					height: calc(61.5 / 750 * 100vw);
					margin-right: calc(30 / 750 * 100vw);
				}
			}

			&::after {
				background: $gray-color03;
				width: 100%;
				height: 1px;
			}
		}

		&.usa {
			h2 {
				&::before {
					background: url('/img/icon-usa.png') no-repeat center center / 100% 100%;
				}
			}
		}

		&.tha {
			h2 {
				&::before {
					background: url('/img/icon-tha.png') no-repeat center center / 100% 100%;
				}
			}
		}

		&.kor {
			h2 {
				&::before {
					background: url('/img/icon-kor.png') no-repeat center center / 100% 100%;
				}
			}
		}

		&.chn {
			h2 {
				&::before {
					background: url('/img/icon-chn.png') no-repeat center center / 100% 100%;
				}
			}
		}

		&.aus {
			h2 {
				&::before {
					background: url('/img/icon-aus.png') no-repeat center center / 100% 100%;
				}
			}
		}

		&.alb {
			h2 {
				&::before {
					background: url('/img/icon-alb.png') no-repeat center center / 100% 100%;
				}
			}
		}

		&.twn {
			h2 {
				&::before {
					background: url('/img/icon-twn.png') no-repeat center center / 100% 100%;
				}
			}
		}

		&.ita {
			h2 {
				&::before {
					background: url('/img/icon-ita.png') no-repeat center center / 100% 100%;
				}
			}
		}
	}

	// 🍔
	.bge-title-h2 {
		margin-top: 0;
	}

	h3 {
		font-size: 2.4rem;
		font-weight: normal;
		line-height: 1.5;
		background: $gray-color02;
		margin: 1.5em 0 20px;
		padding: 10px 16px;
		border-left: 2px solid $secondary-color;

		@media (--sm-lte) {
			font-size: calc(44 / 750 * 100vw);
		}

		[lang='en'] & {
			@media (--sm-gt) {
				font-size: 2rem;
			}
		}

		&:first-child {
			margin-top: 0;
		}
	}

	// 🍔
	.bge-title-h3 {
		margin-top: 0;
	}

	h4 {
		position: relative;
		font-size: 2.2rem;
		font-weight: normal;
		line-height: 1.6;
		border-bottom: 1px solid $primary-color-lighter;
		margin: 1.2em 0 20px;
		overflow-x: hidden;
		padding: 0 0 10px 15px;

		@media (--sm-lte) {
			font-size: calc(40 / 750 * 100vw);
		}

		[lang='en'] & {
			@media (--sm-gt) {
				font-size: 1.8rem;
			}
		}

		&::before,
		&::after {
			content: '';
			position: absolute;
			display: block;
			width: 2px;
			height: calc(1 / 2 * 100% - 4px);
			left: 0;
		}

		&::before {
			background: $primary-color-darker;
			top: 0;
		}

		&::after {
			background: $secondary-color;
			top: calc(50% - 4px);
		}

		&:first-child {
			margin-top: 0;
		}
	}

	h5 {
		font-size: 2rem;
		font-weight: 500;
		line-height: 1.7;
		margin: 1.2em 0 15px;

		@media (--sm-lte) {
			font-size: calc(36 / 750 * 100vw);
		}

		[lang='en'] & {
			@media (--sm-gt) {
				font-size: 1.8rem;
			}
		}

		&:first-child {
			margin-top: 0;
		}
	}

	h6 {
		position: relative;
		font-size: 1.8rem;
		font-weight: 500;
		line-height: 1.8;
		margin: 1.2em 0 0;
		padding-left: 20px;

		@media (--sm-lte) {
			font-size: calc(32 / 750 * 100vw);
		}

		[lang='en'] & {
			@media (--sm-gt) {
				font-size: 1.6rem;
			}
		}

		&::before {
			content: '';
			position: absolute;
			width: 14px;
			height: 14px;
			background: $lightest-color;
			border: 4px solid $secondary-color;
			border-radius: 50%;
			top: calc(1em - 7px);
			left: 0;
		}

		&:first-child {
			margin-top: 0;
		}
	}

	ul {
		padding-left: 20px;

		@media (--sm-lte) {
			padding-left: 0;
		}

		&.indent-none {
			padding-left: 0;
		}

		> li {
			list-style: none;
			line-height: 1.7;
			font-size: 1.5rem;
			margin-bottom: 16px;
			padding-left: 15px;
			position: relative;

			&::before {
				content: '';
				display: inline-block;
				vertical-align: 3px;
				margin: 0 10px 0 -15px;
				width: 6px;
				height: 6px;
				background: $secondary-color;
				position: absolute;
				top: 10px;

				@media (--sm-lte) {
					top: calc(20 / 750 * 100vw);
				}
			}

			&[style*='text-align: center'] {
				&::before {
					position: static;
				}
			}

			> ul {
				li::before {
					background: $secondary-color-lighter;
				}

				> ul {
					li::before {
						background: $gray-color10;
					}
				}
			}
		}

		ul,
		ol {
			// Lv.2以降
			// margin-left: $indent-sub-list;
			margin-left: 5px;
			margin-top: 18px;
		}
	}

	ol,
	ol.list-ol-normal {
		// counter-reset: content-main-ol-default;
		padding-left: 0;

		> li {
			list-style: none;
			line-height: 1.7;
			counter-increment: section01;
			margin-bottom: 18px;
			padding-left: 2.8em;
			position: relative;
			// padding-left: calc(2.6rem + 3px);
			// text-indent: calc(-2.6rem - 3px);

			&::before {
				content: counter(section01, decimal) '. ';
				counter-increment: content-main-ol-default;
				color: $secondary-color;
				font-family: $font-family-secondary;
				font-weight: bold;
				font-size: 1.6rem;
				left: 0;
				position: absolute;
				top: 0;
				width: 2.4em;
				display: flex;
				justify-content: flex-end;
				// margin-right: 3px;
				white-space: nowrap;

				@media (--sm-lte) {
					font-size: calc(28 / 750 * 100vw);
				}
			}

			> ol,
			ol.list-ol-normal-sub {
				> li {
					counter-increment: section02;

					&::before {
						content: counter(section02, decimal) '. ';
						color: $secondary-color-lighter2;
					}

					> ol {
						> li {
							counter-increment: section03;

							&::before {
								content: counter(section03, decimal) '. ';
								color: $gray-color11;
								//width: auto;
							}
						}
					}
				}
			}
		}

		ul,
		ol {
			// Lv.2以降
			// margin-left: $indent-sub-list;
			margin-left: 2px;
			margin-top: 18px;
		}
	}

	.list-upper-alpha {
		padding-left: 20px;

		li {
			counter-increment: section04;
			list-style-type: none;
			padding-left: 1.5em;
			position: relative;

			&::before {
				content: counter(section04, upper-alpha);
				color: $secondary-color;
				justify-content: flex-start;
				min-width: 1em;
				text-align: center;
			}

			&::after {
				content: '.';
				color: $secondary-color;
				left: 1em;
				position: absolute;
				top: 0;
			}
		}

		&-sub {
			li {
				counter-increment: section05 !important; // stylelint-disable-line declaration-no-important
				list-style-type: none;
				padding-left: 1.5em;
				position: relative;

				&::before {
					content: counter(section05, upper-alpha) !important; // stylelint-disable-line declaration-no-important
					color: $secondary-color-lighter2;
					justify-content: flex-start;
					min-width: 1em;
					text-align: center;
				}

				&::after {
					content: '.';
					color: $secondary-color-lighter2;
					left: 1em;
					position: absolute;
					top: 0;
				}
			}
		}
	}

	.list-lower-alpha {
		li {
			counter-increment: section06;
			list-style-type: none;
			padding-left: 1.4em;
			position: relative;

			&::before {
				content: counter(section06, lower-alpha);
				color: $secondary-color;
				justify-content: flex-start;
				min-width: 0.8em;
				text-align: center;
			}

			&::after {
				content: '.';
				color: $secondary-color;
				left: 0.9em;
				position: absolute;
				top: 0;
			}
		}

		&-sub {
			li {
				counter-increment: section07 !important; // stylelint-disable-line declaration-no-important
				list-style-type: none;
				padding-left: 1.4em;
				position: relative;

				&::before {
					content: counter(section07, lower-alpha) !important; // stylelint-disable-line declaration-no-important
					color: $secondary-color-lighter2;
					justify-content: flex-start;
					min-width: 0.8em;
					text-align: center;
				}

				&::after {
					content: '.';
					color: $secondary-color-lighter2;
					left: 0.9em;
					position: absolute;
					top: 0;
				}
			}
		}
	}

	.list-brackets {
		> li {
			counter-increment: section08;
			list-style-type: none;
			padding-left: 2.8em;
			position: relative;

			&::before {
				content: '(' counter(section08) ') ';
				color: $secondary-color;
				letter-spacing: 0.04em;
				min-width: 2em;
				text-align: center;
			}
		}

		&-sub {
			> li {
				counter-increment: section09 !important; // stylelint-disable-line declaration-no-important
				list-style-type: none;
				padding-left: 2.8em;
				position: relative;

				&::before {
					content: '(' counter(section09) ') ' !important; // stylelint-disable-line declaration-no-important
					color: $secondary-color-lighter2;
					letter-spacing: 0.04em;
					min-width: 2em;
					text-align: center;
					display: block;
				}
			}
		}

		&-third {
			li {
				counter-increment: section15 !important; // stylelint-disable-line declaration-no-important
				list-style-type: none;
				padding-left: 2em;
				position: relative;

				&::before {
					content: '(' counter(section15) ') ' !important; // stylelint-disable-line declaration-no-important
					color: $gray-color10;
					letter-spacing: 0.04em;
					min-width: 0.8em;
					text-align: center;
					justify-content: flex-start;
				}
			}
		}
	}

	.list-roman {
		> li {
			counter-increment: section10;
			list-style-type: none;
			padding-left: 2.2em;
			position: relative;

			&::before {
				content: counter(section10, upper-roman) '.';
				color: $primary-color-darker12;
				width: 1.8em;
				text-align: right;
				font-family: $font-family-secondary;
				font-size: 1.6rem;
				font-weight: bold;
				top: 0;
				white-space: nowrap;

				@media (--sm-lte) {
					font-size: calc(32 / 750 * 100vw);
					top: calc(2 / 750 * -100vw);
				}
			}

			ol {
				> li {
					counter-increment: section11;

					&::before {
						content: counter(section11, upper-roman) '.';
						color: $secondary-color;
						width: 2.4em;
						white-space: nowrap;

						> ol {
							> li {
								counter-increment: section12;

								&::before {
									content: counter(section12, upper-roman) '.';
									color: $secondary-color-lighter2;
								}
							}
						}
					}
				}
			}
		}
	}

	.list-alpha {
		> li {
			counter-increment: section16;
			list-style-type: none;
			padding-left: 2.8em;
			position: relative;

			&::before {
				content: '(' counter(section16, lower-alpha) ') ';
				color: $secondary-color;
				letter-spacing: 0.04em;
				min-width: 2em;
				text-align: center;
			}
		}

		&-sub {
			> li {
				counter-increment: section17 !important; // stylelint-disable-line declaration-no-important
				list-style-type: none;
				padding-left: 2.8em;
				position: relative;

				&::before {
					content: '(' counter(section17, lower-alpha) ') ' !important; // stylelint-disable-line declaration-no-important
					color: $secondary-color-lighter2;
					letter-spacing: 0.04em;
					min-width: 2em;
					text-align: center;
					display: block;
				}
			}
		}

		&-third {
			li {
				counter-increment: section18 !important; // stylelint-disable-line declaration-no-important
				list-style-type: none;
				padding-left: 2em;
				position: relative;

				&::before {
					content: '(' counter(section18, lower-alpha) ') ' !important; // stylelint-disable-line declaration-no-important
					color: $gray-color10;
					letter-spacing: 0.04em;
					min-width: 0.8em;
					text-align: center;
					justify-content: flex-start;
				}
			}
		}
	}

	.list-cjk-ideographic {
		> li {
			counter-increment: section13;
			list-style-type: none;
			padding-left: 2.4em;
			position: relative;

			&::before {
				content: '(' counter(section13, cjk-ideographic) ') ';
				color: $primary-color-darker12;
				width: 1.2em;
				justify-content: flex-start;
				text-align: right;
				font-family: $font-family-secondary;
				font-weight: bold;

				@media (--sm-lte) {
					font-size: calc(32 / 750 * 100vw);
					top: calc(4 / 750 * -100vw);
				}
			}
		}

		&-sub {
			padding-left: 1em;

			> li {
				counter-increment: section14 !important; // stylelint-disable-line declaration-no-important
				padding-left: 2.4em;

				&::before {
					justify-content: flex-start;
					content: '(' counter(section14, cjk-ideographic) ') ' !important; // stylelint-disable-line declaration-no-important
					color: $secondary-color;
				}
			}
		}
	}

	.list-campusmap {
		column-count: 3;

		@media (--sm-lte) {
			column-count: 1;
		}
	}

	table {
		border-collapse: collapse;
		width: 100%;
		margin-bottom: 30px;
		border-right: 1px solid $border-primary-color;

		@media (--sm-lte) {
			width: 100%;
		}

		a {
			&[href$='pdf'],
			&[href$='ppt'],
			&[href$='pptx'],
			&[href$='doc'],
			&[href$='docx'],
			&[href$='xls'],
			&[href$='xlsx'],
			&[href$='zip'] {
				display: inline;
			}
		}

		// table総計
		th.table-total,
		td.table-total {
			border-left: 2px solid #00a2e6 !important; // stylelint-disable-line declaration-no-important
			border-right: 2px solid #00a2e6 !important; // stylelint-disable-line declaration-no-important
		}

		tr:first-child {
			th.table-total,
			td.table-total {
				border-top: 2px solid #00a2e6;
			}
		}

		tr:last-child {
			th.table-total,
			td.table-total {
				border-bottom: 2px solid #00a2e6;
			}
		}
	}

	caption {
		font-weight: bold;
		text-align: left;

		@media (--sm-lte) {
			font-size: calc(25 / 750 * 100vw);
			margin-bottom: calc(20 / 750 * 100vw);
		}
	}

	thead {
		@media (--xs) {
			display: none;
		}
	}

	tfoot {
		@media (--xs) {
			display: none;
		}
	}

	tbody {
		@media (--sm-lte) {
			// display: block;
			// width: 100%;
		}

		@media (--xs) {
			//
		}
	}

	tr {
		@media (--sm-lte) {
			// display: block;
			// width: 100%;
		}

		@media (--xs) {
			//
		}

		&.bg-highlight {
			th {
				background: $secondary-color-lighter9;
			}
		}
	}

	th,
	td {
		font-size: calc(15 / 16 * 1em);
		// padding: 1.3em 1.7em;
		padding: 1em;
		color: $font-primary-color;
		border-top: 1px solid $border-primary-color;
		border-bottom: 1px solid $border-primary-color;
		border-left: 1px solid $border-primary-color;
		line-height: 1.7;

		@media (--sm-lte) {
			font-size: calc(28 / 750 * 100vw);
			padding: calc(30 / 750 * 100vw) calc(40 / 750 * 100vw);
		}

		[data-path='/daigaku/disclosure/hyoka/'] & {
			width: calc(100% / 2);
		}

		ul,
		ol {
			padding-left: 0;
		}

		ol {
			li {
				padding-left: 1.4em;
				text-indent: -1.4em;

				&::before {
					width: auto;
					position: relative;
					left: auto;
					top: auto;
					display: inline;
				}

				ul {
					padding-left: 1em;

					li {
						padding-left: 0;
						text-indent: 0;

						&::before {
							display: inline-block;
							width: 6px;
							position: absolute;
							top: 10px;
						}
					}
				}
			}
		}

		&.bg-yellow {
			background: $accent-color02;
		}
	}

	th {
		background: $gray-color02;
		text-align: left;
		// text-align: center;

		[data-path='/daigaku/syokai/enkaku'] & {
			white-space: nowrap;
		}
	}

	table.bge-type-table {
		@media (--sm-lte) {
			display: block;
			width: 100%;
			border-right: none;
		}

		caption {
			@media (--sm-lte) {
				display: block;
				width: 100%;
			}

			&:empty {
				margin: 0;
			}
		}

		tbody,
		tr {
			@media (--sm-lte) {
				display: block;
				width: 100%;
			}

			// &:not(:first-child) {
			// 	th {
			// 		@media (--sm-lte) {
			// 			border-top: none;
			// 		}
			// 	}
			// }
		}

		tbody {
			@media (--sm-lte) {
				border-top: 1px solid $border-primary-color;
			}
		}

		th,
		td {
			@media (--sm-lte) {
				display: block;
				width: 100%;
				border-right: 1px solid $border-primary-color;
			}
		}

		th {
			@media (--sm-lte) {
				border-top: none;
				text-align: left;
			}

			[lang='en'] & {
				width: calc(100% * 0.35);

				@media (--sm-lte) {
					width: 100%;
				}
			}
		}

		td {
			@media (--sm-lte) {
				border-top: none;
			}
		}
	}

	// .table-th-top {
	// 	@media (--sm-lte) {
	// 		border-bottom: 1px solid $border-primary-color;
	// 		border-right: none;
	// 		table-layout: fixed;

	// 		tr {
	// 			display: table-row;
	// 			width: auto;

	// 			&:not(:last-child) {
	// 				td,
	// 				th {
	// 					border-right: 1px solid $border-primary-color;
	// 				}
	// 			}

	// 			td,
	// 			th {
	// 				border-left: none;
	// 				// border-right: none;
	// 				border-bottom: none;
	// 				display: block;
	// 				height: 48px;
	// 				min-width: 120px;
	// 				white-space: nowrap;
	// 				width: auto !important; // stylelint-disable-line declaration-no-important
	// 			}
	// 		}

	// 		thead {
	// 			tr {
	// 				width: 100%;
	// 			}
	// 		}

	// 		tbody {
	// 			display: block;
	// 			overflow-x: scroll;
	// 			white-space: nowrap;
	// 			width: auto;

	// 			tr {
	// 				display: inline-block;
	// 			}
	// 		}
	// 	}

	// 	caption {
	// 		display: block;
	// 	}

	// 	thead {
	// 		display: contents;

	// 		@media (--sm-lte) {
	// 			display: block;
	// 			float: left;
	// 		}
	// 	}
	// }

	.table-rating {
		text-align: center;

		th {
			@media (--sm-lte) {
				padding: 10px 4%;
			}

			&:nth-child(1) {
				@media (--sm-gt) {
					width: calc(100% * 0.23);
				}
			}

			&:nth-child(2) {
				@media (--sm-gt) {
					width: calc(100% * 0.18);
				}
			}

			&:nth-child(3) {
				@media (--sm-gt) {
					width: calc(100% * 0.29);
				}
			}

			&:nth-child(4) {
				@media (--sm-gt) {
					width: calc(100% * 0.3);
				}
			}
		}

		td {
			@media (--sm-lte) {
				padding: 10px 4%;
			}
		}
	}

	.table-th-white {
		th {
			background: $lightest-color !important; // stylelint-disable-line declaration-no-important
		}
	}

	.table-schedule {
		tr {
			@media (--sm-lte) {
				display: block;
			}

			&:last-child {
				td {
					border-bottom: 1px solid $border-primary-color;
				}
			}
		}

		th {
			background: $lightest-color !important; // stylelint-disable-line declaration-no-important
		}

		td {
			@media (--sm-lte) {
				border: none;
			}
		}

		th,
		td {
			@media (--sm-lte) {
				display: block;
				width: 100%;
			}
		}
	}

	.table-student-number {
		border-left: 1px solid $border-primary-color;
		border-right: 1px solid $border-primary-color;
		width: 360px;

		@media (--sm-lte) {
			width: 100%;
		}

		td {
			padding: 1em 1.2em;
			text-align: center;

			&:last-child {
				text-align: left;
			}
		}
	}

	.table-narrow {
		table {
			th,
			td {
				padding: 0.5em 0.8em;
			}
		}
	}

	.table-scroll {
		@media (--sm-lte) {
			overflow: auto;
			margin: 0 0 30px;

			table {
				display: table;
				width: calc(1800 / 750 * 100vw) !important; // stylelint-disable-line declaration-no-important
				margin-bottom: 5px;

				caption {
					display: table-caption;
				}

				tr {
					display: table-row;
				}

				tbody {
					display: table-row-group;
				}

				thead {
					display: table-header-group;
				}

				td,
				th {
					display: table-cell;
					//width: auto !important; // stylelint-disable-line declaration-no-important
				}

				th + th {
					border-left: 1px solid $border-primary-color;
				}

				td + td {
					border-left: 1px solid $border-primary-color;
				}

				table {
					width: auto !important; // stylelint-disable-line declaration-no-important
				}
			}

			&::-webkit-scrollbar {
				height: 5px;
			}

			&::-webkit-scrollbar-track {
				background: $border-primary-color;
				border-radius: 5px;
			}

			&::-webkit-scrollbar-thumb {
				background: $border-primary-color;
				border-radius: 5px;
			}
		}

		&-sp {
			display: none;

			@media (--sm-lte) {
				display: inline-block;
				font-size: calc(25 / 750 * 100vw);
				background: $primary-color-darker4 url('/img/icon-table-scroll.png') no-repeat calc(13 / 750 * 100vw) center / calc(46 / 750 * 100vw) auto;
				padding: 0 calc(18 / 750 * 100vw) 0 calc(70 / 750 * 100vw);
				line-height: 1.95;
				letter-spacing: 0.09em;
				margin-bottom: calc(40 / 750 * 100vw);
				color: $lightest-color;
				font-weight: 500;
				border-radius: calc(48 / 750 * 100vw);
			}
		}
	}

	.table-number-text {
		td {
			text-align: center;
			width: calc(100% * 0.15);

			@media (--sm-lte) {
				padding: calc(20 / 750 * 100vw);
			}

			& + td,
			&:first-child:last-child {
				text-align: left;
				width: calc(100% * 0.9);

				@media (--sm-lte) {
					padding: calc(20 / 750 * 100vw) calc(30 / 750 * 100vw);
				}
			}
		}

		tr {
			&:not(:last-child) {
				td {
					border-bottom: none;

					&[rowspan] {
						border-bottom: 1px solid $border-primary-color;
					}
				}
			}
		}
	}

	.table-th-vertical {
		p {
			align-items: center;
			display: flex;
			justify-content: center;
			letter-spacing: 0.4em;
			margin: 0;
			text-align: justify;
			writing-mode: vertical-lr;

			> span {
				&:nth-child(2) {
					text-orientation: sideways;
					-webkit-text-orientation: sideways;
				}
			}

			&.text-height-12em {
				height: 12em;
			}
		}
	}

	.table-history {
		table {
			th {
				@media (--sm-gt) {
					text-align: right;
					width: calc(100% * 0.28);
				}
			}
		}
	}

	.table-col2-block {
		@media (--sm-gt) {
			display: flex;
			justify-content: flex-start;
			align-items: stretch;

			> div {
				width: calc(100% / 2);

				&:first-child {
					margin-right: -1px;
				}
			}
		}

		@media (--sm-lte) {
			> div {
				&:first-child {
					margin-bottom: -1px;

					table {
						margin: 0;
					}
				}
			}
		}
	}

	p {
		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	p,
	span,
	a {
		&.text-notice {
			color: $pink-color02;
		}
	}

	.text-notice {
		p {
			color: $pink-color02;
		}
	}

	.text-emphasis {
		span {
			color: $pink-color02;
		}
	}

	.text-title-sub {
		font-size: calc(21 / 32 * 1em);

		@media (--sm-lte) {
			font-size: calc(31 / 750 * 100vw);
		}
	}

	.text-center {
		text-align: center;

		tr {
			th,
			td {
				text-align: center;
			}
		}
	}

	.text-right {
		text-align: right;
	}

	.text-td-right {
		tr {
			td {
				text-align: right;
			}
		}
	}

	.text-link-icon {
		margin-left: 8px;
	}

	blockquote {
		background: $gray-color02;
		margin: 0;
		padding: 1em 2em;
		position: relative;

		&::before {
			content: '“';
			display: block;
			font-size: calc(40 / 16 * 1em);
			left: 0;
			line-height: 0;
			margin-top: 0.5em;
			opacity: 0.2;
			pointer-events: none;
			position: absolute;
			top: 0;
			user-select: none;
		}

		&::after {
			bottom: 0;
			content: '”';
			display: block;
			font-size: calc(40 / 16 * 1em);
			line-height: 0;
			opacity: 0.2;
			pointer-events: none;
			position: absolute;
			right: 0;
			user-select: none;
		}
	}

	figure {
		margin: 0;
	}

	figcaption {
		background: none;
		font-size: calc(14 / 16 * 1em);
		font-weight: normal;
		line-height: 1.6;
		padding: 0.8em 1.2em;
		text-align: left;
	}

	hr {
		color: $border-primary-color;
		background: $border-primary-color;
		border-style: none;
		height: 2px;
		margin: 50px auto;
		width: calc(95 / 100 * 100%);

		&:first-child {
			margin-top: 0;
		}
	}

	audio {
		@media (--sm-lte) {
			width: 100%;
		}
	}

	/**
	 * メールフォーム
	 *
	 */
	.cc-form-description {
		margin: 0 0 32px;
		letter-spacing: 0.1em;
		font-weight: 500;

		@media (--sm-lte) {
			margin: 0 0 calc(40 / 750 * 100vw);
		}

		&:first-child {
			margin-top: 57px;

			@media (--sm-lte) {
				margin-top: 0;
			}
		}

		span {
			color: $pink-color02;
		}

		h2 {
			[lang='en'] & {
				margin-bottom: 2.3em;
			}
		}

		h4 {
			font-size: 2.2rem;
			letter-spacing: 0.04em;
			padding-bottom: 9px;

			&::before {
				top: 4px;
			}

			&::before,
			&::after {
				height: calc(1 / 2 * 100% - 8px);
			}
		}
	}

	.cc-form {
		margin: 0 auto;

		&-agreement {
			text-align: center;
			margin-top: 51px;
			letter-spacing: 0.06em;

			@media (--sm-lte) {
				margin-top: calc(60 / 750 * 100vw);
			}

			a[target='_blank']::after {
				content: '';
				width: 13px;
				height: 13px;
				background: url("<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='13' height='13' viewBox='0 0 13 13' xml:space='preserve'><polygon points='10.5,13 0,13 0,2.5 1,2.5 1,12 10.5,12' fill='" + $primary-color + "'/><path d='M12,1v8H4V1H12 M13,0H3v10h10V0L13,0z' fill='" + $primary-color + "'/></svg>") no-repeat;
				display: inline-block;
				margin: 0 5px;
			}
		}

		&-submit {
			text-align: center;
			margin-top: 42px;

			@media (--sm-lte) {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				margin-top: calc(60 / 750 * 100vw);
			}

			button,
			a {
				display: inline-block;
				text-decoration: none;
				line-height: 1.15;
				margin: 0 7px;
				appearance: none;
				font-family: inherit;
				font-weight: 500;
				font-size: calc(17 / 16 * 1em);
				border: 0;
				min-width: 16.5em;
				padding: 0.9em 2.1em;
				letter-spacing: 0.08em;
				color: $lightest-color;
				background: $primary-color;
				position: relative;
				cursor: pointer;

				@media (--sm-gt) {
					&:hover {
						&::after {
							right: 12px;
						}
					}
				}

				@media (--sm-lte) {
					margin: calc(14 / 750 * 100vw);
				}

				[lang='en'] & {
					letter-spacing: 0.04em;
				}

				&::after {
					content: '';
					display: block;
					position: absolute;
					top: 0;
					bottom: 0;
					margin: auto 0;
					right: 17px;
					width: 7px;
					height: 7px;
					border-top: 1px solid #fff;
					border-right: 1px solid #fff;
					transform: rotate(45deg);
					transition: right 300ms;

					@media (--sm-lte) {
						right: calc(34 / 750 * 100vw);
						width: calc(14 / 750 * 100vw);
						height: calc(14 / 750 * 100vw);
					}
				}

				&:visited {
					color: $lightest-color !important; // stylelint-disable-line declaration-no-important
				}

				&.btn-em {
					background: $primary-color-darker3;
				}

				&.btn-back {
					background: $primary-color-darker4;

					@media (--sm-gt) {
						&:hover {
							&::before {
								left: 12px;
							}
						}
					}

					@media (--sm-lte) {
						order: 1;
					}

					&::after {
						display: none;
					}

					&::before {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						bottom: 0;
						margin: auto 0;
						left: 17px;
						width: 7px;
						height: 7px;
						border-top: 1px solid #fff;
						border-right: 1px solid #fff;
						transform: rotate(-135deg);
						transition: left 300ms;

						@media (--sm-lte) {
							left: calc(34 / 750 * 100vw);
							width: calc(14 / 750 * 100vw);
							height: calc(14 / 750 * 100vw);
						}
					}
				}
			}
		}
	}

	.cc-form-field-list {
		border-collapse: collapse;
		width: 100%;
		border-right: none;

		@media (--sm-lte) {
			display: block;
			border-bottom: 1px solid $gray-color03;

			tbody {
				display: block;
			}
		}

		tr {
			th,
			td {
				&:first-child {
					border-left: none;
				}
			}
		}

		ul {
			margin: 0;

			li {
				padding: 0;
				margin: 0;

				&::before {
					display: none;
				}
			}
		}
	}

	.cc-form-fieldset {
		@media (--sm-lte) {
			display: block;
		}

		&-heading {
			width: 240px;
			font-weight: bold;
			vertical-align: middle;
			text-align: left;
			padding: 0 0 0 48px;
			letter-spacing: 0.05em;
			background: $gray-color07;
			border-top: 1px solid $gray-color03;
			border-bottom: 1px solid $gray-color03;
			font-size: calc(15 / 16 * 1em);

			@media (--sm-lte) {
				width: auto;
				display: block;
				border-bottom: 0;
				padding: calc(20 / 750 * 100vw);
			}

			@media (--sm-gt) {
				[lang='en'] & {
					padding: 0 0 0 28px;
				}
			}

			.required,
			.optional {
				color: $lightest-color;
				display: inline-block;
				font-size: calc(14 / 15 * 1em);
				line-height: 1;
				padding: 0.3em;
				margin: 0 0 0 0.9em;

				[lang='en'] & {
					margin: 0 0 0 0.5em;
					letter-spacing: 0;
				}
			}

			.required {
				background: $pink-color02;
			}

			.optional {
				background: $darkest-color;
			}
		}

		&-attention {
			font-weight: normal;
			font-size: calc(13 / 15 * 1em);
			margin: 0.7em 0 0;
			line-height: 1.4;
			letter-spacing: 0.09em;

			[lang='en'] & {
				letter-spacing: 0.05em;
			}
		}

		&-body {
			padding: 26px 26px 25px;
			border-left: 0 !important; // stylelint-disable-line declaration-no-important
			border-top: 1px solid $gray-color03;
			border-bottom: 1px solid $gray-color03;
			font-size: calc(15 / 16 * 1em);

			@media (--sm-lte) {
				width: auto;
				display: block;
				padding: calc(50 / 750 * 100vw) 0;
				border-bottom: 0;
			}
		}
	}

	.alert-message {
		color: $warning-color;
	}

	.mail-field {
		margin: 0;
		padding: 0;
		display: inline-block;
		letter-spacing: 0.03em;

		& + .mail-field {
			margin-left: 15px;

			@media (--sm-lte) {
				[lang='en'] & {
					margin-left: 0;
					margin-top: calc(20 / 750 * 100vw);
				}
			}
		}

		&[data-type='checkbox'] {
			display: flex;
			flex-wrap: wrap;

			@media (--sm-lte) {
				margin-bottom: -10px;
			}
		}

		&[data-type='radio'] {
			display: block;
		}

		&[data-type='text'],
		&[data-type='textarea'],
		&[data-type='select'] {
			.mail-before-attachment {
				font-feature-settings: normal;
				margin: 0 0.3em 0 0;

				[lang='en'] & {
					margin: 0 0.5em 0 0;
				}

				&:empty {
					margin: 0;
				}
			}

			.mail-input {
				width: 639px;

				@media (--sm-lte) {
					width: calc(670 / 750 * 100vw);
				}

				&.input-s {
					display: inline-block;
					width: 190px;

					@media (--sm-lte) {
						width: calc(240 / 750 * 100vw);
					}
				}
			}

			.mail-after-attachment {
				grid-area: after-attachment;
				margin: 0 0 0 1em;

				&:empty {
					margin: 0;
				}
			}

			.mail-description {
				grid-area: description;
				font-size: calc(12 / 16 * 1em);
			}

			.error-message {
				grid-area: error-message;
				font-size: calc(12 / 16 * 1em);
				color: $warning-color;
			}
		}

		.mail-before-attachment {
			font-size: calc(15 / 16 * 1em);
		}

		.mail-input {
			display: block;
			letter-spacing: 0.1em;

			input:not([type='radio']):not([type='checkbox']),
			textarea {
				font-size: 1.6rem;
				font-family: inherit;
				max-width: 100%;
				background: $lightest-color;
				border: 1px solid $border-primary-color;
				border-radius: 0;
				padding: 0.35em 0.6em;

				&:focus {
					border-color: $btn-glow-color;
					box-shadow: 0 0 5px 1px $btn-glow-color, 0 0 5px 0 $lightest-color;
					outline: none;
				}

				&:not([size]) {
					width: 100%;
				}
			}

			textarea {
				resize: vertical;
				line-height: 1.5;

				&:not([cols]) {
					width: 100%;
				}
			}

			select {
				font-size: 1.6rem;
				max-width: 100%;
				padding: 0.35em calc(0.5em + 24px + 2px) 0.35em 0.6em;
				appearance: none;
				background: $lightest-color;
				background-image: url("<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' height='20' viewBox='0 0 20 20' xml:space='preserve'><rect width='20' height='20' fill='" + $secondary-color + "'/><polygon points='15.1,7.5 10.5,12.1 5.9,7.5 4.5,8.9 10.5,14.9 16.5,8.9' fill='" + $lightest-color + "'/></svg>") !important; // stylelint-disable-line declaration-no-important
				background-repeat: no-repeat;
				background-position: right calc(0.2em + 2px) center;
				border: 1px solid $gray-color03;
				border-radius: 0;

				@media (--sm-lte) {
					width: 100%;
				}

				&:focus {
					border-color: $btn-glow-color;
					box-shadow: 0 0 5px 1px $btn-glow-color, 0 0 5px 0 $lightest-color;
					outline: none;
				}

				&::-ms-expand {
					display: none;
				}
			}

			.-bc-form-element {
				select {
					display: none;

					@media (--sm-lte) {
						width: 100%;
					}

					&.-bc-os-i-os,
					&.-bc-os-android {
						display: block;
					}
				}

				&.-bc-os-i-os,
				&.-bc-os-android {
					.-bc-form-element {
						&.-bc-pseudo-select {
							display: none;
						}
					}
				}

				.-bc-form-element {
					&.-bc-pseudo-select {
						width: 465px;
						text-decoration: none;
						color: inherit;
						font-size: 1.5rem;
						line-height: 1.3;
						max-width: 100%;
						letter-spacing: 0.13em;
						padding: 0.35em calc(0.5em + 24px + 2px) 0.4em 0.6em;
						background: $lightest-color;
						border: 1px solid $gray-color03;
						padding-right: 50px;
						position: relative;
						display: inline-block;

						[lang='en'] & {
							letter-spacing: 0.053em;
						}

						&::before {
							content: '';
							display: block;
							width: 20px;
							height: 20px;
							background: $secondary-color;
							position: absolute;
							top: 0;
							bottom: 0;
							right: 5px;
							margin: auto 0;
							box-shadow: 0 1px 3px rgba(94, 94, 94, 0.14);
						}

						&::after {
							content: '';
							position: absolute;
							right: 11px;
							top: 0;
							bottom: 2px;
							margin: auto 0;
							display: block;
							width: 8px;
							height: 8px;
							border-top: 2px solid $lightest-color;
							border-right: 2px solid $lightest-color;
							transform: rotate(135deg);
						}

						&__selected-display {
							display: inline-block;
						}
					}

					.-bc-pseudo-select__option-list {
						display: none;
						margin: 0;
						position: absolute;
						top: 100%;
						left: 0;
						width: 640px;
						background: $lightest-color;
						border: 1px solid $gray-color03;
						box-shadow: 0 1px 3px rgba(94, 94, 94, 0.14);
						z-index: 10;

						@media (--sm-lte) {
							width: 100%;
						}

						li {
							width: calc(100% / 2);
							padding: 8px 10px;
							margin: 0;
							border-bottom: 1px solid $gray-color03;

							@media (--sm-lte) {
								width: 100%;
							}

							&:nth-child(2n + 1) {
								border-right: 1px solid $gray-color03;

								@media (--sm-lte) {
									border-right: 0;
								}
							}

							&:last-child,
							&:nth-last-child(2):nth-child(2n + 1) {
								border-bottom: 0;
							}

							&:nth-last-child(2):nth-child(2n + 1) {
								@media (--sm-lte) {
									border-bottom: 1px solid $gray-color03;
								}
							}

							&::before {
								display: none;
							}

							&:hover {
								background: $secondary-color;
								color: $lightest-color;
							}
						}
					}

					&.-bc-pseudo-select--focus {
						border-color: $btn-glow-color;
						box-shadow: 0 0 5px 1px $btn-glow-color, 0 0 5px 0 $lightest-color;
						outline: none;

						&::after {
							transform: rotate(-45deg);
							top: 4px;
							bottom: 0;
						}

						.-bc-pseudo-select__option-list {
							display: flex;
							flex-wrap: wrap;

							@media (--sm-lte) {
								display: block;
							}
						}
					}
				}
			}
		}

		.checkbox {
			margin-right: 26px;
			display: flex;
			justify-content: flex-start;
			align-items: center;

			@media (--sm-lte) {
				margin: 0 20px 10px 0;
			}

			input {
				display: block;
				line-height: 1;
				margin: 0;
				padding: 0;

				&[type='checkbox'] {
					appearance: none;
					position: absolute;
					width: 0;
					height: 0;
					opacity: 0;

					&:checked {
						& + label {
							&::after {
								content: '';
								display: block;
								border-left: 3px solid $secondary-color;
								border-bottom: 3px solid $secondary-color;
								width: 13px;
								height: 10px;
								transform: rotate(-45deg);
								position: absolute;
								left: 5px;
								top: 1em;
								margin-top: -7px;
							}
						}
					}

					&:focus {
						& + label {
							&::before {
								border-color: $btn-glow-color;
								box-shadow: 0 0 5px 1px $btn-glow-color, 0 0 5px 0 $lightest-color;
								outline: none;
							}
						}
					}

					& + label {
						cursor: pointer;
						padding-left: 29px;
						position: relative;

						&::before {
							content: '';
							display: block;
							border-radius: 0;
							width: 22px;
							height: 22px;
							border: 1px solid $gray-color03;
							position: absolute;
							left: 0;
							top: 1em;
							margin-top: -11px;
						}
					}
				}
			}
		}

		.mail-group-radio {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;

			@media (--sm-lte) {
				margin-bottom: -10px;
			}

			> span {
				margin-right: 26px;
				display: flex;
				justify-content: flex-start;
				align-items: center;
				flex-wrap: wrap;

				@media (--sm-lte) {
					margin: 0 20px 10px 0;
				}

				span {
					display: block;
					width: 100%;
					padding-left: 29px;
				}

				input {
					display: block;
					line-height: 1;
					margin: 0 0.5em 0 0;
					padding: 0;

					&[type='radio'] {
						appearance: none;
						position: absolute;
						width: 0;
						height: 0;
						opacity: 0;

						&:checked {
							& + label {
								&::after {
									content: '';
									display: block;
									background: $secondary-color;
									width: 8px;
									height: 8px;
									border-radius: 50%;
									position: absolute;
									left: 5px;
									top: 1em;
									margin-top: -4px;
								}
							}
						}

						&:focus {
							& + label {
								&::before {
									border-color: $btn-glow-color;
									box-shadow: 0 0 5px 1px $btn-glow-color, 0 0 5px 0 $lightest-color;
									outline: none;
								}
							}
						}

						& + label {
							cursor: pointer;
							padding-left: 29px;
							position: relative;

							&::before {
								content: '';
								display: block;
								border-radius: 50%;
								width: 18px;
								height: 18px;
								border: 1px solid $gray-color03;
								position: absolute;
								left: 0;
								top: 1em;
								margin-top: -9px;
							}
						}
					}
				}
			}
		}
	}

	//
	// 💊 ヘルパークラス
	//
	// 👇 以下 👇
	//
	// 🚫 削除非推奨 🚫
	//
	@for $value from 1 through 100 {
		.w-#{$value} {
			width: $value * 1% !important; // stylelint-disable-line
		}
	}

	@for $value from 1 through 100 {
		.w-pc-#{$value} {
			@media (--sm-gt) {
				width: $value * 1% !important; // stylelint-disable-line
			}
		}
	}

	@for $value from 1 through 100 {
		.w-sp-#{$value} {
			@media (--sm-lte) {
				width: $value * 1% !important; // stylelint-disable-line
			}
		}
	}

	@for $value from 1 through 100 {
		.w-img-#{$value} {
			img {
				width: $value * 1% !important; // stylelint-disable-line
			}
		}
	}

	@for $value from 1 through 100 {
		.w-pc-img-#{$value} {
			@media (--sm-gt) {
				img {
					width: $value * 1% !important; // stylelint-disable-line
				}
			}
		}
	}

	@for $value from 1 through 100 {
		.w-sp-img-#{$value} {
			@media (--sm-lte) {
				img {
					width: $value * 1% !important; // stylelint-disable-line
				}
			}
		}
	}

	%grid-frame {
		align-content: flex-start;
		align-items: flex-start;
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
	}

	.grid-frame {
		@extend %grid-frame;
	}

	.grid-frame-flex {
		@extend %grid-frame;
		flex-wrap: nowrap;
	}

	@for $i from 1 through 12 {
		.grid-xs-#{$i} {
			@media (--xs) {
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: calc(#{$i} / 12 * 100%);
			}
		}

		.grid-sm-#{$i} {
			@media (--sm) {
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: calc(#{$i} / 12 * 100%);
			}
		}

		.grid-md-#{$i} {
			@media (--md) {
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: calc(#{$i} / 12 * 100%);
			}
		}

		.grid-lg-#{$i} {
			@media (--lg) {
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: calc(#{$i} / 12 * 100%);
			}
		}

		.grid-xl-#{$i} {
			@media (--xl) {
				flex-grow: 0;
				flex-shrink: 0;
				flex-basis: calc(#{$i} / 12 * 100%);
			}
		}
	}

	.grid-xs-d5 {
		@media (--xs) {
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: calc(1 / 5 * 100%);
		}
	}

	.grid-sm-d5 {
		@media (--sm) {
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: calc(1 / 5 * 100%);
		}
	}

	.grid-md-d5 {
		@media (--md) {
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: calc(1 / 5 * 100%);
		}
	}

	.grid-lg-d5 {
		@media (--lg) {
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: calc(1 / 5 * 100%);
		}
	}

	.grid-xl-d5 {
		@media (--xl) {
			flex-grow: 0;
			flex-shrink: 0;
			flex-basis: calc(1 / 5 * 100%);
		}
	}

	.grid-xs-flex {
		@media (--xs) {
			flex: 0 1 100%;
		}
	}

	.grid-sm-flex {
		@media (--sm) {
			flex: 0 1 100%;
		}
	}

	.grid-md-flex {
		@media (--md) {
			flex: 0 1 100%;
		}
	}

	.grid-lg-flex {
		@media (--lg) {
			flex: 0 1 100%;
		}
	}

	.grid-xl-flex {
		@media (--xl) {
			flex: 0 1 100%;
		}
	}

	// マージンヘルパー
	.mt-zero {
		margin-top: 0;
	}

	.mb-zero {
		margin-bottom: 0;
	}

	.mt-narrow {
		// stylelint-disable at-rule-empty-line-before
		@media (--xs) {
			margin-top: $margin-narrow-xs;
		}

		@media (--sm) {
			margin-top: $margin-narrow-sm;
		}

		@media (--md) {
			margin-top: $margin-narrow-md;
		}

		@media (--lg) {
			margin-top: $margin-narrow-lg;
		}

		@media (--xl) {
			margin-top: $margin-narrow-xl;
		}
		// stylelint-enable at-rule-empty-line-before
	}

	.mt-wide {
		// stylelint-disable at-rule-empty-line-before
		@media (--xs) {
			margin-top: $margin-wide-xs;
		}

		@media (--sm) {
			margin-top: $margin-wide-sm;
		}

		@media (--md) {
			margin-top: $margin-wide-md;
		}

		@media (--lg) {
			margin-top: $margin-wide-lg;
		}

		@media (--xl) {
			margin-top: $margin-wide-xl;
		}
		// stylelint-enable at-rule-empty-line-before
	}

	.mb-narrow {
		// stylelint-disable at-rule-empty-line-before
		@media (--xs) {
			margin-bottom: $margin-narrow-xs;
		}

		@media (--sm) {
			margin-bottom: $margin-narrow-sm;
		}

		@media (--md) {
			margin-bottom: $margin-narrow-md;
		}

		@media (--lg) {
			margin-bottom: $margin-narrow-lg;
		}

		@media (--xl) {
			margin-bottom: $margin-narrow-xl;
		}
		// stylelint-enable at-rule-empty-line-before
	}

	.mb-wide {
		// stylelint-disable at-rule-empty-line-before
		@media (--xs) {
			margin-bottom: $margin-wide-xs;
		}

		@media (--sm) {
			margin-bottom: $margin-wide-sm;
		}

		@media (--md) {
			margin-bottom: $margin-wide-md;
		}

		@media (--lg) {
			margin-bottom: $margin-wide-lg;
		}

		@media (--xl) {
			margin-bottom: $margin-wide-xl;
		}
		// stylelint-enable at-rule-empty-line-before
	}
	//
	// 💊 ヘルパークラス
	//
	// 👆 以上 👆
	//

	//
	// 🍔 BurgerEditor用
	//
	// 👇 以下 👇
	//
	// 必要ない場合は削除する 🔪
	//
	[data-bgb] {
		// margin-bottom: $bgb-default-margin-bottom;
		margin: 0 0 2em !important; // stylelint-disable-line declaration-no-important
	}

	// ボタンタイプ
	.bgt-btn,
	.btn-link a {
		appearance: none;
		background: $primary-color;
		border: none;
		color: $lightest-color;
		display: inline-block;
		font-size: 1.7rem;
		line-height: 1.5;
		min-width: 15em;
		padding: 12px 30px;
		position: relative;
		text-align: center;
		text-decoration: none;
		vertical-align: middle;

		@media (--sm-lte) {
			font-size: calc(34 / 750 * 100vw);
			padding: calc(24 / 750 * 100vw) calc(60 / 750 * 100vw);
		}

		&:hover {
			@media (--sm-gt) {
				opacity: 0.7;
			}
		}

		&:visited {
			color: $lightest-color !important; // stylelint-disable-line declaration-no-important
		}

		&:active,
		&:focus {
			box-shadow: 0 0 10px 0 $btn-glow-color, 0 0 10px 0 $lightest-color;
		}

		&:disabled {
			opacity: 0.6;
		}

		&::after {
			border: 1px solid currentColor;
			border-width: 1px 1px 0 0;
			content: '';
			display: block;
			height: 8px;
			margin-top: -5px;
			position: absolute;
			right: 0.7em;
			top: 50%;
			transform: rotate(45deg);
			width: 8px;
		}

		&[target='_blank'] {
			padding: 12px 28px;

			&::after {
				background: url('/img/icon-external-nav-local-white.png');
				border: none;
				content: '';
				width: 13px;
				height: 13px;
				display: inline-block;
				transform: rotate(0);
			}
		}
	}

	[data-bgt-button-kind='link'] .bgt-btn {
		//
	}

	[data-bgt-button-kind='em'] .bgt-btn {
		background: $primary-color-darker3;
	}

	[data-bgt-button-kind='external'] .bgt-btn,
	.btn.external a {
		background: $lightest-color;
		color: $primary-color;
		border: 2px solid $primary-color;
		padding-left: 15px;
		padding-top: 10px;
		padding-bottom: 10px;

		&::after {
			transform: none;
			width: 13px;
			height: 13px;
			right: 15px;
			border: 0;
			background: url('/img/icon-external-btn.png') no-repeat;
		}

		&:visited {
			color: $primary-color !important; // stylelint-disable-line declaration-no-important
		}
	}

	[data-bgt-button-kind='back'] .bgt-btn {
		background: $primary-color-darker4;

		&::before {
			background: none;
			border: 1px solid currentColor;
			border-width: 0 0 1px 1px;
			content: '';
			display: block;
			height: 8px;
			left: 0.7em;
			margin-top: -5px;
			position: absolute;
			top: 50%;
			transform: rotate(45deg);
			width: 8px;
		}

		&::after {
			display: none;
		}
	}

	.bgt-hr {
		margin: 0 auto;
	}

	[data-bgt-hr-kind='dashed'] .bgt-hr {
		height: 0;
		border: none;
		background: none;
		border-bottom: 2px dashed currentColor;
	}

	[data-bgt-hr-kind='bold'] .bgt-hr {
		height: 5px;
	}

	[data-bgt-hr-kind='narrow'] .bgt-hr {
		height: 1px;
	}

	[data-bgt-hr-kind='short'] .bgt-hr {
		width: calc(60 / 100 * 100%);
		height: 1px;
	}

	// ダウンロードファイルタイプ
	.bgt-download-file__link,
	.btn-download a {
		appearance: none;
		background: $primary-color;
		border: none;
		color: $lightest-color;
		display: inline-block;
		font-size: calc(17 / 16 * 1em);
		line-height: 1.5;
		min-width: 15em;
		padding: 12px 55px;
		position: relative;
		text-align: center;
		text-decoration: none;
		vertical-align: middle;

		&:hover {
			@media (--sm-gt) {
				opacity: 0.7;
			}
		}

		&:visited {
			color: $lightest-color !important; // stylelint-disable-line declaration-no-important
		}

		&:active,
		&:focus {
			box-shadow: 0 0 10px 0 $btn-glow-color, 0 0 10px 0 $lightest-color;
		}

		&::before {
			content: none;
		}

		&::after {
			content: '';
			display: block;
			background: url('/img/icon-pdf-white.svg') no-repeat;
			background-size: cover;
			margin-top: -13px;
			position: absolute;
			right: 24px;
			top: 50%;
			width: 21px;
			height: 27px;
		}

		.bgt-link__icon {
			&::after {
				content: none !important; // stylelint-disable-line declaration-no-important
			}
		}
	}

	.bgt-link__size {
		font-size: calc(13 / 16 * 1em);
		font-weight: normal;
		opacity: 0.7;
	}

	.bgt-link__icon {
		&::after {
			vertical-align: middle;
		}
	}

	.anchor-link__btn5 {
		display: block;

		+ p {
			@media (--sm-lte) {
				margin-top: 2.4em; //
			}
		}

		&-inner03 {
			display: flex;
			justify-content: center;
			margin-left: 0 !important; // stylelint-disable-line declaration-no-important

			@media (--sm-lte) {
				display: block;
			}

			div {
				max-width: 300px;
				flex-grow: 1;

				@media (--sm-lte) {
					max-width: 400px;
					width: 100%;
					margin-right: auto;
					margin-left: auto;
				}

				+ div {
					@media (--sm-lte) {
						margin-top: 1.4em;
					}
				}
			}
		}

		&-inner02 {
			display: flex;
			justify-content: center;
			margin-left: 0 !important; // stylelint-disable-line declaration-no-important

			@media (--sm-lte) {
				display: block;
			}

			> div {
				max-width: 300px;
				min-width: 13em;
				flex-grow: 1;

				@media (--sm-lte) {
					max-width: 400px;
					min-width: auto;
					width: 100%;
					margin-right: auto;
					margin-left: auto;
				}

				&:nth-child(2n-1) {
					margin-left: 0 !important; // stylelint-disable-line declaration-no-important

					@media (--sm-lte) {
						margin-left: auto !important; // stylelint-disable-line declaration-no-important
					}
				}

				+ div {
					@media (--sm-lte) {
						margin-top: 1.4em;
					}
				}
			}
		}

		div {
			+ div {
				@media (--sm-gt) {
					margin-left: 1.4em;
				}
			}
		} //div

		> div {
			margin-top: 1.4em;

			&:first-child {
				margin-top: 0;
			}
		}

		a {
			width: 100%;
			height: 100%;
			appearance: none;
			background: $primary-color;
			border: none;
			color: $lightest-color;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.7rem;
			line-height: 1.5;
			padding: 12px 30px;
			position: relative;
			text-align: center;
			text-decoration: none;
			vertical-align: middle;

			@media (--sm-lte) {
				font-size: calc(34 / 750 * 100vw);
				padding: calc(24 / 750 * 100vw) calc(60 / 750 * 100vw);
			}

			&:hover {
				@media (--sm-gt) {
					opacity: 0.7;
				}
			}

			&:active,
			&:focus {
				box-shadow: 0 0 10px 0 $btn-glow-color, 0 0 10px 0 $lightest-color;
			}

			&:disabled {
				opacity: 0.6;
			}

			&::after {
				border: 1px solid currentColor;
				border-width: 1px 1px 0 0;
				content: '';
				display: block;
				height: 8px;
				margin-top: -5px;
				position: absolute;
				right: 0.7em;
				top: 50%;
				transform: rotate(45deg);
				width: 8px;
			}

			&[target='_blank'] {
				padding: 12px 28px;

				&::after {
					background: url('/img/icon-external-nav-local-white.png');
					border: none;
					content: '';
					width: 13px;
					height: 13px;
					display: inline-block;
					transform: rotate(0);
				}
			}
		}
	}

	// 1カラムテキスト用ボタン（5列）
	.bgb-button5 {
		.bgb-download-file3,
		.bgb-download-file2 {
			display: flex;
			justify-content: center;
			margin: 0 0 1.4em;

			@media (--sm-lte) {
				display: block;
			}

			.bgt-grid {
				float: none;
				width: auto;

				.bgt-btn {
					max-width: 300px;
					min-width: 13em;

					@media (--sm-lte) {
						max-width: 100%;
						margin-bottom: 1.4em;
					}
				}
			}
		}

		.bgb-download-file3 {
			@media (--sm-lte) {
				padding-bottom: 0;
				margin-bottom: 0;
			}

			.bgt-grid {
				&:nth-of-type(1),
				&:nth-of-type(2) {
					.bgt-btn {
						margin-right: 1.4em;

						@media (--sm-lte) {
							margin-right: 0;
						}
					}
				}
			}
		}

		.bgb-download-file2 {
			margin-bottom: 0;

			.bgt-grid {
				&:nth-of-type(1) {
					.bgt-btn {
						margin-right: 1.4em;

						@media (--sm-lte) {
							margin-right: 0;
							margin-bottom: 1.4em;
						}
					}
				}
			}
		}

		.bgt-container {
			@media (--sm-lte) {
				width: 100%;
			}
		}

		.bgt-btn {
			max-width: 300px;
			min-width: 13em;

			@media (--sm-lte) {
				width: 100%;
			}
		}
	}

	// Google Maps
	.bgt-google-maps-link {
		@media (--xs) {
			border: solid 1px $gray-color03;
			border-radius: 3px;
			color: $gray-color04;
			margin-top: 10px;
			padding: 1em;
			text-align: center;
			text-decoration: none;

			span {
				display: inline-block;
				vertical-align: middle;
			}

			&::after {
				@include bge-icon;
				content: $fa-external-link;
				font-size: 1em;
				vertical-align: middle;
			}
		}
	}

	// 画像リンクのフォーカス
	.bgt-image,
	.bgt-trimmed-image,
	.bgt-image-link,
	.bgt-trimmed-image-link {
		// stylelint-disable selector-pseudo-class-blacklist
		:link {
			outline: none; // 🍔 Burger ISSUE 👎
		}
		// stylelint-enable selector-pseudo-class-blacklist

		a {
			@media (--sm-gt) {
				&[href] {
					transition: opacity 300ms;

					&:hover {
						opacity: 0.6;
					}
				}
			}
		}
	}

	//
	// ブロックオプション用
	//

	// 背景色
	.bgb-opt--bg-gray,
	.bgb-opt--bg-blue,
	.bgb-opt--bg-pink {
		padding: $bgb-opt-padding;
	}

	.bgb-opt--bg-gray {
		background: $gray-color02 !important; // stylelint-disable-line declaration-no-important
	}

	.bgb-opt--bg-blue {
		background: $secondary-color-lighter3 !important; // stylelint-disable-line declaration-no-important
	}

	.bgb-opt--bg-pink {
		background: $pink-color !important; // stylelint-disable-line declaration-no-important
	}

	// 枠線 - 適用箇所
	.bgb-opt--border-trbl {
		border-color: $gray-color06;
		padding: $bgb-opt-padding;
	}

	.bgb-opt--border-td, // クラス名のタイポ // 🍔 Burger ISSUE 👎
	.bgb-opt--border-tb {
		border-color: $gray-color06;
		border-left-width: 0 !important; // stylelint-disable-line declaration-no-important
		border-right-width: 0 !important; // stylelint-disable-line declaration-no-important
		padding-bottom: $bgb-opt-padding;
		padding-top: $bgb-opt-padding;
	}

	.bgb-opt--border-lr {
		border-color: $gray-color06;
		padding-left: $bgb-opt-padding;
		padding-right: $bgb-opt-padding;
	}

	.bgb-opt--border-trl {
		border-color: $gray-color06;
		padding-left: $bgb-opt-padding;
		padding-right: $bgb-opt-padding;
		padding-top: $bgb-opt-padding;
	}

	.bgb-opt--border-rbl {
		border-color: $gray-color06;
		padding-bottom: $bgb-opt-padding;
		padding-left: $bgb-opt-padding;
		padding-right: $bgb-opt-padding;
	}

	.bgb-opt--border-bold {
		border-color: $gray-color06;
		border-width: 2px !important; // stylelint-disable-line declaration-no-important

		&.bgb-opt--border-trl {
			border-bottom-width: 0 !important; // stylelint-disable-line declaration-no-important
		}

		&.bgb-opt--border-rbl {
			border-top-width: 0 !important; // stylelint-disable-line declaration-no-important
		}

		&.bgb-opt--border-lr {
			border-top-width: 0 !important; // stylelint-disable-line declaration-no-important
			border-bottom-width: 0 !important; // stylelint-disable-line declaration-no-important
		}

		&.bgb-opt--border-tb {
			border-left-width: 0 !important; // stylelint-disable-line declaration-no-important
			border-right-width: 0 !important; // stylelint-disable-line declaration-no-important
		}
	}

	.bgb-opt--border-pink {
		border-color: $pink-color02;
	}

	// 余白

	.bgb-opt--mb-large {
		margin-bottom: 5em !important; // stylelint-disable-line declaration-no-important
		// margin-bottom: calc(#{$bgb-default-margin-bottom} * 3) !important; // stylelint-disable-line declaration-no-important

		@media (--sm-lte) {
			margin-bottom: 3em !important; // stylelint-disable-line declaration-no-important
		}
	}

	.bgb-opt--mb-small {
		margin-bottom: 1em !important; // stylelint-disable-line declaration-no-important
		// margin-bottom: calc(#{$bgb-default-margin-bottom} / 2) !important; // stylelint-disable-line declaration-no-important

		figcaption {
			@media (--sm-gt) {
				padding: 0.8em 1.2em 0 1.2em;
			}
		}
	}

	.bgb-opt--mb-none {
		margin-bottom: 0 !important; // stylelint-disable-line declaration-no-important

		//上抜けブロック内のリスト、上余白
		+ .bgb-opt--border-rbl {
			ul {
				margin-top: 0;
			}
		}
	}

	// 下抜けブロック下余白、左右のみ枠線ブロック下余白 ※中の要素の余白によって枠線が途切れないよう調整
	.bgb-opt--border-trl.bgb-opt--pb-wide,
	.bgb-opt--border-lr.bgb-opt--pb-wide {
		margin-bottom: 0;
		padding-bottom: 3rem;

		@media (--sm-lte) {
			padding-bottom: 1rem;
		}
	}

	.bgb-opt--border-trl.bgb-opt--pb-wide-smaller,
	.bgb-opt--border-lr.bgb-opt--pb-wide-smaller {
		margin-bottom: 0;
		padding-bottom: 1rem;

		@media (--sm-lte) {
			padding-bottom: 0.5rem;
		}
	}

	.bgb-opt--border-trl.bgb-opt--pb-wide-larger,
	.bgb-opt--border-lr.bgb-opt--pb-wide-larger {
		margin-bottom: 0;
		padding-bottom: 4.5rem;

		@media (--sm-lte) {
			padding-bottom: 2rem;
		}
	}

	// フロート回り込みあり
	[data-bgb='text-float-image1'] .bgt-grid--first {
		margin-left: 30px;

		@media (--sm-lte) {
			margin-bottom: 1.5em;
		}
	}

	[data-bgb='text-float-image2'] .bgt-grid--first {
		margin-right: 30px;
	}

	//フロート回り込みなし
	[data-bgb='text-image1'] .bgt-grid--first {
		padding-right: 15px;

		@media (--sm-lte) {
			padding-right: 0;
		}
	}

	[data-bgb='text-image1'] .bgt-grid--last {
		padding-left: 10px;

		@media (--sm-lte) {
			padding-left: 0;
		}
	}

	[data-bgb='text-image2'] .bgt-grid--first {
		float: left;
		padding-right: 15px;

		@media (--sm-lte) {
			padding-right: 0;
		}
	}

	[data-bgb='text-image2'] .bgt-grid--last {
		float: right;
		padding-left: 15px;

		@media (--sm-lte) {
			padding-left: 0;
		}
	}

	// 2カラムブロック
	[data-bgb='button2'] .bgt-grid:nth-child(1),
	[data-bgb='download-file2'] .bgt-grid:nth-child(1),
	[data-bgb='image-link-text2'] .bgt-grid:nth-child(1),
	[data-bgb='image-link2'] .bgt-grid:nth-child(1),
	[data-bgb='image-text2'] .bgt-grid:nth-child(1),
	[data-bgb='image2'] .bgt-grid:nth-child(1),
	[data-bgb='trimmed-image-link2'] .bgt-grid:nth-child(1),
	[data-bgb='trimmed-image2'] .bgt-grid:nth-child(1),
	[data-bgb='wysiwyg2'] .bgt-grid:nth-child(1) {
		padding-right: 15px;

		@media (--sm-lte) {
			padding-right: 0;
		}
	}

	[data-bgb='button2'] .bgt-grid:nth-child(2),
	[data-bgb='download-file2'] .bgt-grid:nth-child(2),
	[data-bgb='image-link-text2'] .bgt-grid:nth-child(2),
	[data-bgb='image-link2'] .bgt-grid:nth-child(2),
	[data-bgb='image-text2'] .bgt-grid:nth-child(2),
	[data-bgb='image2'] .bgt-grid:nth-child(2),
	[data-bgb='trimmed-image-link2'] .bgt-grid:nth-child(2),
	[data-bgb='trimmed-image2'] .bgt-grid:nth-child(2),
	[data-bgb='wysiwyg2'] .bgt-grid:nth-child(2) {
		padding-left: 15px;

		@media (--sm-lte) {
			padding-left: 0;
		}
	}

	// 3カラムブロック
	[data-bgb='wysiwyg3'] .bgt-grid {
		width: calc(100% * 0.3333);
		float: left;
		padding-left: calc(100px * 0.0666);
		padding-right: calc(100px * 0.0666);

		@media (--sm-lte) {
			width: 100%;
			float: none;
			padding-left: 0;
			padding-right: 0;
		}

		&:nth-child(1) {
			padding-left: 0;
			padding-right: calc(100px * 0.1333);

			@media (--sm-lte) {
				padding-right: 0;
			}
		}

		&:nth-child(3) {
			padding-left: calc(100px * 0.1333);
			padding-right: 0;

			@media (--sm-lte) {
				padding-left: 0;
			}
		}
	}

	[data-bgb='wysiwyg3'] .bgt-grid [data-bgt] > :last-child {
		@media (--sm-lte) {
			margin-bottom: 20px;
		}
	}

	[data-bgb='wysiwyg3'] .bgt-grid[class*='bgt-sp-grid'] [data-bgt] > :last-child {
		@media (--sm-lte) {
			margin-bottom: 0;
		}
	}

	[data-bgb='button3'],
	[data-bgb='download-file3'],
	[data-bgb='image3'],
	[data-bgb='image-link3'],
	[data-bgb='image-link-text3'],
	[data-bgb='image-text3'],
	[data-bgb='trimmed-image3'],
	[data-bgb='trimmed-image-link3'] {
		.bgt-grid {
			padding-left: 8px;
			padding-right: 8px;

			@media (--sm-lte) {
				padding: 0;
			}

			&:first-child {
				padding-left: 0;
				padding-right: 16px;

				@media (--sm-lte) {
					padding: 0;
				}
			}

			&:nth-child(3) {
				padding-left: 16px;
				padding-right: 0;

				@media (--sm-lte) {
					padding: 0;
				}
			}
		}
	}

	// Wysiwygタイプと画像の余白
	[data-bgt='ckeditor'] {
		&:nth-child(2) {
			margin-top: 1em;
		}
	}

	// Wysiwygの画像
	[data-bge='ckeditor'] {
		img {
			max-width: 100%;
			height: auto;
		}
	}

	// galleryタイプ
	[data-bgt='gallery'] {
		$thumb-size: calc(60 / 16 * 1em);

		.bgt-gallery {
			padding-top: calc(9 / 16 * 100%);

			&:hover {
				.bgt-gallery-ctrl {
					opacity: 1;
				}
			}

			&__img {
				img {
					object-fit: cover;
				}
			}

			&__caption {
				color: $lightest-color;
				background: color($darkest-color a(0.6));
				padding: 0.7em 1em;
				text-align: center;
			}

			&-ctrl {
				top: 50%;
				opacity: 0;
				transition: opacity 200ms ease-in;

				&__prev,
				&__next {
					display: block;
					appearance: none;
					margin: -20px 0 0;
					padding: 0;
					background: none;
					border: none;
					transform-origin: center center;

					&:hover {
						opacity: 0.5;
					}

					&:focus {
						outline: none;
					}

					span {
						display: block;
						overflow: hidden;
						width: 0;
						height: 0;
					}

					&::after {
						content: '';
						display: block;
						width: 40px;
						height: 40px;
						border: solid $lightest-color;
						border-width: 5px 5px 0 0;
					}
				}

				&__prev {
					left: 20px;
					transform: translate3d(0, 0, 0) rotate(225deg) !important; // stylelint-disable-line declaration-no-important
				}

				&__next {
					right: 20px;
					transform: translate3d(0, 0, 0) rotate(45deg) !important; // stylelint-disable-line declaration-no-important
				}
			}
		}

		.bgt-gallery-marker {
			li {
				&::before {
					display: none;
				}
			}
		}

		[data-gallery-marker='thumbs'] {
			+ .bgt-gallery-marker {
				margin-top: 10px;

				li {
					margin: 0 5px;
					width: $thumb-size;
					height: $thumb-size;
				}
			}
		}

		[data-gallery-marker='dot'] {
			+ .bgt-gallery-marker {
				margin-top: 10px;

				li {
					margin: 0 2px;
					width: 10px;
					height: 10px;
					background-color: $primary-color;
					border-radius: 100%;
					opacity: 0.6;

					&.current {
						opacity: 1;
					}
				}
			}
		}
	}

	//
	// 👆 以上 👆
	//
	// 🍔 BurgerEditor用
	//

	//
	// 👇 以下 👇
	//
	// 🐲 baserCMS用
	//
	// 必要ない場合は削除する 🔪
	//
	.bc-heading {
		h2 {
			//
		}
	}

	.bc-time {
		margin-bottom: 5em;
		text-align: right;

		time {
			//
		}
	}

	.bc-cat {
		margin-bottom: 1em;
		text-align: right;

		> div {
			background: $primary-color;
			color: $lightest-color;
			display: inline-block;
			font-size: calc(13 / 16 * 1em);
			padding: 0.2em 0.4em;
		}
	}

	.bc-area-cta {
		margin-top: 4em;
		text-align: center;

		a {
			background: $primary-color;

			span {
				&::before {
					@include icon;
					color: $gray-color03;
					content: $glyph-email;
					margin-right: 0.5em;
				}
			}
		}
	}

	.bc-area-ctrl {
		margin-top: 6em;

		@media (--xs) {
			text-align: center;
		}
	}

	.bc-form-table {
		//
	}

	.bc-form-agreement {
		margin-top: 4em;
		text-align: center;
	}

	.bc-form-submit {
		margin-top: 3em;
		text-align: center;
	}

	.bgb-text-image1 {
		.bgt-grid--first {
			@media (--sm-lte) {
				margin-bottom: 20px;
			}
		}
	}

	.bgb-text-image2 {
		.bgt-grid--first {
			@media (--sm-lte) {
				margin-bottom: 20px;
			}
		}
	}

	.news-sns {
		display: flex;
		justify-content: center;
		margin: 60px 0 30px;

		@media (--sm-lte) {
			margin: calc(100 / 750 * 100vw) 0 calc(60 / 750 * 100vw);
		}

		ul {
			display: flex;
			padding-left: 0;
			border-bottom: 1px solid $gray-color03;
			margin: 0;

			li {
				padding: 0 !important; // stylelint-disable-line declaration-no-important
				margin: 0 6px 7px !important; // stylelint-disable-line declaration-no-important

				@media (--sm-lte) {
					margin: 0 calc(10 / 750 * 100vw) calc(14 / 750 * 100vw) !important; // stylelint-disable-line declaration-no-important
				}

				&::before {
					display: none !important; // stylelint-disable-line declaration-no-important
				}
			}
		}
	}

	//
	// 独自パーツ
	//

	// 大学学生数
	.table-students {
		table {
			font-size: 1.3rem;

			th {
				text-align: center;
			}

			th,
			td {
				padding: 0.5em 0.4em;
			}
		}
	}

	// テキスト＋youtube
	.text-youtube {
		@media (--sm-gt) {
			&[data-bgb='wysiwyg2'] {
				.bgt-grid--first {
					padding-right: 20px;
				}

				.bgt-grid--last {
					padding-left: 20px;
				}
			}
		}

		figure {
			margin: 0;

			> div {
				width: 100%;
				padding-top: 56.25%;
				position: relative;

				iframe {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}

	// 2カラムyoutube
	.col2-youtube {
		@media (--sm-gt) {
			&[data-bgb='wysiwyg2'] {
				.bgt-grid--first {
					padding-right: 20px;
				}

				.bgt-grid--last {
					padding-left: 20px;
				}
			}
		}

		@media (--sm-lte) {
			.bgt-grid [data-bgt] > :last-child {
				margin-bottom: 30px;
			}
		}

		h4 {
			font-size: 2.2rem;
			border-bottom: none;
			line-height: 1.4;
			padding: 0 0 20px;
			margin: 0.7em 0 10px;

			@media (--sm-lte) {
				font-size: calc(42 / 750 * 100vw);
				padding: 0 0 calc(30 / 750 * 100vw);
			}

			&::before,
			&::after {
				content: '';
				position: absolute;
				display: block;
				height: 2px;
				top: auto;
				bottom: 0;
				left: 0;
			}

			&::before {
				background: $primary-color-lighter;
				width: 100%;
			}

			&::after {
				background: $secondary-color;
				width: 120px;
			}
		}

		figure {
			margin: 0;

			> div {
				width: 100%;
				padding-top: 56.25%;
				position: relative;

				iframe {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 0;
					left: 0;
				}
			}

			figcaption {
				background: $gray-color02;
				font-size: 1.6rem;
				font-weight: 500;

				@media (--sm-lte) {
					font-size: calc(32 / 750 * 100vw);
				}

				strong {
					font-size: 1.8rem;

					@media (--sm-lte) {
						font-size: calc(36 / 750 * 100vw);
					}
				}
			}
		}
	}

	// ポップアップ
	.youtube-popup-btn {
		.bgt-btn {
			padding: 12px 55px;

			@media (--sm-lte) {
				padding: calc(24 / 750 * 100vw) calc(110 / 750 * 100vw);
			}

			&::after {
				border: none;
				right: 20px;
				width: 30px;
				height: 22px;
				background: url('/img/icon-youtube-btn.png') no-repeat center center / 100% 100%;
				transform: rotate(0deg);
				margin-top: -11px;
			}
		}
	}

	// FAQ
	.faq-title {
		position: relative;
		margin: 0;
		cursor: pointer;

		&::before {
			content: 'Q';
			display: block;
			font-size: 2rem;
			font-family: $font-family-secondary;
			color: $secondary-color;
			position: absolute;
			top: 7px;
			left: 35px;
			z-index: 10;
			font-weight: bold;

			@media (--sm-lte) {
				top: calc(14 / 750 * 100vw);
				left: calc(30 / 750 * 100vw);
				font-size: calc(40 / 750 * 100vw);
			}
		}

		h2 {
			font-size: 1.8rem;
			font-weight: bold;
			border-bottom: none;
			background: $gray-color02;
			padding: 13px 40px 13px 64px;

			@media (--sm-lte) {
				padding: calc(26 / 750 * 100vw) calc(95 / 750 * 100vw) calc(26 / 750 * 100vw) calc(90 / 750 * 100vw);
				font-size: calc(34 / 750 * 100vw);
			}

			&::before,
			&::after {
				content: '';
				height: 2px;
				width: 15px;
				position: absolute;
				left: inherit;
				right: 19px;
				top: 0;
				bottom: 0;
				margin: auto 0;
				z-index: 10;
				border: none;
				transform: none;
				transition: transform 300ms;
				background: $secondary-color;
			}

			&::after {
				transform: rotate(90deg);
			}
		}

		&.open {
			h2::after {
				transform: rotate(0deg);
			}
		}
	}

	.faq-body {
		padding: 0 30px 30px 64px;
		position: relative;

		@media (--sm-lte) {
			padding: 0 0 calc(26 / 750 * 100vw) calc(90 / 750 * 100vw);
		}

		&::before {
			content: 'A';
			display: block;
			font-size: 2rem;
			font-family: $font-family-secondary;
			position: absolute;
			top: -5px;
			left: 35px;
			z-index: 10;
			font-weight: bold;

			@media (--sm-lte) {
				top: calc(-14 / 750 * 100vw);
				left: calc(30 / 750 * 100vw);
				font-size: calc(40 / 750 * 100vw);
			}
		}
	}

	// タブ
	.tab-nav {
		ul {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			position: relative;

			@media (--sm-lte) {
				margin: 0 calc(-5 / 750 * 100vw);
			}

			&::before {
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				left: 5px;
				right: 5px;
				height: 1px;
				background: $primary-color-darker5;

				@media (--sm-lte) {
					left: calc(5 / 750 * 100vw);
					right: calc(5 / 750 * 100vw);
				}
			}

			li {
				flex: 1 1 100%;
				padding: 0 5px;
				margin: 0;

				@media (--sm-lte) {
					padding: 0 calc(5 / 750 * 100vw);
				}

				&::before {
					content: none;
				}

				a {
					display: block;
					color: $primary-color-darker5;
					text-align: center;
					text-decoration: none;
					padding: 8px;
					border: 1px solid $primary-color-darker5;
					border-width: 1px 1px 0;
					border-radius: 5px 5px 0 0;
					position: relative;
					z-index: 10;

					@media (--sm-lte) {
						padding: calc(8.25 / 750 * 100vw);
						font-size: calc(30 / 750 * 100vw);
					}
				}

				&:not(.tab-nav-current) {
					@media (--sm-gt) {
						a {
							transition: color 300ms, background 300ms;
						}

						&:hover {
							a {
								color: $lightest-color;
								background: $primary-color-darker5;
							}
						}
					}
				}

				&.tab-nav-current {
					a {
						border-bottom: 3px solid $lightest-color;
						font-size: 1.7rem;
						font-weight: bold;
						padding: 10px;
						margin-bottom: -1px;

						@media (--sm-lte) {
							font-size: calc(34 / 750 * 100vw);
							padding: calc(20 / 750 * 100vw);
						}
					}
				}
			}
		}
	}

	.tab-switch {
		position: relative;

		ul {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			padding: 0;
			margin: 0 5px;
			overflow: auto;

			@media (--sm-lte) {
				margin: 0 calc(-5 / 750 * 100vw);
			}

			&::before {
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				left: 10px;
				right: 10px;
				height: 1px;
				background: $primary-color-darker5;

				@media (--sm-lte) {
					left: 0;
					right: 0;
				}
			}

			li {
				flex: 1 1 100%;
				padding: 0 5px;
				margin: 0;

				@media (--sm-lte) {
					padding: 0 calc(5 / 750 * 100vw);
				}

				&::before {
					content: none;
				}

				a {
					display: block;
					color: $primary-color-darker5;
					text-align: center;
					text-decoration: none;
					padding: 8px;
					border: 1px solid $primary-color-darker5;
					border-width: 1px 1px 0;
					border-radius: 5px 5px 0 0;
					position: relative;
					z-index: 10;
					white-space: nowrap;
					cursor: pointer;

					@media (--sm-lte) {
						padding: calc(8.25 / 750 * 100vw);
						font-size: calc(30 / 750 * 100vw);
					}
				}

				_:-ms-lang(x)::-ms-backdrop,
				a {
					white-space: normal;
				}

				&:not(.tab-switch-current) {
					@media (--sm-gt) {
						a {
							transition: all 300ms;
						}

						&:hover {
							a {
								color: $lightest-color;
								background: $primary-color-darker5;
							}
						}
					}
				}

				&.tab-switch-current {
					a {
						border-bottom: 3px solid $lightest-color;
						font-size: 1.7rem;
						font-weight: bold;

						@media (--sm-lte) {
							font-size: calc(34 / 750 * 100vw);
							padding: calc(20 / 750 * 100vw);
						}
					}
				}
			}
		}

		&-area {
			display: none;
			opacity: 0;

			&.active {
				display: block;
				animation-name: displayAnime;
				animation-duration: 200ms;
				animation-fill-mode: forwards;
			}

			@keyframes displayAnime {
				from {
					opacity: 0;
				}

				to {
					opacity: 1;
				}
			}
		}
	}

	// 横並びボタン
	.btn-side-list {
		ul {
			margin: 0 5px;
			display: flex;
			justify-content: flex-start;
			align-items: stretch;
			flex-wrap: wrap;

			@media (--sm-lte) {
				margin: 0;
				flex-wrap: nowrap;

				&.sp-wrap {
					flex-wrap: wrap;
				}
			}

			li {
				padding: 0;
				display: flex;
				justify-content: flex-start;
				align-items: stretch;
				margin: 0 1px 1px 0;

				&::before {
					content: none;
				}

				a {
					appearance: none;
					background: $primary-color;
					border: 2px solid $primary-color;
					color: $lightest-color;
					display: flex;
					align-items: center;
					justify-content: center;
					width: 100%;
					font-size: 1.4rem;
					line-height: 1.5;
					padding: 10px 28px 10px 13px;
					position: relative;
					text-align: center;
					text-decoration: none;
					vertical-align: middle;

					@media (--sm-gt) {
						transition: opacity 300ms;

						&:hover {
							opacity: 0.6;
						}
					}

					@media (--sm-lte) {
						padding: calc(7 / 750 * 100vw) calc(25 / 750 * 100vw) calc(7 / 750 * 100vw) calc(6 / 750 * 100vw);
						font-size: calc(24 / 750 * 100vw);
					}

					&::after {
						border: 1px solid currentColor;
						border-width: 1px 1px 0 0;
						content: '';
						display: block;
						height: 8px;
						margin-top: -5px;
						position: absolute;
						right: 0.7em;
						top: 50%;
						transform: rotate(45deg);
						width: 8px;

						@media (--sm-lte) {
							right: calc(10 / 750 * 100vw);
							width: calc(12 / 750 * 100vw);
							height: calc(12 / 750 * 100vw);
							margin-top: -2px;
						}
					}

					&:visited {
						color: $lightest-color !important; // stylelint-disable-line declaration-no-important
					}
				}

				&.btn-current {
					a {
						background: $lightest-color;
						color: $primary-color;

						&:visited {
							color: $primary-color !important; // stylelint-disable-line declaration-no-important
						}
					}
				}
			}
		}
	}

	// インデックス
	.index-parts {
		@media (--sm-gt) {
			&[data-bgb='image-link2'] {
				.bgt-grid--first {
					padding-right: 20px;
				}

				.bgt-grid--last {
					padding-left: 20px;
				}
			}
		}

		a {
			transition: opacity 300ms;

			&:hover {
				opacity: 0.6;
			}
		}

		.bgt-box__caption {
			background: $gray-color02;
			border-bottom: 2px solid $primary-color;
			font-size: 1.7rem;
			text-align: center;
			padding: 10px 25px;
			color: $primary-color;
			font-weight: bold;
			position: relative;

			@media (--sm-lte) {
				font-size: calc(30 / 750 * 100vw);
				padding: calc(20 / 750 * 100vw) calc(40 / 750 * 100vw);
			}

			&::before,
			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				margin: auto 0;
			}

			&::before {
				width: 16px;
				height: 16px;
				background: $primary-color;
				right: 0;

				@media (--sm-lte) {
					width: calc(32 / 750 * 100vw);
					height: calc(32 / 750 * 100vw);
				}
			}

			&::after {
				width: 7px;
				height: 7px;
				border-top: 2px solid $lightest-color;
				border-right: 2px solid $lightest-color;
				transform: rotate(45deg);
				right: 6px;

				@media (--sm-lte) {
					width: calc(14 / 750 * 100vw);
					height: calc(14 / 750 * 100vw);
					right: calc(12 / 750 * 100vw);
				}
			}
		}
	}

	// インデックス（タグ付き）
	.tags {
		[data-bgt='image'] {
			.bgt-image {
				&:first-child {
					@media (--sm-lte) {
						margin-bottom: 0;
					}
				}
			}
		}

		.tags-ttl {
			font-size: 1.4rem;
			font-weight: bold;
			margin: calc(-5 / 14 * 1em) 0 calc(6 / 14 * 1em);

			@media (--sm-lte) {
				font-size: calc(14 / 375 * 100vw);
			}
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;
			display: flex;
			flex-wrap: wrap;

			li {
				padding: calc(5 / 13 * 1em) calc(10 / 13 * 1em);
				border: 1px solid $primary-color;
				margin-bottom: 5px;
				font-size: 1.3rem;
				margin-top: 0;

				@media (--sm-lte) {
					font-size: calc(13 / 375 * 100vw);
					margin-bottom: calc(5 / 375 * 100vw);
				}

				&:not(:last-child) {
					margin-right: 5px;

					@media (--sm-lte) {
						margin-right: calc(5 / 375 * 100vw);
					}
				}

				&:before {
					content: none;
				}
			}
		}
	}

	// RELATED PAGES
	.related-post {
		margin: 80px 0 0;

		@media (--sm-lte) {
			margin: calc(80 / 750 * 100vw) 0 0;
		}

		h2 {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin: 0;
			line-height: 1;
			font-size: 2.6rem;
			font-weight: bold;
			font-family: $font-family-secondary;
			letter-spacing: 0.075em;

			@media (--sm-lte) {
				font-size: calc(36 / 750 * 100vw);
				letter-spacing: normal;
			}

			&::before,
			&::after {
				content: '';
				display: block;
				flex: 0 0 auto;
				width: 20px;
				height: 3px;
				background: $primary-color-darker;
				margin-right: 7px;
				position: relative;

				@media (--sm-lte) {
					width: calc(20 / 750 * 100vw);
					height: 2px;
					margin-right: calc(5 / 750 * 100vw);
				}
			}

			&::after {
				content: '';
				flex: 1 1 auto;
				margin-left: 7px;
				margin-right: 0;

				@media (--sm-lte) {
					margin-right: 0;
					margin-left: calc(20 / 750 * 100vw);
				}
			}
		}

		&-body {
			padding: 40px 30px 10px;
			background: $gray-color02;
			margin-top: -22px;

			@media (--sm-lte) {
				padding: calc(50 / 750 * 100vw) calc(30 / 750 * 100vw) calc(20 / 750 * 100vw);
				margin-top: calc(-36 / 750 * 100vw);
			}

			ul {
				margin: 0;

				&::after {
					content: '';
					display: block;
					clear: both;
				}

				li {
					padding: 0;
					margin: 0;

					@media (--sm-gt) {
						margin-bottom: 15px;
						padding: 0 10px;
						width: calc(100% / 3);
						float: left;

						&:nth-child(3n + 1) {
							padding: 0 20px 0 0;
						}

						&:nth-child(3n) {
							padding: 0 0 0 20px;
						}

						a {
							display: block;
							transition: opacity 300ms;

							&:hover {
								opacity: 0.6;
							}
						}
					}

					@media (--sm-lte) {
						margin-bottom: calc(36 / 750 * 100vw);

						a {
							display: flex;
							justify-content: flex-start;
							align-items: flex-start;

							figure {
								flex: 0 0 auto;
								width: calc(200 / 750 * 100vw);
							}

							.related-body-text {
								margin-top: calc(-15 / 750 * 100vw);
								margin-left: calc(20 / 750 * 100vw);
							}
						}

						.bge-ckeditor:last-child {
							margin-bottom: calc(30 / 750 * 100vw);
						}
					}

					&::before {
						display: none;
					}

					a {
						text-decoration: none;
						color: inherit;

						figure {
							img {
								width: 100%;
							}
						}
					}
				}
			}

			h6 {
				padding: 0;
				margin: 0;
				font-size: 1.6rem;

				@media (--sm-lte) {
					font-size: calc(28 / 750 * 100vw);
				}

				&::before {
					content: none;
				}
			}

			p {
				margin: 0;
				font-size: 1.4rem;
				line-height: 1.6;

				@media (--sm-lte) {
					font-size: calc(26 / 750 * 100vw);
				}
			}
		}
	}

	// カラムリスト
	.flex-column-list {
		ul {
			@media (--sm-gt) {
				column-count: 3;
				margin-left: 20px;
				margin-right: 20px;

				li {
					margin: 0 0 15px;
				}
			}

			@media (--sm-lte) {
				column-count: 2;
				margin-left: calc(20 / 750 * 100vw);
				margin-right: calc(20 / 750 * 100vw);
			}

			li {
				font-weight: 500;
				page-break-inside: avoid;
				break-inside: avoid;

				@media (--sm-lte) {
					padding-bottom: 8px;
					margin: 0;
				}
			}
		}
	}

	// データパーツ
	.data-parts {
		@media (--sm-gt) {
			&[data-bgb='text-image2'] {
				display: flex;
				align-items: center;

				.bgt-grid--first {
					padding-right: 20px;
				}

				.bgt-grid--last {
					padding-left: 20px;
				}
			}
		}

		@media (--sm-lte) {
			&[data-bgb='text-image2'] {
				.bgt-grid--first {
					margin-bottom: calc(40 / 750 * 100vw);
				}
			}
		}

		.bgt-grid--last {
			.bge-ckeditor {
				display: flex;
				justify-content: space-between;
				align-items: flex-start;
				flex-wrap: wrap;

				@media (--sm-gt) {
					margin-right: 45px;
				}

				dl {
					display: flex;
					justify-content: space-between;
					align-items: flex-start;
					margin: 15px 0;
					padding: 0 12px 10px 12px;
					border-bottom: 1px solid $gray-color03;
					position: relative;

					@media (--sm-gt) {
						width: calc(100% / 2 - 20px);
					}

					@media (--sm-lte) {
						width: calc(100% / 2 - (40 / 750 * 100vw));
						margin: calc(14 / 750 * 100vw) calc(20 / 750 * 100vw);
						padding: 0 0 calc(10 / 750 * 100vw) 0;
					}

					&::before {
						content: '';
						display: block;
						width: 136px;
						height: 1px;
						position: absolute;
						bottom: -1px;
						left: 0;
						background: $secondary-color-lighter7;

						@media (--sm-lte) {
							width: calc(200 / 750 * 100vw);
						}
					}

					dt {
						flex: 1 1 auto;
						margin-right: 14px;
						font-size: 1.6rem;
						font-weight: bold;

						@media (--sm-lte) {
							margin-right: calc(7.5 / 750 * 100vw);
							font-size: calc(28 / 750 * 100vw);
						}
					}

					dd {
						flex: 0 0 auto;
						margin: 0;
						font-weight: bold;

						@media (--sm-lte) {
							font-size: calc(24 / 750 * 100vw);
						}

						strong {
							font-size: 2.4rem;
							font-family: $font-family-secondary;
							line-height: 1.3;

							@media (--sm-lte) {
								font-size: calc(40 / 750 * 100vw);
							}
						}
					}
				}
			}
		}
	}

	// インデックス リンクリスト
	.index-list-parts {
		@media (--sm-gt) {
			&[data-bgb='wysiwyg2'] {
				.bgt-grid--first {
					padding-left: 0;
					padding-right: 20px;
				}

				.bgt-grid--last {
					padding-right: 0;
					padding-left: 20px;
				}
			}

			&[data-bgb='wysiwyg3'] {
				.bgt-grid {
					padding-left: 15px;
					padding-right: 15px;
				}

				.bgt-grid--first {
					padding-left: 0;
					padding-right: 30px;
				}

				.bgt-grid--last {
					padding-right: 0;
					padding-left: 30px;
				}

				[data-path='/nyushi/'] & {
					.bgt-grid:nth-last-child(3) {
						width: 100%;

						ul {
							columns: 3;
							margin-right: -15px;

							li {
								padding-right: 15px;
							}
						}
					}
				}
			}
		}

		&:nth-child(3) {
			&[data-bgb='wysiwyg3'] {
				[data-path='/nyushi/'] & {
					.bgt-grid:first-child {
						float: right;
						margin-top: -656px;

						@media (--sm-lte) {
							float: none;
							margin-top: 0;
						}
					}
				}
			}
		}

		ul {
			margin: 0 10px 32px;
			padding-left: 0;

			@media (--sm-lte) {
				margin-bottom: 30px;
			}

			li {
				position: relative;
				padding-left: 0;
				margin: 0 0 11px;
				font-size: 1.6rem;

				@media (--sm-lte) {
					margin: calc(22 / 750 * 100vw) 0;
					font-size: calc(30 / 750 * 100vw);
				}

				&:first-child {
					margin-top: 0;
				}

				&::before {
					content: none;
				}

				&.menu-text {
					ul {
						margin: 0 0 0 1.4em;

						@media (--sm-lte) {
							margin: 0 0 0 1.2em;
						}
					}
				}

				a {
					color: $darkest-color;
					text-decoration: none;
					display: inline-block;
					padding-left: 23px;

					@media (--sm-gt) {
						transition: color 300ms;

						&::before {
							transition: background-color 300ms;
						}

						&:hover {
							color: $secondary-color;
							text-decoration: underline;

							&::before {
								background-color: $secondary-color;
							}
						}
					}

					@media (--sm-lte) {
						padding-left: calc(46 / 750 * 100vw);
					}

					&::before {
						content: '';
						display: block;
						position: absolute;
						margin: auto 0;
						width: 14px;
						height: 14px;
						border-radius: 50%;
						left: 0;
						top: 6px;
						background: url('/img/icon-arrow-w.png') no-repeat center center / 6px auto;
						background-color: $primary-color;

						@media (--sm-lte) {
							top: calc(10 / 750 * 100vw);
							width: calc(28 / 750 * 100vw);
							height: calc(28 / 750 * 100vw);
							left: 0;
							background-size: calc(12 / 750 * 100vw) auto;
						}
					}
				}
			}
		}

		h2 {
			font-size: 2.2rem;
			font-weight: normal;
			line-height: 1.5;
			background: $gray-color02;
			margin: 1em 0 25px;
			padding: 8px 15px;
			display: flex;
			align-items: center;
			border-left: 2px solid $secondary-color;

			@media (--sm-lte) {
				font-size: calc(44 / 750 * 100vw);
			}

			&:first-child {
				margin-top: 0;
			}

			&::before,
			&::after {
				display: none;
			}

			[lang='en'] & {
				font-size: 2.4rem;
				padding-top: 10px;
				padding-bottom: 10px;
				letter-spacing: 0.05em;

				@media (--sm-lte) {
					font-size: calc(44 / 750 * 100vw);
				}
			}
		}
	}

	.banner-box {
		text-align: center;
		display: flex;
		justify-content: center;

		p {
			margin: 20px;

			a {
				display: block;
				transition: opacity 300ms;

				@media (--sm-gt) {
					&:hover {
						opacity: 0.6;
					}
				}
			}
		}
	}

	// アコーディオン
	.acc-title {
		h2,
		&.acc-title-btn .bgt-btn {
			font-size: 1.8rem;
			font-weight: bold;
			padding-right: 40px;
			border-bottom: 1px solid $secondary-color-lighter4;
			transition: opacity 300ms;

			@media (--sm-gt) {
				&:hover {
					cursor: pointer;
					opacity: 0.6;
				}
			}

			@media (--sm-lte) {
				padding-right: calc(100 / 750 * 100vw);
			}

			&::before,
			&::after {
				content: '';
				height: 1px;
				width: 21px;
				position: absolute;
				left: inherit;
				right: 19px;
				top: 0;
				bottom: 0;
				margin: auto 0;
				z-index: 10;
				border: none;
				transform: none;
				transition: transform 300ms;
				background: $secondary-color;

				@media (--sm-lte) {
					bottom: 13px;
				}
			}

			&::after {
				transform: rotate(90deg);
			}
		}

		&.open {
			h2,
			.bgt-btn {
				&::after {
					transform: rotate(0deg);
				}
			}

			&.bgb-opt--mb-large {
				margin-bottom: 0 !important; // stylelint-disable-line declaration-no-important
			}
		}

		&.acc-title-btn {
			display: table;
			margin-left: auto !important; // stylelint-disable-line declaration-no-important
			margin-right: auto !important; // stylelint-disable-line declaration-no-important
			.bgt-btn {
				&::before,
				&::after {
					background: $lightest-color;

					@media (--sm-lte) {
						bottom: 0;
					}
				}
			}
		}
	}

	.acc-body {
		@media (--sm-gt) {
			&[data-bgb='text-image2'] {
				.bgt-grid--first {
					padding-right: 20px;
				}

				.bgt-grid--last {
					padding-left: 20px;
				}
			}
		}

		@media (--sm-lte) {
			&[data-bgb='text-image2'] {
				.bgt-grid--first {
					margin-bottom: calc(40 / 750 * 100vw);
				}
			}
		}

		h6 {
			display: inline-block;
			margin-bottom: 0;

			+ p {
				margin-top: 0;

				@media (--sm-gt) {
					margin-left: 20px;
				}
			}
		}

		&.pc_only {
			figure {
				@media (--sm-lte) {
					display: none;
				}
			}
		}

		&.sp_only {
			figure {
				@media (--sm-gt) {
					display: none;
				}
			}
		}
	}

	// 年間スケジュールアコーディオン
	[data-path='/gakusei/nittei/'] & {
		.acc-title {
			background: $primary-color;
			position: relative;
			cursor: pointer;

			&::before,
			&::after {
				content: '';
				height: 2px;
				width: 16px;
				position: absolute;
				left: inherit;
				right: 20px;
				top: 0;
				bottom: 0;
				margin: auto 0;
				z-index: 10;
				border: none;
				transform: none;
				transition: transform 300ms;
				background: $secondary-color;
			}

			&::after {
				transform: rotate(90deg);
			}

			&.open {
				&::after {
					transform: rotate(0deg);
				}
			}

			h2 {
				font-size: 2rem;
				color: $lightest-color;
				padding: 8px 0 9px 32px;
				margin: 0;

				&::before {
					display: none;
				}

				&::after {
					font-size: 1.1rem;
					font-weight: 500;
					transform: none;
					background: none;
					position: static;
					height: auto;
					width: auto;
					margin: 0 0 0 16px;
					vertical-align: 1px;
					display: inline-block;
				}
			}

			&[data-bgb] {
				margin: 0 0 2px !important; // stylelint-disable-line declaration-no-important
			}

			small {
				font-size: 1.1rem;
				padding-left: 18px;

				@media (--sm-lte) {
					padding-left: 1em;
				}
			}

			&[id='bge-group10'],
			&[id='bge-group11'],
			&[id='bge-group12'] {
				h2 {
					padding-left: 19px;
				}
			}

			&[id='bge-group01'] h2 {
				&::after {
					content: 'January';
				}
			}

			&[id='bge-group02'] h2 {
				&::after {
					content: 'February';
				}
			}

			&[id='bge-group03'] h2 {
				&::after {
					content: 'March';
				}
			}

			&[id='bge-group04'] h2 {
				&::after {
					content: 'April';
				}
			}

			&[id='bge-group05'] h2 {
				&::after {
					content: 'May';
				}
			}

			&[id='bge-group06'] h2 {
				&::after {
					content: 'June';
				}
			}

			&[id='bge-group07'] h2 {
				&::after {
					content: 'July';
				}
			}

			&[id='bge-group08'] h2 {
				&::after {
					content: 'August';
				}
			}

			&[id='bge-group09'] h2 {
				&::after {
					content: 'September';
				}
			}

			&[id='bge-group10'] h2 {
				&::after {
					content: 'October';
				}
			}

			&[id='bge-group11'] h2 {
				&::after {
					content: 'November';
				}
			}

			&[id='bge-group12'] h2 {
				&::after {
					content: 'December';
				}
			}
		}

		.acc-body {
			padding: 28px 20px 32px;

			@media (--sm-lte) {
				padding: 2em 0 1em;
			}

			.date {
				width: 58px;
				font-family: $font-family-secondary;

				@media (--sm-lte) {
					width: calc(120 / 750 * 100vw);
				}
			}

			.week {
				width: 58px;

				@media (--sm-lte) {
					width: calc(120 / 750 * 100vw);
				}
			}

			.sat {
				background: $secondary-color-lighter3;

				.week {
					color: $secondary-color;
				}
			}

			.sun {
				background: $pink-color;

				.week {
					color: $pink-color02;
				}
			}
		}
	}

	.sns-link {
		padding: 20px 30px;
		background: $gray-color02;

		@media (--sm-lte) {
			padding: calc(30 / 750 * 100vw) calc(40 / 750 * 100vw);
		}

		ul {
			margin: 0;

			li {
				padding-left: 0;

				@media (--sm-gt) {
					display: inline-block;
					margin-right: 25px;
					font-size: 1.6rem;
				}

				&::before {
					content: none;
				}

				a {
					color: $darkest-color;
				}
			}
		}
	}

	// 規程リスト
	ol.first-none-ol {
		li:first-child {
			&::before {
				display: none;
			}
		}

		ol {
			li:first-child {
				&::before {
					display: block;
				}
			}
		}
	}

	// 丸囲み数字リスト
	ol.circle-ol {
		margin-left: 20px;

		li {
			padding: 0;
			counter-increment: section19;

			&::before {
				display: inline-block;
				text-align: center;
				content: counter(section19, decimal);
				color: $darkest-color;
				width: 1.35em;
				height: 1.35em;
				padding-top: 0.1em;
				line-height: 1;
				border-radius: 50%;
				margin-right: 0.75em;
				vertical-align: 2px;
				border: 1px solid $darkest-color;
				font-size: 0.75em; // stylelint-disable-line declaration-property-value-whitelist
				position: relative;
				left: auto;
				top: auto;
				font-weight: normal;
				padding-left: 0.07em;
				text-indent: 0;

				@media (--sm-lte) {
					padding-top: 0;
					vertical-align: 1px;
				}
			}

			&:nth-child(n + 10) {
				&::before {
					width: 1.557em;
					height: 1.557em;
					font-size: 0.65em; // stylelint-disable-line declaration-property-value-whitelist
					letter-spacing: -0.05em;
					line-height: 1.2;
					padding-left: 0;

					@media (--sm-lte) {
						padding-top: 0.1em;
					}
				}
			}
		}
	}

	// 枠線パーツ
	.border-parts {
		border: 1px solid $darkest-color;
		padding: 10px;
		margin: 10px 0;
	}

	// カタカナリスト
	.katakana-ol {
		li {
			counter-increment: section20;

			&::before {
				content: counter(section20, decimal) '.';
				content: counter(section20, katakana) '.'; // stylelint-disable-line declaration-block-no-duplicate-properties
			}
		}
	}

	// サイトマップ
	.sitemap {
		@media (--sm-gt) {
			&[data-bgb='wysiwyg2'] {
				.bgt-grid--first {
					padding-right: 20px;
				}

				.bgt-grid--last {
					padding-left: 20px;
				}
			}
		}

		h2 {
			position: relative;
			padding-bottom: 0;

			a {
				display: block;
				text-decoration: none;
				color: $secondary-color !important; // stylelint-disable-line declaration-no-important
				padding: 0 50px 16px 0;
				transition: color 300ms;

				&::before {
					content: '';
					display: block;
					position: absolute;
					margin: auto 0;
					width: 21px;
					height: 21px;
					border-radius: 50%;
					left: auto;
					right: 20px;
					top: calc(50% - 15px);
					background: url('/img/icon-arrow-w.png') no-repeat center center / 6px auto;
					background-color: $gray-color06;

					@media (--sm-lte) {
						top: calc(50% - (30 / 750 * 100vw));
						width: calc(36 / 750 * 100vw);
						height: calc(36 / 750 * 100vw);
						right: calc(20 / 750 * 100vw);
						background-size: calc(12 / 750 * 100vw) auto;
					}
				}

				&:hover {
					&::before {
						background-color: $secondary-color;
					}
				}
			}
		}

		&.titlelink {
			h3 {
				position: relative;
				padding: 0;

				a {
					display: block;
					text-decoration: none;
					color: $darkest-color !important; // stylelint-disable-line declaration-no-important
					padding: 10px 50px 10px 16px;
					transition: color 300ms;

					&::before {
						content: '';
						display: block;
						position: absolute;
						margin: auto 0;
						width: 21px;
						height: 21px;
						border-radius: 50%;
						right: 20px;
						top: calc(50% - 12px);
						background: url('/img/icon-arrow-w.png') no-repeat center center / 6px auto;
						background-color: $gray-color06;

						@media (--sm-lte) {
							top: calc(50% - (18 / 750 * 100vw));
							width: calc(36 / 750 * 100vw);
							height: calc(36 / 750 * 100vw);
							left: auto;
							right: calc(20 / 750 * 100vw);
							background-size: calc(12 / 750 * 100vw) auto;
						}
					}

					&:hover {
						&::before {
							background-color: $secondary-color;
						}
					}
				}
			}
		}

		h3 {
			position: relative;

			&[data-title='link'] {
				padding: 0;
			}

			a {
				display: block;
				text-decoration: none;
				color: $darkest-color !important; // stylelint-disable-line declaration-no-important
				padding: 10px 50px 10px 16px;
				transition: color 300ms;

				&::before {
					content: '';
					display: block;
					position: absolute;
					margin: auto 0;
					width: 21px;
					height: 21px;
					border-radius: 50%;
					right: 20px;
					top: calc(50% - 12px);
					background: url('/img/icon-arrow-w.png') no-repeat center center / 6px auto;
					background-color: $gray-color06;

					@media (--sm-lte) {
						top: calc(50% - (18 / 750 * 100vw));
						width: calc(36 / 750 * 100vw);
						height: calc(36 / 750 * 100vw);
						left: auto;
						right: calc(20 / 750 * 100vw);
						background-size: calc(12 / 750 * 100vw) auto;
					}
				}

				&:hover {
					&::before {
						background-color: $secondary-color;
					}
				}
			}
		}

		ul {
			@media (--sm-gt) {
				margin: 0 15px;
				padding-left: 0;
			}

			li {
				position: relative;
				margin: 10px 0;
				padding: 0;
				font-size: 1.6rem;
				padding-left: 20px;

				@media (--sm-lte) {
					padding-left: calc(40 / 750 * 100vw);
					margin: calc(20 / 750 * 100vw) 0;
					font-size: calc(30 / 750 * 100vw);
				}

				&::before {
					display: none;
				}

				a,
				span {
					color: $darkest-color;
					text-decoration: none;
					transition: color 300ms;

					&::before,
					&::after {
						content: '';
						display: block;
						position: absolute;
						top: 6px;
						margin: auto 0;

						@media (--sm-lte) {
							top: calc(12 / 750 * 100vw);
							left: calc(40 / 750 * -100vw);
						}
					}

					&::before {
						content: '';
						display: block;
						position: absolute;
						margin: auto 0;
						width: 14px;
						height: 14px;
						border-radius: 50%;
						left: 0;
						top: 6px;
						background: url('/img/icon-arrow-w.png') no-repeat center center / 6px auto;
						background-color: $gray-color06;

						@media (--sm-lte) {
							top: calc(10 / 750 * 100vw);
							width: calc(28 / 750 * 100vw);
							height: calc(28 / 750 * 100vw);
							left: 0;
							background-size: calc(12 / 750 * 100vw) auto;
						}
					}

					&[target='_blank'] {
						&::after {
							position: static;
							display: inline-block;
							margin: 0 5px;
						}
					}

					&[href$='pdf'] {
						position: static;

						&::after {
							display: inline-block;
							margin: 0 8px;

							@media (--sm-lte) {
								left: auto;
							}
						}
					}
				}

				a {
					@media (--sm-gt) {
						&:hover {
							color: $secondary-color;

							&::before {
								background-color: $secondary-color;
							}
						}
					}
				}

				&.sitemap-sub-ttl {
					font-weight: 500;
					margin: 0;

					&::before {
						content: '';
						display: block;
						width: 14px;
						height: 2px;
						background: $secondary-color-lighter4;
						margin: 0;
						position: absolute;
						top: 12px;
						left: 0;

						@media (--sm-lte) {
							width: calc(28 / 750 * 100vw);
							top: calc(20 / 750 * 100vw);
						}
					}

					&::after {
						content: none;
					}
				}

				ul {
					margin: 0;

					li {
						margin: 5px 0;
						padding-left: 18px;

						@media (--sm-lte) {
							padding-left: calc(36 / 750 * 100vw);
							margin: calc(10 / 750 * 100vw) 0;
						}

						a {
							font-size: 1.3rem;

							@media (--sm-lte) {
								font-size: calc(26 / 750 * 100vw);
							}

							&[target='_blank'] {
								padding-right: 15px;
								background: url("<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='13' height='13' viewBox='0 0 13 13' xml:space='preserve'><polygon points='10.5,13 0,13 0,2.5 1,2.5 1,12 10.5,12' fill='" + $primary-color + "'/><path d='M12,1v8H4V1H12 M13,0H3v10h10V0L13,0z' fill='" + $primary-color + "'/></svg>") no-repeat right bottom 5px /
									10px 11px;

								@media (--sm-lte) {
									padding-right: calc(30 / 750 * 100vw);
									background-size: calc(20 / 750 * 100vw) calc(22 / 750 * 100vw);
								}
							}

							&::before {
								width: 12px;
								height: 12px;
								top: 8px;

								@media (--sm-lte) {
									width: calc(24 / 750 * 100vw);
									height: calc(24 / 750 * 100vw);
									top: calc(14 / 750 * 100vw);
								}
							}

							&::after {
								content: none;
							}
						}
					}
				}
			}
		}
	}

	// 2カラムパーツ内に右画像左テキスト
	.col1-image,
	.col2-image {
		> .bgt-grid:nth-child(1) {
			padding-right: 20px;

			@media (--sm-lte) {
				padding-right: 0;
			}
		}

		> .bgt-grid:nth-child(2) {
			padding-left: 20px;

			@media (--sm-lte) {
				padding-left: 0;
			}
		}

		&-box {
			overflow: hidden;
		}

		.bgt-grid {
			margin-bottom: 1.5em;

			@media (--sm-lte) {
				margin-bottom: 2.85em;
			}
		}

		h2 {
			font-size: 2rem;
			border-bottom: none;
			line-height: 1.4;
			margin: 0 0 20px;
			letter-spacing: 0.09em;
			padding: 0 0 20px;

			@media (--sm-lte) {
				font-size: calc(44 / 750 * 100vw);
				padding: 0 0 calc(30 / 750 * 100vw);
			}

			&::before,
			&::after {
				content: '';
				position: absolute;
				display: block;
				height: 2px;
				top: auto;
				bottom: 0;
				left: 0;
			}

			&::before {
				background: $primary-color-lighter;
				width: 100%;
			}

			&::after {
				background: $secondary-color;
				width: 120px;
			}
		}

		.btn-download,
		.btn-link {
			margin: 0 0 10px;
			max-width: 300px;

			@media (--sm-lte) {
				margin: 0 auto 10px;
				width: 100%;
			}

			&:last-child {
				margin-bottom: 0;
			}

			a {
				min-width: 100%;
				font-size: 1.4rem;

				@media (--sm-lte) {
					font-size: calc(28 / 750 * 100vw);
					border-width: 1px;
					padding: calc(24 / 750 * 100vw) calc(60 / 750 * 100vw) calc(24 / 750 * 100vw) calc(24 / 750 * 100vw);
				}

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.btn-link {
			a {
				border: 2px solid $primary-color-darker;
				background: $lightest-color;
				color: $primary-color-darker;

				&::after {
					width: 13px;
					height: 13px;
					background: url('/img/icon-external-btn.png') no-repeat center / 13px 13px;

					@media (--sm-lte) {
						width: calc(26 / 750 * 100vw);
						height: calc(26 / 750 * 100vw);
						background-size: calc(26 / 750 * 100vw) calc(26 / 750 * 100vw);
					}
				}

				&:visited {
					color: $primary-color-darker !important; // stylelint-disable-line declaration-no-important
				}
			}
		}

		.bgt-download-file__link,
		.bgt-btn {
			@media (--sm-lte) {
				font-size: calc(28.22 / 750 * 100vw);
				border-width: 1px;
			}
		}

		.bge-ckeditor {
			p {
				margin: 0 0 25px;
				font-size: 1.5rem;

				@media (--sm-lte) {
					font-size: calc(30 / 750 * 100vw);
				}
			}
		}
	}

	.col1-image-image {
		width: calc(234 / 800 * 100%);
		float: left;
		text-align: center;

		@media (--sm-lte) {
			width: 100%;
			float: none;
			margin-bottom: calc(20 / 750 * 100vw);
		}

		a {
			img {
				transition: opacity 300ms;
			}

			&:hover {
				img {
					opacity: 0.7;
				}
			}
		}

		img {
			width: 100%;

			&[data-width='140'] {
				width: 140px;

				@media (--sm-lte) {
					width: calc(280 / 750 * 100vw);
				}
			}
		}
	}

	.col1-image-text {
		width: calc(560 / 800 * 100%);
		float: right;
		padding-left: 30px;

		@media (--sm-lte) {
			width: 100%;
			float: none;
			padding-left: 0;
			display: flex;
			flex-flow: column;
			justify-content: flex-start;
			align-items: center;

			ul {
				width: 100%;
			}
		}

		h2 {
			font-weight: bold;
			font-size: 2rem;
			padding: 0;
			line-height: 2;
			margin-bottom: 15px;

			@media (--sm-lte) {
				font-size: calc(40 / 750 * 100vw);
				margin-bottom: calc(20 / 750 * 100vw);
			}

			&::before,
			&::after {
				content: none;
			}
		}
	}

	.col2-image-text {
		width: calc(100% * 0.547);
		float: left;
		padding-left: 0;
		padding-right: 10px;

		@media (--sm-lte) {
			padding-right: calc(20 / 750 * 100vw);
		}
	}

	.col2-image-image {
		width: calc(100% * 0.439);
		float: right;
		padding-left: 10px;

		@media (--sm-lte) {
			padding-left: calc(20 / 750 * 100vw);
		}

		a {
			img {
				transition: opacity 300ms;
			}

			&:hover {
				img {
					opacity: 0.7;
				}
			}
		}

		img {
			width: 100%;
		}
	}

	// ローマ数字リスト
	.roman-list ol,
	ol.roman-list {
		counter-reset: number 0;

		@media (--sm-lte) {
			margin-left: calc(42 / 750 * -100vw);
		}

		li::before {
			counter-increment: number 1;
			content: counter(number, upper-roman) '.';
			color: $primary-color-darker12;
			width: 60px;
			text-align: right;
			font-family: $font-family-secondary;
			font-size: 1.6rem;
			font-weight: bold;
			top: 0;
			white-space: nowrap;

			@media (--sm-lte) {
				font-size: calc(34 / 750 * 100vw);
				width: calc(80 / 750 * 100vw);
				top: calc(2 / 750 * 100vw);
			}
		}

		> li {
			padding-left: 66px;
			font-size: 1.7rem;
			font-weight: 500;
			letter-spacing: 0.05em;
			margin-bottom: 20px;

			@media (--sm-lte) {
				padding-left: calc(92 / 750 * 100vw);
				font-size: calc(34 / 750 * 100vw);
				margin-bottom: calc(40 / 750 * 100vw);
			}

			> ol {
				counter-reset: number02 0;
				margin-left: -16px;
				margin-top: 16px;

				@media (--sm-lte) {
					margin-left: calc(32 / 750 * -100vw);
				}

				li {
					font-size: 1.4rem;
					padding-left: 42px;
					line-height: 2;
					letter-spacing: 0.06em;
					margin-bottom: 16px;

					@media (--sm-lte) {
						font-size: calc(28 / 750 * 100vw);
						padding-left: calc(84 / 750 * 100vw);
					}

					&::before {
						counter-increment: number02 1;
						content: counter(number02, upper-roman) '.';
						width: 35px;
						font-size: 1.6rem;
						color: $secondary-color-lighter2;
						top: -1px;

						@media (--sm-lte) {
							width: calc(70 / 750 * 100vw);
							font-size: calc(32 / 750 * 100vw);
						}
					}
				}
			}
		}
	}

	// アンカーリンク
	.anchor-link {
		ul {
			background: $gray-color02;
			padding: 25px 32px 15px 22px;
			display: flex;
			flex-wrap: wrap;
			margin: 0;

			@media (--sm-gt) {
				word-break: keep-all;
			}

			@media (--sm-lte) {
				padding: calc(40 / 750 * 100vw) calc(44 / 750 * 100vw) calc(24 / 750 * 100vw) calc(34 / 750 * 100vw);
			}

			li {
				padding: 0;
				margin: 0 10px 17px;
				letter-spacing: 0.07em;

				@media (--sm-lte) {
					font-size: calc(30 / 750 * 100vw);
					margin: 0 calc(20 / 750 * 100vw) calc(33 / 750 * 100vw);
				}

				a {
					border-bottom: 1px solid #000;
					color: inherit;
					text-decoration: none;
					padding-right: 20px;
					padding-bottom: 3px;
					position: relative;

					@media (--sm-lte) {
						padding-right: 0;
						padding-bottom: calc(6 / 750 * 100vw);
					}

					&[href$='pdf'] {
						padding-right: 0;
					}

					&:not([href$='pdf'])::after {
						content: '';
						position: absolute;
						right: 2px;
						bottom: 10px;
						display: block;
						width: 7px;
						height: 7px;
						border-top: 2px solid $secondary-color;
						border-right: 2px solid $secondary-color;
						transform: rotate(135deg);
						transition: bottom 300ms;

						@media (--sm-lte) {
							position: static;
							display: inline-block;
							margin-left: calc(14 / 750 * 100vw);
							vertical-align: calc(6 / 750 * 100vw);
							width: calc(14 / 750 * 100vw);
							height: calc(14 / 750 * 100vw);
						}
					}

					&:hover {
						&::after {
							bottom: 5px;

							@media (--sm-lte) {
								bottom: calc(20 / 750 * 100vw);
							}
						}
					}
				}

				&::before {
					display: none;
				}
			}
		}
	}

	.flex-link-list {
		ul {
			background: $gray-color02;
			padding: 25px 32px 15px 22px;
			display: flex;
			flex-wrap: wrap;
			margin: 0;

			@media (--sm-lte) {
				padding: calc(40 / 750 * 100vw) calc(44 / 750 * 100vw) calc(24 / 750 * 100vw) calc(34 / 750 * 100vw);
			}

			li {
				padding: 0;
				margin: 0 10px 17px;
				letter-spacing: 0.07em;

				@media (--sm-lte) {
					font-size: calc(30 / 750 * 100vw);
					margin: 0 calc(20 / 750 * 100vw) calc(33 / 750 * 100vw);
				}

				a {
					border-bottom: 1px solid #000;
					color: inherit;
					text-decoration: none;
					padding-right: 20px;
					padding-bottom: 3px;
					position: relative;
					word-break: keep-all;

					@media (--sm-lte) {
						padding-right: calc(40 / 750 * 100vw);
						padding-bottom: calc(6 / 750 * 100vw);
					}

					&::after {
						content: '';
						position: absolute;
						right: 2px;
						top: 8px;
						display: block;
						width: 7px;
						height: 7px;
						border-top: 2px solid $secondary-color;
						border-right: 2px solid $secondary-color;
						transform: rotate(45deg);
						transition: right 300ms;

						@media (--sm-lte) {
							right: calc(4 / 750 * 100vw);
							top: calc(16 / 750 * 100vw);
							width: calc(14 / 750 * 100vw);
							height: calc(14 / 750 * 100vw);
						}
					}

					&:hover {
						&::after {
							right: -2px;

							@media (--sm-lte) {
								right: calc(-4 / 750 * 100vw);
							}
						}
					}
				}

				&::before {
					display: none;
				}
			}
		}
	}

	// リンクリスト
	.link-list,
	.link-list-small {
		a {
			color: inherit;

			@media (--sm-gt) {
				transition: color 300ms;

				&:hover {
					color: $secondary-color;
					text-decoration: underline;
				}
			}
		}

		p {
			a {
				text-decoration: none;
			}
		}

		ul {
			margin: 0;
			letter-spacing: 0.1em;

			& + ul {
				margin-top: 30px;

				@media (--sm-lte) {
					margin-top: calc(60 / 750 * 100vw);
				}
			}

			li {
				padding: 0;
				font-size: 1.6rem;

				@media (--sm-lte) {
					font-size: calc(32 / 750 * 100vw);
				}

				&::before {
					display: none;
				}

				a {
					// stylelint-disable selector-max-specificity
					padding-left: 25px;
					display: inline-block;
					position: relative;

					@media (--sm-lte) {
						padding-left: calc(50 / 750 * 100vw);
					}

					@media (--sm-gt) {
						&::before {
							transition: background-color 300ms;
						}

						&:hover {
							&::before {
								background-color: $secondary-color;
							}
						}
					}

					&::before {
						content: '';
						width: 16px;
						height: 16px;
						background: url('/img/icon-arrow-w.png') no-repeat center center / 7px auto;
						background-color: $primary-color;
						position: absolute;
						left: 0;
						top: 7px;
						border-radius: 50%;

						@media (--sm-lte) {
							width: calc(32 / 750 * 100vw);
							height: calc(32 / 750 * 100vw);
							top: calc(10 / 750 * 100vw);
							background-size: calc(14 / 750 * 100vw) auto;
						}
					}
					// stylelint-enable selector-max-specificity
				}
			}
		}
	}

	.link-list-small {
		ul {
			li {
				font-size: 1.4rem;

				@media (--sm-lte) {
					font-size: calc(28 / 750 * 100vw);
				}

				a {
					&[href$='pdf'],
					&[href$='ppt'],
					&[href$='pptx'],
					&[href$='doc'],
					&[href$='docx'],
					&[href$='xls'],
					&[href$='xlsx'],
					&[href$='zip'] {
						padding: 4px 0 4px 25px;

						@media (--sm-lte) {
							padding: calc(8 / 750 * 100vw) 0 calc(8 / 750 * 100vw) calc(50 / 750 * 100vw);
						}

						span {
							&::before {
								top: 7px;

								@media (--sm-lte) {
									top: calc(14 / 750 * 100vw);
								}
							}

							&::after {
								top: 11px;

								@media (--sm-lte) {
									top: calc(22 / 750 * 100vw);
								}
							}
						}
					}
				}
			}
		}
	}

	.link-no-icon {
		&[target='_blank']::after {
			content: none;
		}
	}

	// キャッチコピー
	.catch-title {
		padding-bottom: 0;
		color: $secondary-color-lighter8;
		font-weight: 500;
		font-size: 3rem;
		letter-spacing: 0.1em;
		line-height: 1.7;
		margin-bottom: 15px;
		font-style: normal;

		@media (--sm-lte) {
			font-size: calc(46 / 750 * 100vw);
			margin-bottom: calc(36 / 750 * 100vw);
		}

		[lang='en'] & {
			font-size: 2.4rem;
			letter-spacing: 0.05em;
			line-height: 1.6;

			@media (--sm-lte) {
				font-size: calc(40 / 750 * 100vw);
			}
		}
	}

	// アイコンつきタイトル
	.icon-title {
		margin-bottom: 24px;

		@media (--sm-lte) {
			margin-bottom: calc(44 / 750 * 100vw);
		}

		h2 {
			display: flex;
			align-items: stretch;
			padding: 0;
			margin-bottom: 13px;
			min-height: 80px;
			background: $gray-color02;
			position: relative;

			@media (--sm-lte) {
				margin-bottom: calc(10 / 750 * 100vw);
			}

			&::before {
				position: static;
				height: 80px;
				width: 1px;
				margin-right: -1px;
			}

			> span:first-child {
				margin: 0;
				display: flex;
				align-items: center;
				padding: 5px 0 6px 13px;
				letter-spacing: 0.1em;
				width: 111px;
				font-size: 1.6rem;
				color: $lightest-color;
				background: $secondary-color;
				font-weight: bold;
				border-left: 1px solid $primary-color;
				position: relative;
				z-index: 2;

				@media (--sm-lte) {
					font-size: calc(24 / 750 * 100vw);
					width: calc(142.5 / 750 * 100vw);
					padding: calc(10 / 750 * 100vw) 0 calc(12 / 750 * 100vw) calc(18 / 750 * 100vw);
					text-align: center;
				}

				&::before {
					content: '';
					display: block;
					position: absolute;
					width: 2px;
					top: 1px;
					bottom: 1px;
					right: -1px;
					background: $secondary-color;
				}
			}

			&::after {
				content: '';
				display: block;
				position: absolute;
				left: 105px;
				top: 0;
				bottom: 0;
				width: 20px;
				height: 100%;
				background: url('/img/bg-obi-arrow.png') no-repeat center center / 100% 100%;

				@media (--sm-lte) {
					width: calc(40 / 750 * 100vw);
					border-width: calc(58 / 750 * 100vw) 0 calc(58 / 750 * 100vw) calc(24 / 750 * 100vw);
					left: calc(128 / 750 * 100vw);
				}
			}

			> span:last-child {
				display: flex;
				justify-content: center;
				flex-flow: column;
				width: calc(100% - 111px);
				margin: 0;
				padding: 8px 10px 8px 30px;
				line-height: 1.45;

				@media (--sm-lte) {
					width: calc(100% - (132 / 750 * 100vw));
					padding: calc(10 / 750 * 100vw) calc(20 / 750 * 100vw) calc(10 / 750 * 100vw) calc(54 / 750 * 100vw);
				}

				.icon-title-title {
					line-height: 1.45;
					font-size: 3rem;
					letter-spacing: 0.1em;
					font-weight: 500;

					@media (--sm-lte) {
						font-size: calc(46 / 750 * 100vw);
						letter-spacing: 0.08em;
					}
				}

				span:not(.icon-title-title) {
					display: block;
					font-size: 1.8rem;
					font-weight: bold;
					letter-spacing: 0.03em;

					@media (--sm-lte) {
						font-size: calc(24 / 750 * 100vw);
						margin-top: calc(4 / 750 * 100vw);
					}
				}

				[lang='en'] {
					font-family: $font-family-secondary;
					font-weight: bold;
				}
			}
		}

		p {
			margin-top: 13px;

			@media (--sm-lte) {
				margin-top: calc(10 / 750 * 100vw);
			}
		}
	}

	// 3カラムブロック（ポイント）
	.point-column {
		&[data-bgb='wysiwyg3'] {
			display: flex;
			justify-content: space-between;
			align-items: stretch;

			@media (--sm-lte) {
				display: block;
			}

			&::after {
				content: none;
			}

			.bgt-grid {
				border-top: 2px solid $primary-color;
				float: none;
				width: 244px;
				padding: 25px;
				background: $gray-color02;

				@media (--sm-lte) {
					width: 100%;
					padding: calc(50 / 750 * 100vw);
					margin-bottom: calc(60 / 750 * 100vw);

					&:last-child {
						margin-bottom: 0;
					}
				}
			}

			.bgt-grid:nth-child(1) {
				padding: 25px;

				@media (--sm-lte) {
					padding: calc(50 / 750 * 100vw);
				}
			}

			.bgt-grid:nth-child(3) {
				padding: 25px;

				@media (--sm-lte) {
					padding: calc(50 / 750 * 100vw);
				}
			}
		}

		h2 {
			text-align: center;
			font-family: $font-family-secondary;
			font-size: 1.5rem;
			font-weight: bold;
			line-height: 1.15;
			padding: 15px 0 7px;
			color: $secondary-color;
			letter-spacing: 0.05em;
			margin-bottom: 0;

			@media (--sm-lte) {
				font-size: calc(30 / 750 * 100vw);
				padding: calc(44 / 750 * 100vw) 0 calc(14 / 750 * 100vw);
			}

			span {
				display: block;
				font-size: 3.2rem;
				letter-spacing: 0.05em;

				@media (--sm-lte) {
					font-size: calc(64 / 750 * 100vw);
				}
			}

			&::before,
			&::after {
				display: none;
			}
		}

		h3 {
			background: none;
			text-align: center;
			font-size: 2.2rem;
			letter-spacing: 0.05em;
			border: 0;
			padding: 0;
			margin: 0;

			@media (--sm-lte) {
				font-size: calc(44 / 750 * 100vw);
				margin-bottom: calc(40 / 750 * 100vw);
			}
		}

		p {
			font-size: 1.5rem;

			@media (--sm-lte) {
				font-size: calc(30 / 750 * 100vw);
			}
		}
	}

	// 2カラムブロックのそれぞれに背景を敷く
	.color-column {
		&[data-bgb='image-text2'] {
			.bgt-grid:nth-child(1) {
				padding-right: 20px;

				@media (--sm-lte) {
					padding-right: 0;
					margin-bottom: 1.5em;
				}
			}

			.bgt-grid [data-bgt] > :last-child {
				@media (--sm-lte) {
					margin-bottom: 0;
				}
			}

			[data-bgt='image'] {
				border-radius: 18px 18px 0 0;
				overflow: hidden;

				@media (--sm-lte) {
					border-radius: calc(36 / 750 * 100vw) calc(36 / 750 * 100vw) 0 0;
				}
			}
		}

		&[data-bgb='image-text2'] .bgt-grid:nth-child(2) {
			padding-left: 20px;

			@media (--sm-lte) {
				padding-left: 0;
			}
		}

		[data-bgt='ckeditor']:nth-child(2) {
			margin-top: 0;
		}

		&-pink,
		&-yellow,
		&-green {
			border-radius: 0 0 18px 18px;
			padding: 23px 30px;

			@media (--sm-lte) {
				border-radius: 0 0 calc(36 / 750 * 100vw) calc(36 / 750 * 100vw);
				padding: calc(46 / 750 * 100vw) calc(60 / 750 * 100vw);
			}

			p {
				margin-top: 12px;
				line-height: 1.9;

				@media (--sm-lte) {
					margin-top: calc(24 / 750 * 100vw);
				}

				span {
					display: inline-block;
					line-height: 1.6;
				}
			}
		}

		&-pink {
			background: $pink-color03;
		}

		&-yellow {
			background: $yellow-color;
		}

		&-green {
			background: $green-color;
		}

		h6 {
			font-size: 1.8rem;
			letter-spacing: 0.08em;
			margin-bottom: 0;
			padding: 0;
			line-height: 1.6;
			font-weight: bold;

			@media (--sm-lte) {
				font-size: calc(36 / 750 * 100vw);
			}

			&::before,
			&::after {
				display: none;
			}
		}
	}

	// 入試カレンダー
	.nushi-calendar {
		&-hanrei {
			dl {
				display: flex;
				justify-content: flex-start;
				align-items: stretch;
				border: 1px solid $secondary-color-lighter4;
				letter-spacing: normal;
				line-height: 1.5;

				@media (--sm-lte) {
					display: block;
				}

				dt {
					width: 186px;
					padding: 1px 5px;
					text-align: center;
					background: $secondary-color-lighter4;
					flex: 0 0 auto;
					color: $lightest-color;
					font-size: 1.3rem;
					display: flex;
					align-items: center;
					justify-content: center;

					@media (--sm-lte) {
						width: 100%;
						padding: calc(10 / 750 * 100vw) calc(15 / 750 * 100vw);
						font-size: calc(26 / 750 * 100vw);
					}
				}

				dd {
					flex: 1 1 auto;
					margin: 0;
					padding: 1px 5px 2px;
					font-size: 1.3rem;

					@media (--sm-lte) {
						padding: calc(10 / 750 * 100vw) calc(15 / 750 * 100vw);
						font-size: calc(26 / 750 * 100vw);
					}

					p {
						display: inline-block;
						margin: 0 2em 0 0;

						@media (--sm-lte) {
							display: block;
						}

						&::before {
							content: '';
							display: inline-block;
							width: 10px;
							height: 10px;
							border-radius: 50%;
							margin-right: 3px;

							@media (--sm-lte) {
								width: calc(20 / 750 * 100vw);
								height: calc(20 / 750 * 100vw);
								margin-right: calc(6 / 750 * 100vw);
							}
						}

						&.hanrei01 {
							&::before {
								background-color: $hanrei-color01;
							}
						}

						&.hanrei02 {
							&::before {
								background-color: $hanrei-color02;
							}
						}

						&.hanrei03 {
							&::before {
								background-color: $hanrei-color03;
							}
						}

						&.hanrei04 {
							&::before {
								background-color: $hanrei-color04;
							}
						}

						&.hanrei05 {
							&::before {
								background-color: $hanrei-color05;
							}
						}

						&.hanrei06 {
							&::before {
								background-color: $hanrei-color06;
							}
						}
					}
				}
			}
		}

		&-slide {
			padding-bottom: 22px;

			&-item {
				img {
					width: 100%;
				}
			}

			.slick-list {
				z-index: 10;
			}

			.slick-arrow {
				appearance: none;
				position: absolute;
				bottom: 0;
				background: $primary-color;
				color: $lightest-color;
				font-family: $font-family-secondary;
				font-size: 1.5rem;
				font-weight: bold;
				border: none;
				cursor: pointer;
				line-height: 22px;
				transition: opacity 300ms;

				&::before,
				&::after {
					border-top: solid 2px $lightest-color;
					border-right: solid 2px $lightest-color;
					display: inline-block;
					width: 7px;
					height: 7px;
					margin: 0 10px;
					vertical-align: 2px;
				}

				&:hover {
					opacity: 0.6;
				}
			}

			.slick-prev {
				left: 0;
				border-radius: 0 0 10px 0;
				padding-right: 15px;

				&::before {
					content: '';
					transform: rotate(-135deg);
					right: 3px;
				}
			}

			.slick-next {
				right: 0;
				border-radius: 0 0 0 10px;
				padding-left: 15px;

				&::after {
					content: '';
					transform: rotate(45deg);
					left: 3px;
				}
			}
		}
	}

	// 研究now
	.now-block {
		ul {
			display: flex;
			flex-wrap: wrap;
			margin: 0;
			padding: 0;

			@media (--sm-lte) {
				display: block;
			}

			li {
				width: calc(100% * 0.313);
				margin: 0 0 50px 3.05%;
				padding: 0;

				@media (--sm-lte) {
					width: 100%;
					margin-left: 0;
				}

				&:nth-child(3n + 1) {
					margin-left: 0;
				}

				&::before {
					display: none;
				}

				figure {
					margin-bottom: 4px;
				}
			}
		}

		a {
			color: inherit;
			transition: opacity 300ms;
			text-decoration: none;
			width: 100%;

			&::after {
				display: none !important; // stylelint-disable-line declaration-no-important
			}

			&:hover {
				opacity: 0.6;

				@media (--sm-lte) {
					opacity: 1;
				}
			}
		}

		h5 {
			text-align: center;
			background: $primary-color;
			padding: 0 10px;
			color: $lightest-color;
			line-height: 1.6;
			margin: 0 0 7px;
			font-size: 1.3rem;

			@media (--sm-lte) {
				font-size: calc(26 / 750 * 100vw);
				margin: 0 0 10px;
			}
		}

		p {
			text-align: center;
			font-size: 1.8rem;
			font-weight: bold;
			margin: 0;
			letter-spacing: 0.02em;
			line-height: 1.45;

			@media (--sm-lte) {
				font-size: calc(32 / 750 * 100vw);
			}
		}
	}

	// タイトルの頭に階層記載
	.floor-title {
		&[data-bgb='text-image2'] {
			.bgt-grid--first {
				padding-right: 20px;

				@media (--sm-lte) {
					padding-right: 0;
				}
			}

			.bgt-grid--last {
				padding-left: 20px;

				@media (--sm-lte) {
					padding-left: 0;
				}
			}
		}

		h2 {
			font-size: 1.8rem;
			font-weight: bold;
			padding: 0;
			margin-bottom: 0;
			letter-spacing: 0.1em;

			@media (--sm-lte) {
				font-size: calc(36 / 750 * 100vw);
				margin-top: calc(34 / 750 * 100vw);
			}

			span {
				display: inline-block;
				font-weight: 500;
				font-size: 1.5rem;
				background: $primary-color;
				color: $lightest-color;
				text-align: center;
				min-width: 47px;
				padding: 0 10px;
				margin-right: 10px;

				@media (--sm-lte) {
					font-size: calc(30 / 750 * 100vw);
					min-width: calc(94 / 750 * 100vw);
					padding: 0 calc(20 / 750 * 100vw);
					margin-right: calc(20 / 750 * 100vw);
					margin-bottom: 4px;
					display: table;
				}
			}

			&::before,
			&::after {
				display: none;
			}
		}

		.btn-link {
			text-align: center;
		}

		.btn-link a {
			min-width: 300px;

			@media (--sm-lte) {
				min-width: 0;
				width: 100%;
				max-width: calc(600 / 750 * 100vw);
			}
		}
	}

	// 水色ラベル付きタイトル
	.flow-title {
		margin-bottom: 14px;

		@media (--sm-lte) {
			margin-bottom: calc(28 / 750 * 100vw);
		}

		h3 {
			display: flex;
			align-items: center;
			margin: 0;
			background: $gray-color09;
			border: 0;
			font-size: 2.2rem;
			line-height: 1.6;
			font-weight: 500;
			padding: 10px 5px 9px 12px;
			letter-spacing: 0.1em;

			@media (--sm-lte) {
				margin: 0;
				line-height: 1.35;
				font-size: calc(40 / 750 * 100vw);
				padding: calc(24 / 750 * 100vw) calc(10 / 750 * 100vw) calc(24 / 750 * 100vw) calc(24 / 750 * 100vw);
			}

			span {
				width: 100px;
				flex: 0 0 auto;
				text-align: center;
				padding: 4px 0;
				letter-spacing: 0.05em;
				background: $secondary-color;
				color: $lightest-color;
				font-size: 1.4rem;
				font-weight: bold;
				border-radius: 0 15px 15px 0;
				margin: 0 12px 1px -12px;

				@media (--sm-lte) {
					width: calc(150 / 750 * 100vw);
					font-size: calc(22 / 750 * 100vw);
					padding: calc(10 / 750 * 100vw) 0;
					border-radius: 0 calc(30 / 750 * 100vw) calc(30 / 750 * 100vw) 0;
					margin: 2px calc(20 / 750 * 100vw) 0 calc(-24 / 750 * 100vw);
				}
			}
		}
	}

	.flow-text {
		padding: 0 32px;
		margin: 16px 0 14px;

		@media (--sm-lte) {
			padding: 0;
			font-size: calc(32 / 750 * 100vw);
		}

		p {
			letter-spacing: 0.1em;
			line-height: 1.9;
		}
	}

	.triangle {
		margin-bottom: 12px;

		@media (--sm-lte) {
			margin-bottom: calc(24 / 750 * 100vw);
		}

		hr {
			display: block;
			width: 0;
			height: 0;
			background: none;
			border-style: solid;
			border-width: 14px 32px 0;
			border-color: $primary-color-darker11 transparent transparent;

			@media (--sm-lte) {
				border-width: calc(28 / 750 * 100vw) calc(64 / 750 * 100vw) 0;
				top: calc(52 / 750 * -100vw);
			}
		}
	}

	.flow-btn {
		&.bgb-button {
			margin: 25px 0 19px;

			@media (--sm-lte) {
				margin: calc(32 / 750 * 100vw) 0 calc(38 / 750 * 100vw);
			}

			.bgt-btn {
				min-width: 300px;

				@media (--sm-lte) {
					min-width: 0;
					width: 100%;
					max-width: calc(600 / 750 * 100vw);
				}
			}
		}
	}

	.image-link_last-no-link {
		.bgt-grid {
			&:last-child {
				a {
					pointer-events: none;
				}
			}
		}
	}

	//社会環境学部 2色ブロック
	.syakai_kankyo-block[data-bgb='wysiwyg2'] {
		display: flex;

		@media (--sm-lte) {
			display: block;
		}

		&::after {
			content: none;
		}

		.bgt-grid {
			padding: 20px 20px 40px 20px !important; // stylelint-disable-line declaration-no-important
			font-feature-settings: 'palt';
			float: none;

			&:first-child {
				background: $kankyo-color02;
				position: relative;

				.syakai_kankyo-ttl {
					background: $kankyo-color01;
				}

				.syakai_kankyo-example-ttl {
					color: $kankyo-color01;
				}

				figcaption {
					color: $kankyo-color01;
				}

				.syakai_kankyo-example-ttl {
					&::after {
						background: $kankyo-color01;
					}
				}
			}

			&:last-child {
				background: $kankyo-color04;

				.syakai_kankyo-ttl {
					background: $kankyo-color03;
				}

				.syakai_kankyo-lead {
					@media (--sm-gt) {
						visibility: hidden;
					}
				}

				.syakai_kankyo-example-ttl {
					color: $kankyo-color03;
				}

				figcaption {
					color: $kankyo-color03;
				}

				.syakai_kankyo-example-ttl {
					&::after {
						background: $kankyo-color03;
					}
				}
			}
		}

		.syakai_kankyo-ttl {
			margin: -20px -20px 1em -20px;
			font-size: 2rem;
			line-height: 1;
			padding: calc(15 / 20 * 1em) 10px;
			color: $lightest-color;
			text-align: center;
			font-weight: 500;

			@media (--sm-lte) {
				font-size: calc(18 / 375 * 100vw);
			}

			&::after,
			&::before {
				content: none;
			}
		}

		.syakai_kankyo-example-img {
			font-size: 0;
		}

		.syakai_kankyo-lead {
			font-weight: 500;
			font-size: 1.8rem;
			line-height: calc(30 / 18);
			letter-spacing: calc(60 / 1000 * 1em);
			margin: calc(27 / 18 * 1em) 0;

			@media (--sm-lte) {
				font-size: calc(16 / 375 * 100vw);
			}

			@media (--sm-gt) {
				width: calc(200% + 40px);
				text-align: center;
			}
		}

		.syakai_kankyo-description {
			font-size: 1.4rem;
			line-height: calc(26 / 14);
			letter-spacing: calc(60 / 1000 * 1em);
			margin-top: calc(13 / 14 * 1em);
		}

		.syakai_kankyo-example-ttl {
			font-size: 2rem;
			letter-spacing: calc(60 / 1000 * 1em);
			margin: calc(18 / 20 * 1em) 0 calc(15 / 20 * 1em);
			position: relative;
			padding-left: calc(22 / 20 * 1em);
			font-weight: 500;

			@media (--sm-lte) {
				font-size: calc(18 / 375 * 100vw);
			}

			&::after {
				position: absolute;
				content: '';
				display: block;
				left: 0;
				top: 50%;
				transform: translateY(-50%);
				width: 3px;
				height: calc(30 / 20 * 1em);
			}
		}

		.syakai_kankyo-example-description {
			font-size: 1.4rem;
			line-height: calc(26 / 14);
			letter-spacing: calc(60 / 1000 * 1em);
			margin-top: 0;

			@media (--sm-lte) {
				font-size: calc(14 / 375 * 100vw);
			}
		}

		figure {
			figcaption {
				font-size: 1.8rem;
				margin: calc(14 / 18 * 1em) 0 calc(7 / 18 * 1em);
				padding: 0;
				font-weight: 500;

				@media (--sm-lte) {
					font-size: calc(16 / 375 * 100vw);
				}
			}
		}
	}

	//国際プログラム
	.kokusai-table {
		th,
		td {
			text-align: center;
			letter-spacing: normal;

			@media (--sm-gt) {
				font-size: 1.4rem;
			}

			&.text-left {
				text-align: left;
			}
		}

		tr {
			&:first-child {
				th {
					&:first-child {
						width: 10%;
						background: $secondary-color-lighter8;
						color: $lightest-color;
					}

					&:nth-child(2) {
						width: 18%;
					}

					&:nth-child(3) {
						width: 54%;
					}

					&:nth-child(4) {
						width: 18%;
					}

					&:not(:first-child) {
						background: $primary-color;
						color: $lightest-color;
					}
				}
			}
		}
	}

	// 検索結果
	[data-path$='/search'] & {
		line-height: 2;

		form {
			background: $gray-color02;
			padding: 30px;

			@media (--sm-lte) {
				padding: calc(40 / 750 * 100vw) 5%;
			}

			& > div {
				position: relative;
			}

			input[type='text'] {
				appearance: none;
				width: 100%;
				border-radius: 0;
				border: 3px solid $gray-color03;
				font-size: 1.8rem;
				height: 65px;
				padding: 0 16px;

				@media (--sm-lte) {
					height: calc(104 / 750 * 100vw);
					border-width: 2px;
					font-size: calc(32 / 750 * 100vw);
				}

				&:focus {
					outline: none;
				}
			}

			input[type='submit'] {
				border-radius: 0;
				border: 0;
				width: 280px;
				display: block;
				padding: 14px 10px 17px 12px;
				letter-spacing: 0.1em;
				margin: 22px auto 0;
				font-family: inherit;
				background: $primary-color url('/img/icon-arrow-search.svg') no-repeat right 15px center;
				color: $lightest-color;
				font-size: 1.7rem;
				cursor: pointer;

				@media (--sm-gt) {
					transition: opacity 300ms;

					&:hover {
						opacity: 0.6;
					}
				}

				@media (--sm-lte) {
					font-size: calc(32 / 750 * 100vw);
					width: 100%;
					margin-top: calc(40 / 750 * 100vw);
					padding: calc(28 / 750 * 100vw) calc(20 / 750 * 100vw) calc(34 / 750 * 100vw) calc(24 / 750 * 100vw);
				}

				&:focus {
					outline: none;
				}
			}
		}

		[id='proboOverlay'] {
			display: none !important; // stylelint-disable-line declaration-no-important
		}

		._pbox-refine-1,
		._pbox-refine-2 {
			display: flex;
			margin-top: 19px;

			@media (--sm-lte) {
				margin-top: calc(38 / 750 * 100vw);
				display: block;
			}

			._title {
				font-size: 1.5rem;
				font-weight: bold;
				letter-spacing: 0.02em;
				padding: 3px 29px 3px 0;
				border-right: 1px solid $gray-color03;

				@media (--sm-lte) {
					font-size: calc(30 / 750 * 100vw);
					padding: 0;
					border: 0;
				}
			}

			._refs {
				margin: 0 -20px 0 0;
				padding: 0 0 0 29px;
				display: flex;
				flex-wrap: wrap;
				border: 0;
				flex: 1;

				@media (--sm-lte) {
					margin: 0 calc(-40 / 750 * 100vw) 0 0;
					padding: 0;
				}

				li {
					padding: 0;
					margin: 6px 29px 0 0;
					letter-spacing: 0;
					font-size: 1.5rem;
					word-break: keep-all;

					@media (--sm-lte) {
						margin: calc(12 / 750 * 100vw) calc(58 / 750 * 100vw) 0 0;
						font-size: calc(30 / 750 * 100vw);
					}

					&::before {
						display: none;
					}
				}
			}

			.check {
				position: absolute;
				top: 0;
				left: 0;
				width: 0;
				height: 0;
				opacity: 0;
				visibility: hidden;

				& + ._text {
					appearance: none;
					display: inline-block;
					padding-left: 22px;
					position: relative;

					@media (--sm-lte) {
						padding-left: calc(44 / 750 * 100vw);
					}

					&::before {
						content: '';
						display: block;
						position: absolute;
						top: 5px;
						left: 0;
						width: 16px;
						height: 16px;
						box-sizing: border-box;
						border-radius: 0;
						border: 1px solid $gray-color03;
						background: $lightest-color;

						@media (--sm-lte) {
							top: calc(10 / 750 * 100vw);
							width: calc(32 / 750 * 100vw);
							height: calc(32 / 750 * 100vw);
							vertical-align: calc(-4 / 750 * 100vw);
						}
					}
				}

				&:checked {
					& + ._text {
						&::before {
							background: $primary-color;
						}

						&::after {
							content: '';
							display: block;
							border-left: 2px solid $lightest-color;
							border-bottom: 2px solid $lightest-color;
							width: 8px;
							height: 5px;
							transform: rotate(-45deg);
							position: absolute;
							left: 4px;
							top: 9.2px;

							@media (--sm-lte) {
								width: calc(16 / 750 * 100vw);
								height: calc(10 / 750 * 100vw);
								left: calc(8 / 750 * 100vw);
								top: calc(18 / 750 * 100vw);
							}
						}
					}
				}

				&:focus {
					outline: none;
				}
			}
		}

		.poplink_suggest,
		div.poplink.pc022 {
			width: 100% !important; // stylelint-disable-line declaration-no-important
		}

		.pbox-search-status {
			margin-top: 12px;
			text-align: right;
			font-weight: 500;
			font-size: 1.5rem;
			letter-spacing: 0.05em;

			@media (--sm-lte) {
				margin-top: calc(24 / 750 * 100vw);
				font-size: calc(28 / 750 * 100vw);
			}

			span {
				font-weight: bold;
			}
		}

		.pbox-sort-selector,
		.pbox-count-selector {
			display: none !important; // stylelint-disable-line declaration-no-important
		}

		._pbox-pagenav {
			text-align: center;
			margin: 43px 0 50px;

			@media (--sm-lte) {
				margin: calc(60 / 750 * 100vw) 0;
			}

			._navs {
				display: flex;
				justify-content: center;

				@media (--sm-lte) {
					padding-top: calc(68 / 750 * 100vw);
					position: relative;
				}

				a {
					text-decoration: none;
					color: $darkest-color;

					@media (--sm-gt) {
						transition: opacity 300ms;

						&:hover {
							opacity: 0.6;
						}
					}

					&:visited {
						color: $darkest-color !important; // stylelint-disable-line declaration-no-important
					}
				}
			}

			._page {
				width: 37px;
				height: 38px;
				margin: 0 4px;
				line-height: 37px;
				display: block;
				font-size: 1.6rem;
				font-weight: bold;
				font-family: $font-family-secondary;

				@media (--sm-lte) {
					width: calc(74 / 750 * 100vw);
					height: calc(76 / 750 * 100vw);
					margin: 0 calc(6 / 750 * 100vw);
					line-height: calc(74 / 750 * 100vw);
					font-size: calc(30 / 750 * 100vw);
				}

				a {
					display: block;
					width: 100%;
				}

				&._cur {
					background: $gray-color13;
					color: $lightest-color;
				}
			}

			._next,
			._prev {
				padding-top: 3px;

				@media (--sm-lte) {
					position: absolute;
					top: 0;
					left: 0;
				}

				a {
					display: block;
					overflow: hidden;
					width: 60px;
					position: relative;
					text-indent: -999px;
					letter-spacing: 0.05em;

					@media (--sm-lte) {
						width: calc(102 / 750 * 100vw);
						letter-spacing: 0;
						font-size: calc(28 / 750 * 100vw);
					}

					&::before {
						content: 'NEXT';
						font-family: $font-family-secondary;
						font-weight: bold;
						font-size: 1.5rem;
						position: absolute;
						top: 0;
						bottom: 0;
						left: 0;
						text-indent: 0;

						@media (--sm-lte) {
							font-size: calc(28 / 750 * 100vw);
						}
					}

					&::after {
						content: '';
						display: block;
						position: absolute;
						top: 13px;
						right: 5px;
						width: 6px;
						height: 6px;
						border-top: 2px solid $darkest-color;
						border-right: 2px solid $darkest-color;
						transform: rotate(45deg);
						transition: 300ms;

						@media (--sm-lte) {
							width: calc(12 / 750 * 100vw);
							height: calc(12 / 750 * 100vw);
							top: calc(22 / 750 * 100vw);
							right: calc(4 / 750 * 100vw);
							border-top-width: 1px;
							border-right-width: 1px;
						}
					}
				}
			}

			._next {
				margin-left: 35px;

				@media (--sm-lte) {
					margin-left: 0;
					right: 0;
					left: auto;
				}
			}

			._prev {
				margin-right: 35px;

				@media (--sm-lte) {
					margin-right: 0;
				}

				a {
					&::before {
						content: 'BACK';
						right: 0;
						left: auto;
					}

					&::after {
						right: auto;
						left: 5px;
						transform: rotate(-135deg);

						@media (--sm-lte) {
							right: auto;
							left: calc(4 / 750 * 100vw);
						}
					}
				}
			}

			._next._noanc,
			._prev._noanc {
				display: block;
				overflow: hidden;
				width: 60px;
				position: relative;
				top: 3px;
				text-indent: -999px;
				letter-spacing: 0.05em;
				opacity: 0.3;

				@media (--sm-lte) {
					position: absolute;
					top: calc(4 / 750 * 100vw);
					right: 0;
					width: calc(102 / 750 * 100vw);
					letter-spacing: 0;
				}

				&::before {
					content: 'NEXT';
					font-family: $font-family-secondary;
					font-weight: bold;
					font-size: 1.5rem;
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					text-indent: 0;

					@media (--sm-lte) {
						font-size: calc(28 / 750 * 100vw);
					}
				}

				&::after {
					content: '';
					display: block;
					position: absolute;
					top: 13px;
					right: 5px;
					width: 6px;
					height: 6px;
					border-top: 2px solid $darkest-color;
					border-right: 2px solid $darkest-color;
					transform: rotate(45deg);
					transition: 300ms;

					@media (--sm-lte) {
						width: calc(12 / 750 * 100vw);
						height: calc(12 / 750 * 100vw);
						top: calc(22 / 750 * 100vw);
						right: calc(4 / 750 * 100vw);
						border-top-width: 1px;
						border-right-width: 1px;
					}
				}
			}

			._prev._noanc {
				&::before {
					content: 'BACK';
					right: 0;
					left: auto;
				}

				&::after {
					right: auto;
					left: 5px;
					transform: rotate(-135deg);

					@media (--sm-lte) {
						right: auto;
						left: calc(4 / 750 * 100vw);
					}
				}
			}
		}

		._record {
			display: flex;
			flex-wrap: wrap;
			border: 1px solid $darkest-color;
			padding: 0 0 23px;
			margin-bottom: 20px;

			@media (--sm-lte) {
				padding-bottom: calc(46 / 750 * 100vw);
				margin-bottom: calc(40 / 750 * 100vw);
			}

			&::after {
				display: none;
			}

			._id {
				order: 0;
				padding: 15px 0 15px 30px;
				color: $secondary-color;
				font-size: 2.2rem;
				font-family: $font-family-secondary;
				font-weight: bold;
				background: $gray-color02;

				@media (--sm-lte) {
					padding: calc(20 / 750 * 100vw) 0 calc(20 / 750 * 100vw) calc(30 / 750 * 100vw);
					font-size: calc(36 / 750 * 100vw);
					line-height: 1.8;
				}

				&::after {
					content: '.';
				}
			}

			._title {
				order: 1;
				letter-spacing: -0.01em;
				background: $gray-color02;
				flex: 1;
				font-size: 2.2rem;
				font-weight: bold;
				margin: 0;

				@media (--sm-lte) {
					font-size: calc(36 / 750 * 100vw);
					line-height: 1.8;
				}

				a {
					text-decoration: none;
					display: block;
					padding: 12px 30px 15px 8px;
					color: $darkest-color;

					@media (--sm-gt) {
						transition: opacity 300ms;

						&:hover {
							opacity: 0.6;
						}
					}

					@media (--sm-lte) {
						padding: calc(20 / 750 * 100vw) calc(30 / 750 * 100vw) calc(20 / 750 * 100vw) calc(10 / 750 * 100vw);
					}

					&:visited {
						color: $darkest-color !important; // stylelint-disable-line declaration-no-important
					}
				}
			}

			._thumb {
				order: 3;
				width: 210px;
				margin-left: 30px;

				@media (--sm-lte) {
					width: 100%;
					margin-left: 0;
					padding: 0 calc(30 / 750 * 100vw);
				}

				img {
					width: 100%;
					max-width: none;
					max-height: none;
					margin-right: 0;
					border: 0;
				}
			}

			._nearby {
				order: 4;
				margin: -10px 0 0;
				letter-spacing: 0.055em;
				padding: 0 20px 0 30px;
				line-height: 1.9;
				font-size: inherit;
				width: 100%;

				@media (--sm-lte) {
					margin-top: 0;
					padding: 0 calc(30 / 750 * 100vw);
					font-size: calc(28 / 750 * 100vw);
					line-height: 1.8;
				}
			}

			._url {
				order: 2;
				width: 930px;
				visibility: hidden;
				height: 30px;

				@media (--sm-lte) {
					width: 100%;
					height: calc(40 / 750 * 100vw);
				}
			}
		}

		._pbox-recommend {
			border: 0;

			._caption {
				margin-bottom: 13px;

				@media (--sm-lte) {
					margin-bottom: calc(20 / 750 * 100vw);
				}
			}

			._record {
				padding: 20px 20px 14px;
				display: grid;
				grid-template-columns: 170px calc(100% - 170px);
				grid-template-rows: auto 1fr;

				@media (--sm-lte) {
					padding: calc(40 / 750 * 100vw);
					grid-template-columns: calc(220 / 750 * 100vw) calc(100% - (200 / 750 * 100vw));
				}
			}

			._image {
				width: 150px;
				grid-column: 1 / 2;
				grid-row: 1 / 3;

				@media (--sm-lte) {
					width: calc(200 / 750 * 100vw);
				}

				img {
					width: 100%;
					display: block;
					margin: 0;
				}
			}

			._summary {
				order: 2;
				line-height: 1.7;
				letter-spacing: 0.065em;
				grid-column: 2 / 3;
				grid-row: 2;

				@media (--sm-lte) {
					display: none;
				}
			}

			._title {
				background: none;
				margin: -9px 0 5px;
				grid-column: 2 / 3;
				grid-row: 1;

				@media (--sm-lte) {
					line-height: 1.45;
					margin: calc(-4 / 750 * 100vw) 0 0;
					font-size: calc(32 / 750 * 100vw);
				}

				a {
					font-feature-settings: normal;
					padding: 0;
					letter-spacing: 0.06em;
				}
			}

			._url {
				display: none;
			}
		}
	}

	// マークdl

	dl.mark-list {
		display: flex;
		margin: 0;

		dd {
			flex: 1;
			margin: 0;
		}
	}

	//test 後で消す
	.design-title {
		background-color: $gray-color06;
		text-align: center;
		margin: 0 0 1em;
		font-weight: bold;
		border-radius: 15px;
	}

	// FIT女子会
	[data-path='/gakusei/bukatsu/fit_joshikai'] & {
		a {
			color: $pink-color02;
		}

		h2 {
			&::after {
				background: $pink-color02;
			}
		}

		h3 {
			border-left-color: $pink-color02;
		}

		h4 {
			&::after {
				background: $pink-color02;
			}
		}

		h6 {
			&::before {
				border-color: $pink-color02;
			}
		}

		ul {
			li {
				&::before {
					background: $pink-color02;
				}

				&.link-list {
					a {
						color: inherit;

						&:hover {
							color: $pink-color02;
						}
					}
				}
			}
		}
	}

	//各学科ページ 学科オリジナルサイトボタン
	.original-site-btn[data-bgb] {
		position: absolute;
		top: 0;
		right: 0;
		margin-top: -133px !important; // stylelint-disable-line declaration-no-important

		[data-path='/gakubu/kougaku/'] &,
		[data-path='/gakubu/joho_kougaku/'] &,
		[data-path='/gakubu/syakai_kankyo/'] &,
		[data-path='/gakubu/jc/'] & {
			@media (--sm-gt) {
				margin-top: -81px !important; // stylelint-disable-line declaration-no-important
			}
		}

		@media (--sm-lte) {
			top: 0;
			right: 0;
			left: 0;
			margin: 0 !important; // stylelint-disable-line declaration-no-important
			margin-top: 0vw !important; // stylelint-disable-line declaration-no-important
			transform: translateY(-150%);
		}
	}

	.department-image {
		@media (--sm-lte) {
			[data-path='/gakubu/kougaku/'] &,
			[data-path='/gakubu/joho_kougaku/'] &,
			[data-path='/gakubu/syakai_kankyo/'] &,
			[data-path='/gakubu/jc/'] &,
			[data-path='/gakubu/jc/joho_media/'] & {
				margin-top: 26vw !important; // stylelint-disable-line declaration-no-important

				> div {
					margin-bottom: 0;
				}
			}
		}
	}

	//受験生ブログブロック
	.juken-blog-block {
		.bgt-link__box {
			@media (--sm-gt) {
				display: flex;
			}
		}

		.bgt-box__image-container {
			margin-bottom: 0;

			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
			}

			@media (--sm-gt) {
				width: 50%;
			}
		}

		.bgt-box__caption {
			background: $gray-color02;
			padding: 33px 40px;
			line-height: 1.6;
			font-size: 2.2rem;
			letter-spacing: 0.1em;
			font-weight: 500;

			@media (--sm-lte) {
				padding: calc(26 / 750 * 100vw) calc(40 / 750 * 100vw);
			}

			span {
				font-weight: normal;
				font-size: 0.65em;
				display: block;
				margin-top: 0.5em;
			}

			@media (--sm-gt) {
				width: 50%;
			}
		}
	}
}

.c-content-main {
	.pc_only,
	[data-device='pc'] {
		@media (--sm-lte) {
			display: none;
		}
	}

	.sp_only,
	[data-device='sp'] {
		@media (--sm-gt) {
			display: none;
		}

		@media print {
			display: none;
		}
	}
}

// stylelint-enable selector-class-pattern, selector-nested-pattern
