// # 全般
// ========== ========== ========== ========== ==========
//
// サイトで最も明るい色
$lightest-color: #fff;
// サイトで最も暗い色
$darkest-color: #101010;
// メインカラー
$primary-color: #0d212e;
$primary-color-lighter: rgba(146, 160, 170, 0.5);
$primary-color-lighter2: #3d4d58;
$primary-color-darker: #05141e;
$primary-color-darker2: rgba(17, 19, 20, 0.5);
$primary-color-darker3: #004e81;
$primary-color-darker4: #384e5c;
$primary-color-darker5: #111314;
$primary-color-darker6: #0b1b25;
$primary-color-darker7: #081925;
$primary-color-darker8: #2d3f4a;
$primary-color-darker9: #3d4c56;
$primary-color-darker10: #0c293b;
$primary-color-darker11: #314958;
$primary-color-darker12: #0e212e;
$primary-color-darker-renew7: #06294a;
// サブカラー
$secondary-color: #00a2e6;
$secondary-color-lighter: #729cad;
$secondary-color-lighter2: #739aaa;
$secondary-color-lighter3: #dfedf1;
$secondary-color-lighter4: #92a0aa;
$secondary-color-lighter5: #08a4e6;
$secondary-color-lighter6: #cdd0d2;
$secondary-color-lighter7: #1ca9e4;
$secondary-color-lighter8: #5a6d7a;
$secondary-color-lighter9: #73c0e0;
$secondary-color-lighter10: #4953b5;
$secondary-color-darker: darken($secondary-color, 30%); // 未使用
$secondary-color-darker2: darken($secondary-color, 50%); // 未使用
// アクセントカラー
$accent-color: #f07100; // 未使用
$accent-color02: #fffca3;
// グレイカラー
$gray-color: #787878;
$gray-color02: #f3f3f3;
$gray-color03: #c8cfd4;
$gray-color04: rgba(45, 45, 45, 0.5);
$gray-color05: rgba(0, 0, 0, 0.2);
$gray-color06: #ababab;
$gray-color07: #f4f5f6;
$gray-color08: #d3d9dd;
$gray-color09: #dde4e9;
$gray-color10: #b1c0cd;
$gray-color11: #92a7b9;
$gray-color12: #696969;
$gray-color13: #161616;
$gray-color14: #f2f2f2;
$gray-color15: #333;
$gray-color16: #e3e3e3;

// ピンクカラー
$pink-color: #f6e9e9;
$pink-color02: #e60057;
$pink-color03: #ffc5b3;

// イエローカラー
$yellow-color: #ffec6b;

$hanrei-color01: #2e9362;
$hanrei-color02: #d0613b;
$hanrei-color03: #e13737;
$hanrei-color04: #3a8cc1;
$hanrei-color05: #863ab1;
$hanrei-color06: #b04c82;

$kankyo-color01: #17456f;
$kankyo-color02: #edf3f8;
$kankyo-color03: #97331b;
$kankyo-color04: #fdf0ed;

// グリーンカラー
$green-color: #9cf6cd;
// 警告カラー
$warning-color: #ad1313;

//NEWS borderカラー
$news-border-color: rgba(146, 160, 170, 0.4);

//footer link borderカラー
$footer-link-border-color: rgba(255, 255, 255, 0.4);

// ses カラー
$ses-primary-color: #00ae34;
$ses-primary-color-darker: #00781f;
$ses-primary-color-btn-back: #788e80;

// grd カラー
$grd-primary-color: #700000;
$grd-primary-color-lighter: #b77f7f;
$grd-primary-color-darker: #5f0000;
$grd-primary-color-line: #999;

// jc カラー
$jc-primary-color: #c80000;
$jc-primary-color-lighter: #f90;
$jc-primary-color-darker: #c57600;
$jc-primary-color-darker2: #e78b00;
$jc-primary-color-link: #1d3994;
$jc-primary-color-link02: #551a8b;
$jc-primary-color-link03: #5699ff;

// # タイポグラフィ
// ========== ========== ========== ========== ==========
//
// 標準のテキストの色
$font-primary-color: $darkest-color; // 未使用
// 選択時の背景色（ブラウザデフォルト: #B3D4FF）
$selection-background-color: $primary-color-lighter; // 未使用

// # ボックスモデル
// ========== ========== ========== ========== ==========
//
// 標準の境界線の色
$border-primary-color: #d2cdcd; // 未使用

// # フォーム
// ========== ========== ========== ========== ==========
//
// フォーム要素フォーカス時のグローカラー
$btn-glow-color: #0570c7; // 未使用
