// stylelint-disable selector-class-pattern, selector-nested-pattern
//
// メインコンテンツ（content-mainコンポーネント）の内容はCMSなどで管理されるHTMLを含んだり、
// ページ独自のスタイルを扱うことが多いので例外的に専用のルールを設ける。
//
// メインコンテンツのエレメントは基本的に自由である。
// ただし、クラス名は`c-`で開始してはいけない。もちろん 【`c-` + `コンポーネント名` + `__` + `エレメント名`】 という規則でクラス名をつけない。
// さらにそのルールに従って、メインコンテンツの中にコンポーネントを内包してはいけない。
//
// 🍔 マークは BurgerEditor専用の意味で、利用しない場合は削除する
//
.bge-contents, // 🍔
.c-content-main {
	p {
		margin: 0; //サンプル
	}

	a {
		color: $ses-primary-color;
	}

	h2 {
		font-size: 1.24rem;
		font-weight: 200;
		padding: 14px 0;
		color: $ses-primary-color-darker;
		border-bottom: 1px solid $ses-primary-color;
		font-family: $font-family-ses-primary;

		&::before,
		&::after {
			display: none;
		}
	}

	h3 {
		font-size: 1.16rem;
		border-color: $ses-primary-color;
	}

	h4 {
		font-size: 1.08rem;

		&::before {
			background: $ses-primary-color-darker;
		}

		&::after {
			background: $ses-primary-color;
		}
	}

	h5 {
		font-size: 1rem;
	}

	h6 {
		font-size: 0.92rem;

		&::before {
			width: 6px;
			height: 6px;
			border: 4px solid $ses-primary-color;
			top: calc(1em - 7px);
		}
	}

	ul {
		&.indent-none {
			padding-left: 0;
		}

		> li {
			font-size: 1rem;

			&::before {
				top: 11px;
				background: $ses-primary-color;
			}
		}
	}

	ol,
	ol.list-ol-normal {
		> li {
			&::before {
				color: $ses-primary-color;
				font-size: 1.1rem;
				width: 1.4em;
				margin-top: -3px;
			}
		}
	}

	.bgt-btn,
	.btn-link a {
		font-size: 1rem;
		color: $ses-primary-color;
		background: $lightest-color;
		border: solid 2px $ses-primary-color;

		@media (--sm-lte) {
			font-size: calc(34 / 750 * 100vw);
		}

		&::after {
			width: 18px;
			height: 6px;
			border: none;
			background: url('/images/common/icon05.gif');
			background-size: cover;
			transform: none;
		}
	}

	[data-bgt-button-kind='link'] .bgt-btn > span {
		color: $ses-primary-color;
	}

	[data-bgt-button-kind='em'] .bgt-btn {
		background-color: $ses-primary-color;
		border: none;

		&::after {
			width: 18px;
			height: 6px;
			background: url('/images/common/icon04.png');
			background-size: cover;
		}
	}

	[data-bgt-button-kind='back'] .bgt-btn {
		background-color: $ses-primary-color-btn-back;
		border: none;

		&::before {
			width: 18px;
			height: 6px;
			border: none;
			background: url('/images/common/icon04.png');
			background-size: cover;
			transform: scale(-1, 1) rotate(0);
		}
	}

	[data-bgt-button-kind='external'] .bgt-btn > span {
		color: $ses-primary-color;
	}

	[data-bgt-button-kind='external'] .bgt-btn,
	.btn.external a {
		background-color: $lightest-color;
		border: 2px solid $ses-primary-color;

		&::after {
			background: url('/img/icon-external-btn-ses.png') no-repeat;
			background-size: cover;
		}
	}

	.bgt-download-file__link,
	.btn-download a {
		color: $ses-primary-color;
		background: $lightest-color;
		border: 2px solid $ses-primary-color;

		&::after {
			background: url('/img/icon-pdf-green.svg') no-repeat;
		}
	}

	// galleryタイプ
	[data-bgt='gallery'] {
		.bgt-gallery {
			&__img {
				img {
					object-fit: cover;
					margin: 0;
				}
			}

			&-ctrl {
				&__prev,
				&__next {
					background-color: $ses-primary-color;
					padding: 12px;

					&::after {
						width: 16px;
						height: 16px;
						border-width: 3px 3px 0 0;
					}
				}

				&__prev {
					transform: none;

					&::after {
						transform-origin: center center;
						transform: rotate(-135deg) translate(-3px, 3px);
					}
				}

				&__next {
					transform: none;

					&::after {
						transform-origin: center center;
						transform: rotate(45deg) translate(-3px, 3px);
					}
				}
			}
		}
	}
}

// stylelint-enable selector-class-pattern, selector-nested-pattern
