@charset "UTF-8";
@custom-media --xs screen and (max-width: 576px);
@custom-media --sm screen and (min-width: 576px) and (max-width: 767px);
@custom-media --md screen and (min-width: 768px) and (max-width: 969px);
@custom-media --lg screen and (min-width: 970px) and (max-width: 1149px);
@custom-media --xl screen and (min-width: 1150px);
@custom-media --xs-lte screen and (max-width: 575px);
@custom-media --sm-lte screen and (max-width: 767px);
@custom-media --md-lte screen and (max-width: 969px);
@custom-media --md02-lte screen and (max-width: 1039px);
@custom-media --lg-lte screen and (max-width: 1149px);
@custom-media --lg02-lte screen and (max-width: 1229px);
@custom-media --xl-lte screen and (max-width: 1399px);
@custom-media --h-lte screen and (max-height: 759px);
@custom-media --xs-gt screen and (min-width: 576px);
@custom-media --sm-gt screen and (min-width: 768px);
@custom-media --md-gt screen and (min-width: 970px);
@custom-media --lg-gt screen and (min-width: 1150px);
@custom-media --lg02-gt screen and (min-width: 1230px);
@custom-media --xl-gt screen and (min-width: 1400px);
@custom-media --xxl-gt screen and (min-width: 1800px);
@custom-media --hr (min-resolution: 2dppx);
@font-face {
  font-family: YuGothicM;
  src: local("Yu Gothic Medium");
}
@font-face {
  font-family: icons;
  font-style: normal;
  font-weight: normal;
  src: url("../font/icons.eot");
  src: url("../font/icons.eot?#iefix") format("embedded-opentype"), url("../font/icons.woff") format("woff"), url("../font/icons.ttf") format("truetype");
}
.bge-contents,
.c-content-main {
  font-size: 1.6rem;
  letter-spacing: 0.08em;
  line-height: 2;
  overflow-wrap: break-all;
  word-break: break-all;
  /**
   * メールフォーム
   *
   */
}
@media (--xs) {
  .bge-contents,
.c-content-main {
    font-size: 4vw;
  }
}
@media (--sm-lte) {
  .bge-contents img,
.c-content-main img {
    max-width: 100%;
  }
}
[lang=en] .bge-contents,
[lang=en] .c-content-main {
  word-break: normal;
  word-wrap: break-word;
  line-height: 1.7;
}
.bge-contents a,
.c-content-main a {
  color: #00a2e6;
}
.bge-contents a figcaption,
.c-content-main a figcaption {
  color: #101010;
}
.bge-contents a:hover,
.c-content-main a:hover {
  text-decoration: none;
}
.bge-contents a:visited,
.c-content-main a:visited {
  color: #4953b5 !important;
}
@media (--sm-gt) {
  .bge-contents p a[href^="tel:"],
.bge-contents table a[href^="tel:"],
.bge-contents ul a[href^="tel:"],
.bge-contents ol a[href^="tel:"],
.c-content-main p a[href^="tel:"],
.c-content-main table a[href^="tel:"],
.c-content-main ul a[href^="tel:"],
.c-content-main ol a[href^="tel:"] {
    color: #101010;
    pointer-events: none;
    text-decoration: none;
  }
}
.bge-contents p a[target=_blank]::after,
.bge-contents table a[target=_blank]::after,
.bge-contents ul a[target=_blank]::after,
.bge-contents ol a[target=_blank]::after,
.c-content-main p a[target=_blank]::after,
.c-content-main table a[target=_blank]::after,
.c-content-main ul a[target=_blank]::after,
.c-content-main ol a[target=_blank]::after {
  content: "";
  width: 13px;
  height: 13px;
  background: url("<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='13' height='13' viewBox='0 0 13 13' xml:space='preserve'><polygon points='10.5,13 0,13 0,2.5 1,2.5 1,12 10.5,12' fill='#0d212e'/><path d='M12,1v8H4V1H12 M13,0H3v10h10V0L13,0z' fill='#0d212e'/></svg>") no-repeat center center/100% 100%;
  display: inline-block;
  margin: 0 5px;
}
@media (--sm-lte) {
  .bge-contents p a[target=_blank]::after,
.bge-contents table a[target=_blank]::after,
.bge-contents ul a[target=_blank]::after,
.bge-contents ol a[target=_blank]::after,
.c-content-main p a[target=_blank]::after,
.c-content-main table a[target=_blank]::after,
.c-content-main ul a[target=_blank]::after,
.c-content-main ol a[target=_blank]::after {
    width: 3.4666666667vw;
    height: 3.4666666667vw;
    margin: 0 1.3333333333vw;
  }
}
.bge-contents p a[href$=pdf], .bge-contents p a[href*=".pdf#"], .bge-contents p a[href$=ppt], .bge-contents p a[href$=pptx], .bge-contents p a[href$=doc], .bge-contents p a[href$=docx], .bge-contents p a[href$=xls], .bge-contents p a[href$=xlsx], .bge-contents p a[href$=zip],
.bge-contents table a[href$=pdf],
.bge-contents table a[href*=".pdf#"],
.bge-contents table a[href$=ppt],
.bge-contents table a[href$=pptx],
.bge-contents table a[href$=doc],
.bge-contents table a[href$=docx],
.bge-contents table a[href$=xls],
.bge-contents table a[href$=xlsx],
.bge-contents table a[href$=zip],
.bge-contents ul a[href$=pdf],
.bge-contents ul a[href*=".pdf#"],
.bge-contents ul a[href$=ppt],
.bge-contents ul a[href$=pptx],
.bge-contents ul a[href$=doc],
.bge-contents ul a[href$=docx],
.bge-contents ul a[href$=xls],
.bge-contents ul a[href$=xlsx],
.bge-contents ul a[href$=zip],
.bge-contents ol a[href$=pdf],
.bge-contents ol a[href*=".pdf#"],
.bge-contents ol a[href$=ppt],
.bge-contents ol a[href$=pptx],
.bge-contents ol a[href$=doc],
.bge-contents ol a[href$=docx],
.bge-contents ol a[href$=xls],
.bge-contents ol a[href$=xlsx],
.bge-contents ol a[href$=zip],
.c-content-main p a[href$=pdf],
.c-content-main p a[href*=".pdf#"],
.c-content-main p a[href$=ppt],
.c-content-main p a[href$=pptx],
.c-content-main p a[href$=doc],
.c-content-main p a[href$=docx],
.c-content-main p a[href$=xls],
.c-content-main p a[href$=xlsx],
.c-content-main p a[href$=zip],
.c-content-main table a[href$=pdf],
.c-content-main table a[href*=".pdf#"],
.c-content-main table a[href$=ppt],
.c-content-main table a[href$=pptx],
.c-content-main table a[href$=doc],
.c-content-main table a[href$=docx],
.c-content-main table a[href$=xls],
.c-content-main table a[href$=xlsx],
.c-content-main table a[href$=zip],
.c-content-main ul a[href$=pdf],
.c-content-main ul a[href*=".pdf#"],
.c-content-main ul a[href$=ppt],
.c-content-main ul a[href$=pptx],
.c-content-main ul a[href$=doc],
.c-content-main ul a[href$=docx],
.c-content-main ul a[href$=xls],
.c-content-main ul a[href$=xlsx],
.c-content-main ul a[href$=zip],
.c-content-main ol a[href$=pdf],
.c-content-main ol a[href*=".pdf#"],
.c-content-main ol a[href$=ppt],
.c-content-main ol a[href$=pptx],
.c-content-main ol a[href$=doc],
.c-content-main ol a[href$=docx],
.c-content-main ol a[href$=xls],
.c-content-main ol a[href$=xlsx],
.c-content-main ol a[href$=zip] {
  border-color: #101010;
  display: inline-block;
  font-feature-settings: normal;
  position: relative;
}
.bge-contents p a[href$=pdf]::after, .bge-contents p a[href*=".pdf#"]::after, .bge-contents p a[href$=ppt]::after, .bge-contents p a[href$=pptx]::after, .bge-contents p a[href$=doc]::after, .bge-contents p a[href$=docx]::after, .bge-contents p a[href$=xls]::after, .bge-contents p a[href$=xlsx]::after, .bge-contents p a[href$=zip]::after,
.bge-contents table a[href$=pdf]::after,
.bge-contents table a[href*=".pdf#"]::after,
.bge-contents table a[href$=ppt]::after,
.bge-contents table a[href$=pptx]::after,
.bge-contents table a[href$=doc]::after,
.bge-contents table a[href$=docx]::after,
.bge-contents table a[href$=xls]::after,
.bge-contents table a[href$=xlsx]::after,
.bge-contents table a[href$=zip]::after,
.bge-contents ul a[href$=pdf]::after,
.bge-contents ul a[href*=".pdf#"]::after,
.bge-contents ul a[href$=ppt]::after,
.bge-contents ul a[href$=pptx]::after,
.bge-contents ul a[href$=doc]::after,
.bge-contents ul a[href$=docx]::after,
.bge-contents ul a[href$=xls]::after,
.bge-contents ul a[href$=xlsx]::after,
.bge-contents ul a[href$=zip]::after,
.bge-contents ol a[href$=pdf]::after,
.bge-contents ol a[href*=".pdf#"]::after,
.bge-contents ol a[href$=ppt]::after,
.bge-contents ol a[href$=pptx]::after,
.bge-contents ol a[href$=doc]::after,
.bge-contents ol a[href$=docx]::after,
.bge-contents ol a[href$=xls]::after,
.bge-contents ol a[href$=xlsx]::after,
.bge-contents ol a[href$=zip]::after,
.c-content-main p a[href$=pdf]::after,
.c-content-main p a[href*=".pdf#"]::after,
.c-content-main p a[href$=ppt]::after,
.c-content-main p a[href$=pptx]::after,
.c-content-main p a[href$=doc]::after,
.c-content-main p a[href$=docx]::after,
.c-content-main p a[href$=xls]::after,
.c-content-main p a[href$=xlsx]::after,
.c-content-main p a[href$=zip]::after,
.c-content-main table a[href$=pdf]::after,
.c-content-main table a[href*=".pdf#"]::after,
.c-content-main table a[href$=ppt]::after,
.c-content-main table a[href$=pptx]::after,
.c-content-main table a[href$=doc]::after,
.c-content-main table a[href$=docx]::after,
.c-content-main table a[href$=xls]::after,
.c-content-main table a[href$=xlsx]::after,
.c-content-main table a[href$=zip]::after,
.c-content-main ul a[href$=pdf]::after,
.c-content-main ul a[href*=".pdf#"]::after,
.c-content-main ul a[href$=ppt]::after,
.c-content-main ul a[href$=pptx]::after,
.c-content-main ul a[href$=doc]::after,
.c-content-main ul a[href$=docx]::after,
.c-content-main ul a[href$=xls]::after,
.c-content-main ul a[href$=xlsx]::after,
.c-content-main ul a[href$=zip]::after,
.c-content-main ol a[href$=pdf]::after,
.c-content-main ol a[href*=".pdf#"]::after,
.c-content-main ol a[href$=ppt]::after,
.c-content-main ol a[href$=pptx]::after,
.c-content-main ol a[href$=doc]::after,
.c-content-main ol a[href$=docx]::after,
.c-content-main ol a[href$=xls]::after,
.c-content-main ol a[href$=xlsx]::after,
.c-content-main ol a[href$=zip]::after {
  background: url("/img/icon-pdf-color.png") no-repeat;
  background-size: 15px 20px;
  content: "";
  display: inline-block;
  height: 20px;
  width: 15px;
  margin: 0 8px;
  vertical-align: -4px;
}
@media (--sm-lte) {
  .bge-contents p a[href$=pdf]::after, .bge-contents p a[href*=".pdf#"]::after, .bge-contents p a[href$=ppt]::after, .bge-contents p a[href$=pptx]::after, .bge-contents p a[href$=doc]::after, .bge-contents p a[href$=docx]::after, .bge-contents p a[href$=xls]::after, .bge-contents p a[href$=xlsx]::after, .bge-contents p a[href$=zip]::after,
.bge-contents table a[href$=pdf]::after,
.bge-contents table a[href*=".pdf#"]::after,
.bge-contents table a[href$=ppt]::after,
.bge-contents table a[href$=pptx]::after,
.bge-contents table a[href$=doc]::after,
.bge-contents table a[href$=docx]::after,
.bge-contents table a[href$=xls]::after,
.bge-contents table a[href$=xlsx]::after,
.bge-contents table a[href$=zip]::after,
.bge-contents ul a[href$=pdf]::after,
.bge-contents ul a[href*=".pdf#"]::after,
.bge-contents ul a[href$=ppt]::after,
.bge-contents ul a[href$=pptx]::after,
.bge-contents ul a[href$=doc]::after,
.bge-contents ul a[href$=docx]::after,
.bge-contents ul a[href$=xls]::after,
.bge-contents ul a[href$=xlsx]::after,
.bge-contents ul a[href$=zip]::after,
.bge-contents ol a[href$=pdf]::after,
.bge-contents ol a[href*=".pdf#"]::after,
.bge-contents ol a[href$=ppt]::after,
.bge-contents ol a[href$=pptx]::after,
.bge-contents ol a[href$=doc]::after,
.bge-contents ol a[href$=docx]::after,
.bge-contents ol a[href$=xls]::after,
.bge-contents ol a[href$=xlsx]::after,
.bge-contents ol a[href$=zip]::after,
.c-content-main p a[href$=pdf]::after,
.c-content-main p a[href*=".pdf#"]::after,
.c-content-main p a[href$=ppt]::after,
.c-content-main p a[href$=pptx]::after,
.c-content-main p a[href$=doc]::after,
.c-content-main p a[href$=docx]::after,
.c-content-main p a[href$=xls]::after,
.c-content-main p a[href$=xlsx]::after,
.c-content-main p a[href$=zip]::after,
.c-content-main table a[href$=pdf]::after,
.c-content-main table a[href*=".pdf#"]::after,
.c-content-main table a[href$=ppt]::after,
.c-content-main table a[href$=pptx]::after,
.c-content-main table a[href$=doc]::after,
.c-content-main table a[href$=docx]::after,
.c-content-main table a[href$=xls]::after,
.c-content-main table a[href$=xlsx]::after,
.c-content-main table a[href$=zip]::after,
.c-content-main ul a[href$=pdf]::after,
.c-content-main ul a[href*=".pdf#"]::after,
.c-content-main ul a[href$=ppt]::after,
.c-content-main ul a[href$=pptx]::after,
.c-content-main ul a[href$=doc]::after,
.c-content-main ul a[href$=docx]::after,
.c-content-main ul a[href$=xls]::after,
.c-content-main ul a[href$=xlsx]::after,
.c-content-main ul a[href$=zip]::after,
.c-content-main ol a[href$=pdf]::after,
.c-content-main ol a[href*=".pdf#"]::after,
.c-content-main ol a[href$=ppt]::after,
.c-content-main ol a[href$=pptx]::after,
.c-content-main ol a[href$=doc]::after,
.c-content-main ol a[href$=docx]::after,
.c-content-main ol a[href$=xls]::after,
.c-content-main ol a[href$=xlsx]::after,
.c-content-main ol a[href$=zip]::after {
    height: 5.3333333333vw;
    width: 4vw;
    background-size: 100% 100%;
    margin: 0 1.3333333333vw;
  }
}
.bge-contents p a[href$=ppt]::after, .bge-contents p a[href$=pptx]::after,
.bge-contents table a[href$=ppt]::after,
.bge-contents table a[href$=pptx]::after,
.bge-contents ul a[href$=ppt]::after,
.bge-contents ul a[href$=pptx]::after,
.bge-contents ol a[href$=ppt]::after,
.bge-contents ol a[href$=pptx]::after,
.c-content-main p a[href$=ppt]::after,
.c-content-main p a[href$=pptx]::after,
.c-content-main table a[href$=ppt]::after,
.c-content-main table a[href$=pptx]::after,
.c-content-main ul a[href$=ppt]::after,
.c-content-main ul a[href$=pptx]::after,
.c-content-main ol a[href$=ppt]::after,
.c-content-main ol a[href$=pptx]::after {
  background-image: url("/img/icon-ppt-color.png");
}
.bge-contents p a[href$=doc]::after, .bge-contents p a[href$=docx]::after,
.bge-contents table a[href$=doc]::after,
.bge-contents table a[href$=docx]::after,
.bge-contents ul a[href$=doc]::after,
.bge-contents ul a[href$=docx]::after,
.bge-contents ol a[href$=doc]::after,
.bge-contents ol a[href$=docx]::after,
.c-content-main p a[href$=doc]::after,
.c-content-main p a[href$=docx]::after,
.c-content-main table a[href$=doc]::after,
.c-content-main table a[href$=docx]::after,
.c-content-main ul a[href$=doc]::after,
.c-content-main ul a[href$=docx]::after,
.c-content-main ol a[href$=doc]::after,
.c-content-main ol a[href$=docx]::after {
  background-image: url("/img/icon-doc-color.png");
}
.bge-contents p a[href$=xls]::after, .bge-contents p a[href$=xlsx]::after,
.bge-contents table a[href$=xls]::after,
.bge-contents table a[href$=xlsx]::after,
.bge-contents ul a[href$=xls]::after,
.bge-contents ul a[href$=xlsx]::after,
.bge-contents ol a[href$=xls]::after,
.bge-contents ol a[href$=xlsx]::after,
.c-content-main p a[href$=xls]::after,
.c-content-main p a[href$=xlsx]::after,
.c-content-main table a[href$=xls]::after,
.c-content-main table a[href$=xlsx]::after,
.c-content-main ul a[href$=xls]::after,
.c-content-main ul a[href$=xlsx]::after,
.c-content-main ol a[href$=xls]::after,
.c-content-main ol a[href$=xlsx]::after {
  background-image: url("/img/icon-xls-color.png");
}
.bge-contents p a[href$=zip]::after,
.bge-contents table a[href$=zip]::after,
.bge-contents ul a[href$=zip]::after,
.bge-contents ol a[href$=zip]::after,
.c-content-main p a[href$=zip]::after,
.c-content-main table a[href$=zip]::after,
.c-content-main ul a[href$=zip]::after,
.c-content-main ol a[href$=zip]::after {
  background-image: url("/img/icon-zip-black.png");
}
.bge-contents p a.icon-external::after,
.bge-contents table a.icon-external::after,
.bge-contents ul a.icon-external::after,
.bge-contents ol a.icon-external::after,
.c-content-main p a.icon-external::after,
.c-content-main table a.icon-external::after,
.c-content-main ul a.icon-external::after,
.c-content-main ol a.icon-external::after {
  content: "";
  width: 13px;
  height: 13px;
  background: url("<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='13' height='13' viewBox='0 0 13 13' xml:space='preserve'><polygon points='10.5,13 0,13 0,2.5 1,2.5 1,12 10.5,12' fill='#0d212e'/><path d='M12,1v8H4V1H12 M13,0H3v10h10V0L13,0z' fill='#0d212e'/></svg>") no-repeat center center/100% 100%;
  display: inline-block;
  margin: 0 5px;
}
@media (--sm-lte) {
  .bge-contents p a.icon-external::after,
.bge-contents table a.icon-external::after,
.bge-contents ul a.icon-external::after,
.bge-contents ol a.icon-external::after,
.c-content-main p a.icon-external::after,
.c-content-main table a.icon-external::after,
.c-content-main ul a.icon-external::after,
.c-content-main ol a.icon-external::after {
    width: 3.4666666667vw;
    height: 3.4666666667vw;
    margin: 0 1.3333333333vw;
  }
}
.bge-contents ul li a + br + a,
.bge-contents ol li a + br + a,
.c-content-main ul li a + br + a,
.c-content-main ol li a + br + a {
  margin-top: 8px;
}
.bge-contents ul li + li,
.bge-contents ol li + li,
.c-content-main ul li + li,
.c-content-main ol li + li {
  margin-top: 8px;
}
.bge-contents h2,
.c-content-main h2 {
  position: relative;
  font-size: 2.8rem;
  font-weight: normal;
  line-height: 1.6;
  margin: 1em 0 25px;
  padding: 0 0 16px;
  color: #101010;
}
@media (--sm-lte) {
  .bge-contents h2,
.c-content-main h2 {
    font-size: 6.4vw;
  }
}
@media (--sm-gt) {
  [lang=en] .bge-contents h2,
[lang=en] .c-content-main h2 {
    font-size: 2.6rem;
  }
}
@media (--sm-lte) {
  [lang=en] .bge-contents h2,
[lang=en] .c-content-main h2 {
    padding: 0 0 10px;
  }
}
.bge-contents h2::before, .bge-contents h2::after,
.c-content-main h2::before,
.c-content-main h2::after {
  content: "";
  position: absolute;
  display: block;
  height: 2px;
  bottom: 0;
  left: 0;
}
.bge-contents h2::before,
.c-content-main h2::before {
  background: rgba(146, 160, 170, 0.5);
  width: 100%;
}
.bge-contents h2::after,
.c-content-main h2::after {
  background: #00a2e6;
  width: 120px;
}
.bge-contents h2:first-child,
.c-content-main h2:first-child {
  margin-top: 0;
}
.bge-contents h2 .title-en,
.c-content-main h2 .title-en {
  color: #696969;
  font-size: 1.4rem;
}
@media (--sm-lte) {
  .bge-contents h2 .title-en,
.c-content-main h2 .title-en {
    display: block;
    font-size: 3.7333333333vw;
    line-height: 1.4;
  }
}
.bge-contents .roman-title h2::first-letter,
.c-content-main .roman-title h2::first-letter {
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
}
.bge-contents .flag-title.bgb-text-image2,
.c-content-main .flag-title.bgb-text-image2 {
  border-bottom: 1px solid #c8cfd4;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  padding: 0 0 16px;
}
@media (--sm-lte) {
  .bge-contents .flag-title.bgb-text-image2,
.c-content-main .flag-title.bgb-text-image2 {
    padding: 0 0 2.6666666667vw;
  }
}
.bge-contents .flag-title.bgb-text-image2 > div,
.c-content-main .flag-title.bgb-text-image2 > div {
  float: none;
}
.bge-contents .flag-title.bgb-text-image2 > div:first-child,
.c-content-main .flag-title.bgb-text-image2 > div:first-child {
  width: 62px;
  height: 41px;
  position: static;
  flex: 0 0 auto;
  margin-right: 25px;
  padding: 0;
}
@media (--sm-lte) {
  .bge-contents .flag-title.bgb-text-image2 > div:first-child,
.c-content-main .flag-title.bgb-text-image2 > div:first-child {
    width: 12.4vw !important;
    height: 8.2vw;
    margin-right: 4vw;
    margin-bottom: 0;
  }
}
.bge-contents .flag-title.bgb-text-image2 > div:last-child,
.c-content-main .flag-title.bgb-text-image2 > div:last-child {
  padding: 0 !important;
}
.bge-contents .flag-title.bgb-text-image2 > div:last-child h2,
.c-content-main .flag-title.bgb-text-image2 > div:last-child h2 {
  padding: 0;
  margin: 0;
}
.bge-contents .flag-title.bgb-text-image2 > div:last-child h2::before, .bge-contents .flag-title.bgb-text-image2 > div:last-child h2::after,
.c-content-main .flag-title.bgb-text-image2 > div:last-child h2::before,
.c-content-main .flag-title.bgb-text-image2 > div:last-child h2::after {
  content: none;
}
.bge-contents .flag-title h2,
.c-content-main .flag-title h2 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
}
@media (--sm-lte) {
  .bge-contents .flag-title h2,
.c-content-main .flag-title h2 {
    padding: 0 0 2.6666666667vw;
  }
}
.bge-contents .flag-title h2::before,
.c-content-main .flag-title h2::before {
  width: 62px;
  height: 41px;
  position: static;
  flex: 0 0 auto;
  margin-right: 25px;
}
@media (--sm-lte) {
  .bge-contents .flag-title h2::before,
.c-content-main .flag-title h2::before {
    width: 12.4vw;
    height: 8.2vw;
    margin-right: 4vw;
  }
}
.bge-contents .flag-title h2::after,
.c-content-main .flag-title h2::after {
  background: #c8cfd4;
  width: 100%;
  height: 1px;
}
.bge-contents .flag-title.usa h2::before,
.c-content-main .flag-title.usa h2::before {
  background: url("/img/icon-usa.png") no-repeat center center/100% 100%;
}
.bge-contents .flag-title.tha h2::before,
.c-content-main .flag-title.tha h2::before {
  background: url("/img/icon-tha.png") no-repeat center center/100% 100%;
}
.bge-contents .flag-title.kor h2::before,
.c-content-main .flag-title.kor h2::before {
  background: url("/img/icon-kor.png") no-repeat center center/100% 100%;
}
.bge-contents .flag-title.chn h2::before,
.c-content-main .flag-title.chn h2::before {
  background: url("/img/icon-chn.png") no-repeat center center/100% 100%;
}
.bge-contents .flag-title.aus h2::before,
.c-content-main .flag-title.aus h2::before {
  background: url("/img/icon-aus.png") no-repeat center center/100% 100%;
}
.bge-contents .flag-title.alb h2::before,
.c-content-main .flag-title.alb h2::before {
  background: url("/img/icon-alb.png") no-repeat center center/100% 100%;
}
.bge-contents .flag-title.twn h2::before,
.c-content-main .flag-title.twn h2::before {
  background: url("/img/icon-twn.png") no-repeat center center/100% 100%;
}
.bge-contents .flag-title.ita h2::before,
.c-content-main .flag-title.ita h2::before {
  background: url("/img/icon-ita.png") no-repeat center center/100% 100%;
}
.bge-contents .bge-title-h2,
.c-content-main .bge-title-h2 {
  margin-top: 0;
}
.bge-contents h3,
.c-content-main h3 {
  font-size: 2.4rem;
  font-weight: normal;
  line-height: 1.5;
  background: #f3f3f3;
  margin: 1.5em 0 20px;
  padding: 10px 16px;
  border-left: 2px solid #00a2e6;
}
@media (--sm-lte) {
  .bge-contents h3,
.c-content-main h3 {
    font-size: 5.8666666667vw;
  }
}
@media (--sm-gt) {
  [lang=en] .bge-contents h3,
[lang=en] .c-content-main h3 {
    font-size: 2rem;
  }
}
.bge-contents h3:first-child,
.c-content-main h3:first-child {
  margin-top: 0;
}
.bge-contents .bge-title-h3,
.c-content-main .bge-title-h3 {
  margin-top: 0;
}
.bge-contents h4,
.c-content-main h4 {
  position: relative;
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.6;
  border-bottom: 1px solid rgba(146, 160, 170, 0.5);
  margin: 1.2em 0 20px;
  overflow-x: hidden;
  padding: 0 0 10px 15px;
}
@media (--sm-lte) {
  .bge-contents h4,
.c-content-main h4 {
    font-size: 5.3333333333vw;
  }
}
@media (--sm-gt) {
  [lang=en] .bge-contents h4,
[lang=en] .c-content-main h4 {
    font-size: 1.8rem;
  }
}
.bge-contents h4::before, .bge-contents h4::after,
.c-content-main h4::before,
.c-content-main h4::after {
  content: "";
  position: absolute;
  display: block;
  width: 2px;
  height: calc(50% - 4px);
  left: 0;
}
.bge-contents h4::before,
.c-content-main h4::before {
  background: #05141e;
  top: 0;
}
.bge-contents h4::after,
.c-content-main h4::after {
  background: #00a2e6;
  top: calc(50% - 4px);
}
.bge-contents h4:first-child,
.c-content-main h4:first-child {
  margin-top: 0;
}
.bge-contents h5,
.c-content-main h5 {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.7;
  margin: 1.2em 0 15px;
}
@media (--sm-lte) {
  .bge-contents h5,
.c-content-main h5 {
    font-size: 4.8vw;
  }
}
@media (--sm-gt) {
  [lang=en] .bge-contents h5,
[lang=en] .c-content-main h5 {
    font-size: 1.8rem;
  }
}
.bge-contents h5:first-child,
.c-content-main h5:first-child {
  margin-top: 0;
}
.bge-contents h6,
.c-content-main h6 {
  position: relative;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.8;
  margin: 1.2em 0 0;
  padding-left: 20px;
}
@media (--sm-lte) {
  .bge-contents h6,
.c-content-main h6 {
    font-size: 4.2666666667vw;
  }
}
@media (--sm-gt) {
  [lang=en] .bge-contents h6,
[lang=en] .c-content-main h6 {
    font-size: 1.6rem;
  }
}
.bge-contents h6::before,
.c-content-main h6::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 14px;
  background: #fff;
  border: 4px solid #00a2e6;
  border-radius: 50%;
  top: calc(1em - 7px);
  left: 0;
}
.bge-contents h6:first-child,
.c-content-main h6:first-child {
  margin-top: 0;
}
.bge-contents ul,
.c-content-main ul {
  padding-left: 20px;
}
@media (--sm-lte) {
  .bge-contents ul,
.c-content-main ul {
    padding-left: 0;
  }
}
.bge-contents ul.indent-none,
.c-content-main ul.indent-none {
  padding-left: 0;
}
.bge-contents ul > li,
.c-content-main ul > li {
  list-style: none;
  line-height: 1.7;
  font-size: 1.5rem;
  margin-bottom: 16px;
  padding-left: 15px;
  position: relative;
}
.bge-contents ul > li::before,
.c-content-main ul > li::before {
  content: "";
  display: inline-block;
  vertical-align: 3px;
  margin: 0 10px 0 -15px;
  width: 6px;
  height: 6px;
  background: #00a2e6;
  position: absolute;
  top: 10px;
}
@media (--sm-lte) {
  .bge-contents ul > li::before,
.c-content-main ul > li::before {
    top: 2.6666666667vw;
  }
}
.bge-contents ul > li[style*="text-align: center"]::before,
.c-content-main ul > li[style*="text-align: center"]::before {
  position: static;
}
.bge-contents ul > li > ul li::before,
.c-content-main ul > li > ul li::before {
  background: #729cad;
}
.bge-contents ul > li > ul > ul li::before,
.c-content-main ul > li > ul > ul li::before {
  background: #b1c0cd;
}
.bge-contents ul ul,
.bge-contents ul ol,
.c-content-main ul ul,
.c-content-main ul ol {
  margin-left: 5px;
  margin-top: 18px;
}
.bge-contents ol,
.bge-contents ol.list-ol-normal,
.c-content-main ol,
.c-content-main ol.list-ol-normal {
  padding-left: 0;
}
.bge-contents ol > li,
.bge-contents ol.list-ol-normal > li,
.c-content-main ol > li,
.c-content-main ol.list-ol-normal > li {
  list-style: none;
  line-height: 1.7;
  counter-increment: section01;
  margin-bottom: 18px;
  padding-left: 2.8em;
  position: relative;
}
.bge-contents ol > li::before,
.bge-contents ol.list-ol-normal > li::before,
.c-content-main ol > li::before,
.c-content-main ol.list-ol-normal > li::before {
  content: counter(section01, decimal) ". ";
  counter-increment: content-main-ol-default;
  color: #00a2e6;
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
  font-weight: bold;
  font-size: 1.6rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 2.4em;
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;
}
@media (--sm-lte) {
  .bge-contents ol > li::before,
.bge-contents ol.list-ol-normal > li::before,
.c-content-main ol > li::before,
.c-content-main ol.list-ol-normal > li::before {
    font-size: 3.7333333333vw;
  }
}
.bge-contents ol > li > ol > li,
.bge-contents ol > li ol.list-ol-normal-sub > li,
.bge-contents ol.list-ol-normal > li > ol > li,
.bge-contents ol.list-ol-normal > li ol.list-ol-normal-sub > li,
.c-content-main ol > li > ol > li,
.c-content-main ol > li ol.list-ol-normal-sub > li,
.c-content-main ol.list-ol-normal > li > ol > li,
.c-content-main ol.list-ol-normal > li ol.list-ol-normal-sub > li {
  counter-increment: section02;
}
.bge-contents ol > li > ol > li::before,
.bge-contents ol > li ol.list-ol-normal-sub > li::before,
.bge-contents ol.list-ol-normal > li > ol > li::before,
.bge-contents ol.list-ol-normal > li ol.list-ol-normal-sub > li::before,
.c-content-main ol > li > ol > li::before,
.c-content-main ol > li ol.list-ol-normal-sub > li::before,
.c-content-main ol.list-ol-normal > li > ol > li::before,
.c-content-main ol.list-ol-normal > li ol.list-ol-normal-sub > li::before {
  content: counter(section02, decimal) ". ";
  color: #739aaa;
}
.bge-contents ol > li > ol > li > ol > li,
.bge-contents ol > li ol.list-ol-normal-sub > li > ol > li,
.bge-contents ol.list-ol-normal > li > ol > li > ol > li,
.bge-contents ol.list-ol-normal > li ol.list-ol-normal-sub > li > ol > li,
.c-content-main ol > li > ol > li > ol > li,
.c-content-main ol > li ol.list-ol-normal-sub > li > ol > li,
.c-content-main ol.list-ol-normal > li > ol > li > ol > li,
.c-content-main ol.list-ol-normal > li ol.list-ol-normal-sub > li > ol > li {
  counter-increment: section03;
}
.bge-contents ol > li > ol > li > ol > li::before,
.bge-contents ol > li ol.list-ol-normal-sub > li > ol > li::before,
.bge-contents ol.list-ol-normal > li > ol > li > ol > li::before,
.bge-contents ol.list-ol-normal > li ol.list-ol-normal-sub > li > ol > li::before,
.c-content-main ol > li > ol > li > ol > li::before,
.c-content-main ol > li ol.list-ol-normal-sub > li > ol > li::before,
.c-content-main ol.list-ol-normal > li > ol > li > ol > li::before,
.c-content-main ol.list-ol-normal > li ol.list-ol-normal-sub > li > ol > li::before {
  content: counter(section03, decimal) ". ";
  color: #92a7b9;
}
.bge-contents ol ul,
.bge-contents ol ol,
.bge-contents ol.list-ol-normal ul,
.bge-contents ol.list-ol-normal ol,
.c-content-main ol ul,
.c-content-main ol ol,
.c-content-main ol.list-ol-normal ul,
.c-content-main ol.list-ol-normal ol {
  margin-left: 2px;
  margin-top: 18px;
}
.bge-contents .list-upper-alpha,
.c-content-main .list-upper-alpha {
  padding-left: 20px;
}
.bge-contents .list-upper-alpha li,
.c-content-main .list-upper-alpha li {
  counter-increment: section04;
  list-style-type: none;
  padding-left: 1.5em;
  position: relative;
}
.bge-contents .list-upper-alpha li::before,
.c-content-main .list-upper-alpha li::before {
  content: counter(section04, upper-alpha);
  color: #00a2e6;
  justify-content: flex-start;
  min-width: 1em;
  text-align: center;
}
.bge-contents .list-upper-alpha li::after,
.c-content-main .list-upper-alpha li::after {
  content: ".";
  color: #00a2e6;
  left: 1em;
  position: absolute;
  top: 0;
}
.bge-contents .list-upper-alpha-sub li,
.c-content-main .list-upper-alpha-sub li {
  counter-increment: section05 !important;
  list-style-type: none;
  padding-left: 1.5em;
  position: relative;
}
.bge-contents .list-upper-alpha-sub li::before,
.c-content-main .list-upper-alpha-sub li::before {
  content: counter(section05, upper-alpha) !important;
  color: #739aaa;
  justify-content: flex-start;
  min-width: 1em;
  text-align: center;
}
.bge-contents .list-upper-alpha-sub li::after,
.c-content-main .list-upper-alpha-sub li::after {
  content: ".";
  color: #739aaa;
  left: 1em;
  position: absolute;
  top: 0;
}
.bge-contents .list-lower-alpha li,
.c-content-main .list-lower-alpha li {
  counter-increment: section06;
  list-style-type: none;
  padding-left: 1.4em;
  position: relative;
}
.bge-contents .list-lower-alpha li::before,
.c-content-main .list-lower-alpha li::before {
  content: counter(section06, lower-alpha);
  color: #00a2e6;
  justify-content: flex-start;
  min-width: 0.8em;
  text-align: center;
}
.bge-contents .list-lower-alpha li::after,
.c-content-main .list-lower-alpha li::after {
  content: ".";
  color: #00a2e6;
  left: 0.9em;
  position: absolute;
  top: 0;
}
.bge-contents .list-lower-alpha-sub li,
.c-content-main .list-lower-alpha-sub li {
  counter-increment: section07 !important;
  list-style-type: none;
  padding-left: 1.4em;
  position: relative;
}
.bge-contents .list-lower-alpha-sub li::before,
.c-content-main .list-lower-alpha-sub li::before {
  content: counter(section07, lower-alpha) !important;
  color: #739aaa;
  justify-content: flex-start;
  min-width: 0.8em;
  text-align: center;
}
.bge-contents .list-lower-alpha-sub li::after,
.c-content-main .list-lower-alpha-sub li::after {
  content: ".";
  color: #739aaa;
  left: 0.9em;
  position: absolute;
  top: 0;
}
.bge-contents .list-brackets > li,
.c-content-main .list-brackets > li {
  counter-increment: section08;
  list-style-type: none;
  padding-left: 2.8em;
  position: relative;
}
.bge-contents .list-brackets > li::before,
.c-content-main .list-brackets > li::before {
  content: "(" counter(section08) ") ";
  color: #00a2e6;
  letter-spacing: 0.04em;
  min-width: 2em;
  text-align: center;
}
.bge-contents .list-brackets-sub > li,
.c-content-main .list-brackets-sub > li {
  counter-increment: section09 !important;
  list-style-type: none;
  padding-left: 2.8em;
  position: relative;
}
.bge-contents .list-brackets-sub > li::before,
.c-content-main .list-brackets-sub > li::before {
  content: "(" counter(section09) ") " !important;
  color: #739aaa;
  letter-spacing: 0.04em;
  min-width: 2em;
  text-align: center;
  display: block;
}
.bge-contents .list-brackets-third li,
.c-content-main .list-brackets-third li {
  counter-increment: section15 !important;
  list-style-type: none;
  padding-left: 2em;
  position: relative;
}
.bge-contents .list-brackets-third li::before,
.c-content-main .list-brackets-third li::before {
  content: "(" counter(section15) ") " !important;
  color: #b1c0cd;
  letter-spacing: 0.04em;
  min-width: 0.8em;
  text-align: center;
  justify-content: flex-start;
}
.bge-contents .list-roman > li,
.c-content-main .list-roman > li {
  counter-increment: section10;
  list-style-type: none;
  padding-left: 2.2em;
  position: relative;
}
.bge-contents .list-roman > li::before,
.c-content-main .list-roman > li::before {
  content: counter(section10, upper-roman) ".";
  color: #0e212e;
  width: 1.8em;
  text-align: right;
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
  top: 0;
  white-space: nowrap;
}
@media (--sm-lte) {
  .bge-contents .list-roman > li::before,
.c-content-main .list-roman > li::before {
    font-size: 4.2666666667vw;
    top: -0.2666666667vw;
  }
}
.bge-contents .list-roman > li ol > li,
.c-content-main .list-roman > li ol > li {
  counter-increment: section11;
}
.bge-contents .list-roman > li ol > li::before,
.c-content-main .list-roman > li ol > li::before {
  content: counter(section11, upper-roman) ".";
  color: #00a2e6;
  width: 2.4em;
  white-space: nowrap;
}
.bge-contents .list-roman > li ol > li::before > ol > li,
.c-content-main .list-roman > li ol > li::before > ol > li {
  counter-increment: section12;
}
.bge-contents .list-roman > li ol > li::before > ol > li::before,
.c-content-main .list-roman > li ol > li::before > ol > li::before {
  content: counter(section12, upper-roman) ".";
  color: #739aaa;
}
.bge-contents .list-alpha > li,
.c-content-main .list-alpha > li {
  counter-increment: section16;
  list-style-type: none;
  padding-left: 2.8em;
  position: relative;
}
.bge-contents .list-alpha > li::before,
.c-content-main .list-alpha > li::before {
  content: "(" counter(section16, lower-alpha) ") ";
  color: #00a2e6;
  letter-spacing: 0.04em;
  min-width: 2em;
  text-align: center;
}
.bge-contents .list-alpha-sub > li,
.c-content-main .list-alpha-sub > li {
  counter-increment: section17 !important;
  list-style-type: none;
  padding-left: 2.8em;
  position: relative;
}
.bge-contents .list-alpha-sub > li::before,
.c-content-main .list-alpha-sub > li::before {
  content: "(" counter(section17, lower-alpha) ") " !important;
  color: #739aaa;
  letter-spacing: 0.04em;
  min-width: 2em;
  text-align: center;
  display: block;
}
.bge-contents .list-alpha-third li,
.c-content-main .list-alpha-third li {
  counter-increment: section18 !important;
  list-style-type: none;
  padding-left: 2em;
  position: relative;
}
.bge-contents .list-alpha-third li::before,
.c-content-main .list-alpha-third li::before {
  content: "(" counter(section18, lower-alpha) ") " !important;
  color: #b1c0cd;
  letter-spacing: 0.04em;
  min-width: 0.8em;
  text-align: center;
  justify-content: flex-start;
}
.bge-contents .list-cjk-ideographic > li,
.c-content-main .list-cjk-ideographic > li {
  counter-increment: section13;
  list-style-type: none;
  padding-left: 2.4em;
  position: relative;
}
.bge-contents .list-cjk-ideographic > li::before,
.c-content-main .list-cjk-ideographic > li::before {
  content: "(" counter(section13, cjk-ideographic) ") ";
  color: #0e212e;
  width: 1.2em;
  justify-content: flex-start;
  text-align: right;
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
  font-weight: bold;
}
@media (--sm-lte) {
  .bge-contents .list-cjk-ideographic > li::before,
.c-content-main .list-cjk-ideographic > li::before {
    font-size: 4.2666666667vw;
    top: -0.5333333333vw;
  }
}
.bge-contents .list-cjk-ideographic-sub,
.c-content-main .list-cjk-ideographic-sub {
  padding-left: 1em;
}
.bge-contents .list-cjk-ideographic-sub > li,
.c-content-main .list-cjk-ideographic-sub > li {
  counter-increment: section14 !important;
  padding-left: 2.4em;
}
.bge-contents .list-cjk-ideographic-sub > li::before,
.c-content-main .list-cjk-ideographic-sub > li::before {
  justify-content: flex-start;
  content: "(" counter(section14, cjk-ideographic) ") " !important;
  color: #00a2e6;
}
.bge-contents .list-campusmap,
.c-content-main .list-campusmap {
  column-count: 3;
}
@media (--sm-lte) {
  .bge-contents .list-campusmap,
.c-content-main .list-campusmap {
    column-count: 1;
  }
}
.bge-contents table,
.c-content-main table {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 30px;
  border-right: 1px solid #d2cdcd;
}
@media (--sm-lte) {
  .bge-contents table,
.c-content-main table {
    width: 100%;
  }
}
.bge-contents table a[href$=pdf], .bge-contents table a[href$=ppt], .bge-contents table a[href$=pptx], .bge-contents table a[href$=doc], .bge-contents table a[href$=docx], .bge-contents table a[href$=xls], .bge-contents table a[href$=xlsx], .bge-contents table a[href$=zip],
.c-content-main table a[href$=pdf],
.c-content-main table a[href$=ppt],
.c-content-main table a[href$=pptx],
.c-content-main table a[href$=doc],
.c-content-main table a[href$=docx],
.c-content-main table a[href$=xls],
.c-content-main table a[href$=xlsx],
.c-content-main table a[href$=zip] {
  display: inline;
}
.bge-contents table th.table-total,
.bge-contents table td.table-total,
.c-content-main table th.table-total,
.c-content-main table td.table-total {
  border-left: 2px solid #00a2e6 !important;
  border-right: 2px solid #00a2e6 !important;
}
.bge-contents table tr:first-child th.table-total,
.bge-contents table tr:first-child td.table-total,
.c-content-main table tr:first-child th.table-total,
.c-content-main table tr:first-child td.table-total {
  border-top: 2px solid #00a2e6;
}
.bge-contents table tr:last-child th.table-total,
.bge-contents table tr:last-child td.table-total,
.c-content-main table tr:last-child th.table-total,
.c-content-main table tr:last-child td.table-total {
  border-bottom: 2px solid #00a2e6;
}
.bge-contents caption,
.c-content-main caption {
  font-weight: bold;
  text-align: left;
}
@media (--sm-lte) {
  .bge-contents caption,
.c-content-main caption {
    font-size: 3.3333333333vw;
    margin-bottom: 2.6666666667vw;
  }
}
@media (--xs) {
  .bge-contents thead,
.c-content-main thead {
    display: none;
  }
}
@media (--xs) {
  .bge-contents tfoot,
.c-content-main tfoot {
    display: none;
  }
}
.bge-contents tr.bg-highlight th,
.c-content-main tr.bg-highlight th {
  background: #73c0e0;
}
.bge-contents th,
.bge-contents td,
.c-content-main th,
.c-content-main td {
  font-size: 0.9375em;
  padding: 1em;
  color: #101010;
  border-top: 1px solid #d2cdcd;
  border-bottom: 1px solid #d2cdcd;
  border-left: 1px solid #d2cdcd;
  line-height: 1.7;
}
@media (--sm-lte) {
  .bge-contents th,
.bge-contents td,
.c-content-main th,
.c-content-main td {
    font-size: 3.7333333333vw;
    padding: 4vw 5.3333333333vw;
  }
}
[data-path="/daigaku/disclosure/hyoka/"] .bge-contents th,
[data-path="/daigaku/disclosure/hyoka/"] .bge-contents td,
[data-path="/daigaku/disclosure/hyoka/"] .c-content-main th,
[data-path="/daigaku/disclosure/hyoka/"] .c-content-main td {
  width: 50%;
}
.bge-contents th ul,
.bge-contents th ol,
.bge-contents td ul,
.bge-contents td ol,
.c-content-main th ul,
.c-content-main th ol,
.c-content-main td ul,
.c-content-main td ol {
  padding-left: 0;
}
.bge-contents th ol li,
.bge-contents td ol li,
.c-content-main th ol li,
.c-content-main td ol li {
  padding-left: 1.4em;
  text-indent: -1.4em;
}
.bge-contents th ol li::before,
.bge-contents td ol li::before,
.c-content-main th ol li::before,
.c-content-main td ol li::before {
  width: auto;
  position: relative;
  left: auto;
  top: auto;
  display: inline;
}
.bge-contents th ol li ul,
.bge-contents td ol li ul,
.c-content-main th ol li ul,
.c-content-main td ol li ul {
  padding-left: 1em;
}
.bge-contents th ol li ul li,
.bge-contents td ol li ul li,
.c-content-main th ol li ul li,
.c-content-main td ol li ul li {
  padding-left: 0;
  text-indent: 0;
}
.bge-contents th ol li ul li::before,
.bge-contents td ol li ul li::before,
.c-content-main th ol li ul li::before,
.c-content-main td ol li ul li::before {
  display: inline-block;
  width: 6px;
  position: absolute;
  top: 10px;
}
.bge-contents th.bg-yellow,
.bge-contents td.bg-yellow,
.c-content-main th.bg-yellow,
.c-content-main td.bg-yellow {
  background: #fffca3;
}
.bge-contents th,
.c-content-main th {
  background: #f3f3f3;
  text-align: left;
}
[data-path="/daigaku/syokai/enkaku"] .bge-contents th,
[data-path="/daigaku/syokai/enkaku"] .c-content-main th {
  white-space: nowrap;
}
@media (--sm-lte) {
  .bge-contents table.bge-type-table,
.c-content-main table.bge-type-table {
    display: block;
    width: 100%;
    border-right: none;
  }
}
@media (--sm-lte) {
  .bge-contents table.bge-type-table caption,
.c-content-main table.bge-type-table caption {
    display: block;
    width: 100%;
  }
}
.bge-contents table.bge-type-table caption:empty,
.c-content-main table.bge-type-table caption:empty {
  margin: 0;
}
@media (--sm-lte) {
  .bge-contents table.bge-type-table tbody,
.bge-contents table.bge-type-table tr,
.c-content-main table.bge-type-table tbody,
.c-content-main table.bge-type-table tr {
    display: block;
    width: 100%;
  }
}
@media (--sm-lte) {
  .bge-contents table.bge-type-table tbody,
.c-content-main table.bge-type-table tbody {
    border-top: 1px solid #d2cdcd;
  }
}
@media (--sm-lte) {
  .bge-contents table.bge-type-table th,
.bge-contents table.bge-type-table td,
.c-content-main table.bge-type-table th,
.c-content-main table.bge-type-table td {
    display: block;
    width: 100%;
    border-right: 1px solid #d2cdcd;
  }
}
@media (--sm-lte) {
  .bge-contents table.bge-type-table th,
.c-content-main table.bge-type-table th {
    border-top: none;
    text-align: left;
  }
}
[lang=en] .bge-contents table.bge-type-table th,
[lang=en] .c-content-main table.bge-type-table th {
  width: 35%;
}
@media (--sm-lte) {
  [lang=en] .bge-contents table.bge-type-table th,
[lang=en] .c-content-main table.bge-type-table th {
    width: 100%;
  }
}
@media (--sm-lte) {
  .bge-contents table.bge-type-table td,
.c-content-main table.bge-type-table td {
    border-top: none;
  }
}
.bge-contents .table-rating,
.c-content-main .table-rating {
  text-align: center;
}
@media (--sm-lte) {
  .bge-contents .table-rating th,
.c-content-main .table-rating th {
    padding: 10px 4%;
  }
}
@media (--sm-gt) {
  .bge-contents .table-rating th:nth-child(1),
.c-content-main .table-rating th:nth-child(1) {
    width: 23%;
  }
}
@media (--sm-gt) {
  .bge-contents .table-rating th:nth-child(2),
.c-content-main .table-rating th:nth-child(2) {
    width: 18%;
  }
}
@media (--sm-gt) {
  .bge-contents .table-rating th:nth-child(3),
.c-content-main .table-rating th:nth-child(3) {
    width: 29%;
  }
}
@media (--sm-gt) {
  .bge-contents .table-rating th:nth-child(4),
.c-content-main .table-rating th:nth-child(4) {
    width: 30%;
  }
}
@media (--sm-lte) {
  .bge-contents .table-rating td,
.c-content-main .table-rating td {
    padding: 10px 4%;
  }
}
.bge-contents .table-th-white th,
.c-content-main .table-th-white th {
  background: #fff !important;
}
@media (--sm-lte) {
  .bge-contents .table-schedule tr,
.c-content-main .table-schedule tr {
    display: block;
  }
}
.bge-contents .table-schedule tr:last-child td,
.c-content-main .table-schedule tr:last-child td {
  border-bottom: 1px solid #d2cdcd;
}
.bge-contents .table-schedule th,
.c-content-main .table-schedule th {
  background: #fff !important;
}
@media (--sm-lte) {
  .bge-contents .table-schedule td,
.c-content-main .table-schedule td {
    border: none;
  }
}
@media (--sm-lte) {
  .bge-contents .table-schedule th,
.bge-contents .table-schedule td,
.c-content-main .table-schedule th,
.c-content-main .table-schedule td {
    display: block;
    width: 100%;
  }
}
.bge-contents .table-student-number,
.c-content-main .table-student-number {
  border-left: 1px solid #d2cdcd;
  border-right: 1px solid #d2cdcd;
  width: 360px;
}
@media (--sm-lte) {
  .bge-contents .table-student-number,
.c-content-main .table-student-number {
    width: 100%;
  }
}
.bge-contents .table-student-number td,
.c-content-main .table-student-number td {
  padding: 1em 1.2em;
  text-align: center;
}
.bge-contents .table-student-number td:last-child,
.c-content-main .table-student-number td:last-child {
  text-align: left;
}
.bge-contents .table-narrow table th,
.bge-contents .table-narrow table td,
.c-content-main .table-narrow table th,
.c-content-main .table-narrow table td {
  padding: 0.5em 0.8em;
}
@media (--sm-lte) {
  .bge-contents .table-scroll,
.c-content-main .table-scroll {
    overflow: auto;
    margin: 0 0 30px;
  }
  .bge-contents .table-scroll table,
.c-content-main .table-scroll table {
    display: table;
    width: 240vw !important;
    margin-bottom: 5px;
  }
  .bge-contents .table-scroll table caption,
.c-content-main .table-scroll table caption {
    display: table-caption;
  }
  .bge-contents .table-scroll table tr,
.c-content-main .table-scroll table tr {
    display: table-row;
  }
  .bge-contents .table-scroll table tbody,
.c-content-main .table-scroll table tbody {
    display: table-row-group;
  }
  .bge-contents .table-scroll table thead,
.c-content-main .table-scroll table thead {
    display: table-header-group;
  }
  .bge-contents .table-scroll table td,
.bge-contents .table-scroll table th,
.c-content-main .table-scroll table td,
.c-content-main .table-scroll table th {
    display: table-cell;
  }
  .bge-contents .table-scroll table th + th,
.c-content-main .table-scroll table th + th {
    border-left: 1px solid #d2cdcd;
  }
  .bge-contents .table-scroll table td + td,
.c-content-main .table-scroll table td + td {
    border-left: 1px solid #d2cdcd;
  }
  .bge-contents .table-scroll table table,
.c-content-main .table-scroll table table {
    width: auto !important;
  }
  .bge-contents .table-scroll::-webkit-scrollbar,
.c-content-main .table-scroll::-webkit-scrollbar {
    height: 5px;
  }
  .bge-contents .table-scroll::-webkit-scrollbar-track,
.c-content-main .table-scroll::-webkit-scrollbar-track {
    background: #d2cdcd;
    border-radius: 5px;
  }
  .bge-contents .table-scroll::-webkit-scrollbar-thumb,
.c-content-main .table-scroll::-webkit-scrollbar-thumb {
    background: #d2cdcd;
    border-radius: 5px;
  }
}
.bge-contents .table-scroll-sp,
.c-content-main .table-scroll-sp {
  display: none;
}
@media (--sm-lte) {
  .bge-contents .table-scroll-sp,
.c-content-main .table-scroll-sp {
    display: inline-block;
    font-size: 3.3333333333vw;
    background: #384e5c url("/img/icon-table-scroll.png") no-repeat 1.7333333333vw center/6.1333333333vw auto;
    padding: 0 2.4vw 0 9.3333333333vw;
    line-height: 1.95;
    letter-spacing: 0.09em;
    margin-bottom: 5.3333333333vw;
    color: #fff;
    font-weight: 500;
    border-radius: 6.4vw;
  }
}
.bge-contents .table-number-text td,
.c-content-main .table-number-text td {
  text-align: center;
  width: 15%;
}
@media (--sm-lte) {
  .bge-contents .table-number-text td,
.c-content-main .table-number-text td {
    padding: 2.6666666667vw;
  }
}
.bge-contents .table-number-text td + td, .bge-contents .table-number-text td:first-child:last-child,
.c-content-main .table-number-text td + td,
.c-content-main .table-number-text td:first-child:last-child {
  text-align: left;
  width: 90%;
}
@media (--sm-lte) {
  .bge-contents .table-number-text td + td, .bge-contents .table-number-text td:first-child:last-child,
.c-content-main .table-number-text td + td,
.c-content-main .table-number-text td:first-child:last-child {
    padding: 2.6666666667vw 4vw;
  }
}
.bge-contents .table-number-text tr:not(:last-child) td,
.c-content-main .table-number-text tr:not(:last-child) td {
  border-bottom: none;
}
.bge-contents .table-number-text tr:not(:last-child) td[rowspan],
.c-content-main .table-number-text tr:not(:last-child) td[rowspan] {
  border-bottom: 1px solid #d2cdcd;
}
.bge-contents .table-th-vertical p,
.c-content-main .table-th-vertical p {
  align-items: center;
  display: flex;
  justify-content: center;
  letter-spacing: 0.4em;
  margin: 0;
  text-align: justify;
  writing-mode: vertical-lr;
}
.bge-contents .table-th-vertical p > span:nth-child(2),
.c-content-main .table-th-vertical p > span:nth-child(2) {
  text-orientation: sideways;
  -webkit-text-orientation: sideways;
}
.bge-contents .table-th-vertical p.text-height-12em,
.c-content-main .table-th-vertical p.text-height-12em {
  height: 12em;
}
@media (--sm-gt) {
  .bge-contents .table-history table th,
.c-content-main .table-history table th {
    text-align: right;
    width: 28%;
  }
}
@media (--sm-gt) {
  .bge-contents .table-col2-block,
.c-content-main .table-col2-block {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
  }
  .bge-contents .table-col2-block > div,
.c-content-main .table-col2-block > div {
    width: 50%;
  }
  .bge-contents .table-col2-block > div:first-child,
.c-content-main .table-col2-block > div:first-child {
    margin-right: -1px;
  }
}
@media (--sm-lte) {
  .bge-contents .table-col2-block > div:first-child,
.c-content-main .table-col2-block > div:first-child {
    margin-bottom: -1px;
  }
  .bge-contents .table-col2-block > div:first-child table,
.c-content-main .table-col2-block > div:first-child table {
    margin: 0;
  }
}
.bge-contents p:first-child,
.c-content-main p:first-child {
  margin-top: 0;
}
.bge-contents p:last-child,
.c-content-main p:last-child {
  margin-bottom: 0;
}
.bge-contents p.text-notice,
.bge-contents span.text-notice,
.bge-contents a.text-notice,
.c-content-main p.text-notice,
.c-content-main span.text-notice,
.c-content-main a.text-notice {
  color: #e60057;
}
.bge-contents .text-notice p,
.c-content-main .text-notice p {
  color: #e60057;
}
.bge-contents .text-emphasis span,
.c-content-main .text-emphasis span {
  color: #e60057;
}
.bge-contents .text-title-sub,
.c-content-main .text-title-sub {
  font-size: 0.65625em;
}
@media (--sm-lte) {
  .bge-contents .text-title-sub,
.c-content-main .text-title-sub {
    font-size: 4.1333333333vw;
  }
}
.bge-contents .text-center,
.c-content-main .text-center {
  text-align: center;
}
.bge-contents .text-center tr th,
.bge-contents .text-center tr td,
.c-content-main .text-center tr th,
.c-content-main .text-center tr td {
  text-align: center;
}
.bge-contents .text-right,
.c-content-main .text-right {
  text-align: right;
}
.bge-contents .text-td-right tr td,
.c-content-main .text-td-right tr td {
  text-align: right;
}
.bge-contents .text-link-icon,
.c-content-main .text-link-icon {
  margin-left: 8px;
}
.bge-contents blockquote,
.c-content-main blockquote {
  background: #f3f3f3;
  margin: 0;
  padding: 1em 2em;
  position: relative;
}
.bge-contents blockquote::before,
.c-content-main blockquote::before {
  content: "“";
  display: block;
  font-size: 2.5em;
  left: 0;
  line-height: 0;
  margin-top: 0.5em;
  opacity: 0.2;
  pointer-events: none;
  position: absolute;
  top: 0;
  user-select: none;
}
.bge-contents blockquote::after,
.c-content-main blockquote::after {
  bottom: 0;
  content: "”";
  display: block;
  font-size: 2.5em;
  line-height: 0;
  opacity: 0.2;
  pointer-events: none;
  position: absolute;
  right: 0;
  user-select: none;
}
.bge-contents figure,
.c-content-main figure {
  margin: 0;
}
.bge-contents figcaption,
.c-content-main figcaption {
  background: none;
  font-size: 0.875em;
  font-weight: normal;
  line-height: 1.6;
  padding: 0.8em 1.2em;
  text-align: left;
}
.bge-contents hr,
.c-content-main hr {
  color: #d2cdcd;
  background: #d2cdcd;
  border-style: none;
  height: 2px;
  margin: 50px auto;
  width: 95%;
}
.bge-contents hr:first-child,
.c-content-main hr:first-child {
  margin-top: 0;
}
@media (--sm-lte) {
  .bge-contents audio,
.c-content-main audio {
    width: 100%;
  }
}
.bge-contents .cc-form-description,
.c-content-main .cc-form-description {
  margin: 0 0 32px;
  letter-spacing: 0.1em;
  font-weight: 500;
}
@media (--sm-lte) {
  .bge-contents .cc-form-description,
.c-content-main .cc-form-description {
    margin: 0 0 5.3333333333vw;
  }
}
.bge-contents .cc-form-description:first-child,
.c-content-main .cc-form-description:first-child {
  margin-top: 57px;
}
@media (--sm-lte) {
  .bge-contents .cc-form-description:first-child,
.c-content-main .cc-form-description:first-child {
    margin-top: 0;
  }
}
.bge-contents .cc-form-description span,
.c-content-main .cc-form-description span {
  color: #e60057;
}
[lang=en] .bge-contents .cc-form-description h2,
[lang=en] .c-content-main .cc-form-description h2 {
  margin-bottom: 2.3em;
}
.bge-contents .cc-form-description h4,
.c-content-main .cc-form-description h4 {
  font-size: 2.2rem;
  letter-spacing: 0.04em;
  padding-bottom: 9px;
}
.bge-contents .cc-form-description h4::before,
.c-content-main .cc-form-description h4::before {
  top: 4px;
}
.bge-contents .cc-form-description h4::before, .bge-contents .cc-form-description h4::after,
.c-content-main .cc-form-description h4::before,
.c-content-main .cc-form-description h4::after {
  height: calc(50% - 8px);
}
.bge-contents .cc-form,
.c-content-main .cc-form {
  margin: 0 auto;
}
.bge-contents .cc-form-agreement,
.c-content-main .cc-form-agreement {
  text-align: center;
  margin-top: 51px;
  letter-spacing: 0.06em;
}
@media (--sm-lte) {
  .bge-contents .cc-form-agreement,
.c-content-main .cc-form-agreement {
    margin-top: 8vw;
  }
}
.bge-contents .cc-form-agreement a[target=_blank]::after,
.c-content-main .cc-form-agreement a[target=_blank]::after {
  content: "";
  width: 13px;
  height: 13px;
  background: url("<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='13' height='13' viewBox='0 0 13 13' xml:space='preserve'><polygon points='10.5,13 0,13 0,2.5 1,2.5 1,12 10.5,12' fill='#0d212e'/><path d='M12,1v8H4V1H12 M13,0H3v10h10V0L13,0z' fill='#0d212e'/></svg>") no-repeat;
  display: inline-block;
  margin: 0 5px;
}
.bge-contents .cc-form-submit,
.c-content-main .cc-form-submit {
  text-align: center;
  margin-top: 42px;
}
@media (--sm-lte) {
  .bge-contents .cc-form-submit,
.c-content-main .cc-form-submit {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 8vw;
  }
}
.bge-contents .cc-form-submit button,
.bge-contents .cc-form-submit a,
.c-content-main .cc-form-submit button,
.c-content-main .cc-form-submit a {
  display: inline-block;
  text-decoration: none;
  line-height: 1.15;
  margin: 0 7px;
  appearance: none;
  font-family: inherit;
  font-weight: 500;
  font-size: 1.0625em;
  border: 0;
  min-width: 16.5em;
  padding: 0.9em 2.1em;
  letter-spacing: 0.08em;
  color: #fff;
  background: #0d212e;
  position: relative;
  cursor: pointer;
}
@media (--sm-gt) {
  .bge-contents .cc-form-submit button:hover::after,
.bge-contents .cc-form-submit a:hover::after,
.c-content-main .cc-form-submit button:hover::after,
.c-content-main .cc-form-submit a:hover::after {
    right: 12px;
  }
}
@media (--sm-lte) {
  .bge-contents .cc-form-submit button,
.bge-contents .cc-form-submit a,
.c-content-main .cc-form-submit button,
.c-content-main .cc-form-submit a {
    margin: 1.8666666667vw;
  }
}
[lang=en] .bge-contents .cc-form-submit button,
[lang=en] .bge-contents .cc-form-submit a,
[lang=en] .c-content-main .cc-form-submit button,
[lang=en] .c-content-main .cc-form-submit a {
  letter-spacing: 0.04em;
}
.bge-contents .cc-form-submit button::after,
.bge-contents .cc-form-submit a::after,
.c-content-main .cc-form-submit button::after,
.c-content-main .cc-form-submit a::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 17px;
  width: 7px;
  height: 7px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(45deg);
  transition: right 300ms;
}
@media (--sm-lte) {
  .bge-contents .cc-form-submit button::after,
.bge-contents .cc-form-submit a::after,
.c-content-main .cc-form-submit button::after,
.c-content-main .cc-form-submit a::after {
    right: 4.5333333333vw;
    width: 1.8666666667vw;
    height: 1.8666666667vw;
  }
}
.bge-contents .cc-form-submit button:visited,
.bge-contents .cc-form-submit a:visited,
.c-content-main .cc-form-submit button:visited,
.c-content-main .cc-form-submit a:visited {
  color: #fff !important;
}
.bge-contents .cc-form-submit button.btn-em,
.bge-contents .cc-form-submit a.btn-em,
.c-content-main .cc-form-submit button.btn-em,
.c-content-main .cc-form-submit a.btn-em {
  background: #004e81;
}
.bge-contents .cc-form-submit button.btn-back,
.bge-contents .cc-form-submit a.btn-back,
.c-content-main .cc-form-submit button.btn-back,
.c-content-main .cc-form-submit a.btn-back {
  background: #384e5c;
}
@media (--sm-gt) {
  .bge-contents .cc-form-submit button.btn-back:hover::before,
.bge-contents .cc-form-submit a.btn-back:hover::before,
.c-content-main .cc-form-submit button.btn-back:hover::before,
.c-content-main .cc-form-submit a.btn-back:hover::before {
    left: 12px;
  }
}
@media (--sm-lte) {
  .bge-contents .cc-form-submit button.btn-back,
.bge-contents .cc-form-submit a.btn-back,
.c-content-main .cc-form-submit button.btn-back,
.c-content-main .cc-form-submit a.btn-back {
    order: 1;
  }
}
.bge-contents .cc-form-submit button.btn-back::after,
.bge-contents .cc-form-submit a.btn-back::after,
.c-content-main .cc-form-submit button.btn-back::after,
.c-content-main .cc-form-submit a.btn-back::after {
  display: none;
}
.bge-contents .cc-form-submit button.btn-back::before,
.bge-contents .cc-form-submit a.btn-back::before,
.c-content-main .cc-form-submit button.btn-back::before,
.c-content-main .cc-form-submit a.btn-back::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 17px;
  width: 7px;
  height: 7px;
  border-top: 1px solid #fff;
  border-right: 1px solid #fff;
  transform: rotate(-135deg);
  transition: left 300ms;
}
@media (--sm-lte) {
  .bge-contents .cc-form-submit button.btn-back::before,
.bge-contents .cc-form-submit a.btn-back::before,
.c-content-main .cc-form-submit button.btn-back::before,
.c-content-main .cc-form-submit a.btn-back::before {
    left: 4.5333333333vw;
    width: 1.8666666667vw;
    height: 1.8666666667vw;
  }
}
.bge-contents .cc-form-field-list,
.c-content-main .cc-form-field-list {
  border-collapse: collapse;
  width: 100%;
  border-right: none;
}
@media (--sm-lte) {
  .bge-contents .cc-form-field-list,
.c-content-main .cc-form-field-list {
    display: block;
    border-bottom: 1px solid #c8cfd4;
  }
  .bge-contents .cc-form-field-list tbody,
.c-content-main .cc-form-field-list tbody {
    display: block;
  }
}
.bge-contents .cc-form-field-list tr th:first-child,
.bge-contents .cc-form-field-list tr td:first-child,
.c-content-main .cc-form-field-list tr th:first-child,
.c-content-main .cc-form-field-list tr td:first-child {
  border-left: none;
}
.bge-contents .cc-form-field-list ul,
.c-content-main .cc-form-field-list ul {
  margin: 0;
}
.bge-contents .cc-form-field-list ul li,
.c-content-main .cc-form-field-list ul li {
  padding: 0;
  margin: 0;
}
.bge-contents .cc-form-field-list ul li::before,
.c-content-main .cc-form-field-list ul li::before {
  display: none;
}
@media (--sm-lte) {
  .bge-contents .cc-form-fieldset,
.c-content-main .cc-form-fieldset {
    display: block;
  }
}
.bge-contents .cc-form-fieldset-heading,
.c-content-main .cc-form-fieldset-heading {
  width: 240px;
  font-weight: bold;
  vertical-align: middle;
  text-align: left;
  padding: 0 0 0 48px;
  letter-spacing: 0.05em;
  background: #f4f5f6;
  border-top: 1px solid #c8cfd4;
  border-bottom: 1px solid #c8cfd4;
  font-size: 0.9375em;
}
@media (--sm-lte) {
  .bge-contents .cc-form-fieldset-heading,
.c-content-main .cc-form-fieldset-heading {
    width: auto;
    display: block;
    border-bottom: 0;
    padding: 2.6666666667vw;
  }
}
@media (--sm-gt) {
  [lang=en] .bge-contents .cc-form-fieldset-heading,
[lang=en] .c-content-main .cc-form-fieldset-heading {
    padding: 0 0 0 28px;
  }
}
.bge-contents .cc-form-fieldset-heading .required,
.bge-contents .cc-form-fieldset-heading .optional,
.c-content-main .cc-form-fieldset-heading .required,
.c-content-main .cc-form-fieldset-heading .optional {
  color: #fff;
  display: inline-block;
  font-size: 0.9333333333em;
  line-height: 1;
  padding: 0.3em;
  margin: 0 0 0 0.9em;
}
[lang=en] .bge-contents .cc-form-fieldset-heading .required,
[lang=en] .bge-contents .cc-form-fieldset-heading .optional,
[lang=en] .c-content-main .cc-form-fieldset-heading .required,
[lang=en] .c-content-main .cc-form-fieldset-heading .optional {
  margin: 0 0 0 0.5em;
  letter-spacing: 0;
}
.bge-contents .cc-form-fieldset-heading .required,
.c-content-main .cc-form-fieldset-heading .required {
  background: #e60057;
}
.bge-contents .cc-form-fieldset-heading .optional,
.c-content-main .cc-form-fieldset-heading .optional {
  background: #101010;
}
.bge-contents .cc-form-fieldset-attention,
.c-content-main .cc-form-fieldset-attention {
  font-weight: normal;
  font-size: 0.8666666667em;
  margin: 0.7em 0 0;
  line-height: 1.4;
  letter-spacing: 0.09em;
}
[lang=en] .bge-contents .cc-form-fieldset-attention,
[lang=en] .c-content-main .cc-form-fieldset-attention {
  letter-spacing: 0.05em;
}
.bge-contents .cc-form-fieldset-body,
.c-content-main .cc-form-fieldset-body {
  padding: 26px 26px 25px;
  border-left: 0 !important;
  border-top: 1px solid #c8cfd4;
  border-bottom: 1px solid #c8cfd4;
  font-size: 0.9375em;
}
@media (--sm-lte) {
  .bge-contents .cc-form-fieldset-body,
.c-content-main .cc-form-fieldset-body {
    width: auto;
    display: block;
    padding: 6.6666666667vw 0;
    border-bottom: 0;
  }
}
.bge-contents .alert-message,
.c-content-main .alert-message {
  color: #ad1313;
}
.bge-contents .mail-field,
.c-content-main .mail-field {
  margin: 0;
  padding: 0;
  display: inline-block;
  letter-spacing: 0.03em;
}
.bge-contents .mail-field + .mail-field,
.c-content-main .mail-field + .mail-field {
  margin-left: 15px;
}
@media (--sm-lte) {
  [lang=en] .bge-contents .mail-field + .mail-field,
[lang=en] .c-content-main .mail-field + .mail-field {
    margin-left: 0;
    margin-top: 2.6666666667vw;
  }
}
.bge-contents .mail-field[data-type=checkbox],
.c-content-main .mail-field[data-type=checkbox] {
  display: flex;
  flex-wrap: wrap;
}
@media (--sm-lte) {
  .bge-contents .mail-field[data-type=checkbox],
.c-content-main .mail-field[data-type=checkbox] {
    margin-bottom: -10px;
  }
}
.bge-contents .mail-field[data-type=radio],
.c-content-main .mail-field[data-type=radio] {
  display: block;
}
.bge-contents .mail-field[data-type=text] .mail-before-attachment, .bge-contents .mail-field[data-type=textarea] .mail-before-attachment, .bge-contents .mail-field[data-type=select] .mail-before-attachment,
.c-content-main .mail-field[data-type=text] .mail-before-attachment,
.c-content-main .mail-field[data-type=textarea] .mail-before-attachment,
.c-content-main .mail-field[data-type=select] .mail-before-attachment {
  font-feature-settings: normal;
  margin: 0 0.3em 0 0;
}
[lang=en] .bge-contents .mail-field[data-type=text] .mail-before-attachment, [lang=en] .bge-contents .mail-field[data-type=textarea] .mail-before-attachment, [lang=en] .bge-contents .mail-field[data-type=select] .mail-before-attachment,
[lang=en] .c-content-main .mail-field[data-type=text] .mail-before-attachment,
[lang=en] .c-content-main .mail-field[data-type=textarea] .mail-before-attachment,
[lang=en] .c-content-main .mail-field[data-type=select] .mail-before-attachment {
  margin: 0 0.5em 0 0;
}
.bge-contents .mail-field[data-type=text] .mail-before-attachment:empty, .bge-contents .mail-field[data-type=textarea] .mail-before-attachment:empty, .bge-contents .mail-field[data-type=select] .mail-before-attachment:empty,
.c-content-main .mail-field[data-type=text] .mail-before-attachment:empty,
.c-content-main .mail-field[data-type=textarea] .mail-before-attachment:empty,
.c-content-main .mail-field[data-type=select] .mail-before-attachment:empty {
  margin: 0;
}
.bge-contents .mail-field[data-type=text] .mail-input, .bge-contents .mail-field[data-type=textarea] .mail-input, .bge-contents .mail-field[data-type=select] .mail-input,
.c-content-main .mail-field[data-type=text] .mail-input,
.c-content-main .mail-field[data-type=textarea] .mail-input,
.c-content-main .mail-field[data-type=select] .mail-input {
  width: 639px;
}
@media (--sm-lte) {
  .bge-contents .mail-field[data-type=text] .mail-input, .bge-contents .mail-field[data-type=textarea] .mail-input, .bge-contents .mail-field[data-type=select] .mail-input,
.c-content-main .mail-field[data-type=text] .mail-input,
.c-content-main .mail-field[data-type=textarea] .mail-input,
.c-content-main .mail-field[data-type=select] .mail-input {
    width: 89.3333333333vw;
  }
}
.bge-contents .mail-field[data-type=text] .mail-input.input-s, .bge-contents .mail-field[data-type=textarea] .mail-input.input-s, .bge-contents .mail-field[data-type=select] .mail-input.input-s,
.c-content-main .mail-field[data-type=text] .mail-input.input-s,
.c-content-main .mail-field[data-type=textarea] .mail-input.input-s,
.c-content-main .mail-field[data-type=select] .mail-input.input-s {
  display: inline-block;
  width: 190px;
}
@media (--sm-lte) {
  .bge-contents .mail-field[data-type=text] .mail-input.input-s, .bge-contents .mail-field[data-type=textarea] .mail-input.input-s, .bge-contents .mail-field[data-type=select] .mail-input.input-s,
.c-content-main .mail-field[data-type=text] .mail-input.input-s,
.c-content-main .mail-field[data-type=textarea] .mail-input.input-s,
.c-content-main .mail-field[data-type=select] .mail-input.input-s {
    width: 32vw;
  }
}
.bge-contents .mail-field[data-type=text] .mail-after-attachment, .bge-contents .mail-field[data-type=textarea] .mail-after-attachment, .bge-contents .mail-field[data-type=select] .mail-after-attachment,
.c-content-main .mail-field[data-type=text] .mail-after-attachment,
.c-content-main .mail-field[data-type=textarea] .mail-after-attachment,
.c-content-main .mail-field[data-type=select] .mail-after-attachment {
  grid-area: after-attachment;
  margin: 0 0 0 1em;
}
.bge-contents .mail-field[data-type=text] .mail-after-attachment:empty, .bge-contents .mail-field[data-type=textarea] .mail-after-attachment:empty, .bge-contents .mail-field[data-type=select] .mail-after-attachment:empty,
.c-content-main .mail-field[data-type=text] .mail-after-attachment:empty,
.c-content-main .mail-field[data-type=textarea] .mail-after-attachment:empty,
.c-content-main .mail-field[data-type=select] .mail-after-attachment:empty {
  margin: 0;
}
.bge-contents .mail-field[data-type=text] .mail-description, .bge-contents .mail-field[data-type=textarea] .mail-description, .bge-contents .mail-field[data-type=select] .mail-description,
.c-content-main .mail-field[data-type=text] .mail-description,
.c-content-main .mail-field[data-type=textarea] .mail-description,
.c-content-main .mail-field[data-type=select] .mail-description {
  grid-area: description;
  font-size: 0.75em;
}
.bge-contents .mail-field[data-type=text] .error-message, .bge-contents .mail-field[data-type=textarea] .error-message, .bge-contents .mail-field[data-type=select] .error-message,
.c-content-main .mail-field[data-type=text] .error-message,
.c-content-main .mail-field[data-type=textarea] .error-message,
.c-content-main .mail-field[data-type=select] .error-message {
  grid-area: error-message;
  font-size: 0.75em;
  color: #ad1313;
}
.bge-contents .mail-field .mail-before-attachment,
.c-content-main .mail-field .mail-before-attachment {
  font-size: 0.9375em;
}
.bge-contents .mail-field .mail-input,
.c-content-main .mail-field .mail-input {
  display: block;
  letter-spacing: 0.1em;
}
.bge-contents .mail-field .mail-input input:not([type=radio]):not([type=checkbox]),
.bge-contents .mail-field .mail-input textarea,
.c-content-main .mail-field .mail-input input:not([type=radio]):not([type=checkbox]),
.c-content-main .mail-field .mail-input textarea {
  font-size: 1.6rem;
  font-family: inherit;
  max-width: 100%;
  background: #fff;
  border: 1px solid #d2cdcd;
  border-radius: 0;
  padding: 0.35em 0.6em;
}
.bge-contents .mail-field .mail-input input:not([type=radio]):not([type=checkbox]):focus,
.bge-contents .mail-field .mail-input textarea:focus,
.c-content-main .mail-field .mail-input input:not([type=radio]):not([type=checkbox]):focus,
.c-content-main .mail-field .mail-input textarea:focus {
  border-color: #0570c7;
  box-shadow: 0 0 5px 1px #0570c7, 0 0 5px 0 #fff;
  outline: none;
}
.bge-contents .mail-field .mail-input input:not([type=radio]):not([type=checkbox]):not([size]),
.bge-contents .mail-field .mail-input textarea:not([size]),
.c-content-main .mail-field .mail-input input:not([type=radio]):not([type=checkbox]):not([size]),
.c-content-main .mail-field .mail-input textarea:not([size]) {
  width: 100%;
}
.bge-contents .mail-field .mail-input textarea,
.c-content-main .mail-field .mail-input textarea {
  resize: vertical;
  line-height: 1.5;
}
.bge-contents .mail-field .mail-input textarea:not([cols]),
.c-content-main .mail-field .mail-input textarea:not([cols]) {
  width: 100%;
}
.bge-contents .mail-field .mail-input select,
.c-content-main .mail-field .mail-input select {
  font-size: 1.6rem;
  max-width: 100%;
  padding: 0.35em calc(0.5em + 24px + 2px) 0.35em 0.6em;
  appearance: none;
  background: #fff;
  background-image: url("<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='20' height='20' viewBox='0 0 20 20' xml:space='preserve'><rect width='20' height='20' fill='#00a2e6'/><polygon points='15.1,7.5 10.5,12.1 5.9,7.5 4.5,8.9 10.5,14.9 16.5,8.9' fill='#fff'/></svg>") !important;
  background-repeat: no-repeat;
  background-position: right calc(0.2em + 2px) center;
  border: 1px solid #c8cfd4;
  border-radius: 0;
}
@media (--sm-lte) {
  .bge-contents .mail-field .mail-input select,
.c-content-main .mail-field .mail-input select {
    width: 100%;
  }
}
.bge-contents .mail-field .mail-input select:focus,
.c-content-main .mail-field .mail-input select:focus {
  border-color: #0570c7;
  box-shadow: 0 0 5px 1px #0570c7, 0 0 5px 0 #fff;
  outline: none;
}
.bge-contents .mail-field .mail-input select::-ms-expand,
.c-content-main .mail-field .mail-input select::-ms-expand {
  display: none;
}
.bge-contents .mail-field .mail-input .-bc-form-element select,
.c-content-main .mail-field .mail-input .-bc-form-element select {
  display: none;
}
@media (--sm-lte) {
  .bge-contents .mail-field .mail-input .-bc-form-element select,
.c-content-main .mail-field .mail-input .-bc-form-element select {
    width: 100%;
  }
}
.bge-contents .mail-field .mail-input .-bc-form-element select.-bc-os-i-os, .bge-contents .mail-field .mail-input .-bc-form-element select.-bc-os-android,
.c-content-main .mail-field .mail-input .-bc-form-element select.-bc-os-i-os,
.c-content-main .mail-field .mail-input .-bc-form-element select.-bc-os-android {
  display: block;
}
.bge-contents .mail-field .mail-input .-bc-form-element.-bc-os-i-os .-bc-form-element.-bc-pseudo-select, .bge-contents .mail-field .mail-input .-bc-form-element.-bc-os-android .-bc-form-element.-bc-pseudo-select,
.c-content-main .mail-field .mail-input .-bc-form-element.-bc-os-i-os .-bc-form-element.-bc-pseudo-select,
.c-content-main .mail-field .mail-input .-bc-form-element.-bc-os-android .-bc-form-element.-bc-pseudo-select {
  display: none;
}
.bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select,
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select {
  width: 465px;
  text-decoration: none;
  color: inherit;
  font-size: 1.5rem;
  line-height: 1.3;
  max-width: 100%;
  letter-spacing: 0.13em;
  padding: 0.35em calc(0.5em + 24px + 2px) 0.4em 0.6em;
  background: #fff;
  border: 1px solid #c8cfd4;
  padding-right: 50px;
  position: relative;
  display: inline-block;
}
[lang=en] .bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select,
[lang=en] .c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select {
  letter-spacing: 0.053em;
}
.bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select::before,
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select::before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: #00a2e6;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 5px;
  margin: auto 0;
  box-shadow: 0 1px 3px rgba(94, 94, 94, 0.14);
}
.bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select::after,
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select::after {
  content: "";
  position: absolute;
  right: 11px;
  top: 0;
  bottom: 2px;
  margin: auto 0;
  display: block;
  width: 8px;
  height: 8px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(135deg);
}
.bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select__selected-display,
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select__selected-display {
  display: inline-block;
}
.bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list,
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list {
  display: none;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 640px;
  background: #fff;
  border: 1px solid #c8cfd4;
  box-shadow: 0 1px 3px rgba(94, 94, 94, 0.14);
  z-index: 10;
}
@media (--sm-lte) {
  .bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list,
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list {
    width: 100%;
  }
}
.bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li,
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li {
  width: 50%;
  padding: 8px 10px;
  margin: 0;
  border-bottom: 1px solid #c8cfd4;
}
@media (--sm-lte) {
  .bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li,
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li {
    width: 100%;
  }
}
.bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li:nth-child(2n+1),
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li:nth-child(2n+1) {
  border-right: 1px solid #c8cfd4;
}
@media (--sm-lte) {
  .bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li:nth-child(2n+1),
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li:nth-child(2n+1) {
    border-right: 0;
  }
}
.bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li:last-child, .bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li:nth-last-child(2):nth-child(2n+1),
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li:last-child,
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li:nth-last-child(2):nth-child(2n+1) {
  border-bottom: 0;
}
@media (--sm-lte) {
  .bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li:nth-last-child(2):nth-child(2n+1),
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li:nth-last-child(2):nth-child(2n+1) {
    border-bottom: 1px solid #c8cfd4;
  }
}
.bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li::before,
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li::before {
  display: none;
}
.bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li:hover,
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element .-bc-pseudo-select__option-list li:hover {
  background: #00a2e6;
  color: #fff;
}
.bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select--focus,
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select--focus {
  border-color: #0570c7;
  box-shadow: 0 0 5px 1px #0570c7, 0 0 5px 0 #fff;
  outline: none;
}
.bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select--focus::after,
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select--focus::after {
  transform: rotate(-45deg);
  top: 4px;
  bottom: 0;
}
.bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select--focus .-bc-pseudo-select__option-list,
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select--focus .-bc-pseudo-select__option-list {
  display: flex;
  flex-wrap: wrap;
}
@media (--sm-lte) {
  .bge-contents .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select--focus .-bc-pseudo-select__option-list,
.c-content-main .mail-field .mail-input .-bc-form-element .-bc-form-element.-bc-pseudo-select--focus .-bc-pseudo-select__option-list {
    display: block;
  }
}
.bge-contents .mail-field .checkbox,
.c-content-main .mail-field .checkbox {
  margin-right: 26px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media (--sm-lte) {
  .bge-contents .mail-field .checkbox,
.c-content-main .mail-field .checkbox {
    margin: 0 20px 10px 0;
  }
}
.bge-contents .mail-field .checkbox input,
.c-content-main .mail-field .checkbox input {
  display: block;
  line-height: 1;
  margin: 0;
  padding: 0;
}
.bge-contents .mail-field .checkbox input[type=checkbox],
.c-content-main .mail-field .checkbox input[type=checkbox] {
  appearance: none;
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.bge-contents .mail-field .checkbox input[type=checkbox]:checked + label::after,
.c-content-main .mail-field .checkbox input[type=checkbox]:checked + label::after {
  content: "";
  display: block;
  border-left: 3px solid #00a2e6;
  border-bottom: 3px solid #00a2e6;
  width: 13px;
  height: 10px;
  transform: rotate(-45deg);
  position: absolute;
  left: 5px;
  top: 1em;
  margin-top: -7px;
}
.bge-contents .mail-field .checkbox input[type=checkbox]:focus + label::before,
.c-content-main .mail-field .checkbox input[type=checkbox]:focus + label::before {
  border-color: #0570c7;
  box-shadow: 0 0 5px 1px #0570c7, 0 0 5px 0 #fff;
  outline: none;
}
.bge-contents .mail-field .checkbox input[type=checkbox] + label,
.c-content-main .mail-field .checkbox input[type=checkbox] + label {
  cursor: pointer;
  padding-left: 29px;
  position: relative;
}
.bge-contents .mail-field .checkbox input[type=checkbox] + label::before,
.c-content-main .mail-field .checkbox input[type=checkbox] + label::before {
  content: "";
  display: block;
  border-radius: 0;
  width: 22px;
  height: 22px;
  border: 1px solid #c8cfd4;
  position: absolute;
  left: 0;
  top: 1em;
  margin-top: -11px;
}
.bge-contents .mail-field .mail-group-radio,
.c-content-main .mail-field .mail-group-radio {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
@media (--sm-lte) {
  .bge-contents .mail-field .mail-group-radio,
.c-content-main .mail-field .mail-group-radio {
    margin-bottom: -10px;
  }
}
.bge-contents .mail-field .mail-group-radio > span,
.c-content-main .mail-field .mail-group-radio > span {
  margin-right: 26px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
@media (--sm-lte) {
  .bge-contents .mail-field .mail-group-radio > span,
.c-content-main .mail-field .mail-group-radio > span {
    margin: 0 20px 10px 0;
  }
}
.bge-contents .mail-field .mail-group-radio > span span,
.c-content-main .mail-field .mail-group-radio > span span {
  display: block;
  width: 100%;
  padding-left: 29px;
}
.bge-contents .mail-field .mail-group-radio > span input,
.c-content-main .mail-field .mail-group-radio > span input {
  display: block;
  line-height: 1;
  margin: 0 0.5em 0 0;
  padding: 0;
}
.bge-contents .mail-field .mail-group-radio > span input[type=radio],
.c-content-main .mail-field .mail-group-radio > span input[type=radio] {
  appearance: none;
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}
.bge-contents .mail-field .mail-group-radio > span input[type=radio]:checked + label::after,
.c-content-main .mail-field .mail-group-radio > span input[type=radio]:checked + label::after {
  content: "";
  display: block;
  background: #00a2e6;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  left: 5px;
  top: 1em;
  margin-top: -4px;
}
.bge-contents .mail-field .mail-group-radio > span input[type=radio]:focus + label::before,
.c-content-main .mail-field .mail-group-radio > span input[type=radio]:focus + label::before {
  border-color: #0570c7;
  box-shadow: 0 0 5px 1px #0570c7, 0 0 5px 0 #fff;
  outline: none;
}
.bge-contents .mail-field .mail-group-radio > span input[type=radio] + label,
.c-content-main .mail-field .mail-group-radio > span input[type=radio] + label {
  cursor: pointer;
  padding-left: 29px;
  position: relative;
}
.bge-contents .mail-field .mail-group-radio > span input[type=radio] + label::before,
.c-content-main .mail-field .mail-group-radio > span input[type=radio] + label::before {
  content: "";
  display: block;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  border: 1px solid #c8cfd4;
  position: absolute;
  left: 0;
  top: 1em;
  margin-top: -9px;
}
.bge-contents .w-1,
.c-content-main .w-1 {
  width: 1% !important;
}
.bge-contents .w-2,
.c-content-main .w-2 {
  width: 2% !important;
}
.bge-contents .w-3,
.c-content-main .w-3 {
  width: 3% !important;
}
.bge-contents .w-4,
.c-content-main .w-4 {
  width: 4% !important;
}
.bge-contents .w-5,
.c-content-main .w-5 {
  width: 5% !important;
}
.bge-contents .w-6,
.c-content-main .w-6 {
  width: 6% !important;
}
.bge-contents .w-7,
.c-content-main .w-7 {
  width: 7% !important;
}
.bge-contents .w-8,
.c-content-main .w-8 {
  width: 8% !important;
}
.bge-contents .w-9,
.c-content-main .w-9 {
  width: 9% !important;
}
.bge-contents .w-10,
.c-content-main .w-10 {
  width: 10% !important;
}
.bge-contents .w-11,
.c-content-main .w-11 {
  width: 11% !important;
}
.bge-contents .w-12,
.c-content-main .w-12 {
  width: 12% !important;
}
.bge-contents .w-13,
.c-content-main .w-13 {
  width: 13% !important;
}
.bge-contents .w-14,
.c-content-main .w-14 {
  width: 14% !important;
}
.bge-contents .w-15,
.c-content-main .w-15 {
  width: 15% !important;
}
.bge-contents .w-16,
.c-content-main .w-16 {
  width: 16% !important;
}
.bge-contents .w-17,
.c-content-main .w-17 {
  width: 17% !important;
}
.bge-contents .w-18,
.c-content-main .w-18 {
  width: 18% !important;
}
.bge-contents .w-19,
.c-content-main .w-19 {
  width: 19% !important;
}
.bge-contents .w-20,
.c-content-main .w-20 {
  width: 20% !important;
}
.bge-contents .w-21,
.c-content-main .w-21 {
  width: 21% !important;
}
.bge-contents .w-22,
.c-content-main .w-22 {
  width: 22% !important;
}
.bge-contents .w-23,
.c-content-main .w-23 {
  width: 23% !important;
}
.bge-contents .w-24,
.c-content-main .w-24 {
  width: 24% !important;
}
.bge-contents .w-25,
.c-content-main .w-25 {
  width: 25% !important;
}
.bge-contents .w-26,
.c-content-main .w-26 {
  width: 26% !important;
}
.bge-contents .w-27,
.c-content-main .w-27 {
  width: 27% !important;
}
.bge-contents .w-28,
.c-content-main .w-28 {
  width: 28% !important;
}
.bge-contents .w-29,
.c-content-main .w-29 {
  width: 29% !important;
}
.bge-contents .w-30,
.c-content-main .w-30 {
  width: 30% !important;
}
.bge-contents .w-31,
.c-content-main .w-31 {
  width: 31% !important;
}
.bge-contents .w-32,
.c-content-main .w-32 {
  width: 32% !important;
}
.bge-contents .w-33,
.c-content-main .w-33 {
  width: 33% !important;
}
.bge-contents .w-34,
.c-content-main .w-34 {
  width: 34% !important;
}
.bge-contents .w-35,
.c-content-main .w-35 {
  width: 35% !important;
}
.bge-contents .w-36,
.c-content-main .w-36 {
  width: 36% !important;
}
.bge-contents .w-37,
.c-content-main .w-37 {
  width: 37% !important;
}
.bge-contents .w-38,
.c-content-main .w-38 {
  width: 38% !important;
}
.bge-contents .w-39,
.c-content-main .w-39 {
  width: 39% !important;
}
.bge-contents .w-40,
.c-content-main .w-40 {
  width: 40% !important;
}
.bge-contents .w-41,
.c-content-main .w-41 {
  width: 41% !important;
}
.bge-contents .w-42,
.c-content-main .w-42 {
  width: 42% !important;
}
.bge-contents .w-43,
.c-content-main .w-43 {
  width: 43% !important;
}
.bge-contents .w-44,
.c-content-main .w-44 {
  width: 44% !important;
}
.bge-contents .w-45,
.c-content-main .w-45 {
  width: 45% !important;
}
.bge-contents .w-46,
.c-content-main .w-46 {
  width: 46% !important;
}
.bge-contents .w-47,
.c-content-main .w-47 {
  width: 47% !important;
}
.bge-contents .w-48,
.c-content-main .w-48 {
  width: 48% !important;
}
.bge-contents .w-49,
.c-content-main .w-49 {
  width: 49% !important;
}
.bge-contents .w-50,
.c-content-main .w-50 {
  width: 50% !important;
}
.bge-contents .w-51,
.c-content-main .w-51 {
  width: 51% !important;
}
.bge-contents .w-52,
.c-content-main .w-52 {
  width: 52% !important;
}
.bge-contents .w-53,
.c-content-main .w-53 {
  width: 53% !important;
}
.bge-contents .w-54,
.c-content-main .w-54 {
  width: 54% !important;
}
.bge-contents .w-55,
.c-content-main .w-55 {
  width: 55% !important;
}
.bge-contents .w-56,
.c-content-main .w-56 {
  width: 56% !important;
}
.bge-contents .w-57,
.c-content-main .w-57 {
  width: 57% !important;
}
.bge-contents .w-58,
.c-content-main .w-58 {
  width: 58% !important;
}
.bge-contents .w-59,
.c-content-main .w-59 {
  width: 59% !important;
}
.bge-contents .w-60,
.c-content-main .w-60 {
  width: 60% !important;
}
.bge-contents .w-61,
.c-content-main .w-61 {
  width: 61% !important;
}
.bge-contents .w-62,
.c-content-main .w-62 {
  width: 62% !important;
}
.bge-contents .w-63,
.c-content-main .w-63 {
  width: 63% !important;
}
.bge-contents .w-64,
.c-content-main .w-64 {
  width: 64% !important;
}
.bge-contents .w-65,
.c-content-main .w-65 {
  width: 65% !important;
}
.bge-contents .w-66,
.c-content-main .w-66 {
  width: 66% !important;
}
.bge-contents .w-67,
.c-content-main .w-67 {
  width: 67% !important;
}
.bge-contents .w-68,
.c-content-main .w-68 {
  width: 68% !important;
}
.bge-contents .w-69,
.c-content-main .w-69 {
  width: 69% !important;
}
.bge-contents .w-70,
.c-content-main .w-70 {
  width: 70% !important;
}
.bge-contents .w-71,
.c-content-main .w-71 {
  width: 71% !important;
}
.bge-contents .w-72,
.c-content-main .w-72 {
  width: 72% !important;
}
.bge-contents .w-73,
.c-content-main .w-73 {
  width: 73% !important;
}
.bge-contents .w-74,
.c-content-main .w-74 {
  width: 74% !important;
}
.bge-contents .w-75,
.c-content-main .w-75 {
  width: 75% !important;
}
.bge-contents .w-76,
.c-content-main .w-76 {
  width: 76% !important;
}
.bge-contents .w-77,
.c-content-main .w-77 {
  width: 77% !important;
}
.bge-contents .w-78,
.c-content-main .w-78 {
  width: 78% !important;
}
.bge-contents .w-79,
.c-content-main .w-79 {
  width: 79% !important;
}
.bge-contents .w-80,
.c-content-main .w-80 {
  width: 80% !important;
}
.bge-contents .w-81,
.c-content-main .w-81 {
  width: 81% !important;
}
.bge-contents .w-82,
.c-content-main .w-82 {
  width: 82% !important;
}
.bge-contents .w-83,
.c-content-main .w-83 {
  width: 83% !important;
}
.bge-contents .w-84,
.c-content-main .w-84 {
  width: 84% !important;
}
.bge-contents .w-85,
.c-content-main .w-85 {
  width: 85% !important;
}
.bge-contents .w-86,
.c-content-main .w-86 {
  width: 86% !important;
}
.bge-contents .w-87,
.c-content-main .w-87 {
  width: 87% !important;
}
.bge-contents .w-88,
.c-content-main .w-88 {
  width: 88% !important;
}
.bge-contents .w-89,
.c-content-main .w-89 {
  width: 89% !important;
}
.bge-contents .w-90,
.c-content-main .w-90 {
  width: 90% !important;
}
.bge-contents .w-91,
.c-content-main .w-91 {
  width: 91% !important;
}
.bge-contents .w-92,
.c-content-main .w-92 {
  width: 92% !important;
}
.bge-contents .w-93,
.c-content-main .w-93 {
  width: 93% !important;
}
.bge-contents .w-94,
.c-content-main .w-94 {
  width: 94% !important;
}
.bge-contents .w-95,
.c-content-main .w-95 {
  width: 95% !important;
}
.bge-contents .w-96,
.c-content-main .w-96 {
  width: 96% !important;
}
.bge-contents .w-97,
.c-content-main .w-97 {
  width: 97% !important;
}
.bge-contents .w-98,
.c-content-main .w-98 {
  width: 98% !important;
}
.bge-contents .w-99,
.c-content-main .w-99 {
  width: 99% !important;
}
.bge-contents .w-100,
.c-content-main .w-100 {
  width: 100% !important;
}
@media (--sm-gt) {
  .bge-contents .w-pc-1,
.c-content-main .w-pc-1 {
    width: 1% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-2,
.c-content-main .w-pc-2 {
    width: 2% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-3,
.c-content-main .w-pc-3 {
    width: 3% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-4,
.c-content-main .w-pc-4 {
    width: 4% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-5,
.c-content-main .w-pc-5 {
    width: 5% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-6,
.c-content-main .w-pc-6 {
    width: 6% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-7,
.c-content-main .w-pc-7 {
    width: 7% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-8,
.c-content-main .w-pc-8 {
    width: 8% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-9,
.c-content-main .w-pc-9 {
    width: 9% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-10,
.c-content-main .w-pc-10 {
    width: 10% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-11,
.c-content-main .w-pc-11 {
    width: 11% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-12,
.c-content-main .w-pc-12 {
    width: 12% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-13,
.c-content-main .w-pc-13 {
    width: 13% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-14,
.c-content-main .w-pc-14 {
    width: 14% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-15,
.c-content-main .w-pc-15 {
    width: 15% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-16,
.c-content-main .w-pc-16 {
    width: 16% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-17,
.c-content-main .w-pc-17 {
    width: 17% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-18,
.c-content-main .w-pc-18 {
    width: 18% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-19,
.c-content-main .w-pc-19 {
    width: 19% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-20,
.c-content-main .w-pc-20 {
    width: 20% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-21,
.c-content-main .w-pc-21 {
    width: 21% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-22,
.c-content-main .w-pc-22 {
    width: 22% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-23,
.c-content-main .w-pc-23 {
    width: 23% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-24,
.c-content-main .w-pc-24 {
    width: 24% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-25,
.c-content-main .w-pc-25 {
    width: 25% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-26,
.c-content-main .w-pc-26 {
    width: 26% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-27,
.c-content-main .w-pc-27 {
    width: 27% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-28,
.c-content-main .w-pc-28 {
    width: 28% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-29,
.c-content-main .w-pc-29 {
    width: 29% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-30,
.c-content-main .w-pc-30 {
    width: 30% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-31,
.c-content-main .w-pc-31 {
    width: 31% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-32,
.c-content-main .w-pc-32 {
    width: 32% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-33,
.c-content-main .w-pc-33 {
    width: 33% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-34,
.c-content-main .w-pc-34 {
    width: 34% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-35,
.c-content-main .w-pc-35 {
    width: 35% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-36,
.c-content-main .w-pc-36 {
    width: 36% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-37,
.c-content-main .w-pc-37 {
    width: 37% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-38,
.c-content-main .w-pc-38 {
    width: 38% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-39,
.c-content-main .w-pc-39 {
    width: 39% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-40,
.c-content-main .w-pc-40 {
    width: 40% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-41,
.c-content-main .w-pc-41 {
    width: 41% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-42,
.c-content-main .w-pc-42 {
    width: 42% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-43,
.c-content-main .w-pc-43 {
    width: 43% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-44,
.c-content-main .w-pc-44 {
    width: 44% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-45,
.c-content-main .w-pc-45 {
    width: 45% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-46,
.c-content-main .w-pc-46 {
    width: 46% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-47,
.c-content-main .w-pc-47 {
    width: 47% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-48,
.c-content-main .w-pc-48 {
    width: 48% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-49,
.c-content-main .w-pc-49 {
    width: 49% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-50,
.c-content-main .w-pc-50 {
    width: 50% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-51,
.c-content-main .w-pc-51 {
    width: 51% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-52,
.c-content-main .w-pc-52 {
    width: 52% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-53,
.c-content-main .w-pc-53 {
    width: 53% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-54,
.c-content-main .w-pc-54 {
    width: 54% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-55,
.c-content-main .w-pc-55 {
    width: 55% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-56,
.c-content-main .w-pc-56 {
    width: 56% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-57,
.c-content-main .w-pc-57 {
    width: 57% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-58,
.c-content-main .w-pc-58 {
    width: 58% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-59,
.c-content-main .w-pc-59 {
    width: 59% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-60,
.c-content-main .w-pc-60 {
    width: 60% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-61,
.c-content-main .w-pc-61 {
    width: 61% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-62,
.c-content-main .w-pc-62 {
    width: 62% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-63,
.c-content-main .w-pc-63 {
    width: 63% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-64,
.c-content-main .w-pc-64 {
    width: 64% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-65,
.c-content-main .w-pc-65 {
    width: 65% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-66,
.c-content-main .w-pc-66 {
    width: 66% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-67,
.c-content-main .w-pc-67 {
    width: 67% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-68,
.c-content-main .w-pc-68 {
    width: 68% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-69,
.c-content-main .w-pc-69 {
    width: 69% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-70,
.c-content-main .w-pc-70 {
    width: 70% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-71,
.c-content-main .w-pc-71 {
    width: 71% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-72,
.c-content-main .w-pc-72 {
    width: 72% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-73,
.c-content-main .w-pc-73 {
    width: 73% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-74,
.c-content-main .w-pc-74 {
    width: 74% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-75,
.c-content-main .w-pc-75 {
    width: 75% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-76,
.c-content-main .w-pc-76 {
    width: 76% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-77,
.c-content-main .w-pc-77 {
    width: 77% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-78,
.c-content-main .w-pc-78 {
    width: 78% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-79,
.c-content-main .w-pc-79 {
    width: 79% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-80,
.c-content-main .w-pc-80 {
    width: 80% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-81,
.c-content-main .w-pc-81 {
    width: 81% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-82,
.c-content-main .w-pc-82 {
    width: 82% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-83,
.c-content-main .w-pc-83 {
    width: 83% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-84,
.c-content-main .w-pc-84 {
    width: 84% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-85,
.c-content-main .w-pc-85 {
    width: 85% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-86,
.c-content-main .w-pc-86 {
    width: 86% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-87,
.c-content-main .w-pc-87 {
    width: 87% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-88,
.c-content-main .w-pc-88 {
    width: 88% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-89,
.c-content-main .w-pc-89 {
    width: 89% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-90,
.c-content-main .w-pc-90 {
    width: 90% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-91,
.c-content-main .w-pc-91 {
    width: 91% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-92,
.c-content-main .w-pc-92 {
    width: 92% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-93,
.c-content-main .w-pc-93 {
    width: 93% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-94,
.c-content-main .w-pc-94 {
    width: 94% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-95,
.c-content-main .w-pc-95 {
    width: 95% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-96,
.c-content-main .w-pc-96 {
    width: 96% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-97,
.c-content-main .w-pc-97 {
    width: 97% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-98,
.c-content-main .w-pc-98 {
    width: 98% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-99,
.c-content-main .w-pc-99 {
    width: 99% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-100,
.c-content-main .w-pc-100 {
    width: 100% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-1,
.c-content-main .w-sp-1 {
    width: 1% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-2,
.c-content-main .w-sp-2 {
    width: 2% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-3,
.c-content-main .w-sp-3 {
    width: 3% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-4,
.c-content-main .w-sp-4 {
    width: 4% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-5,
.c-content-main .w-sp-5 {
    width: 5% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-6,
.c-content-main .w-sp-6 {
    width: 6% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-7,
.c-content-main .w-sp-7 {
    width: 7% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-8,
.c-content-main .w-sp-8 {
    width: 8% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-9,
.c-content-main .w-sp-9 {
    width: 9% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-10,
.c-content-main .w-sp-10 {
    width: 10% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-11,
.c-content-main .w-sp-11 {
    width: 11% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-12,
.c-content-main .w-sp-12 {
    width: 12% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-13,
.c-content-main .w-sp-13 {
    width: 13% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-14,
.c-content-main .w-sp-14 {
    width: 14% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-15,
.c-content-main .w-sp-15 {
    width: 15% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-16,
.c-content-main .w-sp-16 {
    width: 16% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-17,
.c-content-main .w-sp-17 {
    width: 17% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-18,
.c-content-main .w-sp-18 {
    width: 18% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-19,
.c-content-main .w-sp-19 {
    width: 19% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-20,
.c-content-main .w-sp-20 {
    width: 20% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-21,
.c-content-main .w-sp-21 {
    width: 21% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-22,
.c-content-main .w-sp-22 {
    width: 22% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-23,
.c-content-main .w-sp-23 {
    width: 23% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-24,
.c-content-main .w-sp-24 {
    width: 24% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-25,
.c-content-main .w-sp-25 {
    width: 25% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-26,
.c-content-main .w-sp-26 {
    width: 26% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-27,
.c-content-main .w-sp-27 {
    width: 27% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-28,
.c-content-main .w-sp-28 {
    width: 28% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-29,
.c-content-main .w-sp-29 {
    width: 29% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-30,
.c-content-main .w-sp-30 {
    width: 30% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-31,
.c-content-main .w-sp-31 {
    width: 31% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-32,
.c-content-main .w-sp-32 {
    width: 32% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-33,
.c-content-main .w-sp-33 {
    width: 33% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-34,
.c-content-main .w-sp-34 {
    width: 34% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-35,
.c-content-main .w-sp-35 {
    width: 35% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-36,
.c-content-main .w-sp-36 {
    width: 36% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-37,
.c-content-main .w-sp-37 {
    width: 37% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-38,
.c-content-main .w-sp-38 {
    width: 38% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-39,
.c-content-main .w-sp-39 {
    width: 39% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-40,
.c-content-main .w-sp-40 {
    width: 40% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-41,
.c-content-main .w-sp-41 {
    width: 41% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-42,
.c-content-main .w-sp-42 {
    width: 42% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-43,
.c-content-main .w-sp-43 {
    width: 43% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-44,
.c-content-main .w-sp-44 {
    width: 44% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-45,
.c-content-main .w-sp-45 {
    width: 45% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-46,
.c-content-main .w-sp-46 {
    width: 46% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-47,
.c-content-main .w-sp-47 {
    width: 47% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-48,
.c-content-main .w-sp-48 {
    width: 48% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-49,
.c-content-main .w-sp-49 {
    width: 49% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-50,
.c-content-main .w-sp-50 {
    width: 50% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-51,
.c-content-main .w-sp-51 {
    width: 51% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-52,
.c-content-main .w-sp-52 {
    width: 52% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-53,
.c-content-main .w-sp-53 {
    width: 53% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-54,
.c-content-main .w-sp-54 {
    width: 54% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-55,
.c-content-main .w-sp-55 {
    width: 55% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-56,
.c-content-main .w-sp-56 {
    width: 56% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-57,
.c-content-main .w-sp-57 {
    width: 57% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-58,
.c-content-main .w-sp-58 {
    width: 58% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-59,
.c-content-main .w-sp-59 {
    width: 59% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-60,
.c-content-main .w-sp-60 {
    width: 60% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-61,
.c-content-main .w-sp-61 {
    width: 61% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-62,
.c-content-main .w-sp-62 {
    width: 62% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-63,
.c-content-main .w-sp-63 {
    width: 63% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-64,
.c-content-main .w-sp-64 {
    width: 64% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-65,
.c-content-main .w-sp-65 {
    width: 65% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-66,
.c-content-main .w-sp-66 {
    width: 66% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-67,
.c-content-main .w-sp-67 {
    width: 67% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-68,
.c-content-main .w-sp-68 {
    width: 68% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-69,
.c-content-main .w-sp-69 {
    width: 69% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-70,
.c-content-main .w-sp-70 {
    width: 70% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-71,
.c-content-main .w-sp-71 {
    width: 71% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-72,
.c-content-main .w-sp-72 {
    width: 72% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-73,
.c-content-main .w-sp-73 {
    width: 73% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-74,
.c-content-main .w-sp-74 {
    width: 74% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-75,
.c-content-main .w-sp-75 {
    width: 75% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-76,
.c-content-main .w-sp-76 {
    width: 76% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-77,
.c-content-main .w-sp-77 {
    width: 77% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-78,
.c-content-main .w-sp-78 {
    width: 78% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-79,
.c-content-main .w-sp-79 {
    width: 79% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-80,
.c-content-main .w-sp-80 {
    width: 80% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-81,
.c-content-main .w-sp-81 {
    width: 81% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-82,
.c-content-main .w-sp-82 {
    width: 82% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-83,
.c-content-main .w-sp-83 {
    width: 83% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-84,
.c-content-main .w-sp-84 {
    width: 84% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-85,
.c-content-main .w-sp-85 {
    width: 85% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-86,
.c-content-main .w-sp-86 {
    width: 86% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-87,
.c-content-main .w-sp-87 {
    width: 87% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-88,
.c-content-main .w-sp-88 {
    width: 88% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-89,
.c-content-main .w-sp-89 {
    width: 89% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-90,
.c-content-main .w-sp-90 {
    width: 90% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-91,
.c-content-main .w-sp-91 {
    width: 91% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-92,
.c-content-main .w-sp-92 {
    width: 92% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-93,
.c-content-main .w-sp-93 {
    width: 93% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-94,
.c-content-main .w-sp-94 {
    width: 94% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-95,
.c-content-main .w-sp-95 {
    width: 95% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-96,
.c-content-main .w-sp-96 {
    width: 96% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-97,
.c-content-main .w-sp-97 {
    width: 97% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-98,
.c-content-main .w-sp-98 {
    width: 98% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-99,
.c-content-main .w-sp-99 {
    width: 99% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-100,
.c-content-main .w-sp-100 {
    width: 100% !important;
  }
}
.bge-contents .w-img-1 img,
.c-content-main .w-img-1 img {
  width: 1% !important;
}
.bge-contents .w-img-2 img,
.c-content-main .w-img-2 img {
  width: 2% !important;
}
.bge-contents .w-img-3 img,
.c-content-main .w-img-3 img {
  width: 3% !important;
}
.bge-contents .w-img-4 img,
.c-content-main .w-img-4 img {
  width: 4% !important;
}
.bge-contents .w-img-5 img,
.c-content-main .w-img-5 img {
  width: 5% !important;
}
.bge-contents .w-img-6 img,
.c-content-main .w-img-6 img {
  width: 6% !important;
}
.bge-contents .w-img-7 img,
.c-content-main .w-img-7 img {
  width: 7% !important;
}
.bge-contents .w-img-8 img,
.c-content-main .w-img-8 img {
  width: 8% !important;
}
.bge-contents .w-img-9 img,
.c-content-main .w-img-9 img {
  width: 9% !important;
}
.bge-contents .w-img-10 img,
.c-content-main .w-img-10 img {
  width: 10% !important;
}
.bge-contents .w-img-11 img,
.c-content-main .w-img-11 img {
  width: 11% !important;
}
.bge-contents .w-img-12 img,
.c-content-main .w-img-12 img {
  width: 12% !important;
}
.bge-contents .w-img-13 img,
.c-content-main .w-img-13 img {
  width: 13% !important;
}
.bge-contents .w-img-14 img,
.c-content-main .w-img-14 img {
  width: 14% !important;
}
.bge-contents .w-img-15 img,
.c-content-main .w-img-15 img {
  width: 15% !important;
}
.bge-contents .w-img-16 img,
.c-content-main .w-img-16 img {
  width: 16% !important;
}
.bge-contents .w-img-17 img,
.c-content-main .w-img-17 img {
  width: 17% !important;
}
.bge-contents .w-img-18 img,
.c-content-main .w-img-18 img {
  width: 18% !important;
}
.bge-contents .w-img-19 img,
.c-content-main .w-img-19 img {
  width: 19% !important;
}
.bge-contents .w-img-20 img,
.c-content-main .w-img-20 img {
  width: 20% !important;
}
.bge-contents .w-img-21 img,
.c-content-main .w-img-21 img {
  width: 21% !important;
}
.bge-contents .w-img-22 img,
.c-content-main .w-img-22 img {
  width: 22% !important;
}
.bge-contents .w-img-23 img,
.c-content-main .w-img-23 img {
  width: 23% !important;
}
.bge-contents .w-img-24 img,
.c-content-main .w-img-24 img {
  width: 24% !important;
}
.bge-contents .w-img-25 img,
.c-content-main .w-img-25 img {
  width: 25% !important;
}
.bge-contents .w-img-26 img,
.c-content-main .w-img-26 img {
  width: 26% !important;
}
.bge-contents .w-img-27 img,
.c-content-main .w-img-27 img {
  width: 27% !important;
}
.bge-contents .w-img-28 img,
.c-content-main .w-img-28 img {
  width: 28% !important;
}
.bge-contents .w-img-29 img,
.c-content-main .w-img-29 img {
  width: 29% !important;
}
.bge-contents .w-img-30 img,
.c-content-main .w-img-30 img {
  width: 30% !important;
}
.bge-contents .w-img-31 img,
.c-content-main .w-img-31 img {
  width: 31% !important;
}
.bge-contents .w-img-32 img,
.c-content-main .w-img-32 img {
  width: 32% !important;
}
.bge-contents .w-img-33 img,
.c-content-main .w-img-33 img {
  width: 33% !important;
}
.bge-contents .w-img-34 img,
.c-content-main .w-img-34 img {
  width: 34% !important;
}
.bge-contents .w-img-35 img,
.c-content-main .w-img-35 img {
  width: 35% !important;
}
.bge-contents .w-img-36 img,
.c-content-main .w-img-36 img {
  width: 36% !important;
}
.bge-contents .w-img-37 img,
.c-content-main .w-img-37 img {
  width: 37% !important;
}
.bge-contents .w-img-38 img,
.c-content-main .w-img-38 img {
  width: 38% !important;
}
.bge-contents .w-img-39 img,
.c-content-main .w-img-39 img {
  width: 39% !important;
}
.bge-contents .w-img-40 img,
.c-content-main .w-img-40 img {
  width: 40% !important;
}
.bge-contents .w-img-41 img,
.c-content-main .w-img-41 img {
  width: 41% !important;
}
.bge-contents .w-img-42 img,
.c-content-main .w-img-42 img {
  width: 42% !important;
}
.bge-contents .w-img-43 img,
.c-content-main .w-img-43 img {
  width: 43% !important;
}
.bge-contents .w-img-44 img,
.c-content-main .w-img-44 img {
  width: 44% !important;
}
.bge-contents .w-img-45 img,
.c-content-main .w-img-45 img {
  width: 45% !important;
}
.bge-contents .w-img-46 img,
.c-content-main .w-img-46 img {
  width: 46% !important;
}
.bge-contents .w-img-47 img,
.c-content-main .w-img-47 img {
  width: 47% !important;
}
.bge-contents .w-img-48 img,
.c-content-main .w-img-48 img {
  width: 48% !important;
}
.bge-contents .w-img-49 img,
.c-content-main .w-img-49 img {
  width: 49% !important;
}
.bge-contents .w-img-50 img,
.c-content-main .w-img-50 img {
  width: 50% !important;
}
.bge-contents .w-img-51 img,
.c-content-main .w-img-51 img {
  width: 51% !important;
}
.bge-contents .w-img-52 img,
.c-content-main .w-img-52 img {
  width: 52% !important;
}
.bge-contents .w-img-53 img,
.c-content-main .w-img-53 img {
  width: 53% !important;
}
.bge-contents .w-img-54 img,
.c-content-main .w-img-54 img {
  width: 54% !important;
}
.bge-contents .w-img-55 img,
.c-content-main .w-img-55 img {
  width: 55% !important;
}
.bge-contents .w-img-56 img,
.c-content-main .w-img-56 img {
  width: 56% !important;
}
.bge-contents .w-img-57 img,
.c-content-main .w-img-57 img {
  width: 57% !important;
}
.bge-contents .w-img-58 img,
.c-content-main .w-img-58 img {
  width: 58% !important;
}
.bge-contents .w-img-59 img,
.c-content-main .w-img-59 img {
  width: 59% !important;
}
.bge-contents .w-img-60 img,
.c-content-main .w-img-60 img {
  width: 60% !important;
}
.bge-contents .w-img-61 img,
.c-content-main .w-img-61 img {
  width: 61% !important;
}
.bge-contents .w-img-62 img,
.c-content-main .w-img-62 img {
  width: 62% !important;
}
.bge-contents .w-img-63 img,
.c-content-main .w-img-63 img {
  width: 63% !important;
}
.bge-contents .w-img-64 img,
.c-content-main .w-img-64 img {
  width: 64% !important;
}
.bge-contents .w-img-65 img,
.c-content-main .w-img-65 img {
  width: 65% !important;
}
.bge-contents .w-img-66 img,
.c-content-main .w-img-66 img {
  width: 66% !important;
}
.bge-contents .w-img-67 img,
.c-content-main .w-img-67 img {
  width: 67% !important;
}
.bge-contents .w-img-68 img,
.c-content-main .w-img-68 img {
  width: 68% !important;
}
.bge-contents .w-img-69 img,
.c-content-main .w-img-69 img {
  width: 69% !important;
}
.bge-contents .w-img-70 img,
.c-content-main .w-img-70 img {
  width: 70% !important;
}
.bge-contents .w-img-71 img,
.c-content-main .w-img-71 img {
  width: 71% !important;
}
.bge-contents .w-img-72 img,
.c-content-main .w-img-72 img {
  width: 72% !important;
}
.bge-contents .w-img-73 img,
.c-content-main .w-img-73 img {
  width: 73% !important;
}
.bge-contents .w-img-74 img,
.c-content-main .w-img-74 img {
  width: 74% !important;
}
.bge-contents .w-img-75 img,
.c-content-main .w-img-75 img {
  width: 75% !important;
}
.bge-contents .w-img-76 img,
.c-content-main .w-img-76 img {
  width: 76% !important;
}
.bge-contents .w-img-77 img,
.c-content-main .w-img-77 img {
  width: 77% !important;
}
.bge-contents .w-img-78 img,
.c-content-main .w-img-78 img {
  width: 78% !important;
}
.bge-contents .w-img-79 img,
.c-content-main .w-img-79 img {
  width: 79% !important;
}
.bge-contents .w-img-80 img,
.c-content-main .w-img-80 img {
  width: 80% !important;
}
.bge-contents .w-img-81 img,
.c-content-main .w-img-81 img {
  width: 81% !important;
}
.bge-contents .w-img-82 img,
.c-content-main .w-img-82 img {
  width: 82% !important;
}
.bge-contents .w-img-83 img,
.c-content-main .w-img-83 img {
  width: 83% !important;
}
.bge-contents .w-img-84 img,
.c-content-main .w-img-84 img {
  width: 84% !important;
}
.bge-contents .w-img-85 img,
.c-content-main .w-img-85 img {
  width: 85% !important;
}
.bge-contents .w-img-86 img,
.c-content-main .w-img-86 img {
  width: 86% !important;
}
.bge-contents .w-img-87 img,
.c-content-main .w-img-87 img {
  width: 87% !important;
}
.bge-contents .w-img-88 img,
.c-content-main .w-img-88 img {
  width: 88% !important;
}
.bge-contents .w-img-89 img,
.c-content-main .w-img-89 img {
  width: 89% !important;
}
.bge-contents .w-img-90 img,
.c-content-main .w-img-90 img {
  width: 90% !important;
}
.bge-contents .w-img-91 img,
.c-content-main .w-img-91 img {
  width: 91% !important;
}
.bge-contents .w-img-92 img,
.c-content-main .w-img-92 img {
  width: 92% !important;
}
.bge-contents .w-img-93 img,
.c-content-main .w-img-93 img {
  width: 93% !important;
}
.bge-contents .w-img-94 img,
.c-content-main .w-img-94 img {
  width: 94% !important;
}
.bge-contents .w-img-95 img,
.c-content-main .w-img-95 img {
  width: 95% !important;
}
.bge-contents .w-img-96 img,
.c-content-main .w-img-96 img {
  width: 96% !important;
}
.bge-contents .w-img-97 img,
.c-content-main .w-img-97 img {
  width: 97% !important;
}
.bge-contents .w-img-98 img,
.c-content-main .w-img-98 img {
  width: 98% !important;
}
.bge-contents .w-img-99 img,
.c-content-main .w-img-99 img {
  width: 99% !important;
}
.bge-contents .w-img-100 img,
.c-content-main .w-img-100 img {
  width: 100% !important;
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-1 img,
.c-content-main .w-pc-img-1 img {
    width: 1% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-2 img,
.c-content-main .w-pc-img-2 img {
    width: 2% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-3 img,
.c-content-main .w-pc-img-3 img {
    width: 3% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-4 img,
.c-content-main .w-pc-img-4 img {
    width: 4% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-5 img,
.c-content-main .w-pc-img-5 img {
    width: 5% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-6 img,
.c-content-main .w-pc-img-6 img {
    width: 6% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-7 img,
.c-content-main .w-pc-img-7 img {
    width: 7% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-8 img,
.c-content-main .w-pc-img-8 img {
    width: 8% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-9 img,
.c-content-main .w-pc-img-9 img {
    width: 9% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-10 img,
.c-content-main .w-pc-img-10 img {
    width: 10% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-11 img,
.c-content-main .w-pc-img-11 img {
    width: 11% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-12 img,
.c-content-main .w-pc-img-12 img {
    width: 12% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-13 img,
.c-content-main .w-pc-img-13 img {
    width: 13% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-14 img,
.c-content-main .w-pc-img-14 img {
    width: 14% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-15 img,
.c-content-main .w-pc-img-15 img {
    width: 15% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-16 img,
.c-content-main .w-pc-img-16 img {
    width: 16% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-17 img,
.c-content-main .w-pc-img-17 img {
    width: 17% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-18 img,
.c-content-main .w-pc-img-18 img {
    width: 18% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-19 img,
.c-content-main .w-pc-img-19 img {
    width: 19% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-20 img,
.c-content-main .w-pc-img-20 img {
    width: 20% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-21 img,
.c-content-main .w-pc-img-21 img {
    width: 21% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-22 img,
.c-content-main .w-pc-img-22 img {
    width: 22% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-23 img,
.c-content-main .w-pc-img-23 img {
    width: 23% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-24 img,
.c-content-main .w-pc-img-24 img {
    width: 24% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-25 img,
.c-content-main .w-pc-img-25 img {
    width: 25% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-26 img,
.c-content-main .w-pc-img-26 img {
    width: 26% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-27 img,
.c-content-main .w-pc-img-27 img {
    width: 27% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-28 img,
.c-content-main .w-pc-img-28 img {
    width: 28% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-29 img,
.c-content-main .w-pc-img-29 img {
    width: 29% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-30 img,
.c-content-main .w-pc-img-30 img {
    width: 30% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-31 img,
.c-content-main .w-pc-img-31 img {
    width: 31% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-32 img,
.c-content-main .w-pc-img-32 img {
    width: 32% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-33 img,
.c-content-main .w-pc-img-33 img {
    width: 33% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-34 img,
.c-content-main .w-pc-img-34 img {
    width: 34% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-35 img,
.c-content-main .w-pc-img-35 img {
    width: 35% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-36 img,
.c-content-main .w-pc-img-36 img {
    width: 36% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-37 img,
.c-content-main .w-pc-img-37 img {
    width: 37% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-38 img,
.c-content-main .w-pc-img-38 img {
    width: 38% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-39 img,
.c-content-main .w-pc-img-39 img {
    width: 39% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-40 img,
.c-content-main .w-pc-img-40 img {
    width: 40% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-41 img,
.c-content-main .w-pc-img-41 img {
    width: 41% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-42 img,
.c-content-main .w-pc-img-42 img {
    width: 42% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-43 img,
.c-content-main .w-pc-img-43 img {
    width: 43% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-44 img,
.c-content-main .w-pc-img-44 img {
    width: 44% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-45 img,
.c-content-main .w-pc-img-45 img {
    width: 45% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-46 img,
.c-content-main .w-pc-img-46 img {
    width: 46% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-47 img,
.c-content-main .w-pc-img-47 img {
    width: 47% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-48 img,
.c-content-main .w-pc-img-48 img {
    width: 48% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-49 img,
.c-content-main .w-pc-img-49 img {
    width: 49% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-50 img,
.c-content-main .w-pc-img-50 img {
    width: 50% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-51 img,
.c-content-main .w-pc-img-51 img {
    width: 51% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-52 img,
.c-content-main .w-pc-img-52 img {
    width: 52% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-53 img,
.c-content-main .w-pc-img-53 img {
    width: 53% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-54 img,
.c-content-main .w-pc-img-54 img {
    width: 54% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-55 img,
.c-content-main .w-pc-img-55 img {
    width: 55% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-56 img,
.c-content-main .w-pc-img-56 img {
    width: 56% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-57 img,
.c-content-main .w-pc-img-57 img {
    width: 57% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-58 img,
.c-content-main .w-pc-img-58 img {
    width: 58% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-59 img,
.c-content-main .w-pc-img-59 img {
    width: 59% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-60 img,
.c-content-main .w-pc-img-60 img {
    width: 60% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-61 img,
.c-content-main .w-pc-img-61 img {
    width: 61% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-62 img,
.c-content-main .w-pc-img-62 img {
    width: 62% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-63 img,
.c-content-main .w-pc-img-63 img {
    width: 63% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-64 img,
.c-content-main .w-pc-img-64 img {
    width: 64% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-65 img,
.c-content-main .w-pc-img-65 img {
    width: 65% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-66 img,
.c-content-main .w-pc-img-66 img {
    width: 66% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-67 img,
.c-content-main .w-pc-img-67 img {
    width: 67% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-68 img,
.c-content-main .w-pc-img-68 img {
    width: 68% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-69 img,
.c-content-main .w-pc-img-69 img {
    width: 69% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-70 img,
.c-content-main .w-pc-img-70 img {
    width: 70% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-71 img,
.c-content-main .w-pc-img-71 img {
    width: 71% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-72 img,
.c-content-main .w-pc-img-72 img {
    width: 72% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-73 img,
.c-content-main .w-pc-img-73 img {
    width: 73% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-74 img,
.c-content-main .w-pc-img-74 img {
    width: 74% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-75 img,
.c-content-main .w-pc-img-75 img {
    width: 75% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-76 img,
.c-content-main .w-pc-img-76 img {
    width: 76% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-77 img,
.c-content-main .w-pc-img-77 img {
    width: 77% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-78 img,
.c-content-main .w-pc-img-78 img {
    width: 78% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-79 img,
.c-content-main .w-pc-img-79 img {
    width: 79% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-80 img,
.c-content-main .w-pc-img-80 img {
    width: 80% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-81 img,
.c-content-main .w-pc-img-81 img {
    width: 81% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-82 img,
.c-content-main .w-pc-img-82 img {
    width: 82% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-83 img,
.c-content-main .w-pc-img-83 img {
    width: 83% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-84 img,
.c-content-main .w-pc-img-84 img {
    width: 84% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-85 img,
.c-content-main .w-pc-img-85 img {
    width: 85% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-86 img,
.c-content-main .w-pc-img-86 img {
    width: 86% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-87 img,
.c-content-main .w-pc-img-87 img {
    width: 87% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-88 img,
.c-content-main .w-pc-img-88 img {
    width: 88% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-89 img,
.c-content-main .w-pc-img-89 img {
    width: 89% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-90 img,
.c-content-main .w-pc-img-90 img {
    width: 90% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-91 img,
.c-content-main .w-pc-img-91 img {
    width: 91% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-92 img,
.c-content-main .w-pc-img-92 img {
    width: 92% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-93 img,
.c-content-main .w-pc-img-93 img {
    width: 93% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-94 img,
.c-content-main .w-pc-img-94 img {
    width: 94% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-95 img,
.c-content-main .w-pc-img-95 img {
    width: 95% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-96 img,
.c-content-main .w-pc-img-96 img {
    width: 96% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-97 img,
.c-content-main .w-pc-img-97 img {
    width: 97% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-98 img,
.c-content-main .w-pc-img-98 img {
    width: 98% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-99 img,
.c-content-main .w-pc-img-99 img {
    width: 99% !important;
  }
}
@media (--sm-gt) {
  .bge-contents .w-pc-img-100 img,
.c-content-main .w-pc-img-100 img {
    width: 100% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-1 img,
.c-content-main .w-sp-img-1 img {
    width: 1% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-2 img,
.c-content-main .w-sp-img-2 img {
    width: 2% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-3 img,
.c-content-main .w-sp-img-3 img {
    width: 3% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-4 img,
.c-content-main .w-sp-img-4 img {
    width: 4% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-5 img,
.c-content-main .w-sp-img-5 img {
    width: 5% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-6 img,
.c-content-main .w-sp-img-6 img {
    width: 6% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-7 img,
.c-content-main .w-sp-img-7 img {
    width: 7% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-8 img,
.c-content-main .w-sp-img-8 img {
    width: 8% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-9 img,
.c-content-main .w-sp-img-9 img {
    width: 9% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-10 img,
.c-content-main .w-sp-img-10 img {
    width: 10% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-11 img,
.c-content-main .w-sp-img-11 img {
    width: 11% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-12 img,
.c-content-main .w-sp-img-12 img {
    width: 12% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-13 img,
.c-content-main .w-sp-img-13 img {
    width: 13% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-14 img,
.c-content-main .w-sp-img-14 img {
    width: 14% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-15 img,
.c-content-main .w-sp-img-15 img {
    width: 15% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-16 img,
.c-content-main .w-sp-img-16 img {
    width: 16% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-17 img,
.c-content-main .w-sp-img-17 img {
    width: 17% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-18 img,
.c-content-main .w-sp-img-18 img {
    width: 18% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-19 img,
.c-content-main .w-sp-img-19 img {
    width: 19% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-20 img,
.c-content-main .w-sp-img-20 img {
    width: 20% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-21 img,
.c-content-main .w-sp-img-21 img {
    width: 21% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-22 img,
.c-content-main .w-sp-img-22 img {
    width: 22% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-23 img,
.c-content-main .w-sp-img-23 img {
    width: 23% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-24 img,
.c-content-main .w-sp-img-24 img {
    width: 24% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-25 img,
.c-content-main .w-sp-img-25 img {
    width: 25% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-26 img,
.c-content-main .w-sp-img-26 img {
    width: 26% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-27 img,
.c-content-main .w-sp-img-27 img {
    width: 27% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-28 img,
.c-content-main .w-sp-img-28 img {
    width: 28% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-29 img,
.c-content-main .w-sp-img-29 img {
    width: 29% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-30 img,
.c-content-main .w-sp-img-30 img {
    width: 30% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-31 img,
.c-content-main .w-sp-img-31 img {
    width: 31% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-32 img,
.c-content-main .w-sp-img-32 img {
    width: 32% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-33 img,
.c-content-main .w-sp-img-33 img {
    width: 33% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-34 img,
.c-content-main .w-sp-img-34 img {
    width: 34% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-35 img,
.c-content-main .w-sp-img-35 img {
    width: 35% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-36 img,
.c-content-main .w-sp-img-36 img {
    width: 36% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-37 img,
.c-content-main .w-sp-img-37 img {
    width: 37% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-38 img,
.c-content-main .w-sp-img-38 img {
    width: 38% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-39 img,
.c-content-main .w-sp-img-39 img {
    width: 39% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-40 img,
.c-content-main .w-sp-img-40 img {
    width: 40% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-41 img,
.c-content-main .w-sp-img-41 img {
    width: 41% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-42 img,
.c-content-main .w-sp-img-42 img {
    width: 42% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-43 img,
.c-content-main .w-sp-img-43 img {
    width: 43% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-44 img,
.c-content-main .w-sp-img-44 img {
    width: 44% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-45 img,
.c-content-main .w-sp-img-45 img {
    width: 45% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-46 img,
.c-content-main .w-sp-img-46 img {
    width: 46% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-47 img,
.c-content-main .w-sp-img-47 img {
    width: 47% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-48 img,
.c-content-main .w-sp-img-48 img {
    width: 48% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-49 img,
.c-content-main .w-sp-img-49 img {
    width: 49% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-50 img,
.c-content-main .w-sp-img-50 img {
    width: 50% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-51 img,
.c-content-main .w-sp-img-51 img {
    width: 51% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-52 img,
.c-content-main .w-sp-img-52 img {
    width: 52% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-53 img,
.c-content-main .w-sp-img-53 img {
    width: 53% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-54 img,
.c-content-main .w-sp-img-54 img {
    width: 54% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-55 img,
.c-content-main .w-sp-img-55 img {
    width: 55% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-56 img,
.c-content-main .w-sp-img-56 img {
    width: 56% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-57 img,
.c-content-main .w-sp-img-57 img {
    width: 57% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-58 img,
.c-content-main .w-sp-img-58 img {
    width: 58% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-59 img,
.c-content-main .w-sp-img-59 img {
    width: 59% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-60 img,
.c-content-main .w-sp-img-60 img {
    width: 60% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-61 img,
.c-content-main .w-sp-img-61 img {
    width: 61% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-62 img,
.c-content-main .w-sp-img-62 img {
    width: 62% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-63 img,
.c-content-main .w-sp-img-63 img {
    width: 63% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-64 img,
.c-content-main .w-sp-img-64 img {
    width: 64% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-65 img,
.c-content-main .w-sp-img-65 img {
    width: 65% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-66 img,
.c-content-main .w-sp-img-66 img {
    width: 66% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-67 img,
.c-content-main .w-sp-img-67 img {
    width: 67% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-68 img,
.c-content-main .w-sp-img-68 img {
    width: 68% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-69 img,
.c-content-main .w-sp-img-69 img {
    width: 69% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-70 img,
.c-content-main .w-sp-img-70 img {
    width: 70% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-71 img,
.c-content-main .w-sp-img-71 img {
    width: 71% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-72 img,
.c-content-main .w-sp-img-72 img {
    width: 72% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-73 img,
.c-content-main .w-sp-img-73 img {
    width: 73% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-74 img,
.c-content-main .w-sp-img-74 img {
    width: 74% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-75 img,
.c-content-main .w-sp-img-75 img {
    width: 75% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-76 img,
.c-content-main .w-sp-img-76 img {
    width: 76% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-77 img,
.c-content-main .w-sp-img-77 img {
    width: 77% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-78 img,
.c-content-main .w-sp-img-78 img {
    width: 78% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-79 img,
.c-content-main .w-sp-img-79 img {
    width: 79% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-80 img,
.c-content-main .w-sp-img-80 img {
    width: 80% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-81 img,
.c-content-main .w-sp-img-81 img {
    width: 81% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-82 img,
.c-content-main .w-sp-img-82 img {
    width: 82% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-83 img,
.c-content-main .w-sp-img-83 img {
    width: 83% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-84 img,
.c-content-main .w-sp-img-84 img {
    width: 84% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-85 img,
.c-content-main .w-sp-img-85 img {
    width: 85% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-86 img,
.c-content-main .w-sp-img-86 img {
    width: 86% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-87 img,
.c-content-main .w-sp-img-87 img {
    width: 87% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-88 img,
.c-content-main .w-sp-img-88 img {
    width: 88% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-89 img,
.c-content-main .w-sp-img-89 img {
    width: 89% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-90 img,
.c-content-main .w-sp-img-90 img {
    width: 90% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-91 img,
.c-content-main .w-sp-img-91 img {
    width: 91% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-92 img,
.c-content-main .w-sp-img-92 img {
    width: 92% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-93 img,
.c-content-main .w-sp-img-93 img {
    width: 93% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-94 img,
.c-content-main .w-sp-img-94 img {
    width: 94% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-95 img,
.c-content-main .w-sp-img-95 img {
    width: 95% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-96 img,
.c-content-main .w-sp-img-96 img {
    width: 96% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-97 img,
.c-content-main .w-sp-img-97 img {
    width: 97% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-98 img,
.c-content-main .w-sp-img-98 img {
    width: 98% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-99 img,
.c-content-main .w-sp-img-99 img {
    width: 99% !important;
  }
}
@media (--sm-lte) {
  .bge-contents .w-sp-img-100 img,
.c-content-main .w-sp-img-100 img {
    width: 100% !important;
  }
}
.bge-contents .grid-frame-flex, .bge-contents .grid-frame,
.c-content-main .grid-frame-flex,
.c-content-main .grid-frame {
  align-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
}
.bge-contents .grid-frame-flex,
.c-content-main .grid-frame-flex {
  flex-wrap: nowrap;
}
@media (--xs) {
  .bge-contents .grid-xs-1,
.c-content-main .grid-xs-1 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(1 / 12 * 100%);
  }
}
@media (--sm) {
  .bge-contents .grid-sm-1,
.c-content-main .grid-sm-1 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(1 / 12 * 100%);
  }
}
@media (--md) {
  .bge-contents .grid-md-1,
.c-content-main .grid-md-1 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(1 / 12 * 100%);
  }
}
@media (--lg) {
  .bge-contents .grid-lg-1,
.c-content-main .grid-lg-1 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(1 / 12 * 100%);
  }
}
@media (--xl) {
  .bge-contents .grid-xl-1,
.c-content-main .grid-xl-1 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(1 / 12 * 100%);
  }
}
@media (--xs) {
  .bge-contents .grid-xs-2,
.c-content-main .grid-xs-2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(2 / 12 * 100%);
  }
}
@media (--sm) {
  .bge-contents .grid-sm-2,
.c-content-main .grid-sm-2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(2 / 12 * 100%);
  }
}
@media (--md) {
  .bge-contents .grid-md-2,
.c-content-main .grid-md-2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(2 / 12 * 100%);
  }
}
@media (--lg) {
  .bge-contents .grid-lg-2,
.c-content-main .grid-lg-2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(2 / 12 * 100%);
  }
}
@media (--xl) {
  .bge-contents .grid-xl-2,
.c-content-main .grid-xl-2 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(2 / 12 * 100%);
  }
}
@media (--xs) {
  .bge-contents .grid-xs-3,
.c-content-main .grid-xs-3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(3 / 12 * 100%);
  }
}
@media (--sm) {
  .bge-contents .grid-sm-3,
.c-content-main .grid-sm-3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(3 / 12 * 100%);
  }
}
@media (--md) {
  .bge-contents .grid-md-3,
.c-content-main .grid-md-3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(3 / 12 * 100%);
  }
}
@media (--lg) {
  .bge-contents .grid-lg-3,
.c-content-main .grid-lg-3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(3 / 12 * 100%);
  }
}
@media (--xl) {
  .bge-contents .grid-xl-3,
.c-content-main .grid-xl-3 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(3 / 12 * 100%);
  }
}
@media (--xs) {
  .bge-contents .grid-xs-4,
.c-content-main .grid-xs-4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(4 / 12 * 100%);
  }
}
@media (--sm) {
  .bge-contents .grid-sm-4,
.c-content-main .grid-sm-4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(4 / 12 * 100%);
  }
}
@media (--md) {
  .bge-contents .grid-md-4,
.c-content-main .grid-md-4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(4 / 12 * 100%);
  }
}
@media (--lg) {
  .bge-contents .grid-lg-4,
.c-content-main .grid-lg-4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(4 / 12 * 100%);
  }
}
@media (--xl) {
  .bge-contents .grid-xl-4,
.c-content-main .grid-xl-4 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(4 / 12 * 100%);
  }
}
@media (--xs) {
  .bge-contents .grid-xs-5,
.c-content-main .grid-xs-5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(5 / 12 * 100%);
  }
}
@media (--sm) {
  .bge-contents .grid-sm-5,
.c-content-main .grid-sm-5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(5 / 12 * 100%);
  }
}
@media (--md) {
  .bge-contents .grid-md-5,
.c-content-main .grid-md-5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(5 / 12 * 100%);
  }
}
@media (--lg) {
  .bge-contents .grid-lg-5,
.c-content-main .grid-lg-5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(5 / 12 * 100%);
  }
}
@media (--xl) {
  .bge-contents .grid-xl-5,
.c-content-main .grid-xl-5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(5 / 12 * 100%);
  }
}
@media (--xs) {
  .bge-contents .grid-xs-6,
.c-content-main .grid-xs-6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(6 / 12 * 100%);
  }
}
@media (--sm) {
  .bge-contents .grid-sm-6,
.c-content-main .grid-sm-6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(6 / 12 * 100%);
  }
}
@media (--md) {
  .bge-contents .grid-md-6,
.c-content-main .grid-md-6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(6 / 12 * 100%);
  }
}
@media (--lg) {
  .bge-contents .grid-lg-6,
.c-content-main .grid-lg-6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(6 / 12 * 100%);
  }
}
@media (--xl) {
  .bge-contents .grid-xl-6,
.c-content-main .grid-xl-6 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(6 / 12 * 100%);
  }
}
@media (--xs) {
  .bge-contents .grid-xs-7,
.c-content-main .grid-xs-7 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(7 / 12 * 100%);
  }
}
@media (--sm) {
  .bge-contents .grid-sm-7,
.c-content-main .grid-sm-7 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(7 / 12 * 100%);
  }
}
@media (--md) {
  .bge-contents .grid-md-7,
.c-content-main .grid-md-7 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(7 / 12 * 100%);
  }
}
@media (--lg) {
  .bge-contents .grid-lg-7,
.c-content-main .grid-lg-7 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(7 / 12 * 100%);
  }
}
@media (--xl) {
  .bge-contents .grid-xl-7,
.c-content-main .grid-xl-7 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(7 / 12 * 100%);
  }
}
@media (--xs) {
  .bge-contents .grid-xs-8,
.c-content-main .grid-xs-8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(8 / 12 * 100%);
  }
}
@media (--sm) {
  .bge-contents .grid-sm-8,
.c-content-main .grid-sm-8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(8 / 12 * 100%);
  }
}
@media (--md) {
  .bge-contents .grid-md-8,
.c-content-main .grid-md-8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(8 / 12 * 100%);
  }
}
@media (--lg) {
  .bge-contents .grid-lg-8,
.c-content-main .grid-lg-8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(8 / 12 * 100%);
  }
}
@media (--xl) {
  .bge-contents .grid-xl-8,
.c-content-main .grid-xl-8 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(8 / 12 * 100%);
  }
}
@media (--xs) {
  .bge-contents .grid-xs-9,
.c-content-main .grid-xs-9 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(9 / 12 * 100%);
  }
}
@media (--sm) {
  .bge-contents .grid-sm-9,
.c-content-main .grid-sm-9 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(9 / 12 * 100%);
  }
}
@media (--md) {
  .bge-contents .grid-md-9,
.c-content-main .grid-md-9 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(9 / 12 * 100%);
  }
}
@media (--lg) {
  .bge-contents .grid-lg-9,
.c-content-main .grid-lg-9 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(9 / 12 * 100%);
  }
}
@media (--xl) {
  .bge-contents .grid-xl-9,
.c-content-main .grid-xl-9 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(9 / 12 * 100%);
  }
}
@media (--xs) {
  .bge-contents .grid-xs-10,
.c-content-main .grid-xs-10 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(10 / 12 * 100%);
  }
}
@media (--sm) {
  .bge-contents .grid-sm-10,
.c-content-main .grid-sm-10 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(10 / 12 * 100%);
  }
}
@media (--md) {
  .bge-contents .grid-md-10,
.c-content-main .grid-md-10 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(10 / 12 * 100%);
  }
}
@media (--lg) {
  .bge-contents .grid-lg-10,
.c-content-main .grid-lg-10 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(10 / 12 * 100%);
  }
}
@media (--xl) {
  .bge-contents .grid-xl-10,
.c-content-main .grid-xl-10 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(10 / 12 * 100%);
  }
}
@media (--xs) {
  .bge-contents .grid-xs-11,
.c-content-main .grid-xs-11 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(11 / 12 * 100%);
  }
}
@media (--sm) {
  .bge-contents .grid-sm-11,
.c-content-main .grid-sm-11 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(11 / 12 * 100%);
  }
}
@media (--md) {
  .bge-contents .grid-md-11,
.c-content-main .grid-md-11 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(11 / 12 * 100%);
  }
}
@media (--lg) {
  .bge-contents .grid-lg-11,
.c-content-main .grid-lg-11 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(11 / 12 * 100%);
  }
}
@media (--xl) {
  .bge-contents .grid-xl-11,
.c-content-main .grid-xl-11 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(11 / 12 * 100%);
  }
}
@media (--xs) {
  .bge-contents .grid-xs-12,
.c-content-main .grid-xs-12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(12 / 12 * 100%);
  }
}
@media (--sm) {
  .bge-contents .grid-sm-12,
.c-content-main .grid-sm-12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(12 / 12 * 100%);
  }
}
@media (--md) {
  .bge-contents .grid-md-12,
.c-content-main .grid-md-12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(12 / 12 * 100%);
  }
}
@media (--lg) {
  .bge-contents .grid-lg-12,
.c-content-main .grid-lg-12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(12 / 12 * 100%);
  }
}
@media (--xl) {
  .bge-contents .grid-xl-12,
.c-content-main .grid-xl-12 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(12 / 12 * 100%);
  }
}
@media (--xs) {
  .bge-contents .grid-xs-d5,
.c-content-main .grid-xs-d5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
  }
}
@media (--sm) {
  .bge-contents .grid-sm-d5,
.c-content-main .grid-sm-d5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
  }
}
@media (--md) {
  .bge-contents .grid-md-d5,
.c-content-main .grid-md-d5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
  }
}
@media (--lg) {
  .bge-contents .grid-lg-d5,
.c-content-main .grid-lg-d5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
  }
}
@media (--xl) {
  .bge-contents .grid-xl-d5,
.c-content-main .grid-xl-d5 {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 20%;
  }
}
@media (--xs) {
  .bge-contents .grid-xs-flex,
.c-content-main .grid-xs-flex {
    flex: 0 1 100%;
  }
}
@media (--sm) {
  .bge-contents .grid-sm-flex,
.c-content-main .grid-sm-flex {
    flex: 0 1 100%;
  }
}
@media (--md) {
  .bge-contents .grid-md-flex,
.c-content-main .grid-md-flex {
    flex: 0 1 100%;
  }
}
@media (--lg) {
  .bge-contents .grid-lg-flex,
.c-content-main .grid-lg-flex {
    flex: 0 1 100%;
  }
}
@media (--xl) {
  .bge-contents .grid-xl-flex,
.c-content-main .grid-xl-flex {
    flex: 0 1 100%;
  }
}
.bge-contents .mt-zero,
.c-content-main .mt-zero {
  margin-top: 0;
}
.bge-contents .mb-zero,
.c-content-main .mb-zero {
  margin-bottom: 0;
}
@media (--xs) {
  .bge-contents .mt-narrow,
.c-content-main .mt-narrow {
    margin-top: 5px;
  }
}
@media (--sm) {
  .bge-contents .mt-narrow,
.c-content-main .mt-narrow {
    margin-top: 10px;
  }
}
@media (--md) {
  .bge-contents .mt-narrow,
.c-content-main .mt-narrow {
    margin-top: 10px;
  }
}
@media (--lg) {
  .bge-contents .mt-narrow,
.c-content-main .mt-narrow {
    margin-top: 15px;
  }
}
@media (--xl) {
  .bge-contents .mt-narrow,
.c-content-main .mt-narrow {
    margin-top: 15px;
  }
}
@media (--xs) {
  .bge-contents .mt-wide,
.c-content-main .mt-wide {
    margin-top: 30px;
  }
}
@media (--sm) {
  .bge-contents .mt-wide,
.c-content-main .mt-wide {
    margin-top: 50px;
  }
}
@media (--md) {
  .bge-contents .mt-wide,
.c-content-main .mt-wide {
    margin-top: 50px;
  }
}
@media (--lg) {
  .bge-contents .mt-wide,
.c-content-main .mt-wide {
    margin-top: 80px;
  }
}
@media (--xl) {
  .bge-contents .mt-wide,
.c-content-main .mt-wide {
    margin-top: 80px;
  }
}
@media (--xs) {
  .bge-contents .mb-narrow,
.c-content-main .mb-narrow {
    margin-bottom: 5px;
  }
}
@media (--sm) {
  .bge-contents .mb-narrow,
.c-content-main .mb-narrow {
    margin-bottom: 10px;
  }
}
@media (--md) {
  .bge-contents .mb-narrow,
.c-content-main .mb-narrow {
    margin-bottom: 10px;
  }
}
@media (--lg) {
  .bge-contents .mb-narrow,
.c-content-main .mb-narrow {
    margin-bottom: 15px;
  }
}
@media (--xl) {
  .bge-contents .mb-narrow,
.c-content-main .mb-narrow {
    margin-bottom: 15px;
  }
}
@media (--xs) {
  .bge-contents .mb-wide,
.c-content-main .mb-wide {
    margin-bottom: 30px;
  }
}
@media (--sm) {
  .bge-contents .mb-wide,
.c-content-main .mb-wide {
    margin-bottom: 50px;
  }
}
@media (--md) {
  .bge-contents .mb-wide,
.c-content-main .mb-wide {
    margin-bottom: 50px;
  }
}
@media (--lg) {
  .bge-contents .mb-wide,
.c-content-main .mb-wide {
    margin-bottom: 80px;
  }
}
@media (--xl) {
  .bge-contents .mb-wide,
.c-content-main .mb-wide {
    margin-bottom: 80px;
  }
}
.bge-contents [data-bgb],
.c-content-main [data-bgb] {
  margin: 0 0 2em !important;
}
.bge-contents .bgt-btn,
.bge-contents .btn-link a,
.c-content-main .bgt-btn,
.c-content-main .btn-link a {
  appearance: none;
  background: #0d212e;
  border: none;
  color: #fff;
  display: inline-block;
  font-size: 1.7rem;
  line-height: 1.5;
  min-width: 15em;
  padding: 12px 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
}
@media (--sm-lte) {
  .bge-contents .bgt-btn,
.bge-contents .btn-link a,
.c-content-main .bgt-btn,
.c-content-main .btn-link a {
    font-size: 4.5333333333vw;
    padding: 3.2vw 8vw;
  }
}
@media (--sm-gt) {
  .bge-contents .bgt-btn:hover,
.bge-contents .btn-link a:hover,
.c-content-main .bgt-btn:hover,
.c-content-main .btn-link a:hover {
    opacity: 0.7;
  }
}
.bge-contents .bgt-btn:visited,
.bge-contents .btn-link a:visited,
.c-content-main .bgt-btn:visited,
.c-content-main .btn-link a:visited {
  color: #fff !important;
}
.bge-contents .bgt-btn:active, .bge-contents .bgt-btn:focus,
.bge-contents .btn-link a:active,
.bge-contents .btn-link a:focus,
.c-content-main .bgt-btn:active,
.c-content-main .bgt-btn:focus,
.c-content-main .btn-link a:active,
.c-content-main .btn-link a:focus {
  box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
}
.bge-contents .bgt-btn:disabled,
.bge-contents .btn-link a:disabled,
.c-content-main .bgt-btn:disabled,
.c-content-main .btn-link a:disabled {
  opacity: 0.6;
}
.bge-contents .bgt-btn::after,
.bge-contents .btn-link a::after,
.c-content-main .bgt-btn::after,
.c-content-main .btn-link a::after {
  border: 1px solid currentColor;
  border-width: 1px 1px 0 0;
  content: "";
  display: block;
  height: 8px;
  margin-top: -5px;
  position: absolute;
  right: 0.7em;
  top: 50%;
  transform: rotate(45deg);
  width: 8px;
}
.bge-contents .bgt-btn[target=_blank],
.bge-contents .btn-link a[target=_blank],
.c-content-main .bgt-btn[target=_blank],
.c-content-main .btn-link a[target=_blank] {
  padding: 12px 28px;
}
.bge-contents .bgt-btn[target=_blank]::after,
.bge-contents .btn-link a[target=_blank]::after,
.c-content-main .bgt-btn[target=_blank]::after,
.c-content-main .btn-link a[target=_blank]::after {
  background: url("/img/icon-external-nav-local-white.png");
  border: none;
  content: "";
  width: 13px;
  height: 13px;
  display: inline-block;
  transform: rotate(0);
}
.bge-contents [data-bgt-button-kind=em] .bgt-btn,
.c-content-main [data-bgt-button-kind=em] .bgt-btn {
  background: #004e81;
}
.bge-contents [data-bgt-button-kind=external] .bgt-btn,
.bge-contents .btn.external a,
.c-content-main [data-bgt-button-kind=external] .bgt-btn,
.c-content-main .btn.external a {
  background: #fff;
  color: #0d212e;
  border: 2px solid #0d212e;
  padding-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.bge-contents [data-bgt-button-kind=external] .bgt-btn::after,
.bge-contents .btn.external a::after,
.c-content-main [data-bgt-button-kind=external] .bgt-btn::after,
.c-content-main .btn.external a::after {
  transform: none;
  width: 13px;
  height: 13px;
  right: 15px;
  border: 0;
  background: url("/img/icon-external-btn.png") no-repeat;
}
.bge-contents [data-bgt-button-kind=external] .bgt-btn:visited,
.bge-contents .btn.external a:visited,
.c-content-main [data-bgt-button-kind=external] .bgt-btn:visited,
.c-content-main .btn.external a:visited {
  color: #0d212e !important;
}
.bge-contents [data-bgt-button-kind=back] .bgt-btn,
.c-content-main [data-bgt-button-kind=back] .bgt-btn {
  background: #384e5c;
}
.bge-contents [data-bgt-button-kind=back] .bgt-btn::before,
.c-content-main [data-bgt-button-kind=back] .bgt-btn::before {
  background: none;
  border: 1px solid currentColor;
  border-width: 0 0 1px 1px;
  content: "";
  display: block;
  height: 8px;
  left: 0.7em;
  margin-top: -5px;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  width: 8px;
}
.bge-contents [data-bgt-button-kind=back] .bgt-btn::after,
.c-content-main [data-bgt-button-kind=back] .bgt-btn::after {
  display: none;
}
.bge-contents .bgt-hr,
.c-content-main .bgt-hr {
  margin: 0 auto;
}
.bge-contents [data-bgt-hr-kind=dashed] .bgt-hr,
.c-content-main [data-bgt-hr-kind=dashed] .bgt-hr {
  height: 0;
  border: none;
  background: none;
  border-bottom: 2px dashed currentColor;
}
.bge-contents [data-bgt-hr-kind=bold] .bgt-hr,
.c-content-main [data-bgt-hr-kind=bold] .bgt-hr {
  height: 5px;
}
.bge-contents [data-bgt-hr-kind=narrow] .bgt-hr,
.c-content-main [data-bgt-hr-kind=narrow] .bgt-hr {
  height: 1px;
}
.bge-contents [data-bgt-hr-kind=short] .bgt-hr,
.c-content-main [data-bgt-hr-kind=short] .bgt-hr {
  width: 60%;
  height: 1px;
}
.bge-contents .bgt-download-file__link,
.bge-contents .btn-download a,
.c-content-main .bgt-download-file__link,
.c-content-main .btn-download a {
  appearance: none;
  background: #0d212e;
  border: none;
  color: #fff;
  display: inline-block;
  font-size: 1.0625em;
  line-height: 1.5;
  min-width: 15em;
  padding: 12px 55px;
  position: relative;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
}
@media (--sm-gt) {
  .bge-contents .bgt-download-file__link:hover,
.bge-contents .btn-download a:hover,
.c-content-main .bgt-download-file__link:hover,
.c-content-main .btn-download a:hover {
    opacity: 0.7;
  }
}
.bge-contents .bgt-download-file__link:visited,
.bge-contents .btn-download a:visited,
.c-content-main .bgt-download-file__link:visited,
.c-content-main .btn-download a:visited {
  color: #fff !important;
}
.bge-contents .bgt-download-file__link:active, .bge-contents .bgt-download-file__link:focus,
.bge-contents .btn-download a:active,
.bge-contents .btn-download a:focus,
.c-content-main .bgt-download-file__link:active,
.c-content-main .bgt-download-file__link:focus,
.c-content-main .btn-download a:active,
.c-content-main .btn-download a:focus {
  box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
}
.bge-contents .bgt-download-file__link::before,
.bge-contents .btn-download a::before,
.c-content-main .bgt-download-file__link::before,
.c-content-main .btn-download a::before {
  content: none;
}
.bge-contents .bgt-download-file__link::after,
.bge-contents .btn-download a::after,
.c-content-main .bgt-download-file__link::after,
.c-content-main .btn-download a::after {
  content: "";
  display: block;
  background: url("/img/icon-pdf-white.svg") no-repeat;
  background-size: cover;
  margin-top: -13px;
  position: absolute;
  right: 24px;
  top: 50%;
  width: 21px;
  height: 27px;
}
.bge-contents .bgt-download-file__link .bgt-link__icon::after,
.bge-contents .btn-download a .bgt-link__icon::after,
.c-content-main .bgt-download-file__link .bgt-link__icon::after,
.c-content-main .btn-download a .bgt-link__icon::after {
  content: none !important;
}
.bge-contents .bgt-link__size,
.c-content-main .bgt-link__size {
  font-size: 0.8125em;
  font-weight: normal;
  opacity: 0.7;
}
.bge-contents .bgt-link__icon::after,
.c-content-main .bgt-link__icon::after {
  vertical-align: middle;
}
.bge-contents .anchor-link__btn5,
.c-content-main .anchor-link__btn5 {
  display: block;
}
@media (--sm-lte) {
  .bge-contents .anchor-link__btn5 + p,
.c-content-main .anchor-link__btn5 + p {
    margin-top: 2.4em;
  }
}
.bge-contents .anchor-link__btn5-inner03,
.c-content-main .anchor-link__btn5-inner03 {
  display: flex;
  justify-content: center;
  margin-left: 0 !important;
}
@media (--sm-lte) {
  .bge-contents .anchor-link__btn5-inner03,
.c-content-main .anchor-link__btn5-inner03 {
    display: block;
  }
}
.bge-contents .anchor-link__btn5-inner03 div,
.c-content-main .anchor-link__btn5-inner03 div {
  max-width: 300px;
  flex-grow: 1;
}
@media (--sm-lte) {
  .bge-contents .anchor-link__btn5-inner03 div,
.c-content-main .anchor-link__btn5-inner03 div {
    max-width: 400px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (--sm-lte) {
  .bge-contents .anchor-link__btn5-inner03 div + div,
.c-content-main .anchor-link__btn5-inner03 div + div {
    margin-top: 1.4em;
  }
}
.bge-contents .anchor-link__btn5-inner02,
.c-content-main .anchor-link__btn5-inner02 {
  display: flex;
  justify-content: center;
  margin-left: 0 !important;
}
@media (--sm-lte) {
  .bge-contents .anchor-link__btn5-inner02,
.c-content-main .anchor-link__btn5-inner02 {
    display: block;
  }
}
.bge-contents .anchor-link__btn5-inner02 > div,
.c-content-main .anchor-link__btn5-inner02 > div {
  max-width: 300px;
  min-width: 13em;
  flex-grow: 1;
}
@media (--sm-lte) {
  .bge-contents .anchor-link__btn5-inner02 > div,
.c-content-main .anchor-link__btn5-inner02 > div {
    max-width: 400px;
    min-width: auto;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
}
.bge-contents .anchor-link__btn5-inner02 > div:nth-child(2n-1),
.c-content-main .anchor-link__btn5-inner02 > div:nth-child(2n-1) {
  margin-left: 0 !important;
}
@media (--sm-lte) {
  .bge-contents .anchor-link__btn5-inner02 > div:nth-child(2n-1),
.c-content-main .anchor-link__btn5-inner02 > div:nth-child(2n-1) {
    margin-left: auto !important;
  }
}
@media (--sm-lte) {
  .bge-contents .anchor-link__btn5-inner02 > div + div,
.c-content-main .anchor-link__btn5-inner02 > div + div {
    margin-top: 1.4em;
  }
}
@media (--sm-gt) {
  .bge-contents .anchor-link__btn5 div + div,
.c-content-main .anchor-link__btn5 div + div {
    margin-left: 1.4em;
  }
}
.bge-contents .anchor-link__btn5 > div,
.c-content-main .anchor-link__btn5 > div {
  margin-top: 1.4em;
}
.bge-contents .anchor-link__btn5 > div:first-child,
.c-content-main .anchor-link__btn5 > div:first-child {
  margin-top: 0;
}
.bge-contents .anchor-link__btn5 a,
.c-content-main .anchor-link__btn5 a {
  width: 100%;
  height: 100%;
  appearance: none;
  background: #0d212e;
  border: none;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  line-height: 1.5;
  padding: 12px 30px;
  position: relative;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
}
@media (--sm-lte) {
  .bge-contents .anchor-link__btn5 a,
.c-content-main .anchor-link__btn5 a {
    font-size: 4.5333333333vw;
    padding: 3.2vw 8vw;
  }
}
@media (--sm-gt) {
  .bge-contents .anchor-link__btn5 a:hover,
.c-content-main .anchor-link__btn5 a:hover {
    opacity: 0.7;
  }
}
.bge-contents .anchor-link__btn5 a:active, .bge-contents .anchor-link__btn5 a:focus,
.c-content-main .anchor-link__btn5 a:active,
.c-content-main .anchor-link__btn5 a:focus {
  box-shadow: 0 0 10px 0 #0570c7, 0 0 10px 0 #fff;
}
.bge-contents .anchor-link__btn5 a:disabled,
.c-content-main .anchor-link__btn5 a:disabled {
  opacity: 0.6;
}
.bge-contents .anchor-link__btn5 a::after,
.c-content-main .anchor-link__btn5 a::after {
  border: 1px solid currentColor;
  border-width: 1px 1px 0 0;
  content: "";
  display: block;
  height: 8px;
  margin-top: -5px;
  position: absolute;
  right: 0.7em;
  top: 50%;
  transform: rotate(45deg);
  width: 8px;
}
.bge-contents .anchor-link__btn5 a[target=_blank],
.c-content-main .anchor-link__btn5 a[target=_blank] {
  padding: 12px 28px;
}
.bge-contents .anchor-link__btn5 a[target=_blank]::after,
.c-content-main .anchor-link__btn5 a[target=_blank]::after {
  background: url("/img/icon-external-nav-local-white.png");
  border: none;
  content: "";
  width: 13px;
  height: 13px;
  display: inline-block;
  transform: rotate(0);
}
.bge-contents .bgb-button5 .bgb-download-file3,
.bge-contents .bgb-button5 .bgb-download-file2,
.c-content-main .bgb-button5 .bgb-download-file3,
.c-content-main .bgb-button5 .bgb-download-file2 {
  display: flex;
  justify-content: center;
  margin: 0 0 1.4em;
}
@media (--sm-lte) {
  .bge-contents .bgb-button5 .bgb-download-file3,
.bge-contents .bgb-button5 .bgb-download-file2,
.c-content-main .bgb-button5 .bgb-download-file3,
.c-content-main .bgb-button5 .bgb-download-file2 {
    display: block;
  }
}
.bge-contents .bgb-button5 .bgb-download-file3 .bgt-grid,
.bge-contents .bgb-button5 .bgb-download-file2 .bgt-grid,
.c-content-main .bgb-button5 .bgb-download-file3 .bgt-grid,
.c-content-main .bgb-button5 .bgb-download-file2 .bgt-grid {
  float: none;
  width: auto;
}
.bge-contents .bgb-button5 .bgb-download-file3 .bgt-grid .bgt-btn,
.bge-contents .bgb-button5 .bgb-download-file2 .bgt-grid .bgt-btn,
.c-content-main .bgb-button5 .bgb-download-file3 .bgt-grid .bgt-btn,
.c-content-main .bgb-button5 .bgb-download-file2 .bgt-grid .bgt-btn {
  max-width: 300px;
  min-width: 13em;
}
@media (--sm-lte) {
  .bge-contents .bgb-button5 .bgb-download-file3 .bgt-grid .bgt-btn,
.bge-contents .bgb-button5 .bgb-download-file2 .bgt-grid .bgt-btn,
.c-content-main .bgb-button5 .bgb-download-file3 .bgt-grid .bgt-btn,
.c-content-main .bgb-button5 .bgb-download-file2 .bgt-grid .bgt-btn {
    max-width: 100%;
    margin-bottom: 1.4em;
  }
}
@media (--sm-lte) {
  .bge-contents .bgb-button5 .bgb-download-file3,
.c-content-main .bgb-button5 .bgb-download-file3 {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}
.bge-contents .bgb-button5 .bgb-download-file3 .bgt-grid:nth-of-type(1) .bgt-btn, .bge-contents .bgb-button5 .bgb-download-file3 .bgt-grid:nth-of-type(2) .bgt-btn,
.c-content-main .bgb-button5 .bgb-download-file3 .bgt-grid:nth-of-type(1) .bgt-btn,
.c-content-main .bgb-button5 .bgb-download-file3 .bgt-grid:nth-of-type(2) .bgt-btn {
  margin-right: 1.4em;
}
@media (--sm-lte) {
  .bge-contents .bgb-button5 .bgb-download-file3 .bgt-grid:nth-of-type(1) .bgt-btn, .bge-contents .bgb-button5 .bgb-download-file3 .bgt-grid:nth-of-type(2) .bgt-btn,
.c-content-main .bgb-button5 .bgb-download-file3 .bgt-grid:nth-of-type(1) .bgt-btn,
.c-content-main .bgb-button5 .bgb-download-file3 .bgt-grid:nth-of-type(2) .bgt-btn {
    margin-right: 0;
  }
}
.bge-contents .bgb-button5 .bgb-download-file2,
.c-content-main .bgb-button5 .bgb-download-file2 {
  margin-bottom: 0;
}
.bge-contents .bgb-button5 .bgb-download-file2 .bgt-grid:nth-of-type(1) .bgt-btn,
.c-content-main .bgb-button5 .bgb-download-file2 .bgt-grid:nth-of-type(1) .bgt-btn {
  margin-right: 1.4em;
}
@media (--sm-lte) {
  .bge-contents .bgb-button5 .bgb-download-file2 .bgt-grid:nth-of-type(1) .bgt-btn,
.c-content-main .bgb-button5 .bgb-download-file2 .bgt-grid:nth-of-type(1) .bgt-btn {
    margin-right: 0;
    margin-bottom: 1.4em;
  }
}
@media (--sm-lte) {
  .bge-contents .bgb-button5 .bgt-container,
.c-content-main .bgb-button5 .bgt-container {
    width: 100%;
  }
}
.bge-contents .bgb-button5 .bgt-btn,
.c-content-main .bgb-button5 .bgt-btn {
  max-width: 300px;
  min-width: 13em;
}
@media (--sm-lte) {
  .bge-contents .bgb-button5 .bgt-btn,
.c-content-main .bgb-button5 .bgt-btn {
    width: 100%;
  }
}
@media (--xs) {
  .bge-contents .bgt-google-maps-link,
.c-content-main .bgt-google-maps-link {
    border: solid 1px #c8cfd4;
    border-radius: 3px;
    color: rgba(45, 45, 45, 0.5);
    margin-top: 10px;
    padding: 1em;
    text-align: center;
    text-decoration: none;
  }
  .bge-contents .bgt-google-maps-link span,
.c-content-main .bgt-google-maps-link span {
    display: inline-block;
    vertical-align: middle;
  }
  .bge-contents .bgt-google-maps-link::after,
.c-content-main .bgt-google-maps-link::after {
    display: inline-block;
    font-family: icons;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    line-height: 1;
    speak: none;
    text-align: left;
    text-indent: 0;
    text-rendering: auto;
    text-transform: none;
    vertical-align: middle;
    white-space: nowrap;
    font-family: FontAwesome;
    content: "\f08e";
    font-size: 1em;
    vertical-align: middle;
  }
}
.bge-contents .bgt-image :link,
.bge-contents .bgt-trimmed-image :link,
.bge-contents .bgt-image-link :link,
.bge-contents .bgt-trimmed-image-link :link,
.c-content-main .bgt-image :link,
.c-content-main .bgt-trimmed-image :link,
.c-content-main .bgt-image-link :link,
.c-content-main .bgt-trimmed-image-link :link {
  outline: none;
}
@media (--sm-gt) {
  .bge-contents .bgt-image a[href],
.bge-contents .bgt-trimmed-image a[href],
.bge-contents .bgt-image-link a[href],
.bge-contents .bgt-trimmed-image-link a[href],
.c-content-main .bgt-image a[href],
.c-content-main .bgt-trimmed-image a[href],
.c-content-main .bgt-image-link a[href],
.c-content-main .bgt-trimmed-image-link a[href] {
    transition: opacity 300ms;
  }
  .bge-contents .bgt-image a[href]:hover,
.bge-contents .bgt-trimmed-image a[href]:hover,
.bge-contents .bgt-image-link a[href]:hover,
.bge-contents .bgt-trimmed-image-link a[href]:hover,
.c-content-main .bgt-image a[href]:hover,
.c-content-main .bgt-trimmed-image a[href]:hover,
.c-content-main .bgt-image-link a[href]:hover,
.c-content-main .bgt-trimmed-image-link a[href]:hover {
    opacity: 0.6;
  }
}
.bge-contents .bgb-opt--bg-gray,
.bge-contents .bgb-opt--bg-blue,
.bge-contents .bgb-opt--bg-pink,
.c-content-main .bgb-opt--bg-gray,
.c-content-main .bgb-opt--bg-blue,
.c-content-main .bgb-opt--bg-pink {
  padding: 3rem;
}
.bge-contents .bgb-opt--bg-gray,
.c-content-main .bgb-opt--bg-gray {
  background: #f3f3f3 !important;
}
.bge-contents .bgb-opt--bg-blue,
.c-content-main .bgb-opt--bg-blue {
  background: #dfedf1 !important;
}
.bge-contents .bgb-opt--bg-pink,
.c-content-main .bgb-opt--bg-pink {
  background: #f6e9e9 !important;
}
.bge-contents .bgb-opt--border-trbl,
.c-content-main .bgb-opt--border-trbl {
  border-color: #ababab;
  padding: 3rem;
}
.bge-contents .bgb-opt--border-td,
.bge-contents .bgb-opt--border-tb,
.c-content-main .bgb-opt--border-td,
.c-content-main .bgb-opt--border-tb {
  border-color: #ababab;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  padding-bottom: 3rem;
  padding-top: 3rem;
}
.bge-contents .bgb-opt--border-lr,
.c-content-main .bgb-opt--border-lr {
  border-color: #ababab;
  padding-left: 3rem;
  padding-right: 3rem;
}
.bge-contents .bgb-opt--border-trl,
.c-content-main .bgb-opt--border-trl {
  border-color: #ababab;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-top: 3rem;
}
.bge-contents .bgb-opt--border-rbl,
.c-content-main .bgb-opt--border-rbl {
  border-color: #ababab;
  padding-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
}
.bge-contents .bgb-opt--border-bold,
.c-content-main .bgb-opt--border-bold {
  border-color: #ababab;
  border-width: 2px !important;
}
.bge-contents .bgb-opt--border-bold.bgb-opt--border-trl,
.c-content-main .bgb-opt--border-bold.bgb-opt--border-trl {
  border-bottom-width: 0 !important;
}
.bge-contents .bgb-opt--border-bold.bgb-opt--border-rbl,
.c-content-main .bgb-opt--border-bold.bgb-opt--border-rbl {
  border-top-width: 0 !important;
}
.bge-contents .bgb-opt--border-bold.bgb-opt--border-lr,
.c-content-main .bgb-opt--border-bold.bgb-opt--border-lr {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
}
.bge-contents .bgb-opt--border-bold.bgb-opt--border-tb,
.c-content-main .bgb-opt--border-bold.bgb-opt--border-tb {
  border-left-width: 0 !important;
  border-right-width: 0 !important;
}
.bge-contents .bgb-opt--border-pink,
.c-content-main .bgb-opt--border-pink {
  border-color: #e60057;
}
.bge-contents .bgb-opt--mb-large,
.c-content-main .bgb-opt--mb-large {
  margin-bottom: 5em !important;
}
@media (--sm-lte) {
  .bge-contents .bgb-opt--mb-large,
.c-content-main .bgb-opt--mb-large {
    margin-bottom: 3em !important;
  }
}
.bge-contents .bgb-opt--mb-small,
.c-content-main .bgb-opt--mb-small {
  margin-bottom: 1em !important;
}
@media (--sm-gt) {
  .bge-contents .bgb-opt--mb-small figcaption,
.c-content-main .bgb-opt--mb-small figcaption {
    padding: 0.8em 1.2em 0 1.2em;
  }
}
.bge-contents .bgb-opt--mb-none,
.c-content-main .bgb-opt--mb-none {
  margin-bottom: 0 !important;
}
.bge-contents .bgb-opt--mb-none + .bgb-opt--border-rbl ul,
.c-content-main .bgb-opt--mb-none + .bgb-opt--border-rbl ul {
  margin-top: 0;
}
.bge-contents .bgb-opt--border-trl.bgb-opt--pb-wide,
.bge-contents .bgb-opt--border-lr.bgb-opt--pb-wide,
.c-content-main .bgb-opt--border-trl.bgb-opt--pb-wide,
.c-content-main .bgb-opt--border-lr.bgb-opt--pb-wide {
  margin-bottom: 0;
  padding-bottom: 3rem;
}
@media (--sm-lte) {
  .bge-contents .bgb-opt--border-trl.bgb-opt--pb-wide,
.bge-contents .bgb-opt--border-lr.bgb-opt--pb-wide,
.c-content-main .bgb-opt--border-trl.bgb-opt--pb-wide,
.c-content-main .bgb-opt--border-lr.bgb-opt--pb-wide {
    padding-bottom: 1rem;
  }
}
.bge-contents .bgb-opt--border-trl.bgb-opt--pb-wide-smaller,
.bge-contents .bgb-opt--border-lr.bgb-opt--pb-wide-smaller,
.c-content-main .bgb-opt--border-trl.bgb-opt--pb-wide-smaller,
.c-content-main .bgb-opt--border-lr.bgb-opt--pb-wide-smaller {
  margin-bottom: 0;
  padding-bottom: 1rem;
}
@media (--sm-lte) {
  .bge-contents .bgb-opt--border-trl.bgb-opt--pb-wide-smaller,
.bge-contents .bgb-opt--border-lr.bgb-opt--pb-wide-smaller,
.c-content-main .bgb-opt--border-trl.bgb-opt--pb-wide-smaller,
.c-content-main .bgb-opt--border-lr.bgb-opt--pb-wide-smaller {
    padding-bottom: 0.5rem;
  }
}
.bge-contents .bgb-opt--border-trl.bgb-opt--pb-wide-larger,
.bge-contents .bgb-opt--border-lr.bgb-opt--pb-wide-larger,
.c-content-main .bgb-opt--border-trl.bgb-opt--pb-wide-larger,
.c-content-main .bgb-opt--border-lr.bgb-opt--pb-wide-larger {
  margin-bottom: 0;
  padding-bottom: 4.5rem;
}
@media (--sm-lte) {
  .bge-contents .bgb-opt--border-trl.bgb-opt--pb-wide-larger,
.bge-contents .bgb-opt--border-lr.bgb-opt--pb-wide-larger,
.c-content-main .bgb-opt--border-trl.bgb-opt--pb-wide-larger,
.c-content-main .bgb-opt--border-lr.bgb-opt--pb-wide-larger {
    padding-bottom: 2rem;
  }
}
.bge-contents [data-bgb=text-float-image1] .bgt-grid--first,
.c-content-main [data-bgb=text-float-image1] .bgt-grid--first {
  margin-left: 30px;
}
@media (--sm-lte) {
  .bge-contents [data-bgb=text-float-image1] .bgt-grid--first,
.c-content-main [data-bgb=text-float-image1] .bgt-grid--first {
    margin-bottom: 1.5em;
  }
}
.bge-contents [data-bgb=text-float-image2] .bgt-grid--first,
.c-content-main [data-bgb=text-float-image2] .bgt-grid--first {
  margin-right: 30px;
}
.bge-contents [data-bgb=text-image1] .bgt-grid--first,
.c-content-main [data-bgb=text-image1] .bgt-grid--first {
  padding-right: 15px;
}
@media (--sm-lte) {
  .bge-contents [data-bgb=text-image1] .bgt-grid--first,
.c-content-main [data-bgb=text-image1] .bgt-grid--first {
    padding-right: 0;
  }
}
.bge-contents [data-bgb=text-image1] .bgt-grid--last,
.c-content-main [data-bgb=text-image1] .bgt-grid--last {
  padding-left: 10px;
}
@media (--sm-lte) {
  .bge-contents [data-bgb=text-image1] .bgt-grid--last,
.c-content-main [data-bgb=text-image1] .bgt-grid--last {
    padding-left: 0;
  }
}
.bge-contents [data-bgb=text-image2] .bgt-grid--first,
.c-content-main [data-bgb=text-image2] .bgt-grid--first {
  float: left;
  padding-right: 15px;
}
@media (--sm-lte) {
  .bge-contents [data-bgb=text-image2] .bgt-grid--first,
.c-content-main [data-bgb=text-image2] .bgt-grid--first {
    padding-right: 0;
  }
}
.bge-contents [data-bgb=text-image2] .bgt-grid--last,
.c-content-main [data-bgb=text-image2] .bgt-grid--last {
  float: right;
  padding-left: 15px;
}
@media (--sm-lte) {
  .bge-contents [data-bgb=text-image2] .bgt-grid--last,
.c-content-main [data-bgb=text-image2] .bgt-grid--last {
    padding-left: 0;
  }
}
.bge-contents [data-bgb=button2] .bgt-grid:nth-child(1),
.bge-contents [data-bgb=download-file2] .bgt-grid:nth-child(1),
.bge-contents [data-bgb=image-link-text2] .bgt-grid:nth-child(1),
.bge-contents [data-bgb=image-link2] .bgt-grid:nth-child(1),
.bge-contents [data-bgb=image-text2] .bgt-grid:nth-child(1),
.bge-contents [data-bgb=image2] .bgt-grid:nth-child(1),
.bge-contents [data-bgb=trimmed-image-link2] .bgt-grid:nth-child(1),
.bge-contents [data-bgb=trimmed-image2] .bgt-grid:nth-child(1),
.bge-contents [data-bgb=wysiwyg2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=button2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=download-file2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=image-link-text2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=image-link2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=image-text2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=image2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=trimmed-image-link2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=trimmed-image2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=wysiwyg2] .bgt-grid:nth-child(1) {
  padding-right: 15px;
}
@media (--sm-lte) {
  .bge-contents [data-bgb=button2] .bgt-grid:nth-child(1),
.bge-contents [data-bgb=download-file2] .bgt-grid:nth-child(1),
.bge-contents [data-bgb=image-link-text2] .bgt-grid:nth-child(1),
.bge-contents [data-bgb=image-link2] .bgt-grid:nth-child(1),
.bge-contents [data-bgb=image-text2] .bgt-grid:nth-child(1),
.bge-contents [data-bgb=image2] .bgt-grid:nth-child(1),
.bge-contents [data-bgb=trimmed-image-link2] .bgt-grid:nth-child(1),
.bge-contents [data-bgb=trimmed-image2] .bgt-grid:nth-child(1),
.bge-contents [data-bgb=wysiwyg2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=button2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=download-file2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=image-link-text2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=image-link2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=image-text2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=image2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=trimmed-image-link2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=trimmed-image2] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=wysiwyg2] .bgt-grid:nth-child(1) {
    padding-right: 0;
  }
}
.bge-contents [data-bgb=button2] .bgt-grid:nth-child(2),
.bge-contents [data-bgb=download-file2] .bgt-grid:nth-child(2),
.bge-contents [data-bgb=image-link-text2] .bgt-grid:nth-child(2),
.bge-contents [data-bgb=image-link2] .bgt-grid:nth-child(2),
.bge-contents [data-bgb=image-text2] .bgt-grid:nth-child(2),
.bge-contents [data-bgb=image2] .bgt-grid:nth-child(2),
.bge-contents [data-bgb=trimmed-image-link2] .bgt-grid:nth-child(2),
.bge-contents [data-bgb=trimmed-image2] .bgt-grid:nth-child(2),
.bge-contents [data-bgb=wysiwyg2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=button2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=download-file2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=image-link-text2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=image-link2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=image-text2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=image2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=trimmed-image-link2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=trimmed-image2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=wysiwyg2] .bgt-grid:nth-child(2) {
  padding-left: 15px;
}
@media (--sm-lte) {
  .bge-contents [data-bgb=button2] .bgt-grid:nth-child(2),
.bge-contents [data-bgb=download-file2] .bgt-grid:nth-child(2),
.bge-contents [data-bgb=image-link-text2] .bgt-grid:nth-child(2),
.bge-contents [data-bgb=image-link2] .bgt-grid:nth-child(2),
.bge-contents [data-bgb=image-text2] .bgt-grid:nth-child(2),
.bge-contents [data-bgb=image2] .bgt-grid:nth-child(2),
.bge-contents [data-bgb=trimmed-image-link2] .bgt-grid:nth-child(2),
.bge-contents [data-bgb=trimmed-image2] .bgt-grid:nth-child(2),
.bge-contents [data-bgb=wysiwyg2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=button2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=download-file2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=image-link-text2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=image-link2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=image-text2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=image2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=trimmed-image-link2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=trimmed-image2] .bgt-grid:nth-child(2),
.c-content-main [data-bgb=wysiwyg2] .bgt-grid:nth-child(2) {
    padding-left: 0;
  }
}
.bge-contents [data-bgb=wysiwyg3] .bgt-grid,
.c-content-main [data-bgb=wysiwyg3] .bgt-grid {
  width: 33.33%;
  float: left;
  padding-left: 6.66px;
  padding-right: 6.66px;
}
@media (--sm-lte) {
  .bge-contents [data-bgb=wysiwyg3] .bgt-grid,
.c-content-main [data-bgb=wysiwyg3] .bgt-grid {
    width: 100%;
    float: none;
    padding-left: 0;
    padding-right: 0;
  }
}
.bge-contents [data-bgb=wysiwyg3] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=wysiwyg3] .bgt-grid:nth-child(1) {
  padding-left: 0;
  padding-right: 13.33px;
}
@media (--sm-lte) {
  .bge-contents [data-bgb=wysiwyg3] .bgt-grid:nth-child(1),
.c-content-main [data-bgb=wysiwyg3] .bgt-grid:nth-child(1) {
    padding-right: 0;
  }
}
.bge-contents [data-bgb=wysiwyg3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=wysiwyg3] .bgt-grid:nth-child(3) {
  padding-left: 13.33px;
  padding-right: 0;
}
@media (--sm-lte) {
  .bge-contents [data-bgb=wysiwyg3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=wysiwyg3] .bgt-grid:nth-child(3) {
    padding-left: 0;
  }
}
@media (--sm-lte) {
  .bge-contents [data-bgb=wysiwyg3] .bgt-grid [data-bgt] > :last-child,
.c-content-main [data-bgb=wysiwyg3] .bgt-grid [data-bgt] > :last-child {
    margin-bottom: 20px;
  }
}
@media (--sm-lte) {
  .bge-contents [data-bgb=wysiwyg3] .bgt-grid[class*=bgt-sp-grid] [data-bgt] > :last-child,
.c-content-main [data-bgb=wysiwyg3] .bgt-grid[class*=bgt-sp-grid] [data-bgt] > :last-child {
    margin-bottom: 0;
  }
}
.bge-contents [data-bgb=button3] .bgt-grid,
.bge-contents [data-bgb=download-file3] .bgt-grid,
.bge-contents [data-bgb=image3] .bgt-grid,
.bge-contents [data-bgb=image-link3] .bgt-grid,
.bge-contents [data-bgb=image-link-text3] .bgt-grid,
.bge-contents [data-bgb=image-text3] .bgt-grid,
.bge-contents [data-bgb=trimmed-image3] .bgt-grid,
.bge-contents [data-bgb=trimmed-image-link3] .bgt-grid,
.c-content-main [data-bgb=button3] .bgt-grid,
.c-content-main [data-bgb=download-file3] .bgt-grid,
.c-content-main [data-bgb=image3] .bgt-grid,
.c-content-main [data-bgb=image-link3] .bgt-grid,
.c-content-main [data-bgb=image-link-text3] .bgt-grid,
.c-content-main [data-bgb=image-text3] .bgt-grid,
.c-content-main [data-bgb=trimmed-image3] .bgt-grid,
.c-content-main [data-bgb=trimmed-image-link3] .bgt-grid {
  padding-left: 8px;
  padding-right: 8px;
}
@media (--sm-lte) {
  .bge-contents [data-bgb=button3] .bgt-grid,
.bge-contents [data-bgb=download-file3] .bgt-grid,
.bge-contents [data-bgb=image3] .bgt-grid,
.bge-contents [data-bgb=image-link3] .bgt-grid,
.bge-contents [data-bgb=image-link-text3] .bgt-grid,
.bge-contents [data-bgb=image-text3] .bgt-grid,
.bge-contents [data-bgb=trimmed-image3] .bgt-grid,
.bge-contents [data-bgb=trimmed-image-link3] .bgt-grid,
.c-content-main [data-bgb=button3] .bgt-grid,
.c-content-main [data-bgb=download-file3] .bgt-grid,
.c-content-main [data-bgb=image3] .bgt-grid,
.c-content-main [data-bgb=image-link3] .bgt-grid,
.c-content-main [data-bgb=image-link-text3] .bgt-grid,
.c-content-main [data-bgb=image-text3] .bgt-grid,
.c-content-main [data-bgb=trimmed-image3] .bgt-grid,
.c-content-main [data-bgb=trimmed-image-link3] .bgt-grid {
    padding: 0;
  }
}
.bge-contents [data-bgb=button3] .bgt-grid:first-child,
.bge-contents [data-bgb=download-file3] .bgt-grid:first-child,
.bge-contents [data-bgb=image3] .bgt-grid:first-child,
.bge-contents [data-bgb=image-link3] .bgt-grid:first-child,
.bge-contents [data-bgb=image-link-text3] .bgt-grid:first-child,
.bge-contents [data-bgb=image-text3] .bgt-grid:first-child,
.bge-contents [data-bgb=trimmed-image3] .bgt-grid:first-child,
.bge-contents [data-bgb=trimmed-image-link3] .bgt-grid:first-child,
.c-content-main [data-bgb=button3] .bgt-grid:first-child,
.c-content-main [data-bgb=download-file3] .bgt-grid:first-child,
.c-content-main [data-bgb=image3] .bgt-grid:first-child,
.c-content-main [data-bgb=image-link3] .bgt-grid:first-child,
.c-content-main [data-bgb=image-link-text3] .bgt-grid:first-child,
.c-content-main [data-bgb=image-text3] .bgt-grid:first-child,
.c-content-main [data-bgb=trimmed-image3] .bgt-grid:first-child,
.c-content-main [data-bgb=trimmed-image-link3] .bgt-grid:first-child {
  padding-left: 0;
  padding-right: 16px;
}
@media (--sm-lte) {
  .bge-contents [data-bgb=button3] .bgt-grid:first-child,
.bge-contents [data-bgb=download-file3] .bgt-grid:first-child,
.bge-contents [data-bgb=image3] .bgt-grid:first-child,
.bge-contents [data-bgb=image-link3] .bgt-grid:first-child,
.bge-contents [data-bgb=image-link-text3] .bgt-grid:first-child,
.bge-contents [data-bgb=image-text3] .bgt-grid:first-child,
.bge-contents [data-bgb=trimmed-image3] .bgt-grid:first-child,
.bge-contents [data-bgb=trimmed-image-link3] .bgt-grid:first-child,
.c-content-main [data-bgb=button3] .bgt-grid:first-child,
.c-content-main [data-bgb=download-file3] .bgt-grid:first-child,
.c-content-main [data-bgb=image3] .bgt-grid:first-child,
.c-content-main [data-bgb=image-link3] .bgt-grid:first-child,
.c-content-main [data-bgb=image-link-text3] .bgt-grid:first-child,
.c-content-main [data-bgb=image-text3] .bgt-grid:first-child,
.c-content-main [data-bgb=trimmed-image3] .bgt-grid:first-child,
.c-content-main [data-bgb=trimmed-image-link3] .bgt-grid:first-child {
    padding: 0;
  }
}
.bge-contents [data-bgb=button3] .bgt-grid:nth-child(3),
.bge-contents [data-bgb=download-file3] .bgt-grid:nth-child(3),
.bge-contents [data-bgb=image3] .bgt-grid:nth-child(3),
.bge-contents [data-bgb=image-link3] .bgt-grid:nth-child(3),
.bge-contents [data-bgb=image-link-text3] .bgt-grid:nth-child(3),
.bge-contents [data-bgb=image-text3] .bgt-grid:nth-child(3),
.bge-contents [data-bgb=trimmed-image3] .bgt-grid:nth-child(3),
.bge-contents [data-bgb=trimmed-image-link3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=button3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=download-file3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=image3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=image-link3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=image-link-text3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=image-text3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=trimmed-image3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=trimmed-image-link3] .bgt-grid:nth-child(3) {
  padding-left: 16px;
  padding-right: 0;
}
@media (--sm-lte) {
  .bge-contents [data-bgb=button3] .bgt-grid:nth-child(3),
.bge-contents [data-bgb=download-file3] .bgt-grid:nth-child(3),
.bge-contents [data-bgb=image3] .bgt-grid:nth-child(3),
.bge-contents [data-bgb=image-link3] .bgt-grid:nth-child(3),
.bge-contents [data-bgb=image-link-text3] .bgt-grid:nth-child(3),
.bge-contents [data-bgb=image-text3] .bgt-grid:nth-child(3),
.bge-contents [data-bgb=trimmed-image3] .bgt-grid:nth-child(3),
.bge-contents [data-bgb=trimmed-image-link3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=button3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=download-file3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=image3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=image-link3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=image-link-text3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=image-text3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=trimmed-image3] .bgt-grid:nth-child(3),
.c-content-main [data-bgb=trimmed-image-link3] .bgt-grid:nth-child(3) {
    padding: 0;
  }
}
.bge-contents [data-bgt=ckeditor]:nth-child(2),
.c-content-main [data-bgt=ckeditor]:nth-child(2) {
  margin-top: 1em;
}
.bge-contents [data-bge=ckeditor] img,
.c-content-main [data-bge=ckeditor] img {
  max-width: 100%;
  height: auto;
}
.bge-contents [data-bgt=gallery] .bgt-gallery,
.c-content-main [data-bgt=gallery] .bgt-gallery {
  padding-top: 56.25%;
}
.bge-contents [data-bgt=gallery] .bgt-gallery:hover .bgt-gallery-ctrl,
.c-content-main [data-bgt=gallery] .bgt-gallery:hover .bgt-gallery-ctrl {
  opacity: 1;
}
.bge-contents [data-bgt=gallery] .bgt-gallery__img img,
.c-content-main [data-bgt=gallery] .bgt-gallery__img img {
  object-fit: cover;
}
.bge-contents [data-bgt=gallery] .bgt-gallery__caption,
.c-content-main [data-bgt=gallery] .bgt-gallery__caption {
  color: #fff;
  background: color(#101010 a(0.6));
  padding: 0.7em 1em;
  text-align: center;
}
.bge-contents [data-bgt=gallery] .bgt-gallery-ctrl,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl {
  top: 50%;
  opacity: 0;
  transition: opacity 200ms ease-in;
}
.bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__prev, .bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__next,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__prev,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__next {
  display: block;
  appearance: none;
  margin: -20px 0 0;
  padding: 0;
  background: none;
  border: none;
  transform-origin: center center;
}
.bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__prev:hover, .bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__next:hover,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__prev:hover,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__next:hover {
  opacity: 0.5;
}
.bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__prev:focus, .bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__next:focus,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__prev:focus,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__next:focus {
  outline: none;
}
.bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__prev span, .bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__next span,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__prev span,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__next span {
  display: block;
  overflow: hidden;
  width: 0;
  height: 0;
}
.bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__prev::after, .bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__next::after,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__prev::after,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__next::after {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  border: solid #fff;
  border-width: 5px 5px 0 0;
}
.bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__prev,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__prev {
  left: 20px;
  transform: translate3d(0, 0, 0) rotate(225deg) !important;
}
.bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__next,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__next {
  right: 20px;
  transform: translate3d(0, 0, 0) rotate(45deg) !important;
}
.bge-contents [data-bgt=gallery] .bgt-gallery-marker li::before,
.c-content-main [data-bgt=gallery] .bgt-gallery-marker li::before {
  display: none;
}
.bge-contents [data-bgt=gallery] [data-gallery-marker=thumbs] + .bgt-gallery-marker,
.c-content-main [data-bgt=gallery] [data-gallery-marker=thumbs] + .bgt-gallery-marker {
  margin-top: 10px;
}
.bge-contents [data-bgt=gallery] [data-gallery-marker=thumbs] + .bgt-gallery-marker li,
.c-content-main [data-bgt=gallery] [data-gallery-marker=thumbs] + .bgt-gallery-marker li {
  margin: 0 5px;
  width: 3.75em;
  height: 3.75em;
}
.bge-contents [data-bgt=gallery] [data-gallery-marker=dot] + .bgt-gallery-marker,
.c-content-main [data-bgt=gallery] [data-gallery-marker=dot] + .bgt-gallery-marker {
  margin-top: 10px;
}
.bge-contents [data-bgt=gallery] [data-gallery-marker=dot] + .bgt-gallery-marker li,
.c-content-main [data-bgt=gallery] [data-gallery-marker=dot] + .bgt-gallery-marker li {
  margin: 0 2px;
  width: 10px;
  height: 10px;
  background-color: #0d212e;
  border-radius: 100%;
  opacity: 0.6;
}
.bge-contents [data-bgt=gallery] [data-gallery-marker=dot] + .bgt-gallery-marker li.current,
.c-content-main [data-bgt=gallery] [data-gallery-marker=dot] + .bgt-gallery-marker li.current {
  opacity: 1;
}
.bge-contents .bc-time,
.c-content-main .bc-time {
  margin-bottom: 5em;
  text-align: right;
}
.bge-contents .bc-cat,
.c-content-main .bc-cat {
  margin-bottom: 1em;
  text-align: right;
}
.bge-contents .bc-cat > div,
.c-content-main .bc-cat > div {
  background: #0d212e;
  color: #fff;
  display: inline-block;
  font-size: 0.8125em;
  padding: 0.2em 0.4em;
}
.bge-contents .bc-area-cta,
.c-content-main .bc-area-cta {
  margin-top: 4em;
  text-align: center;
}
.bge-contents .bc-area-cta a,
.c-content-main .bc-area-cta a {
  background: #0d212e;
}
.bge-contents .bc-area-cta a span::before,
.c-content-main .bc-area-cta a span::before {
  display: inline-block;
  font-family: icons;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-align: left;
  text-indent: 0;
  text-rendering: auto;
  text-transform: none;
  vertical-align: middle;
  white-space: nowrap;
  color: #c8cfd4;
  content: "\e100";
  margin-right: 0.5em;
}
.bge-contents .bc-area-ctrl,
.c-content-main .bc-area-ctrl {
  margin-top: 6em;
}
@media (--xs) {
  .bge-contents .bc-area-ctrl,
.c-content-main .bc-area-ctrl {
    text-align: center;
  }
}
.bge-contents .bc-form-agreement,
.c-content-main .bc-form-agreement {
  margin-top: 4em;
  text-align: center;
}
.bge-contents .bc-form-submit,
.c-content-main .bc-form-submit {
  margin-top: 3em;
  text-align: center;
}
@media (--sm-lte) {
  .bge-contents .bgb-text-image1 .bgt-grid--first,
.c-content-main .bgb-text-image1 .bgt-grid--first {
    margin-bottom: 20px;
  }
}
@media (--sm-lte) {
  .bge-contents .bgb-text-image2 .bgt-grid--first,
.c-content-main .bgb-text-image2 .bgt-grid--first {
    margin-bottom: 20px;
  }
}
.bge-contents .news-sns,
.c-content-main .news-sns {
  display: flex;
  justify-content: center;
  margin: 60px 0 30px;
}
@media (--sm-lte) {
  .bge-contents .news-sns,
.c-content-main .news-sns {
    margin: 13.3333333333vw 0 8vw;
  }
}
.bge-contents .news-sns ul,
.c-content-main .news-sns ul {
  display: flex;
  padding-left: 0;
  border-bottom: 1px solid #c8cfd4;
  margin: 0;
}
.bge-contents .news-sns ul li,
.c-content-main .news-sns ul li {
  padding: 0 !important;
  margin: 0 6px 7px !important;
}
@media (--sm-lte) {
  .bge-contents .news-sns ul li,
.c-content-main .news-sns ul li {
    margin: 0 1.3333333333vw 1.8666666667vw !important;
  }
}
.bge-contents .news-sns ul li::before,
.c-content-main .news-sns ul li::before {
  display: none !important;
}
.bge-contents .table-students table,
.c-content-main .table-students table {
  font-size: 1.3rem;
}
.bge-contents .table-students table th,
.c-content-main .table-students table th {
  text-align: center;
}
.bge-contents .table-students table th,
.bge-contents .table-students table td,
.c-content-main .table-students table th,
.c-content-main .table-students table td {
  padding: 0.5em 0.4em;
}
@media (--sm-gt) {
  .bge-contents .text-youtube[data-bgb=wysiwyg2] .bgt-grid--first,
.c-content-main .text-youtube[data-bgb=wysiwyg2] .bgt-grid--first {
    padding-right: 20px;
  }
  .bge-contents .text-youtube[data-bgb=wysiwyg2] .bgt-grid--last,
.c-content-main .text-youtube[data-bgb=wysiwyg2] .bgt-grid--last {
    padding-left: 20px;
  }
}
.bge-contents .text-youtube figure,
.c-content-main .text-youtube figure {
  margin: 0;
}
.bge-contents .text-youtube figure > div,
.c-content-main .text-youtube figure > div {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}
.bge-contents .text-youtube figure > div iframe,
.c-content-main .text-youtube figure > div iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
@media (--sm-gt) {
  .bge-contents .col2-youtube[data-bgb=wysiwyg2] .bgt-grid--first,
.c-content-main .col2-youtube[data-bgb=wysiwyg2] .bgt-grid--first {
    padding-right: 20px;
  }
  .bge-contents .col2-youtube[data-bgb=wysiwyg2] .bgt-grid--last,
.c-content-main .col2-youtube[data-bgb=wysiwyg2] .bgt-grid--last {
    padding-left: 20px;
  }
}
@media (--sm-lte) {
  .bge-contents .col2-youtube .bgt-grid [data-bgt] > :last-child,
.c-content-main .col2-youtube .bgt-grid [data-bgt] > :last-child {
    margin-bottom: 30px;
  }
}
.bge-contents .col2-youtube h4,
.c-content-main .col2-youtube h4 {
  font-size: 2.2rem;
  border-bottom: none;
  line-height: 1.4;
  padding: 0 0 20px;
  margin: 0.7em 0 10px;
}
@media (--sm-lte) {
  .bge-contents .col2-youtube h4,
.c-content-main .col2-youtube h4 {
    font-size: 5.6vw;
    padding: 0 0 4vw;
  }
}
.bge-contents .col2-youtube h4::before, .bge-contents .col2-youtube h4::after,
.c-content-main .col2-youtube h4::before,
.c-content-main .col2-youtube h4::after {
  content: "";
  position: absolute;
  display: block;
  height: 2px;
  top: auto;
  bottom: 0;
  left: 0;
}
.bge-contents .col2-youtube h4::before,
.c-content-main .col2-youtube h4::before {
  background: rgba(146, 160, 170, 0.5);
  width: 100%;
}
.bge-contents .col2-youtube h4::after,
.c-content-main .col2-youtube h4::after {
  background: #00a2e6;
  width: 120px;
}
.bge-contents .col2-youtube figure,
.c-content-main .col2-youtube figure {
  margin: 0;
}
.bge-contents .col2-youtube figure > div,
.c-content-main .col2-youtube figure > div {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
}
.bge-contents .col2-youtube figure > div iframe,
.c-content-main .col2-youtube figure > div iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.bge-contents .col2-youtube figure figcaption,
.c-content-main .col2-youtube figure figcaption {
  background: #f3f3f3;
  font-size: 1.6rem;
  font-weight: 500;
}
@media (--sm-lte) {
  .bge-contents .col2-youtube figure figcaption,
.c-content-main .col2-youtube figure figcaption {
    font-size: 4.2666666667vw;
  }
}
.bge-contents .col2-youtube figure figcaption strong,
.c-content-main .col2-youtube figure figcaption strong {
  font-size: 1.8rem;
}
@media (--sm-lte) {
  .bge-contents .col2-youtube figure figcaption strong,
.c-content-main .col2-youtube figure figcaption strong {
    font-size: 4.8vw;
  }
}
.bge-contents .youtube-popup-btn .bgt-btn,
.c-content-main .youtube-popup-btn .bgt-btn {
  padding: 12px 55px;
}
@media (--sm-lte) {
  .bge-contents .youtube-popup-btn .bgt-btn,
.c-content-main .youtube-popup-btn .bgt-btn {
    padding: 3.2vw 14.6666666667vw;
  }
}
.bge-contents .youtube-popup-btn .bgt-btn::after,
.c-content-main .youtube-popup-btn .bgt-btn::after {
  border: none;
  right: 20px;
  width: 30px;
  height: 22px;
  background: url("/img/icon-youtube-btn.png") no-repeat center center/100% 100%;
  transform: rotate(0deg);
  margin-top: -11px;
}
.bge-contents .faq-title,
.c-content-main .faq-title {
  position: relative;
  margin: 0;
  cursor: pointer;
}
.bge-contents .faq-title::before,
.c-content-main .faq-title::before {
  content: "Q";
  display: block;
  font-size: 2rem;
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
  color: #00a2e6;
  position: absolute;
  top: 7px;
  left: 35px;
  z-index: 10;
  font-weight: bold;
}
@media (--sm-lte) {
  .bge-contents .faq-title::before,
.c-content-main .faq-title::before {
    top: 1.8666666667vw;
    left: 4vw;
    font-size: 5.3333333333vw;
  }
}
.bge-contents .faq-title h2,
.c-content-main .faq-title h2 {
  font-size: 1.8rem;
  font-weight: bold;
  border-bottom: none;
  background: #f3f3f3;
  padding: 13px 40px 13px 64px;
}
@media (--sm-lte) {
  .bge-contents .faq-title h2,
.c-content-main .faq-title h2 {
    padding: 3.4666666667vw 12.6666666667vw 3.4666666667vw 12vw;
    font-size: 4.5333333333vw;
  }
}
.bge-contents .faq-title h2::before, .bge-contents .faq-title h2::after,
.c-content-main .faq-title h2::before,
.c-content-main .faq-title h2::after {
  content: "";
  height: 2px;
  width: 15px;
  position: absolute;
  left: inherit;
  right: 19px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 10;
  border: none;
  transform: none;
  transition: transform 300ms;
  background: #00a2e6;
}
.bge-contents .faq-title h2::after,
.c-content-main .faq-title h2::after {
  transform: rotate(90deg);
}
.bge-contents .faq-title.open h2::after,
.c-content-main .faq-title.open h2::after {
  transform: rotate(0deg);
}
.bge-contents .faq-body,
.c-content-main .faq-body {
  padding: 0 30px 30px 64px;
  position: relative;
}
@media (--sm-lte) {
  .bge-contents .faq-body,
.c-content-main .faq-body {
    padding: 0 0 3.4666666667vw 12vw;
  }
}
.bge-contents .faq-body::before,
.c-content-main .faq-body::before {
  content: "A";
  display: block;
  font-size: 2rem;
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
  position: absolute;
  top: -5px;
  left: 35px;
  z-index: 10;
  font-weight: bold;
}
@media (--sm-lte) {
  .bge-contents .faq-body::before,
.c-content-main .faq-body::before {
    top: -1.8666666667vw;
    left: 4vw;
    font-size: 5.3333333333vw;
  }
}
.bge-contents .tab-nav ul,
.c-content-main .tab-nav ul {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: relative;
}
@media (--sm-lte) {
  .bge-contents .tab-nav ul,
.c-content-main .tab-nav ul {
    margin: 0 -0.6666666667vw;
  }
}
.bge-contents .tab-nav ul::before,
.c-content-main .tab-nav ul::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 5px;
  right: 5px;
  height: 1px;
  background: #111314;
}
@media (--sm-lte) {
  .bge-contents .tab-nav ul::before,
.c-content-main .tab-nav ul::before {
    left: 0.6666666667vw;
    right: 0.6666666667vw;
  }
}
.bge-contents .tab-nav ul li,
.c-content-main .tab-nav ul li {
  flex: 1 1 100%;
  padding: 0 5px;
  margin: 0;
}
@media (--sm-lte) {
  .bge-contents .tab-nav ul li,
.c-content-main .tab-nav ul li {
    padding: 0 0.6666666667vw;
  }
}
.bge-contents .tab-nav ul li::before,
.c-content-main .tab-nav ul li::before {
  content: none;
}
.bge-contents .tab-nav ul li a,
.c-content-main .tab-nav ul li a {
  display: block;
  color: #111314;
  text-align: center;
  text-decoration: none;
  padding: 8px;
  border: 1px solid #111314;
  border-width: 1px 1px 0;
  border-radius: 5px 5px 0 0;
  position: relative;
  z-index: 10;
}
@media (--sm-lte) {
  .bge-contents .tab-nav ul li a,
.c-content-main .tab-nav ul li a {
    padding: 1.1vw;
    font-size: 4vw;
  }
}
@media (--sm-gt) {
  .bge-contents .tab-nav ul li:not(.tab-nav-current) a,
.c-content-main .tab-nav ul li:not(.tab-nav-current) a {
    transition: color 300ms, background 300ms;
  }
  .bge-contents .tab-nav ul li:not(.tab-nav-current):hover a,
.c-content-main .tab-nav ul li:not(.tab-nav-current):hover a {
    color: #fff;
    background: #111314;
  }
}
.bge-contents .tab-nav ul li.tab-nav-current a,
.c-content-main .tab-nav ul li.tab-nav-current a {
  border-bottom: 3px solid #fff;
  font-size: 1.7rem;
  font-weight: bold;
  padding: 10px;
  margin-bottom: -1px;
}
@media (--sm-lte) {
  .bge-contents .tab-nav ul li.tab-nav-current a,
.c-content-main .tab-nav ul li.tab-nav-current a {
    font-size: 4.5333333333vw;
    padding: 2.6666666667vw;
  }
}
.bge-contents .tab-switch,
.c-content-main .tab-switch {
  position: relative;
}
.bge-contents .tab-switch ul,
.c-content-main .tab-switch ul {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0;
  margin: 0 5px;
  overflow: auto;
}
@media (--sm-lte) {
  .bge-contents .tab-switch ul,
.c-content-main .tab-switch ul {
    margin: 0 -0.6666666667vw;
  }
}
.bge-contents .tab-switch ul::before,
.c-content-main .tab-switch ul::before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  height: 1px;
  background: #111314;
}
@media (--sm-lte) {
  .bge-contents .tab-switch ul::before,
.c-content-main .tab-switch ul::before {
    left: 0;
    right: 0;
  }
}
.bge-contents .tab-switch ul li,
.c-content-main .tab-switch ul li {
  flex: 1 1 100%;
  padding: 0 5px;
  margin: 0;
}
@media (--sm-lte) {
  .bge-contents .tab-switch ul li,
.c-content-main .tab-switch ul li {
    padding: 0 0.6666666667vw;
  }
}
.bge-contents .tab-switch ul li::before,
.c-content-main .tab-switch ul li::before {
  content: none;
}
.bge-contents .tab-switch ul li a,
.c-content-main .tab-switch ul li a {
  display: block;
  color: #111314;
  text-align: center;
  text-decoration: none;
  padding: 8px;
  border: 1px solid #111314;
  border-width: 1px 1px 0;
  border-radius: 5px 5px 0 0;
  position: relative;
  z-index: 10;
  white-space: nowrap;
  cursor: pointer;
}
@media (--sm-lte) {
  .bge-contents .tab-switch ul li a,
.c-content-main .tab-switch ul li a {
    padding: 1.1vw;
    font-size: 4vw;
  }
}
.bge-contents .tab-switch ul li _:-ms-lang(x)::-ms-backdrop,
.bge-contents .tab-switch ul li a,
.c-content-main .tab-switch ul li _:-ms-lang(x)::-ms-backdrop,
.c-content-main .tab-switch ul li a {
  white-space: normal;
}
@media (--sm-gt) {
  .bge-contents .tab-switch ul li:not(.tab-switch-current) a,
.c-content-main .tab-switch ul li:not(.tab-switch-current) a {
    transition: all 300ms;
  }
  .bge-contents .tab-switch ul li:not(.tab-switch-current):hover a,
.c-content-main .tab-switch ul li:not(.tab-switch-current):hover a {
    color: #fff;
    background: #111314;
  }
}
.bge-contents .tab-switch ul li.tab-switch-current a,
.c-content-main .tab-switch ul li.tab-switch-current a {
  border-bottom: 3px solid #fff;
  font-size: 1.7rem;
  font-weight: bold;
}
@media (--sm-lte) {
  .bge-contents .tab-switch ul li.tab-switch-current a,
.c-content-main .tab-switch ul li.tab-switch-current a {
    font-size: 4.5333333333vw;
    padding: 2.6666666667vw;
  }
}
.bge-contents .tab-switch-area,
.c-content-main .tab-switch-area {
  display: none;
  opacity: 0;
}
.bge-contents .tab-switch-area.active,
.c-content-main .tab-switch-area.active {
  display: block;
  animation-name: displayAnime;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
}
@keyframes displayAnime {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.bge-contents .btn-side-list ul,
.c-content-main .btn-side-list ul {
  margin: 0 5px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
}
@media (--sm-lte) {
  .bge-contents .btn-side-list ul,
.c-content-main .btn-side-list ul {
    margin: 0;
    flex-wrap: nowrap;
  }
  .bge-contents .btn-side-list ul.sp-wrap,
.c-content-main .btn-side-list ul.sp-wrap {
    flex-wrap: wrap;
  }
}
.bge-contents .btn-side-list ul li,
.c-content-main .btn-side-list ul li {
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 1px 1px 0;
}
.bge-contents .btn-side-list ul li::before,
.c-content-main .btn-side-list ul li::before {
  content: none;
}
.bge-contents .btn-side-list ul li a,
.c-content-main .btn-side-list ul li a {
  appearance: none;
  background: #0d212e;
  border: 2px solid #0d212e;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.5;
  padding: 10px 28px 10px 13px;
  position: relative;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
}
@media (--sm-gt) {
  .bge-contents .btn-side-list ul li a,
.c-content-main .btn-side-list ul li a {
    transition: opacity 300ms;
  }
  .bge-contents .btn-side-list ul li a:hover,
.c-content-main .btn-side-list ul li a:hover {
    opacity: 0.6;
  }
}
@media (--sm-lte) {
  .bge-contents .btn-side-list ul li a,
.c-content-main .btn-side-list ul li a {
    padding: 0.9333333333vw 3.3333333333vw 0.9333333333vw 0.8vw;
    font-size: 3.2vw;
  }
}
.bge-contents .btn-side-list ul li a::after,
.c-content-main .btn-side-list ul li a::after {
  border: 1px solid currentColor;
  border-width: 1px 1px 0 0;
  content: "";
  display: block;
  height: 8px;
  margin-top: -5px;
  position: absolute;
  right: 0.7em;
  top: 50%;
  transform: rotate(45deg);
  width: 8px;
}
@media (--sm-lte) {
  .bge-contents .btn-side-list ul li a::after,
.c-content-main .btn-side-list ul li a::after {
    right: 1.3333333333vw;
    width: 1.6vw;
    height: 1.6vw;
    margin-top: -2px;
  }
}
.bge-contents .btn-side-list ul li a:visited,
.c-content-main .btn-side-list ul li a:visited {
  color: #fff !important;
}
.bge-contents .btn-side-list ul li.btn-current a,
.c-content-main .btn-side-list ul li.btn-current a {
  background: #fff;
  color: #0d212e;
}
.bge-contents .btn-side-list ul li.btn-current a:visited,
.c-content-main .btn-side-list ul li.btn-current a:visited {
  color: #0d212e !important;
}
@media (--sm-gt) {
  .bge-contents .index-parts[data-bgb=image-link2] .bgt-grid--first,
.c-content-main .index-parts[data-bgb=image-link2] .bgt-grid--first {
    padding-right: 20px;
  }
  .bge-contents .index-parts[data-bgb=image-link2] .bgt-grid--last,
.c-content-main .index-parts[data-bgb=image-link2] .bgt-grid--last {
    padding-left: 20px;
  }
}
.bge-contents .index-parts a,
.c-content-main .index-parts a {
  transition: opacity 300ms;
}
.bge-contents .index-parts a:hover,
.c-content-main .index-parts a:hover {
  opacity: 0.6;
}
.bge-contents .index-parts .bgt-box__caption,
.c-content-main .index-parts .bgt-box__caption {
  background: #f3f3f3;
  border-bottom: 2px solid #0d212e;
  font-size: 1.7rem;
  text-align: center;
  padding: 10px 25px;
  color: #0d212e;
  font-weight: bold;
  position: relative;
}
@media (--sm-lte) {
  .bge-contents .index-parts .bgt-box__caption,
.c-content-main .index-parts .bgt-box__caption {
    font-size: 4vw;
    padding: 2.6666666667vw 5.3333333333vw;
  }
}
.bge-contents .index-parts .bgt-box__caption::before, .bge-contents .index-parts .bgt-box__caption::after,
.c-content-main .index-parts .bgt-box__caption::before,
.c-content-main .index-parts .bgt-box__caption::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.bge-contents .index-parts .bgt-box__caption::before,
.c-content-main .index-parts .bgt-box__caption::before {
  width: 16px;
  height: 16px;
  background: #0d212e;
  right: 0;
}
@media (--sm-lte) {
  .bge-contents .index-parts .bgt-box__caption::before,
.c-content-main .index-parts .bgt-box__caption::before {
    width: 4.2666666667vw;
    height: 4.2666666667vw;
  }
}
.bge-contents .index-parts .bgt-box__caption::after,
.c-content-main .index-parts .bgt-box__caption::after {
  width: 7px;
  height: 7px;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  transform: rotate(45deg);
  right: 6px;
}
@media (--sm-lte) {
  .bge-contents .index-parts .bgt-box__caption::after,
.c-content-main .index-parts .bgt-box__caption::after {
    width: 1.8666666667vw;
    height: 1.8666666667vw;
    right: 1.6vw;
  }
}
@media (--sm-lte) {
  .bge-contents .tags [data-bgt=image] .bgt-image:first-child,
.c-content-main .tags [data-bgt=image] .bgt-image:first-child {
    margin-bottom: 0;
  }
}
.bge-contents .tags .tags-ttl,
.c-content-main .tags .tags-ttl {
  font-size: 1.4rem;
  font-weight: bold;
  margin: -0.3571428571em 0 0.4285714286em;
}
@media (--sm-lte) {
  .bge-contents .tags .tags-ttl,
.c-content-main .tags .tags-ttl {
    font-size: 3.7333333333vw;
  }
}
.bge-contents .tags ul,
.c-content-main .tags ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.bge-contents .tags ul li,
.c-content-main .tags ul li {
  padding: 0.3846153846em 0.7692307692em;
  border: 1px solid #0d212e;
  margin-bottom: 5px;
  font-size: 1.3rem;
  margin-top: 0;
}
@media (--sm-lte) {
  .bge-contents .tags ul li,
.c-content-main .tags ul li {
    font-size: 3.4666666667vw;
    margin-bottom: 1.3333333333vw;
  }
}
.bge-contents .tags ul li:not(:last-child),
.c-content-main .tags ul li:not(:last-child) {
  margin-right: 5px;
}
@media (--sm-lte) {
  .bge-contents .tags ul li:not(:last-child),
.c-content-main .tags ul li:not(:last-child) {
    margin-right: 1.3333333333vw;
  }
}
.bge-contents .tags ul li:before,
.c-content-main .tags ul li:before {
  content: none;
}
.bge-contents .related-post,
.c-content-main .related-post {
  margin: 80px 0 0;
}
@media (--sm-lte) {
  .bge-contents .related-post,
.c-content-main .related-post {
    margin: 10.6666666667vw 0 0;
  }
}
.bge-contents .related-post h2,
.c-content-main .related-post h2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  line-height: 1;
  font-size: 2.6rem;
  font-weight: bold;
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
  letter-spacing: 0.075em;
}
@media (--sm-lte) {
  .bge-contents .related-post h2,
.c-content-main .related-post h2 {
    font-size: 4.8vw;
    letter-spacing: normal;
  }
}
.bge-contents .related-post h2::before, .bge-contents .related-post h2::after,
.c-content-main .related-post h2::before,
.c-content-main .related-post h2::after {
  content: "";
  display: block;
  flex: 0 0 auto;
  width: 20px;
  height: 3px;
  background: #05141e;
  margin-right: 7px;
  position: relative;
}
@media (--sm-lte) {
  .bge-contents .related-post h2::before, .bge-contents .related-post h2::after,
.c-content-main .related-post h2::before,
.c-content-main .related-post h2::after {
    width: 2.6666666667vw;
    height: 2px;
    margin-right: 0.6666666667vw;
  }
}
.bge-contents .related-post h2::after,
.c-content-main .related-post h2::after {
  content: "";
  flex: 1 1 auto;
  margin-left: 7px;
  margin-right: 0;
}
@media (--sm-lte) {
  .bge-contents .related-post h2::after,
.c-content-main .related-post h2::after {
    margin-right: 0;
    margin-left: 2.6666666667vw;
  }
}
.bge-contents .related-post-body,
.c-content-main .related-post-body {
  padding: 40px 30px 10px;
  background: #f3f3f3;
  margin-top: -22px;
}
@media (--sm-lte) {
  .bge-contents .related-post-body,
.c-content-main .related-post-body {
    padding: 6.6666666667vw 4vw 2.6666666667vw;
    margin-top: -4.8vw;
  }
}
.bge-contents .related-post-body ul,
.c-content-main .related-post-body ul {
  margin: 0;
}
.bge-contents .related-post-body ul::after,
.c-content-main .related-post-body ul::after {
  content: "";
  display: block;
  clear: both;
}
.bge-contents .related-post-body ul li,
.c-content-main .related-post-body ul li {
  padding: 0;
  margin: 0;
}
@media (--sm-gt) {
  .bge-contents .related-post-body ul li,
.c-content-main .related-post-body ul li {
    margin-bottom: 15px;
    padding: 0 10px;
    width: 33.3333333333%;
    float: left;
  }
  .bge-contents .related-post-body ul li:nth-child(3n+1),
.c-content-main .related-post-body ul li:nth-child(3n+1) {
    padding: 0 20px 0 0;
  }
  .bge-contents .related-post-body ul li:nth-child(3n),
.c-content-main .related-post-body ul li:nth-child(3n) {
    padding: 0 0 0 20px;
  }
  .bge-contents .related-post-body ul li a,
.c-content-main .related-post-body ul li a {
    display: block;
    transition: opacity 300ms;
  }
  .bge-contents .related-post-body ul li a:hover,
.c-content-main .related-post-body ul li a:hover {
    opacity: 0.6;
  }
}
@media (--sm-lte) {
  .bge-contents .related-post-body ul li,
.c-content-main .related-post-body ul li {
    margin-bottom: 4.8vw;
  }
  .bge-contents .related-post-body ul li a,
.c-content-main .related-post-body ul li a {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .bge-contents .related-post-body ul li a figure,
.c-content-main .related-post-body ul li a figure {
    flex: 0 0 auto;
    width: 26.6666666667vw;
  }
  .bge-contents .related-post-body ul li a .related-body-text,
.c-content-main .related-post-body ul li a .related-body-text {
    margin-top: -2vw;
    margin-left: 2.6666666667vw;
  }
  .bge-contents .related-post-body ul li .bge-ckeditor:last-child,
.c-content-main .related-post-body ul li .bge-ckeditor:last-child {
    margin-bottom: 4vw;
  }
}
.bge-contents .related-post-body ul li::before,
.c-content-main .related-post-body ul li::before {
  display: none;
}
.bge-contents .related-post-body ul li a,
.c-content-main .related-post-body ul li a {
  text-decoration: none;
  color: inherit;
}
.bge-contents .related-post-body ul li a figure img,
.c-content-main .related-post-body ul li a figure img {
  width: 100%;
}
.bge-contents .related-post-body h6,
.c-content-main .related-post-body h6 {
  padding: 0;
  margin: 0;
  font-size: 1.6rem;
}
@media (--sm-lte) {
  .bge-contents .related-post-body h6,
.c-content-main .related-post-body h6 {
    font-size: 3.7333333333vw;
  }
}
.bge-contents .related-post-body h6::before,
.c-content-main .related-post-body h6::before {
  content: none;
}
.bge-contents .related-post-body p,
.c-content-main .related-post-body p {
  margin: 0;
  font-size: 1.4rem;
  line-height: 1.6;
}
@media (--sm-lte) {
  .bge-contents .related-post-body p,
.c-content-main .related-post-body p {
    font-size: 3.4666666667vw;
  }
}
@media (--sm-gt) {
  .bge-contents .flex-column-list ul,
.c-content-main .flex-column-list ul {
    column-count: 3;
    margin-left: 20px;
    margin-right: 20px;
  }
  .bge-contents .flex-column-list ul li,
.c-content-main .flex-column-list ul li {
    margin: 0 0 15px;
  }
}
@media (--sm-lte) {
  .bge-contents .flex-column-list ul,
.c-content-main .flex-column-list ul {
    column-count: 2;
    margin-left: 2.6666666667vw;
    margin-right: 2.6666666667vw;
  }
}
.bge-contents .flex-column-list ul li,
.c-content-main .flex-column-list ul li {
  font-weight: 500;
  page-break-inside: avoid;
  break-inside: avoid;
}
@media (--sm-lte) {
  .bge-contents .flex-column-list ul li,
.c-content-main .flex-column-list ul li {
    padding-bottom: 8px;
    margin: 0;
  }
}
@media (--sm-gt) {
  .bge-contents .data-parts[data-bgb=text-image2],
.c-content-main .data-parts[data-bgb=text-image2] {
    display: flex;
    align-items: center;
  }
  .bge-contents .data-parts[data-bgb=text-image2] .bgt-grid--first,
.c-content-main .data-parts[data-bgb=text-image2] .bgt-grid--first {
    padding-right: 20px;
  }
  .bge-contents .data-parts[data-bgb=text-image2] .bgt-grid--last,
.c-content-main .data-parts[data-bgb=text-image2] .bgt-grid--last {
    padding-left: 20px;
  }
}
@media (--sm-lte) {
  .bge-contents .data-parts[data-bgb=text-image2] .bgt-grid--first,
.c-content-main .data-parts[data-bgb=text-image2] .bgt-grid--first {
    margin-bottom: 5.3333333333vw;
  }
}
.bge-contents .data-parts .bgt-grid--last .bge-ckeditor,
.c-content-main .data-parts .bgt-grid--last .bge-ckeditor {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media (--sm-gt) {
  .bge-contents .data-parts .bgt-grid--last .bge-ckeditor,
.c-content-main .data-parts .bgt-grid--last .bge-ckeditor {
    margin-right: 45px;
  }
}
.bge-contents .data-parts .bgt-grid--last .bge-ckeditor dl,
.c-content-main .data-parts .bgt-grid--last .bge-ckeditor dl {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 15px 0;
  padding: 0 12px 10px 12px;
  border-bottom: 1px solid #c8cfd4;
  position: relative;
}
@media (--sm-gt) {
  .bge-contents .data-parts .bgt-grid--last .bge-ckeditor dl,
.c-content-main .data-parts .bgt-grid--last .bge-ckeditor dl {
    width: calc(50% - 20px);
  }
}
@media (--sm-lte) {
  .bge-contents .data-parts .bgt-grid--last .bge-ckeditor dl,
.c-content-main .data-parts .bgt-grid--last .bge-ckeditor dl {
    width: calc(50% - 5.3333333333vw);
    margin: 1.8666666667vw 2.6666666667vw;
    padding: 0 0 1.3333333333vw 0;
  }
}
.bge-contents .data-parts .bgt-grid--last .bge-ckeditor dl::before,
.c-content-main .data-parts .bgt-grid--last .bge-ckeditor dl::before {
  content: "";
  display: block;
  width: 136px;
  height: 1px;
  position: absolute;
  bottom: -1px;
  left: 0;
  background: #1ca9e4;
}
@media (--sm-lte) {
  .bge-contents .data-parts .bgt-grid--last .bge-ckeditor dl::before,
.c-content-main .data-parts .bgt-grid--last .bge-ckeditor dl::before {
    width: 26.6666666667vw;
  }
}
.bge-contents .data-parts .bgt-grid--last .bge-ckeditor dl dt,
.c-content-main .data-parts .bgt-grid--last .bge-ckeditor dl dt {
  flex: 1 1 auto;
  margin-right: 14px;
  font-size: 1.6rem;
  font-weight: bold;
}
@media (--sm-lte) {
  .bge-contents .data-parts .bgt-grid--last .bge-ckeditor dl dt,
.c-content-main .data-parts .bgt-grid--last .bge-ckeditor dl dt {
    margin-right: 1vw;
    font-size: 3.7333333333vw;
  }
}
.bge-contents .data-parts .bgt-grid--last .bge-ckeditor dl dd,
.c-content-main .data-parts .bgt-grid--last .bge-ckeditor dl dd {
  flex: 0 0 auto;
  margin: 0;
  font-weight: bold;
}
@media (--sm-lte) {
  .bge-contents .data-parts .bgt-grid--last .bge-ckeditor dl dd,
.c-content-main .data-parts .bgt-grid--last .bge-ckeditor dl dd {
    font-size: 3.2vw;
  }
}
.bge-contents .data-parts .bgt-grid--last .bge-ckeditor dl dd strong,
.c-content-main .data-parts .bgt-grid--last .bge-ckeditor dl dd strong {
  font-size: 2.4rem;
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
  line-height: 1.3;
}
@media (--sm-lte) {
  .bge-contents .data-parts .bgt-grid--last .bge-ckeditor dl dd strong,
.c-content-main .data-parts .bgt-grid--last .bge-ckeditor dl dd strong {
    font-size: 5.3333333333vw;
  }
}
@media (--sm-gt) {
  .bge-contents .index-list-parts[data-bgb=wysiwyg2] .bgt-grid--first,
.c-content-main .index-list-parts[data-bgb=wysiwyg2] .bgt-grid--first {
    padding-left: 0;
    padding-right: 20px;
  }
  .bge-contents .index-list-parts[data-bgb=wysiwyg2] .bgt-grid--last,
.c-content-main .index-list-parts[data-bgb=wysiwyg2] .bgt-grid--last {
    padding-right: 0;
    padding-left: 20px;
  }
  .bge-contents .index-list-parts[data-bgb=wysiwyg3] .bgt-grid,
.c-content-main .index-list-parts[data-bgb=wysiwyg3] .bgt-grid {
    padding-left: 15px;
    padding-right: 15px;
  }
  .bge-contents .index-list-parts[data-bgb=wysiwyg3] .bgt-grid--first,
.c-content-main .index-list-parts[data-bgb=wysiwyg3] .bgt-grid--first {
    padding-left: 0;
    padding-right: 30px;
  }
  .bge-contents .index-list-parts[data-bgb=wysiwyg3] .bgt-grid--last,
.c-content-main .index-list-parts[data-bgb=wysiwyg3] .bgt-grid--last {
    padding-right: 0;
    padding-left: 30px;
  }
  [data-path="/nyushi/"] .bge-contents .index-list-parts[data-bgb=wysiwyg3] .bgt-grid:nth-last-child(3),
[data-path="/nyushi/"] .c-content-main .index-list-parts[data-bgb=wysiwyg3] .bgt-grid:nth-last-child(3) {
    width: 100%;
  }
  [data-path="/nyushi/"] .bge-contents .index-list-parts[data-bgb=wysiwyg3] .bgt-grid:nth-last-child(3) ul,
[data-path="/nyushi/"] .c-content-main .index-list-parts[data-bgb=wysiwyg3] .bgt-grid:nth-last-child(3) ul {
    columns: 3;
    margin-right: -15px;
  }
  [data-path="/nyushi/"] .bge-contents .index-list-parts[data-bgb=wysiwyg3] .bgt-grid:nth-last-child(3) ul li,
[data-path="/nyushi/"] .c-content-main .index-list-parts[data-bgb=wysiwyg3] .bgt-grid:nth-last-child(3) ul li {
    padding-right: 15px;
  }
}
[data-path="/nyushi/"] .bge-contents .index-list-parts:nth-child(3)[data-bgb=wysiwyg3] .bgt-grid:first-child,
[data-path="/nyushi/"] .c-content-main .index-list-parts:nth-child(3)[data-bgb=wysiwyg3] .bgt-grid:first-child {
  float: right;
  margin-top: -656px;
}
@media (--sm-lte) {
  [data-path="/nyushi/"] .bge-contents .index-list-parts:nth-child(3)[data-bgb=wysiwyg3] .bgt-grid:first-child,
[data-path="/nyushi/"] .c-content-main .index-list-parts:nth-child(3)[data-bgb=wysiwyg3] .bgt-grid:first-child {
    float: none;
    margin-top: 0;
  }
}
.bge-contents .index-list-parts ul,
.c-content-main .index-list-parts ul {
  margin: 0 10px 32px;
  padding-left: 0;
}
@media (--sm-lte) {
  .bge-contents .index-list-parts ul,
.c-content-main .index-list-parts ul {
    margin-bottom: 30px;
  }
}
.bge-contents .index-list-parts ul li,
.c-content-main .index-list-parts ul li {
  position: relative;
  padding-left: 0;
  margin: 0 0 11px;
  font-size: 1.6rem;
}
@media (--sm-lte) {
  .bge-contents .index-list-parts ul li,
.c-content-main .index-list-parts ul li {
    margin: 2.9333333333vw 0;
    font-size: 4vw;
  }
}
.bge-contents .index-list-parts ul li:first-child,
.c-content-main .index-list-parts ul li:first-child {
  margin-top: 0;
}
.bge-contents .index-list-parts ul li::before,
.c-content-main .index-list-parts ul li::before {
  content: none;
}
.bge-contents .index-list-parts ul li.menu-text ul,
.c-content-main .index-list-parts ul li.menu-text ul {
  margin: 0 0 0 1.4em;
}
@media (--sm-lte) {
  .bge-contents .index-list-parts ul li.menu-text ul,
.c-content-main .index-list-parts ul li.menu-text ul {
    margin: 0 0 0 1.2em;
  }
}
.bge-contents .index-list-parts ul li a,
.c-content-main .index-list-parts ul li a {
  color: #101010;
  text-decoration: none;
  display: inline-block;
  padding-left: 23px;
}
@media (--sm-gt) {
  .bge-contents .index-list-parts ul li a,
.c-content-main .index-list-parts ul li a {
    transition: color 300ms;
  }
  .bge-contents .index-list-parts ul li a::before,
.c-content-main .index-list-parts ul li a::before {
    transition: background-color 300ms;
  }
  .bge-contents .index-list-parts ul li a:hover,
.c-content-main .index-list-parts ul li a:hover {
    color: #00a2e6;
    text-decoration: underline;
  }
  .bge-contents .index-list-parts ul li a:hover::before,
.c-content-main .index-list-parts ul li a:hover::before {
    background-color: #00a2e6;
  }
}
@media (--sm-lte) {
  .bge-contents .index-list-parts ul li a,
.c-content-main .index-list-parts ul li a {
    padding-left: 6.1333333333vw;
  }
}
.bge-contents .index-list-parts ul li a::before,
.c-content-main .index-list-parts ul li a::before {
  content: "";
  display: block;
  position: absolute;
  margin: auto 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  left: 0;
  top: 6px;
  background: url("/img/icon-arrow-w.png") no-repeat center center/6px auto;
  background-color: #0d212e;
}
@media (--sm-lte) {
  .bge-contents .index-list-parts ul li a::before,
.c-content-main .index-list-parts ul li a::before {
    top: 1.3333333333vw;
    width: 3.7333333333vw;
    height: 3.7333333333vw;
    left: 0;
    background-size: 1.6vw auto;
  }
}
.bge-contents .index-list-parts h2,
.c-content-main .index-list-parts h2 {
  font-size: 2.2rem;
  font-weight: normal;
  line-height: 1.5;
  background: #f3f3f3;
  margin: 1em 0 25px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  border-left: 2px solid #00a2e6;
}
@media (--sm-lte) {
  .bge-contents .index-list-parts h2,
.c-content-main .index-list-parts h2 {
    font-size: 5.8666666667vw;
  }
}
.bge-contents .index-list-parts h2:first-child,
.c-content-main .index-list-parts h2:first-child {
  margin-top: 0;
}
.bge-contents .index-list-parts h2::before, .bge-contents .index-list-parts h2::after,
.c-content-main .index-list-parts h2::before,
.c-content-main .index-list-parts h2::after {
  display: none;
}
[lang=en] .bge-contents .index-list-parts h2,
[lang=en] .c-content-main .index-list-parts h2 {
  font-size: 2.4rem;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 0.05em;
}
@media (--sm-lte) {
  [lang=en] .bge-contents .index-list-parts h2,
[lang=en] .c-content-main .index-list-parts h2 {
    font-size: 5.8666666667vw;
  }
}
.bge-contents .banner-box,
.c-content-main .banner-box {
  text-align: center;
  display: flex;
  justify-content: center;
}
.bge-contents .banner-box p,
.c-content-main .banner-box p {
  margin: 20px;
}
.bge-contents .banner-box p a,
.c-content-main .banner-box p a {
  display: block;
  transition: opacity 300ms;
}
@media (--sm-gt) {
  .bge-contents .banner-box p a:hover,
.c-content-main .banner-box p a:hover {
    opacity: 0.6;
  }
}
.bge-contents .acc-title h2, .bge-contents .acc-title.acc-title-btn .bgt-btn,
.c-content-main .acc-title h2,
.c-content-main .acc-title.acc-title-btn .bgt-btn {
  font-size: 1.8rem;
  font-weight: bold;
  padding-right: 40px;
  border-bottom: 1px solid #92a0aa;
  transition: opacity 300ms;
}
@media (--sm-gt) {
  .bge-contents .acc-title h2:hover, .bge-contents .acc-title.acc-title-btn .bgt-btn:hover,
.c-content-main .acc-title h2:hover,
.c-content-main .acc-title.acc-title-btn .bgt-btn:hover {
    cursor: pointer;
    opacity: 0.6;
  }
}
@media (--sm-lte) {
  .bge-contents .acc-title h2, .bge-contents .acc-title.acc-title-btn .bgt-btn,
.c-content-main .acc-title h2,
.c-content-main .acc-title.acc-title-btn .bgt-btn {
    padding-right: 13.3333333333vw;
  }
}
.bge-contents .acc-title h2::before, .bge-contents .acc-title h2::after, .bge-contents .acc-title.acc-title-btn .bgt-btn::before, .bge-contents .acc-title.acc-title-btn .bgt-btn::after,
.c-content-main .acc-title h2::before,
.c-content-main .acc-title h2::after,
.c-content-main .acc-title.acc-title-btn .bgt-btn::before,
.c-content-main .acc-title.acc-title-btn .bgt-btn::after {
  content: "";
  height: 1px;
  width: 21px;
  position: absolute;
  left: inherit;
  right: 19px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 10;
  border: none;
  transform: none;
  transition: transform 300ms;
  background: #00a2e6;
}
@media (--sm-lte) {
  .bge-contents .acc-title h2::before, .bge-contents .acc-title h2::after, .bge-contents .acc-title.acc-title-btn .bgt-btn::before, .bge-contents .acc-title.acc-title-btn .bgt-btn::after,
.c-content-main .acc-title h2::before,
.c-content-main .acc-title h2::after,
.c-content-main .acc-title.acc-title-btn .bgt-btn::before,
.c-content-main .acc-title.acc-title-btn .bgt-btn::after {
    bottom: 13px;
  }
}
.bge-contents .acc-title h2::after, .bge-contents .acc-title.acc-title-btn .bgt-btn::after,
.c-content-main .acc-title h2::after,
.c-content-main .acc-title.acc-title-btn .bgt-btn::after {
  transform: rotate(90deg);
}
.bge-contents .acc-title.open h2::after,
.bge-contents .acc-title.open .bgt-btn::after,
.c-content-main .acc-title.open h2::after,
.c-content-main .acc-title.open .bgt-btn::after {
  transform: rotate(0deg);
}
.bge-contents .acc-title.open.bgb-opt--mb-large,
.c-content-main .acc-title.open.bgb-opt--mb-large {
  margin-bottom: 0 !important;
}
.bge-contents .acc-title.acc-title-btn,
.c-content-main .acc-title.acc-title-btn {
  display: table;
  margin-left: auto !important;
  margin-right: auto !important;
}
.bge-contents .acc-title.acc-title-btn .bgt-btn::before, .bge-contents .acc-title.acc-title-btn .bgt-btn::after,
.c-content-main .acc-title.acc-title-btn .bgt-btn::before,
.c-content-main .acc-title.acc-title-btn .bgt-btn::after {
  background: #fff;
}
@media (--sm-lte) {
  .bge-contents .acc-title.acc-title-btn .bgt-btn::before, .bge-contents .acc-title.acc-title-btn .bgt-btn::after,
.c-content-main .acc-title.acc-title-btn .bgt-btn::before,
.c-content-main .acc-title.acc-title-btn .bgt-btn::after {
    bottom: 0;
  }
}
@media (--sm-gt) {
  .bge-contents .acc-body[data-bgb=text-image2] .bgt-grid--first,
.c-content-main .acc-body[data-bgb=text-image2] .bgt-grid--first {
    padding-right: 20px;
  }
  .bge-contents .acc-body[data-bgb=text-image2] .bgt-grid--last,
.c-content-main .acc-body[data-bgb=text-image2] .bgt-grid--last {
    padding-left: 20px;
  }
}
@media (--sm-lte) {
  .bge-contents .acc-body[data-bgb=text-image2] .bgt-grid--first,
.c-content-main .acc-body[data-bgb=text-image2] .bgt-grid--first {
    margin-bottom: 5.3333333333vw;
  }
}
.bge-contents .acc-body h6,
.c-content-main .acc-body h6 {
  display: inline-block;
  margin-bottom: 0;
}
.bge-contents .acc-body h6 + p,
.c-content-main .acc-body h6 + p {
  margin-top: 0;
}
@media (--sm-gt) {
  .bge-contents .acc-body h6 + p,
.c-content-main .acc-body h6 + p {
    margin-left: 20px;
  }
}
@media (--sm-lte) {
  .bge-contents .acc-body.pc_only figure,
.c-content-main .acc-body.pc_only figure {
    display: none;
  }
}
@media (--sm-gt) {
  .bge-contents .acc-body.sp_only figure,
.c-content-main .acc-body.sp_only figure {
    display: none;
  }
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title {
  background: #0d212e;
  position: relative;
  cursor: pointer;
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title::before, [data-path="/gakusei/nittei/"] .bge-contents .acc-title::after,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title::before,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title::after {
  content: "";
  height: 2px;
  width: 16px;
  position: absolute;
  left: inherit;
  right: 20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  z-index: 10;
  border: none;
  transform: none;
  transition: transform 300ms;
  background: #00a2e6;
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title::after,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title::after {
  transform: rotate(90deg);
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title.open::after,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title.open::after {
  transform: rotate(0deg);
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title h2,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title h2 {
  font-size: 2rem;
  color: #fff;
  padding: 8px 0 9px 32px;
  margin: 0;
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title h2::before,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title h2::before {
  display: none;
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title h2::after,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title h2::after {
  font-size: 1.1rem;
  font-weight: 500;
  transform: none;
  background: none;
  position: static;
  height: auto;
  width: auto;
  margin: 0 0 0 16px;
  vertical-align: 1px;
  display: inline-block;
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title[data-bgb],
[data-path="/gakusei/nittei/"] .c-content-main .acc-title[data-bgb] {
  margin: 0 0 2px !important;
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title small,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title small {
  font-size: 1.1rem;
  padding-left: 18px;
}
@media (--sm-lte) {
  [data-path="/gakusei/nittei/"] .bge-contents .acc-title small,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title small {
    padding-left: 1em;
  }
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title[id=bge-group10] h2, [data-path="/gakusei/nittei/"] .bge-contents .acc-title[id=bge-group11] h2, [data-path="/gakusei/nittei/"] .bge-contents .acc-title[id=bge-group12] h2,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title[id=bge-group10] h2,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title[id=bge-group11] h2,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title[id=bge-group12] h2 {
  padding-left: 19px;
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title[id=bge-group01] h2::after,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title[id=bge-group01] h2::after {
  content: "January";
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title[id=bge-group02] h2::after,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title[id=bge-group02] h2::after {
  content: "February";
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title[id=bge-group03] h2::after,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title[id=bge-group03] h2::after {
  content: "March";
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title[id=bge-group04] h2::after,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title[id=bge-group04] h2::after {
  content: "April";
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title[id=bge-group05] h2::after,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title[id=bge-group05] h2::after {
  content: "May";
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title[id=bge-group06] h2::after,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title[id=bge-group06] h2::after {
  content: "June";
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title[id=bge-group07] h2::after,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title[id=bge-group07] h2::after {
  content: "July";
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title[id=bge-group08] h2::after,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title[id=bge-group08] h2::after {
  content: "August";
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title[id=bge-group09] h2::after,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title[id=bge-group09] h2::after {
  content: "September";
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title[id=bge-group10] h2::after,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title[id=bge-group10] h2::after {
  content: "October";
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title[id=bge-group11] h2::after,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title[id=bge-group11] h2::after {
  content: "November";
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-title[id=bge-group12] h2::after,
[data-path="/gakusei/nittei/"] .c-content-main .acc-title[id=bge-group12] h2::after {
  content: "December";
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-body,
[data-path="/gakusei/nittei/"] .c-content-main .acc-body {
  padding: 28px 20px 32px;
}
@media (--sm-lte) {
  [data-path="/gakusei/nittei/"] .bge-contents .acc-body,
[data-path="/gakusei/nittei/"] .c-content-main .acc-body {
    padding: 2em 0 1em;
  }
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-body .date,
[data-path="/gakusei/nittei/"] .c-content-main .acc-body .date {
  width: 58px;
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
}
@media (--sm-lte) {
  [data-path="/gakusei/nittei/"] .bge-contents .acc-body .date,
[data-path="/gakusei/nittei/"] .c-content-main .acc-body .date {
    width: 16vw;
  }
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-body .week,
[data-path="/gakusei/nittei/"] .c-content-main .acc-body .week {
  width: 58px;
}
@media (--sm-lte) {
  [data-path="/gakusei/nittei/"] .bge-contents .acc-body .week,
[data-path="/gakusei/nittei/"] .c-content-main .acc-body .week {
    width: 16vw;
  }
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-body .sat,
[data-path="/gakusei/nittei/"] .c-content-main .acc-body .sat {
  background: #dfedf1;
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-body .sat .week,
[data-path="/gakusei/nittei/"] .c-content-main .acc-body .sat .week {
  color: #00a2e6;
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-body .sun,
[data-path="/gakusei/nittei/"] .c-content-main .acc-body .sun {
  background: #f6e9e9;
}
[data-path="/gakusei/nittei/"] .bge-contents .acc-body .sun .week,
[data-path="/gakusei/nittei/"] .c-content-main .acc-body .sun .week {
  color: #e60057;
}
.bge-contents .sns-link,
.c-content-main .sns-link {
  padding: 20px 30px;
  background: #f3f3f3;
}
@media (--sm-lte) {
  .bge-contents .sns-link,
.c-content-main .sns-link {
    padding: 4vw 5.3333333333vw;
  }
}
.bge-contents .sns-link ul,
.c-content-main .sns-link ul {
  margin: 0;
}
.bge-contents .sns-link ul li,
.c-content-main .sns-link ul li {
  padding-left: 0;
}
@media (--sm-gt) {
  .bge-contents .sns-link ul li,
.c-content-main .sns-link ul li {
    display: inline-block;
    margin-right: 25px;
    font-size: 1.6rem;
  }
}
.bge-contents .sns-link ul li::before,
.c-content-main .sns-link ul li::before {
  content: none;
}
.bge-contents .sns-link ul li a,
.c-content-main .sns-link ul li a {
  color: #101010;
}
.bge-contents ol.first-none-ol li:first-child::before,
.c-content-main ol.first-none-ol li:first-child::before {
  display: none;
}
.bge-contents ol.first-none-ol ol li:first-child::before,
.c-content-main ol.first-none-ol ol li:first-child::before {
  display: block;
}
.bge-contents ol.circle-ol,
.c-content-main ol.circle-ol {
  margin-left: 20px;
}
.bge-contents ol.circle-ol li,
.c-content-main ol.circle-ol li {
  padding: 0;
  counter-increment: section19;
}
.bge-contents ol.circle-ol li::before,
.c-content-main ol.circle-ol li::before {
  display: inline-block;
  text-align: center;
  content: counter(section19, decimal);
  color: #101010;
  width: 1.35em;
  height: 1.35em;
  padding-top: 0.1em;
  line-height: 1;
  border-radius: 50%;
  margin-right: 0.75em;
  vertical-align: 2px;
  border: 1px solid #101010;
  font-size: 0.75em;
  position: relative;
  left: auto;
  top: auto;
  font-weight: normal;
  padding-left: 0.07em;
  text-indent: 0;
}
@media (--sm-lte) {
  .bge-contents ol.circle-ol li::before,
.c-content-main ol.circle-ol li::before {
    padding-top: 0;
    vertical-align: 1px;
  }
}
.bge-contents ol.circle-ol li:nth-child(n+10)::before,
.c-content-main ol.circle-ol li:nth-child(n+10)::before {
  width: 1.557em;
  height: 1.557em;
  font-size: 0.65em;
  letter-spacing: -0.05em;
  line-height: 1.2;
  padding-left: 0;
}
@media (--sm-lte) {
  .bge-contents ol.circle-ol li:nth-child(n+10)::before,
.c-content-main ol.circle-ol li:nth-child(n+10)::before {
    padding-top: 0.1em;
  }
}
.bge-contents .border-parts,
.c-content-main .border-parts {
  border: 1px solid #101010;
  padding: 10px;
  margin: 10px 0;
}
.bge-contents .katakana-ol li,
.c-content-main .katakana-ol li {
  counter-increment: section20;
}
.bge-contents .katakana-ol li::before,
.c-content-main .katakana-ol li::before {
  content: counter(section20, decimal) ".";
  content: counter(section20, katakana) ".";
}
@media (--sm-gt) {
  .bge-contents .sitemap[data-bgb=wysiwyg2] .bgt-grid--first,
.c-content-main .sitemap[data-bgb=wysiwyg2] .bgt-grid--first {
    padding-right: 20px;
  }
  .bge-contents .sitemap[data-bgb=wysiwyg2] .bgt-grid--last,
.c-content-main .sitemap[data-bgb=wysiwyg2] .bgt-grid--last {
    padding-left: 20px;
  }
}
.bge-contents .sitemap h2,
.c-content-main .sitemap h2 {
  position: relative;
  padding-bottom: 0;
}
.bge-contents .sitemap h2 a,
.c-content-main .sitemap h2 a {
  display: block;
  text-decoration: none;
  color: #00a2e6 !important;
  padding: 0 50px 16px 0;
  transition: color 300ms;
}
.bge-contents .sitemap h2 a::before,
.c-content-main .sitemap h2 a::before {
  content: "";
  display: block;
  position: absolute;
  margin: auto 0;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  left: auto;
  right: 20px;
  top: calc(50% - 15px);
  background: url("/img/icon-arrow-w.png") no-repeat center center/6px auto;
  background-color: #ababab;
}
@media (--sm-lte) {
  .bge-contents .sitemap h2 a::before,
.c-content-main .sitemap h2 a::before {
    top: calc(50% - 4vw);
    width: 4.8vw;
    height: 4.8vw;
    right: 2.6666666667vw;
    background-size: 1.6vw auto;
  }
}
.bge-contents .sitemap h2 a:hover::before,
.c-content-main .sitemap h2 a:hover::before {
  background-color: #00a2e6;
}
.bge-contents .sitemap.titlelink h3,
.c-content-main .sitemap.titlelink h3 {
  position: relative;
  padding: 0;
}
.bge-contents .sitemap.titlelink h3 a,
.c-content-main .sitemap.titlelink h3 a {
  display: block;
  text-decoration: none;
  color: #101010 !important;
  padding: 10px 50px 10px 16px;
  transition: color 300ms;
}
.bge-contents .sitemap.titlelink h3 a::before,
.c-content-main .sitemap.titlelink h3 a::before {
  content: "";
  display: block;
  position: absolute;
  margin: auto 0;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  right: 20px;
  top: calc(50% - 12px);
  background: url("/img/icon-arrow-w.png") no-repeat center center/6px auto;
  background-color: #ababab;
}
@media (--sm-lte) {
  .bge-contents .sitemap.titlelink h3 a::before,
.c-content-main .sitemap.titlelink h3 a::before {
    top: calc(50% - 2.4vw);
    width: 4.8vw;
    height: 4.8vw;
    left: auto;
    right: 2.6666666667vw;
    background-size: 1.6vw auto;
  }
}
.bge-contents .sitemap.titlelink h3 a:hover::before,
.c-content-main .sitemap.titlelink h3 a:hover::before {
  background-color: #00a2e6;
}
.bge-contents .sitemap h3,
.c-content-main .sitemap h3 {
  position: relative;
}
.bge-contents .sitemap h3[data-title=link],
.c-content-main .sitemap h3[data-title=link] {
  padding: 0;
}
.bge-contents .sitemap h3 a,
.c-content-main .sitemap h3 a {
  display: block;
  text-decoration: none;
  color: #101010 !important;
  padding: 10px 50px 10px 16px;
  transition: color 300ms;
}
.bge-contents .sitemap h3 a::before,
.c-content-main .sitemap h3 a::before {
  content: "";
  display: block;
  position: absolute;
  margin: auto 0;
  width: 21px;
  height: 21px;
  border-radius: 50%;
  right: 20px;
  top: calc(50% - 12px);
  background: url("/img/icon-arrow-w.png") no-repeat center center/6px auto;
  background-color: #ababab;
}
@media (--sm-lte) {
  .bge-contents .sitemap h3 a::before,
.c-content-main .sitemap h3 a::before {
    top: calc(50% - 2.4vw);
    width: 4.8vw;
    height: 4.8vw;
    left: auto;
    right: 2.6666666667vw;
    background-size: 1.6vw auto;
  }
}
.bge-contents .sitemap h3 a:hover::before,
.c-content-main .sitemap h3 a:hover::before {
  background-color: #00a2e6;
}
@media (--sm-gt) {
  .bge-contents .sitemap ul,
.c-content-main .sitemap ul {
    margin: 0 15px;
    padding-left: 0;
  }
}
.bge-contents .sitemap ul li,
.c-content-main .sitemap ul li {
  position: relative;
  margin: 10px 0;
  padding: 0;
  font-size: 1.6rem;
  padding-left: 20px;
}
@media (--sm-lte) {
  .bge-contents .sitemap ul li,
.c-content-main .sitemap ul li {
    padding-left: 5.3333333333vw;
    margin: 2.6666666667vw 0;
    font-size: 4vw;
  }
}
.bge-contents .sitemap ul li::before,
.c-content-main .sitemap ul li::before {
  display: none;
}
.bge-contents .sitemap ul li a,
.bge-contents .sitemap ul li span,
.c-content-main .sitemap ul li a,
.c-content-main .sitemap ul li span {
  color: #101010;
  text-decoration: none;
  transition: color 300ms;
}
.bge-contents .sitemap ul li a::before, .bge-contents .sitemap ul li a::after,
.bge-contents .sitemap ul li span::before,
.bge-contents .sitemap ul li span::after,
.c-content-main .sitemap ul li a::before,
.c-content-main .sitemap ul li a::after,
.c-content-main .sitemap ul li span::before,
.c-content-main .sitemap ul li span::after {
  content: "";
  display: block;
  position: absolute;
  top: 6px;
  margin: auto 0;
}
@media (--sm-lte) {
  .bge-contents .sitemap ul li a::before, .bge-contents .sitemap ul li a::after,
.bge-contents .sitemap ul li span::before,
.bge-contents .sitemap ul li span::after,
.c-content-main .sitemap ul li a::before,
.c-content-main .sitemap ul li a::after,
.c-content-main .sitemap ul li span::before,
.c-content-main .sitemap ul li span::after {
    top: 1.6vw;
    left: -5.3333333333vw;
  }
}
.bge-contents .sitemap ul li a::before,
.bge-contents .sitemap ul li span::before,
.c-content-main .sitemap ul li a::before,
.c-content-main .sitemap ul li span::before {
  content: "";
  display: block;
  position: absolute;
  margin: auto 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  left: 0;
  top: 6px;
  background: url("/img/icon-arrow-w.png") no-repeat center center/6px auto;
  background-color: #ababab;
}
@media (--sm-lte) {
  .bge-contents .sitemap ul li a::before,
.bge-contents .sitemap ul li span::before,
.c-content-main .sitemap ul li a::before,
.c-content-main .sitemap ul li span::before {
    top: 1.3333333333vw;
    width: 3.7333333333vw;
    height: 3.7333333333vw;
    left: 0;
    background-size: 1.6vw auto;
  }
}
.bge-contents .sitemap ul li a[target=_blank]::after,
.bge-contents .sitemap ul li span[target=_blank]::after,
.c-content-main .sitemap ul li a[target=_blank]::after,
.c-content-main .sitemap ul li span[target=_blank]::after {
  position: static;
  display: inline-block;
  margin: 0 5px;
}
.bge-contents .sitemap ul li a[href$=pdf],
.bge-contents .sitemap ul li span[href$=pdf],
.c-content-main .sitemap ul li a[href$=pdf],
.c-content-main .sitemap ul li span[href$=pdf] {
  position: static;
}
.bge-contents .sitemap ul li a[href$=pdf]::after,
.bge-contents .sitemap ul li span[href$=pdf]::after,
.c-content-main .sitemap ul li a[href$=pdf]::after,
.c-content-main .sitemap ul li span[href$=pdf]::after {
  display: inline-block;
  margin: 0 8px;
}
@media (--sm-lte) {
  .bge-contents .sitemap ul li a[href$=pdf]::after,
.bge-contents .sitemap ul li span[href$=pdf]::after,
.c-content-main .sitemap ul li a[href$=pdf]::after,
.c-content-main .sitemap ul li span[href$=pdf]::after {
    left: auto;
  }
}
@media (--sm-gt) {
  .bge-contents .sitemap ul li a:hover,
.c-content-main .sitemap ul li a:hover {
    color: #00a2e6;
  }
  .bge-contents .sitemap ul li a:hover::before,
.c-content-main .sitemap ul li a:hover::before {
    background-color: #00a2e6;
  }
}
.bge-contents .sitemap ul li.sitemap-sub-ttl,
.c-content-main .sitemap ul li.sitemap-sub-ttl {
  font-weight: 500;
  margin: 0;
}
.bge-contents .sitemap ul li.sitemap-sub-ttl::before,
.c-content-main .sitemap ul li.sitemap-sub-ttl::before {
  content: "";
  display: block;
  width: 14px;
  height: 2px;
  background: #92a0aa;
  margin: 0;
  position: absolute;
  top: 12px;
  left: 0;
}
@media (--sm-lte) {
  .bge-contents .sitemap ul li.sitemap-sub-ttl::before,
.c-content-main .sitemap ul li.sitemap-sub-ttl::before {
    width: 3.7333333333vw;
    top: 2.6666666667vw;
  }
}
.bge-contents .sitemap ul li.sitemap-sub-ttl::after,
.c-content-main .sitemap ul li.sitemap-sub-ttl::after {
  content: none;
}
.bge-contents .sitemap ul li ul,
.c-content-main .sitemap ul li ul {
  margin: 0;
}
.bge-contents .sitemap ul li ul li,
.c-content-main .sitemap ul li ul li {
  margin: 5px 0;
  padding-left: 18px;
}
@media (--sm-lte) {
  .bge-contents .sitemap ul li ul li,
.c-content-main .sitemap ul li ul li {
    padding-left: 4.8vw;
    margin: 1.3333333333vw 0;
  }
}
.bge-contents .sitemap ul li ul li a,
.c-content-main .sitemap ul li ul li a {
  font-size: 1.3rem;
}
@media (--sm-lte) {
  .bge-contents .sitemap ul li ul li a,
.c-content-main .sitemap ul li ul li a {
    font-size: 3.4666666667vw;
  }
}
.bge-contents .sitemap ul li ul li a[target=_blank],
.c-content-main .sitemap ul li ul li a[target=_blank] {
  padding-right: 15px;
  background: url("<svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='13' height='13' viewBox='0 0 13 13' xml:space='preserve'><polygon points='10.5,13 0,13 0,2.5 1,2.5 1,12 10.5,12' fill='#0d212e'/><path d='M12,1v8H4V1H12 M13,0H3v10h10V0L13,0z' fill='#0d212e'/></svg>") no-repeat right bottom 5px/10px 11px;
}
@media (--sm-lte) {
  .bge-contents .sitemap ul li ul li a[target=_blank],
.c-content-main .sitemap ul li ul li a[target=_blank] {
    padding-right: 4vw;
    background-size: 2.6666666667vw 2.9333333333vw;
  }
}
.bge-contents .sitemap ul li ul li a::before,
.c-content-main .sitemap ul li ul li a::before {
  width: 12px;
  height: 12px;
  top: 8px;
}
@media (--sm-lte) {
  .bge-contents .sitemap ul li ul li a::before,
.c-content-main .sitemap ul li ul li a::before {
    width: 3.2vw;
    height: 3.2vw;
    top: 1.8666666667vw;
  }
}
.bge-contents .sitemap ul li ul li a::after,
.c-content-main .sitemap ul li ul li a::after {
  content: none;
}
.bge-contents .col1-image > .bgt-grid:nth-child(1),
.bge-contents .col2-image > .bgt-grid:nth-child(1),
.c-content-main .col1-image > .bgt-grid:nth-child(1),
.c-content-main .col2-image > .bgt-grid:nth-child(1) {
  padding-right: 20px;
}
@media (--sm-lte) {
  .bge-contents .col1-image > .bgt-grid:nth-child(1),
.bge-contents .col2-image > .bgt-grid:nth-child(1),
.c-content-main .col1-image > .bgt-grid:nth-child(1),
.c-content-main .col2-image > .bgt-grid:nth-child(1) {
    padding-right: 0;
  }
}
.bge-contents .col1-image > .bgt-grid:nth-child(2),
.bge-contents .col2-image > .bgt-grid:nth-child(2),
.c-content-main .col1-image > .bgt-grid:nth-child(2),
.c-content-main .col2-image > .bgt-grid:nth-child(2) {
  padding-left: 20px;
}
@media (--sm-lte) {
  .bge-contents .col1-image > .bgt-grid:nth-child(2),
.bge-contents .col2-image > .bgt-grid:nth-child(2),
.c-content-main .col1-image > .bgt-grid:nth-child(2),
.c-content-main .col2-image > .bgt-grid:nth-child(2) {
    padding-left: 0;
  }
}
.bge-contents .col1-image-box,
.bge-contents .col2-image-box,
.c-content-main .col1-image-box,
.c-content-main .col2-image-box {
  overflow: hidden;
}
.bge-contents .col1-image .bgt-grid,
.bge-contents .col2-image .bgt-grid,
.c-content-main .col1-image .bgt-grid,
.c-content-main .col2-image .bgt-grid {
  margin-bottom: 1.5em;
}
@media (--sm-lte) {
  .bge-contents .col1-image .bgt-grid,
.bge-contents .col2-image .bgt-grid,
.c-content-main .col1-image .bgt-grid,
.c-content-main .col2-image .bgt-grid {
    margin-bottom: 2.85em;
  }
}
.bge-contents .col1-image h2,
.bge-contents .col2-image h2,
.c-content-main .col1-image h2,
.c-content-main .col2-image h2 {
  font-size: 2rem;
  border-bottom: none;
  line-height: 1.4;
  margin: 0 0 20px;
  letter-spacing: 0.09em;
  padding: 0 0 20px;
}
@media (--sm-lte) {
  .bge-contents .col1-image h2,
.bge-contents .col2-image h2,
.c-content-main .col1-image h2,
.c-content-main .col2-image h2 {
    font-size: 5.8666666667vw;
    padding: 0 0 4vw;
  }
}
.bge-contents .col1-image h2::before, .bge-contents .col1-image h2::after,
.bge-contents .col2-image h2::before,
.bge-contents .col2-image h2::after,
.c-content-main .col1-image h2::before,
.c-content-main .col1-image h2::after,
.c-content-main .col2-image h2::before,
.c-content-main .col2-image h2::after {
  content: "";
  position: absolute;
  display: block;
  height: 2px;
  top: auto;
  bottom: 0;
  left: 0;
}
.bge-contents .col1-image h2::before,
.bge-contents .col2-image h2::before,
.c-content-main .col1-image h2::before,
.c-content-main .col2-image h2::before {
  background: rgba(146, 160, 170, 0.5);
  width: 100%;
}
.bge-contents .col1-image h2::after,
.bge-contents .col2-image h2::after,
.c-content-main .col1-image h2::after,
.c-content-main .col2-image h2::after {
  background: #00a2e6;
  width: 120px;
}
.bge-contents .col1-image .btn-download,
.bge-contents .col1-image .btn-link,
.bge-contents .col2-image .btn-download,
.bge-contents .col2-image .btn-link,
.c-content-main .col1-image .btn-download,
.c-content-main .col1-image .btn-link,
.c-content-main .col2-image .btn-download,
.c-content-main .col2-image .btn-link {
  margin: 0 0 10px;
  max-width: 300px;
}
@media (--sm-lte) {
  .bge-contents .col1-image .btn-download,
.bge-contents .col1-image .btn-link,
.bge-contents .col2-image .btn-download,
.bge-contents .col2-image .btn-link,
.c-content-main .col1-image .btn-download,
.c-content-main .col1-image .btn-link,
.c-content-main .col2-image .btn-download,
.c-content-main .col2-image .btn-link {
    margin: 0 auto 10px;
    width: 100%;
  }
}
.bge-contents .col1-image .btn-download:last-child,
.bge-contents .col1-image .btn-link:last-child,
.bge-contents .col2-image .btn-download:last-child,
.bge-contents .col2-image .btn-link:last-child,
.c-content-main .col1-image .btn-download:last-child,
.c-content-main .col1-image .btn-link:last-child,
.c-content-main .col2-image .btn-download:last-child,
.c-content-main .col2-image .btn-link:last-child {
  margin-bottom: 0;
}
.bge-contents .col1-image .btn-download a,
.bge-contents .col1-image .btn-link a,
.bge-contents .col2-image .btn-download a,
.bge-contents .col2-image .btn-link a,
.c-content-main .col1-image .btn-download a,
.c-content-main .col1-image .btn-link a,
.c-content-main .col2-image .btn-download a,
.c-content-main .col2-image .btn-link a {
  min-width: 100%;
  font-size: 1.4rem;
}
@media (--sm-lte) {
  .bge-contents .col1-image .btn-download a,
.bge-contents .col1-image .btn-link a,
.bge-contents .col2-image .btn-download a,
.bge-contents .col2-image .btn-link a,
.c-content-main .col1-image .btn-download a,
.c-content-main .col1-image .btn-link a,
.c-content-main .col2-image .btn-download a,
.c-content-main .col2-image .btn-link a {
    font-size: 3.7333333333vw;
    border-width: 1px;
    padding: 3.2vw 8vw 3.2vw 3.2vw;
  }
}
.bge-contents .col1-image .btn-download a:last-child,
.bge-contents .col1-image .btn-link a:last-child,
.bge-contents .col2-image .btn-download a:last-child,
.bge-contents .col2-image .btn-link a:last-child,
.c-content-main .col1-image .btn-download a:last-child,
.c-content-main .col1-image .btn-link a:last-child,
.c-content-main .col2-image .btn-download a:last-child,
.c-content-main .col2-image .btn-link a:last-child {
  margin-bottom: 0;
}
.bge-contents .col1-image .btn-link a,
.bge-contents .col2-image .btn-link a,
.c-content-main .col1-image .btn-link a,
.c-content-main .col2-image .btn-link a {
  border: 2px solid #05141e;
  background: #fff;
  color: #05141e;
}
.bge-contents .col1-image .btn-link a::after,
.bge-contents .col2-image .btn-link a::after,
.c-content-main .col1-image .btn-link a::after,
.c-content-main .col2-image .btn-link a::after {
  width: 13px;
  height: 13px;
  background: url("/img/icon-external-btn.png") no-repeat center/13px 13px;
}
@media (--sm-lte) {
  .bge-contents .col1-image .btn-link a::after,
.bge-contents .col2-image .btn-link a::after,
.c-content-main .col1-image .btn-link a::after,
.c-content-main .col2-image .btn-link a::after {
    width: 3.4666666667vw;
    height: 3.4666666667vw;
    background-size: 3.4666666667vw 3.4666666667vw;
  }
}
.bge-contents .col1-image .btn-link a:visited,
.bge-contents .col2-image .btn-link a:visited,
.c-content-main .col1-image .btn-link a:visited,
.c-content-main .col2-image .btn-link a:visited {
  color: #05141e !important;
}
@media (--sm-lte) {
  .bge-contents .col1-image .bgt-download-file__link,
.bge-contents .col1-image .bgt-btn,
.bge-contents .col2-image .bgt-download-file__link,
.bge-contents .col2-image .bgt-btn,
.c-content-main .col1-image .bgt-download-file__link,
.c-content-main .col1-image .bgt-btn,
.c-content-main .col2-image .bgt-download-file__link,
.c-content-main .col2-image .bgt-btn {
    font-size: 3.7626666667vw;
    border-width: 1px;
  }
}
.bge-contents .col1-image .bge-ckeditor p,
.bge-contents .col2-image .bge-ckeditor p,
.c-content-main .col1-image .bge-ckeditor p,
.c-content-main .col2-image .bge-ckeditor p {
  margin: 0 0 25px;
  font-size: 1.5rem;
}
@media (--sm-lte) {
  .bge-contents .col1-image .bge-ckeditor p,
.bge-contents .col2-image .bge-ckeditor p,
.c-content-main .col1-image .bge-ckeditor p,
.c-content-main .col2-image .bge-ckeditor p {
    font-size: 4vw;
  }
}
.bge-contents .col1-image-image,
.c-content-main .col1-image-image {
  width: 29.25%;
  float: left;
  text-align: center;
}
@media (--sm-lte) {
  .bge-contents .col1-image-image,
.c-content-main .col1-image-image {
    width: 100%;
    float: none;
    margin-bottom: 2.6666666667vw;
  }
}
.bge-contents .col1-image-image a img,
.c-content-main .col1-image-image a img {
  transition: opacity 300ms;
}
.bge-contents .col1-image-image a:hover img,
.c-content-main .col1-image-image a:hover img {
  opacity: 0.7;
}
.bge-contents .col1-image-image img,
.c-content-main .col1-image-image img {
  width: 100%;
}
.bge-contents .col1-image-image img[data-width="140"],
.c-content-main .col1-image-image img[data-width="140"] {
  width: 140px;
}
@media (--sm-lte) {
  .bge-contents .col1-image-image img[data-width="140"],
.c-content-main .col1-image-image img[data-width="140"] {
    width: 37.3333333333vw;
  }
}
.bge-contents .col1-image-text,
.c-content-main .col1-image-text {
  width: 70%;
  float: right;
  padding-left: 30px;
}
@media (--sm-lte) {
  .bge-contents .col1-image-text,
.c-content-main .col1-image-text {
    width: 100%;
    float: none;
    padding-left: 0;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
  }
  .bge-contents .col1-image-text ul,
.c-content-main .col1-image-text ul {
    width: 100%;
  }
}
.bge-contents .col1-image-text h2,
.c-content-main .col1-image-text h2 {
  font-weight: bold;
  font-size: 2rem;
  padding: 0;
  line-height: 2;
  margin-bottom: 15px;
}
@media (--sm-lte) {
  .bge-contents .col1-image-text h2,
.c-content-main .col1-image-text h2 {
    font-size: 5.3333333333vw;
    margin-bottom: 2.6666666667vw;
  }
}
.bge-contents .col1-image-text h2::before, .bge-contents .col1-image-text h2::after,
.c-content-main .col1-image-text h2::before,
.c-content-main .col1-image-text h2::after {
  content: none;
}
.bge-contents .col2-image-text,
.c-content-main .col2-image-text {
  width: 54.7%;
  float: left;
  padding-left: 0;
  padding-right: 10px;
}
@media (--sm-lte) {
  .bge-contents .col2-image-text,
.c-content-main .col2-image-text {
    padding-right: 2.6666666667vw;
  }
}
.bge-contents .col2-image-image,
.c-content-main .col2-image-image {
  width: 43.9%;
  float: right;
  padding-left: 10px;
}
@media (--sm-lte) {
  .bge-contents .col2-image-image,
.c-content-main .col2-image-image {
    padding-left: 2.6666666667vw;
  }
}
.bge-contents .col2-image-image a img,
.c-content-main .col2-image-image a img {
  transition: opacity 300ms;
}
.bge-contents .col2-image-image a:hover img,
.c-content-main .col2-image-image a:hover img {
  opacity: 0.7;
}
.bge-contents .col2-image-image img,
.c-content-main .col2-image-image img {
  width: 100%;
}
.bge-contents .roman-list ol,
.bge-contents ol.roman-list,
.c-content-main .roman-list ol,
.c-content-main ol.roman-list {
  counter-reset: number 0;
}
@media (--sm-lte) {
  .bge-contents .roman-list ol,
.bge-contents ol.roman-list,
.c-content-main .roman-list ol,
.c-content-main ol.roman-list {
    margin-left: -5.6vw;
  }
}
.bge-contents .roman-list ol li::before,
.bge-contents ol.roman-list li::before,
.c-content-main .roman-list ol li::before,
.c-content-main ol.roman-list li::before {
  counter-increment: number 1;
  content: counter(number, upper-roman) ".";
  color: #0e212e;
  width: 60px;
  text-align: right;
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
  top: 0;
  white-space: nowrap;
}
@media (--sm-lte) {
  .bge-contents .roman-list ol li::before,
.bge-contents ol.roman-list li::before,
.c-content-main .roman-list ol li::before,
.c-content-main ol.roman-list li::before {
    font-size: 4.5333333333vw;
    width: 10.6666666667vw;
    top: 0.2666666667vw;
  }
}
.bge-contents .roman-list ol > li,
.bge-contents ol.roman-list > li,
.c-content-main .roman-list ol > li,
.c-content-main ol.roman-list > li {
  padding-left: 66px;
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  margin-bottom: 20px;
}
@media (--sm-lte) {
  .bge-contents .roman-list ol > li,
.bge-contents ol.roman-list > li,
.c-content-main .roman-list ol > li,
.c-content-main ol.roman-list > li {
    padding-left: 12.2666666667vw;
    font-size: 4.5333333333vw;
    margin-bottom: 5.3333333333vw;
  }
}
.bge-contents .roman-list ol > li > ol,
.bge-contents ol.roman-list > li > ol,
.c-content-main .roman-list ol > li > ol,
.c-content-main ol.roman-list > li > ol {
  counter-reset: number02 0;
  margin-left: -16px;
  margin-top: 16px;
}
@media (--sm-lte) {
  .bge-contents .roman-list ol > li > ol,
.bge-contents ol.roman-list > li > ol,
.c-content-main .roman-list ol > li > ol,
.c-content-main ol.roman-list > li > ol {
    margin-left: -4.2666666667vw;
  }
}
.bge-contents .roman-list ol > li > ol li,
.bge-contents ol.roman-list > li > ol li,
.c-content-main .roman-list ol > li > ol li,
.c-content-main ol.roman-list > li > ol li {
  font-size: 1.4rem;
  padding-left: 42px;
  line-height: 2;
  letter-spacing: 0.06em;
  margin-bottom: 16px;
}
@media (--sm-lte) {
  .bge-contents .roman-list ol > li > ol li,
.bge-contents ol.roman-list > li > ol li,
.c-content-main .roman-list ol > li > ol li,
.c-content-main ol.roman-list > li > ol li {
    font-size: 3.7333333333vw;
    padding-left: 11.2vw;
  }
}
.bge-contents .roman-list ol > li > ol li::before,
.bge-contents ol.roman-list > li > ol li::before,
.c-content-main .roman-list ol > li > ol li::before,
.c-content-main ol.roman-list > li > ol li::before {
  counter-increment: number02 1;
  content: counter(number02, upper-roman) ".";
  width: 35px;
  font-size: 1.6rem;
  color: #739aaa;
  top: -1px;
}
@media (--sm-lte) {
  .bge-contents .roman-list ol > li > ol li::before,
.bge-contents ol.roman-list > li > ol li::before,
.c-content-main .roman-list ol > li > ol li::before,
.c-content-main ol.roman-list > li > ol li::before {
    width: 9.3333333333vw;
    font-size: 4.2666666667vw;
  }
}
.bge-contents .anchor-link ul,
.c-content-main .anchor-link ul {
  background: #f3f3f3;
  padding: 25px 32px 15px 22px;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
@media (--sm-gt) {
  .bge-contents .anchor-link ul,
.c-content-main .anchor-link ul {
    word-break: keep-all;
  }
}
@media (--sm-lte) {
  .bge-contents .anchor-link ul,
.c-content-main .anchor-link ul {
    padding: 5.3333333333vw 5.8666666667vw 3.2vw 4.5333333333vw;
  }
}
.bge-contents .anchor-link ul li,
.c-content-main .anchor-link ul li {
  padding: 0;
  margin: 0 10px 17px;
  letter-spacing: 0.07em;
}
@media (--sm-lte) {
  .bge-contents .anchor-link ul li,
.c-content-main .anchor-link ul li {
    font-size: 4vw;
    margin: 0 2.6666666667vw 4.4vw;
  }
}
.bge-contents .anchor-link ul li a,
.c-content-main .anchor-link ul li a {
  border-bottom: 1px solid #000;
  color: inherit;
  text-decoration: none;
  padding-right: 20px;
  padding-bottom: 3px;
  position: relative;
}
@media (--sm-lte) {
  .bge-contents .anchor-link ul li a,
.c-content-main .anchor-link ul li a {
    padding-right: 0;
    padding-bottom: 0.8vw;
  }
}
.bge-contents .anchor-link ul li a[href$=pdf],
.c-content-main .anchor-link ul li a[href$=pdf] {
  padding-right: 0;
}
.bge-contents .anchor-link ul li a:not([href$=pdf])::after,
.c-content-main .anchor-link ul li a:not([href$=pdf])::after {
  content: "";
  position: absolute;
  right: 2px;
  bottom: 10px;
  display: block;
  width: 7px;
  height: 7px;
  border-top: 2px solid #00a2e6;
  border-right: 2px solid #00a2e6;
  transform: rotate(135deg);
  transition: bottom 300ms;
}
@media (--sm-lte) {
  .bge-contents .anchor-link ul li a:not([href$=pdf])::after,
.c-content-main .anchor-link ul li a:not([href$=pdf])::after {
    position: static;
    display: inline-block;
    margin-left: 1.8666666667vw;
    vertical-align: 0.8vw;
    width: 1.8666666667vw;
    height: 1.8666666667vw;
  }
}
.bge-contents .anchor-link ul li a:hover::after,
.c-content-main .anchor-link ul li a:hover::after {
  bottom: 5px;
}
@media (--sm-lte) {
  .bge-contents .anchor-link ul li a:hover::after,
.c-content-main .anchor-link ul li a:hover::after {
    bottom: 2.6666666667vw;
  }
}
.bge-contents .anchor-link ul li::before,
.c-content-main .anchor-link ul li::before {
  display: none;
}
.bge-contents .flex-link-list ul,
.c-content-main .flex-link-list ul {
  background: #f3f3f3;
  padding: 25px 32px 15px 22px;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}
@media (--sm-lte) {
  .bge-contents .flex-link-list ul,
.c-content-main .flex-link-list ul {
    padding: 5.3333333333vw 5.8666666667vw 3.2vw 4.5333333333vw;
  }
}
.bge-contents .flex-link-list ul li,
.c-content-main .flex-link-list ul li {
  padding: 0;
  margin: 0 10px 17px;
  letter-spacing: 0.07em;
}
@media (--sm-lte) {
  .bge-contents .flex-link-list ul li,
.c-content-main .flex-link-list ul li {
    font-size: 4vw;
    margin: 0 2.6666666667vw 4.4vw;
  }
}
.bge-contents .flex-link-list ul li a,
.c-content-main .flex-link-list ul li a {
  border-bottom: 1px solid #000;
  color: inherit;
  text-decoration: none;
  padding-right: 20px;
  padding-bottom: 3px;
  position: relative;
  word-break: keep-all;
}
@media (--sm-lte) {
  .bge-contents .flex-link-list ul li a,
.c-content-main .flex-link-list ul li a {
    padding-right: 5.3333333333vw;
    padding-bottom: 0.8vw;
  }
}
.bge-contents .flex-link-list ul li a::after,
.c-content-main .flex-link-list ul li a::after {
  content: "";
  position: absolute;
  right: 2px;
  top: 8px;
  display: block;
  width: 7px;
  height: 7px;
  border-top: 2px solid #00a2e6;
  border-right: 2px solid #00a2e6;
  transform: rotate(45deg);
  transition: right 300ms;
}
@media (--sm-lte) {
  .bge-contents .flex-link-list ul li a::after,
.c-content-main .flex-link-list ul li a::after {
    right: 0.5333333333vw;
    top: 2.1333333333vw;
    width: 1.8666666667vw;
    height: 1.8666666667vw;
  }
}
.bge-contents .flex-link-list ul li a:hover::after,
.c-content-main .flex-link-list ul li a:hover::after {
  right: -2px;
}
@media (--sm-lte) {
  .bge-contents .flex-link-list ul li a:hover::after,
.c-content-main .flex-link-list ul li a:hover::after {
    right: -0.5333333333vw;
  }
}
.bge-contents .flex-link-list ul li::before,
.c-content-main .flex-link-list ul li::before {
  display: none;
}
.bge-contents .link-list a,
.bge-contents .link-list-small a,
.c-content-main .link-list a,
.c-content-main .link-list-small a {
  color: inherit;
}
@media (--sm-gt) {
  .bge-contents .link-list a,
.bge-contents .link-list-small a,
.c-content-main .link-list a,
.c-content-main .link-list-small a {
    transition: color 300ms;
  }
  .bge-contents .link-list a:hover,
.bge-contents .link-list-small a:hover,
.c-content-main .link-list a:hover,
.c-content-main .link-list-small a:hover {
    color: #00a2e6;
    text-decoration: underline;
  }
}
.bge-contents .link-list p a,
.bge-contents .link-list-small p a,
.c-content-main .link-list p a,
.c-content-main .link-list-small p a {
  text-decoration: none;
}
.bge-contents .link-list ul,
.bge-contents .link-list-small ul,
.c-content-main .link-list ul,
.c-content-main .link-list-small ul {
  margin: 0;
  letter-spacing: 0.1em;
}
.bge-contents .link-list ul + ul,
.bge-contents .link-list-small ul + ul,
.c-content-main .link-list ul + ul,
.c-content-main .link-list-small ul + ul {
  margin-top: 30px;
}
@media (--sm-lte) {
  .bge-contents .link-list ul + ul,
.bge-contents .link-list-small ul + ul,
.c-content-main .link-list ul + ul,
.c-content-main .link-list-small ul + ul {
    margin-top: 8vw;
  }
}
.bge-contents .link-list ul li,
.bge-contents .link-list-small ul li,
.c-content-main .link-list ul li,
.c-content-main .link-list-small ul li {
  padding: 0;
  font-size: 1.6rem;
}
@media (--sm-lte) {
  .bge-contents .link-list ul li,
.bge-contents .link-list-small ul li,
.c-content-main .link-list ul li,
.c-content-main .link-list-small ul li {
    font-size: 4.2666666667vw;
  }
}
.bge-contents .link-list ul li::before,
.bge-contents .link-list-small ul li::before,
.c-content-main .link-list ul li::before,
.c-content-main .link-list-small ul li::before {
  display: none;
}
.bge-contents .link-list ul li a,
.bge-contents .link-list-small ul li a,
.c-content-main .link-list ul li a,
.c-content-main .link-list-small ul li a {
  padding-left: 25px;
  display: inline-block;
  position: relative;
}
@media (--sm-lte) {
  .bge-contents .link-list ul li a,
.bge-contents .link-list-small ul li a,
.c-content-main .link-list ul li a,
.c-content-main .link-list-small ul li a {
    padding-left: 6.6666666667vw;
  }
}
@media (--sm-gt) {
  .bge-contents .link-list ul li a::before,
.bge-contents .link-list-small ul li a::before,
.c-content-main .link-list ul li a::before,
.c-content-main .link-list-small ul li a::before {
    transition: background-color 300ms;
  }
  .bge-contents .link-list ul li a:hover::before,
.bge-contents .link-list-small ul li a:hover::before,
.c-content-main .link-list ul li a:hover::before,
.c-content-main .link-list-small ul li a:hover::before {
    background-color: #00a2e6;
  }
}
.bge-contents .link-list ul li a::before,
.bge-contents .link-list-small ul li a::before,
.c-content-main .link-list ul li a::before,
.c-content-main .link-list-small ul li a::before {
  content: "";
  width: 16px;
  height: 16px;
  background: url("/img/icon-arrow-w.png") no-repeat center center/7px auto;
  background-color: #0d212e;
  position: absolute;
  left: 0;
  top: 7px;
  border-radius: 50%;
}
@media (--sm-lte) {
  .bge-contents .link-list ul li a::before,
.bge-contents .link-list-small ul li a::before,
.c-content-main .link-list ul li a::before,
.c-content-main .link-list-small ul li a::before {
    width: 4.2666666667vw;
    height: 4.2666666667vw;
    top: 1.3333333333vw;
    background-size: 1.8666666667vw auto;
  }
}
.bge-contents .link-list-small ul li,
.c-content-main .link-list-small ul li {
  font-size: 1.4rem;
}
@media (--sm-lte) {
  .bge-contents .link-list-small ul li,
.c-content-main .link-list-small ul li {
    font-size: 3.7333333333vw;
  }
}
.bge-contents .link-list-small ul li a[href$=pdf], .bge-contents .link-list-small ul li a[href$=ppt], .bge-contents .link-list-small ul li a[href$=pptx], .bge-contents .link-list-small ul li a[href$=doc], .bge-contents .link-list-small ul li a[href$=docx], .bge-contents .link-list-small ul li a[href$=xls], .bge-contents .link-list-small ul li a[href$=xlsx], .bge-contents .link-list-small ul li a[href$=zip],
.c-content-main .link-list-small ul li a[href$=pdf],
.c-content-main .link-list-small ul li a[href$=ppt],
.c-content-main .link-list-small ul li a[href$=pptx],
.c-content-main .link-list-small ul li a[href$=doc],
.c-content-main .link-list-small ul li a[href$=docx],
.c-content-main .link-list-small ul li a[href$=xls],
.c-content-main .link-list-small ul li a[href$=xlsx],
.c-content-main .link-list-small ul li a[href$=zip] {
  padding: 4px 0 4px 25px;
}
@media (--sm-lte) {
  .bge-contents .link-list-small ul li a[href$=pdf], .bge-contents .link-list-small ul li a[href$=ppt], .bge-contents .link-list-small ul li a[href$=pptx], .bge-contents .link-list-small ul li a[href$=doc], .bge-contents .link-list-small ul li a[href$=docx], .bge-contents .link-list-small ul li a[href$=xls], .bge-contents .link-list-small ul li a[href$=xlsx], .bge-contents .link-list-small ul li a[href$=zip],
.c-content-main .link-list-small ul li a[href$=pdf],
.c-content-main .link-list-small ul li a[href$=ppt],
.c-content-main .link-list-small ul li a[href$=pptx],
.c-content-main .link-list-small ul li a[href$=doc],
.c-content-main .link-list-small ul li a[href$=docx],
.c-content-main .link-list-small ul li a[href$=xls],
.c-content-main .link-list-small ul li a[href$=xlsx],
.c-content-main .link-list-small ul li a[href$=zip] {
    padding: 1.0666666667vw 0 1.0666666667vw 6.6666666667vw;
  }
}
.bge-contents .link-list-small ul li a[href$=pdf] span::before, .bge-contents .link-list-small ul li a[href$=ppt] span::before, .bge-contents .link-list-small ul li a[href$=pptx] span::before, .bge-contents .link-list-small ul li a[href$=doc] span::before, .bge-contents .link-list-small ul li a[href$=docx] span::before, .bge-contents .link-list-small ul li a[href$=xls] span::before, .bge-contents .link-list-small ul li a[href$=xlsx] span::before, .bge-contents .link-list-small ul li a[href$=zip] span::before,
.c-content-main .link-list-small ul li a[href$=pdf] span::before,
.c-content-main .link-list-small ul li a[href$=ppt] span::before,
.c-content-main .link-list-small ul li a[href$=pptx] span::before,
.c-content-main .link-list-small ul li a[href$=doc] span::before,
.c-content-main .link-list-small ul li a[href$=docx] span::before,
.c-content-main .link-list-small ul li a[href$=xls] span::before,
.c-content-main .link-list-small ul li a[href$=xlsx] span::before,
.c-content-main .link-list-small ul li a[href$=zip] span::before {
  top: 7px;
}
@media (--sm-lte) {
  .bge-contents .link-list-small ul li a[href$=pdf] span::before, .bge-contents .link-list-small ul li a[href$=ppt] span::before, .bge-contents .link-list-small ul li a[href$=pptx] span::before, .bge-contents .link-list-small ul li a[href$=doc] span::before, .bge-contents .link-list-small ul li a[href$=docx] span::before, .bge-contents .link-list-small ul li a[href$=xls] span::before, .bge-contents .link-list-small ul li a[href$=xlsx] span::before, .bge-contents .link-list-small ul li a[href$=zip] span::before,
.c-content-main .link-list-small ul li a[href$=pdf] span::before,
.c-content-main .link-list-small ul li a[href$=ppt] span::before,
.c-content-main .link-list-small ul li a[href$=pptx] span::before,
.c-content-main .link-list-small ul li a[href$=doc] span::before,
.c-content-main .link-list-small ul li a[href$=docx] span::before,
.c-content-main .link-list-small ul li a[href$=xls] span::before,
.c-content-main .link-list-small ul li a[href$=xlsx] span::before,
.c-content-main .link-list-small ul li a[href$=zip] span::before {
    top: 1.8666666667vw;
  }
}
.bge-contents .link-list-small ul li a[href$=pdf] span::after, .bge-contents .link-list-small ul li a[href$=ppt] span::after, .bge-contents .link-list-small ul li a[href$=pptx] span::after, .bge-contents .link-list-small ul li a[href$=doc] span::after, .bge-contents .link-list-small ul li a[href$=docx] span::after, .bge-contents .link-list-small ul li a[href$=xls] span::after, .bge-contents .link-list-small ul li a[href$=xlsx] span::after, .bge-contents .link-list-small ul li a[href$=zip] span::after,
.c-content-main .link-list-small ul li a[href$=pdf] span::after,
.c-content-main .link-list-small ul li a[href$=ppt] span::after,
.c-content-main .link-list-small ul li a[href$=pptx] span::after,
.c-content-main .link-list-small ul li a[href$=doc] span::after,
.c-content-main .link-list-small ul li a[href$=docx] span::after,
.c-content-main .link-list-small ul li a[href$=xls] span::after,
.c-content-main .link-list-small ul li a[href$=xlsx] span::after,
.c-content-main .link-list-small ul li a[href$=zip] span::after {
  top: 11px;
}
@media (--sm-lte) {
  .bge-contents .link-list-small ul li a[href$=pdf] span::after, .bge-contents .link-list-small ul li a[href$=ppt] span::after, .bge-contents .link-list-small ul li a[href$=pptx] span::after, .bge-contents .link-list-small ul li a[href$=doc] span::after, .bge-contents .link-list-small ul li a[href$=docx] span::after, .bge-contents .link-list-small ul li a[href$=xls] span::after, .bge-contents .link-list-small ul li a[href$=xlsx] span::after, .bge-contents .link-list-small ul li a[href$=zip] span::after,
.c-content-main .link-list-small ul li a[href$=pdf] span::after,
.c-content-main .link-list-small ul li a[href$=ppt] span::after,
.c-content-main .link-list-small ul li a[href$=pptx] span::after,
.c-content-main .link-list-small ul li a[href$=doc] span::after,
.c-content-main .link-list-small ul li a[href$=docx] span::after,
.c-content-main .link-list-small ul li a[href$=xls] span::after,
.c-content-main .link-list-small ul li a[href$=xlsx] span::after,
.c-content-main .link-list-small ul li a[href$=zip] span::after {
    top: 2.9333333333vw;
  }
}
.bge-contents .link-no-icon[target=_blank]::after,
.c-content-main .link-no-icon[target=_blank]::after {
  content: none;
}
.bge-contents .catch-title,
.c-content-main .catch-title {
  padding-bottom: 0;
  color: #5a6d7a;
  font-weight: 500;
  font-size: 3rem;
  letter-spacing: 0.1em;
  line-height: 1.7;
  margin-bottom: 15px;
  font-style: normal;
}
@media (--sm-lte) {
  .bge-contents .catch-title,
.c-content-main .catch-title {
    font-size: 6.1333333333vw;
    margin-bottom: 4.8vw;
  }
}
[lang=en] .bge-contents .catch-title,
[lang=en] .c-content-main .catch-title {
  font-size: 2.4rem;
  letter-spacing: 0.05em;
  line-height: 1.6;
}
@media (--sm-lte) {
  [lang=en] .bge-contents .catch-title,
[lang=en] .c-content-main .catch-title {
    font-size: 5.3333333333vw;
  }
}
.bge-contents .icon-title,
.c-content-main .icon-title {
  margin-bottom: 24px;
}
@media (--sm-lte) {
  .bge-contents .icon-title,
.c-content-main .icon-title {
    margin-bottom: 5.8666666667vw;
  }
}
.bge-contents .icon-title h2,
.c-content-main .icon-title h2 {
  display: flex;
  align-items: stretch;
  padding: 0;
  margin-bottom: 13px;
  min-height: 80px;
  background: #f3f3f3;
  position: relative;
}
@media (--sm-lte) {
  .bge-contents .icon-title h2,
.c-content-main .icon-title h2 {
    margin-bottom: 1.3333333333vw;
  }
}
.bge-contents .icon-title h2::before,
.c-content-main .icon-title h2::before {
  position: static;
  height: 80px;
  width: 1px;
  margin-right: -1px;
}
.bge-contents .icon-title h2 > span:first-child,
.c-content-main .icon-title h2 > span:first-child {
  margin: 0;
  display: flex;
  align-items: center;
  padding: 5px 0 6px 13px;
  letter-spacing: 0.1em;
  width: 111px;
  font-size: 1.6rem;
  color: #fff;
  background: #00a2e6;
  font-weight: bold;
  border-left: 1px solid #0d212e;
  position: relative;
  z-index: 2;
}
@media (--sm-lte) {
  .bge-contents .icon-title h2 > span:first-child,
.c-content-main .icon-title h2 > span:first-child {
    font-size: 3.2vw;
    width: 19vw;
    padding: 1.3333333333vw 0 1.6vw 2.4vw;
    text-align: center;
  }
}
.bge-contents .icon-title h2 > span:first-child::before,
.c-content-main .icon-title h2 > span:first-child::before {
  content: "";
  display: block;
  position: absolute;
  width: 2px;
  top: 1px;
  bottom: 1px;
  right: -1px;
  background: #00a2e6;
}
.bge-contents .icon-title h2::after,
.c-content-main .icon-title h2::after {
  content: "";
  display: block;
  position: absolute;
  left: 105px;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 100%;
  background: url("/img/bg-obi-arrow.png") no-repeat center center/100% 100%;
}
@media (--sm-lte) {
  .bge-contents .icon-title h2::after,
.c-content-main .icon-title h2::after {
    width: 5.3333333333vw;
    border-width: 7.7333333333vw 0 7.7333333333vw 3.2vw;
    left: 17.0666666667vw;
  }
}
.bge-contents .icon-title h2 > span:last-child,
.c-content-main .icon-title h2 > span:last-child {
  display: flex;
  justify-content: center;
  flex-flow: column;
  width: calc(100% - 111px);
  margin: 0;
  padding: 8px 10px 8px 30px;
  line-height: 1.45;
}
@media (--sm-lte) {
  .bge-contents .icon-title h2 > span:last-child,
.c-content-main .icon-title h2 > span:last-child {
    width: calc(100% - 17.6vw);
    padding: 1.3333333333vw 2.6666666667vw 1.3333333333vw 7.2vw;
  }
}
.bge-contents .icon-title h2 > span:last-child .icon-title-title,
.c-content-main .icon-title h2 > span:last-child .icon-title-title {
  line-height: 1.45;
  font-size: 3rem;
  letter-spacing: 0.1em;
  font-weight: 500;
}
@media (--sm-lte) {
  .bge-contents .icon-title h2 > span:last-child .icon-title-title,
.c-content-main .icon-title h2 > span:last-child .icon-title-title {
    font-size: 6.1333333333vw;
    letter-spacing: 0.08em;
  }
}
.bge-contents .icon-title h2 > span:last-child span:not(.icon-title-title),
.c-content-main .icon-title h2 > span:last-child span:not(.icon-title-title) {
  display: block;
  font-size: 1.8rem;
  font-weight: bold;
  letter-spacing: 0.03em;
}
@media (--sm-lte) {
  .bge-contents .icon-title h2 > span:last-child span:not(.icon-title-title),
.c-content-main .icon-title h2 > span:last-child span:not(.icon-title-title) {
    font-size: 3.2vw;
    margin-top: 0.5333333333vw;
  }
}
.bge-contents .icon-title h2 > span:last-child [lang=en],
.c-content-main .icon-title h2 > span:last-child [lang=en] {
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
  font-weight: bold;
}
.bge-contents .icon-title p,
.c-content-main .icon-title p {
  margin-top: 13px;
}
@media (--sm-lte) {
  .bge-contents .icon-title p,
.c-content-main .icon-title p {
    margin-top: 1.3333333333vw;
  }
}
.bge-contents .point-column[data-bgb=wysiwyg3],
.c-content-main .point-column[data-bgb=wysiwyg3] {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
@media (--sm-lte) {
  .bge-contents .point-column[data-bgb=wysiwyg3],
.c-content-main .point-column[data-bgb=wysiwyg3] {
    display: block;
  }
}
.bge-contents .point-column[data-bgb=wysiwyg3]::after,
.c-content-main .point-column[data-bgb=wysiwyg3]::after {
  content: none;
}
.bge-contents .point-column[data-bgb=wysiwyg3] .bgt-grid,
.c-content-main .point-column[data-bgb=wysiwyg3] .bgt-grid {
  border-top: 2px solid #0d212e;
  float: none;
  width: 244px;
  padding: 25px;
  background: #f3f3f3;
}
@media (--sm-lte) {
  .bge-contents .point-column[data-bgb=wysiwyg3] .bgt-grid,
.c-content-main .point-column[data-bgb=wysiwyg3] .bgt-grid {
    width: 100%;
    padding: 6.6666666667vw;
    margin-bottom: 8vw;
  }
  .bge-contents .point-column[data-bgb=wysiwyg3] .bgt-grid:last-child,
.c-content-main .point-column[data-bgb=wysiwyg3] .bgt-grid:last-child {
    margin-bottom: 0;
  }
}
.bge-contents .point-column[data-bgb=wysiwyg3] .bgt-grid:nth-child(1),
.c-content-main .point-column[data-bgb=wysiwyg3] .bgt-grid:nth-child(1) {
  padding: 25px;
}
@media (--sm-lte) {
  .bge-contents .point-column[data-bgb=wysiwyg3] .bgt-grid:nth-child(1),
.c-content-main .point-column[data-bgb=wysiwyg3] .bgt-grid:nth-child(1) {
    padding: 6.6666666667vw;
  }
}
.bge-contents .point-column[data-bgb=wysiwyg3] .bgt-grid:nth-child(3),
.c-content-main .point-column[data-bgb=wysiwyg3] .bgt-grid:nth-child(3) {
  padding: 25px;
}
@media (--sm-lte) {
  .bge-contents .point-column[data-bgb=wysiwyg3] .bgt-grid:nth-child(3),
.c-content-main .point-column[data-bgb=wysiwyg3] .bgt-grid:nth-child(3) {
    padding: 6.6666666667vw;
  }
}
.bge-contents .point-column h2,
.c-content-main .point-column h2 {
  text-align: center;
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.15;
  padding: 15px 0 7px;
  color: #00a2e6;
  letter-spacing: 0.05em;
  margin-bottom: 0;
}
@media (--sm-lte) {
  .bge-contents .point-column h2,
.c-content-main .point-column h2 {
    font-size: 4vw;
    padding: 5.8666666667vw 0 1.8666666667vw;
  }
}
.bge-contents .point-column h2 span,
.c-content-main .point-column h2 span {
  display: block;
  font-size: 3.2rem;
  letter-spacing: 0.05em;
}
@media (--sm-lte) {
  .bge-contents .point-column h2 span,
.c-content-main .point-column h2 span {
    font-size: 8.5333333333vw;
  }
}
.bge-contents .point-column h2::before, .bge-contents .point-column h2::after,
.c-content-main .point-column h2::before,
.c-content-main .point-column h2::after {
  display: none;
}
.bge-contents .point-column h3,
.c-content-main .point-column h3 {
  background: none;
  text-align: center;
  font-size: 2.2rem;
  letter-spacing: 0.05em;
  border: 0;
  padding: 0;
  margin: 0;
}
@media (--sm-lte) {
  .bge-contents .point-column h3,
.c-content-main .point-column h3 {
    font-size: 5.8666666667vw;
    margin-bottom: 5.3333333333vw;
  }
}
.bge-contents .point-column p,
.c-content-main .point-column p {
  font-size: 1.5rem;
}
@media (--sm-lte) {
  .bge-contents .point-column p,
.c-content-main .point-column p {
    font-size: 4vw;
  }
}
.bge-contents .color-column[data-bgb=image-text2] .bgt-grid:nth-child(1),
.c-content-main .color-column[data-bgb=image-text2] .bgt-grid:nth-child(1) {
  padding-right: 20px;
}
@media (--sm-lte) {
  .bge-contents .color-column[data-bgb=image-text2] .bgt-grid:nth-child(1),
.c-content-main .color-column[data-bgb=image-text2] .bgt-grid:nth-child(1) {
    padding-right: 0;
    margin-bottom: 1.5em;
  }
}
@media (--sm-lte) {
  .bge-contents .color-column[data-bgb=image-text2] .bgt-grid [data-bgt] > :last-child,
.c-content-main .color-column[data-bgb=image-text2] .bgt-grid [data-bgt] > :last-child {
    margin-bottom: 0;
  }
}
.bge-contents .color-column[data-bgb=image-text2] [data-bgt=image],
.c-content-main .color-column[data-bgb=image-text2] [data-bgt=image] {
  border-radius: 18px 18px 0 0;
  overflow: hidden;
}
@media (--sm-lte) {
  .bge-contents .color-column[data-bgb=image-text2] [data-bgt=image],
.c-content-main .color-column[data-bgb=image-text2] [data-bgt=image] {
    border-radius: 4.8vw 4.8vw 0 0;
  }
}
.bge-contents .color-column[data-bgb=image-text2] .bgt-grid:nth-child(2),
.c-content-main .color-column[data-bgb=image-text2] .bgt-grid:nth-child(2) {
  padding-left: 20px;
}
@media (--sm-lte) {
  .bge-contents .color-column[data-bgb=image-text2] .bgt-grid:nth-child(2),
.c-content-main .color-column[data-bgb=image-text2] .bgt-grid:nth-child(2) {
    padding-left: 0;
  }
}
.bge-contents .color-column [data-bgt=ckeditor]:nth-child(2),
.c-content-main .color-column [data-bgt=ckeditor]:nth-child(2) {
  margin-top: 0;
}
.bge-contents .color-column-pink, .bge-contents .color-column-yellow, .bge-contents .color-column-green,
.c-content-main .color-column-pink,
.c-content-main .color-column-yellow,
.c-content-main .color-column-green {
  border-radius: 0 0 18px 18px;
  padding: 23px 30px;
}
@media (--sm-lte) {
  .bge-contents .color-column-pink, .bge-contents .color-column-yellow, .bge-contents .color-column-green,
.c-content-main .color-column-pink,
.c-content-main .color-column-yellow,
.c-content-main .color-column-green {
    border-radius: 0 0 4.8vw 4.8vw;
    padding: 6.1333333333vw 8vw;
  }
}
.bge-contents .color-column-pink p, .bge-contents .color-column-yellow p, .bge-contents .color-column-green p,
.c-content-main .color-column-pink p,
.c-content-main .color-column-yellow p,
.c-content-main .color-column-green p {
  margin-top: 12px;
  line-height: 1.9;
}
@media (--sm-lte) {
  .bge-contents .color-column-pink p, .bge-contents .color-column-yellow p, .bge-contents .color-column-green p,
.c-content-main .color-column-pink p,
.c-content-main .color-column-yellow p,
.c-content-main .color-column-green p {
    margin-top: 3.2vw;
  }
}
.bge-contents .color-column-pink p span, .bge-contents .color-column-yellow p span, .bge-contents .color-column-green p span,
.c-content-main .color-column-pink p span,
.c-content-main .color-column-yellow p span,
.c-content-main .color-column-green p span {
  display: inline-block;
  line-height: 1.6;
}
.bge-contents .color-column-pink,
.c-content-main .color-column-pink {
  background: #ffc5b3;
}
.bge-contents .color-column-yellow,
.c-content-main .color-column-yellow {
  background: #ffec6b;
}
.bge-contents .color-column-green,
.c-content-main .color-column-green {
  background: #9cf6cd;
}
.bge-contents .color-column h6,
.c-content-main .color-column h6 {
  font-size: 1.8rem;
  letter-spacing: 0.08em;
  margin-bottom: 0;
  padding: 0;
  line-height: 1.6;
  font-weight: bold;
}
@media (--sm-lte) {
  .bge-contents .color-column h6,
.c-content-main .color-column h6 {
    font-size: 4.8vw;
  }
}
.bge-contents .color-column h6::before, .bge-contents .color-column h6::after,
.c-content-main .color-column h6::before,
.c-content-main .color-column h6::after {
  display: none;
}
.bge-contents .nushi-calendar-hanrei dl,
.c-content-main .nushi-calendar-hanrei dl {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  border: 1px solid #92a0aa;
  letter-spacing: normal;
  line-height: 1.5;
}
@media (--sm-lte) {
  .bge-contents .nushi-calendar-hanrei dl,
.c-content-main .nushi-calendar-hanrei dl {
    display: block;
  }
}
.bge-contents .nushi-calendar-hanrei dl dt,
.c-content-main .nushi-calendar-hanrei dl dt {
  width: 186px;
  padding: 1px 5px;
  text-align: center;
  background: #92a0aa;
  flex: 0 0 auto;
  color: #fff;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (--sm-lte) {
  .bge-contents .nushi-calendar-hanrei dl dt,
.c-content-main .nushi-calendar-hanrei dl dt {
    width: 100%;
    padding: 1.3333333333vw 2vw;
    font-size: 3.4666666667vw;
  }
}
.bge-contents .nushi-calendar-hanrei dl dd,
.c-content-main .nushi-calendar-hanrei dl dd {
  flex: 1 1 auto;
  margin: 0;
  padding: 1px 5px 2px;
  font-size: 1.3rem;
}
@media (--sm-lte) {
  .bge-contents .nushi-calendar-hanrei dl dd,
.c-content-main .nushi-calendar-hanrei dl dd {
    padding: 1.3333333333vw 2vw;
    font-size: 3.4666666667vw;
  }
}
.bge-contents .nushi-calendar-hanrei dl dd p,
.c-content-main .nushi-calendar-hanrei dl dd p {
  display: inline-block;
  margin: 0 2em 0 0;
}
@media (--sm-lte) {
  .bge-contents .nushi-calendar-hanrei dl dd p,
.c-content-main .nushi-calendar-hanrei dl dd p {
    display: block;
  }
}
.bge-contents .nushi-calendar-hanrei dl dd p::before,
.c-content-main .nushi-calendar-hanrei dl dd p::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 3px;
}
@media (--sm-lte) {
  .bge-contents .nushi-calendar-hanrei dl dd p::before,
.c-content-main .nushi-calendar-hanrei dl dd p::before {
    width: 2.6666666667vw;
    height: 2.6666666667vw;
    margin-right: 0.8vw;
  }
}
.bge-contents .nushi-calendar-hanrei dl dd p.hanrei01::before,
.c-content-main .nushi-calendar-hanrei dl dd p.hanrei01::before {
  background-color: #2e9362;
}
.bge-contents .nushi-calendar-hanrei dl dd p.hanrei02::before,
.c-content-main .nushi-calendar-hanrei dl dd p.hanrei02::before {
  background-color: #d0613b;
}
.bge-contents .nushi-calendar-hanrei dl dd p.hanrei03::before,
.c-content-main .nushi-calendar-hanrei dl dd p.hanrei03::before {
  background-color: #e13737;
}
.bge-contents .nushi-calendar-hanrei dl dd p.hanrei04::before,
.c-content-main .nushi-calendar-hanrei dl dd p.hanrei04::before {
  background-color: #3a8cc1;
}
.bge-contents .nushi-calendar-hanrei dl dd p.hanrei05::before,
.c-content-main .nushi-calendar-hanrei dl dd p.hanrei05::before {
  background-color: #863ab1;
}
.bge-contents .nushi-calendar-hanrei dl dd p.hanrei06::before,
.c-content-main .nushi-calendar-hanrei dl dd p.hanrei06::before {
  background-color: #b04c82;
}
.bge-contents .nushi-calendar-slide,
.c-content-main .nushi-calendar-slide {
  padding-bottom: 22px;
}
.bge-contents .nushi-calendar-slide-item img,
.c-content-main .nushi-calendar-slide-item img {
  width: 100%;
}
.bge-contents .nushi-calendar-slide .slick-list,
.c-content-main .nushi-calendar-slide .slick-list {
  z-index: 10;
}
.bge-contents .nushi-calendar-slide .slick-arrow,
.c-content-main .nushi-calendar-slide .slick-arrow {
  appearance: none;
  position: absolute;
  bottom: 0;
  background: #0d212e;
  color: #fff;
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  line-height: 22px;
  transition: opacity 300ms;
}
.bge-contents .nushi-calendar-slide .slick-arrow::before, .bge-contents .nushi-calendar-slide .slick-arrow::after,
.c-content-main .nushi-calendar-slide .slick-arrow::before,
.c-content-main .nushi-calendar-slide .slick-arrow::after {
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  display: inline-block;
  width: 7px;
  height: 7px;
  margin: 0 10px;
  vertical-align: 2px;
}
.bge-contents .nushi-calendar-slide .slick-arrow:hover,
.c-content-main .nushi-calendar-slide .slick-arrow:hover {
  opacity: 0.6;
}
.bge-contents .nushi-calendar-slide .slick-prev,
.c-content-main .nushi-calendar-slide .slick-prev {
  left: 0;
  border-radius: 0 0 10px 0;
  padding-right: 15px;
}
.bge-contents .nushi-calendar-slide .slick-prev::before,
.c-content-main .nushi-calendar-slide .slick-prev::before {
  content: "";
  transform: rotate(-135deg);
  right: 3px;
}
.bge-contents .nushi-calendar-slide .slick-next,
.c-content-main .nushi-calendar-slide .slick-next {
  right: 0;
  border-radius: 0 0 0 10px;
  padding-left: 15px;
}
.bge-contents .nushi-calendar-slide .slick-next::after,
.c-content-main .nushi-calendar-slide .slick-next::after {
  content: "";
  transform: rotate(45deg);
  left: 3px;
}
.bge-contents .now-block ul,
.c-content-main .now-block ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
@media (--sm-lte) {
  .bge-contents .now-block ul,
.c-content-main .now-block ul {
    display: block;
  }
}
.bge-contents .now-block ul li,
.c-content-main .now-block ul li {
  width: 31.3%;
  margin: 0 0 50px 3.05%;
  padding: 0;
}
@media (--sm-lte) {
  .bge-contents .now-block ul li,
.c-content-main .now-block ul li {
    width: 100%;
    margin-left: 0;
  }
}
.bge-contents .now-block ul li:nth-child(3n+1),
.c-content-main .now-block ul li:nth-child(3n+1) {
  margin-left: 0;
}
.bge-contents .now-block ul li::before,
.c-content-main .now-block ul li::before {
  display: none;
}
.bge-contents .now-block ul li figure,
.c-content-main .now-block ul li figure {
  margin-bottom: 4px;
}
.bge-contents .now-block a,
.c-content-main .now-block a {
  color: inherit;
  transition: opacity 300ms;
  text-decoration: none;
  width: 100%;
}
.bge-contents .now-block a::after,
.c-content-main .now-block a::after {
  display: none !important;
}
.bge-contents .now-block a:hover,
.c-content-main .now-block a:hover {
  opacity: 0.6;
}
@media (--sm-lte) {
  .bge-contents .now-block a:hover,
.c-content-main .now-block a:hover {
    opacity: 1;
  }
}
.bge-contents .now-block h5,
.c-content-main .now-block h5 {
  text-align: center;
  background: #0d212e;
  padding: 0 10px;
  color: #fff;
  line-height: 1.6;
  margin: 0 0 7px;
  font-size: 1.3rem;
}
@media (--sm-lte) {
  .bge-contents .now-block h5,
.c-content-main .now-block h5 {
    font-size: 3.4666666667vw;
    margin: 0 0 10px;
  }
}
.bge-contents .now-block p,
.c-content-main .now-block p {
  text-align: center;
  font-size: 1.8rem;
  font-weight: bold;
  margin: 0;
  letter-spacing: 0.02em;
  line-height: 1.45;
}
@media (--sm-lte) {
  .bge-contents .now-block p,
.c-content-main .now-block p {
    font-size: 4.2666666667vw;
  }
}
.bge-contents .floor-title[data-bgb=text-image2] .bgt-grid--first,
.c-content-main .floor-title[data-bgb=text-image2] .bgt-grid--first {
  padding-right: 20px;
}
@media (--sm-lte) {
  .bge-contents .floor-title[data-bgb=text-image2] .bgt-grid--first,
.c-content-main .floor-title[data-bgb=text-image2] .bgt-grid--first {
    padding-right: 0;
  }
}
.bge-contents .floor-title[data-bgb=text-image2] .bgt-grid--last,
.c-content-main .floor-title[data-bgb=text-image2] .bgt-grid--last {
  padding-left: 20px;
}
@media (--sm-lte) {
  .bge-contents .floor-title[data-bgb=text-image2] .bgt-grid--last,
.c-content-main .floor-title[data-bgb=text-image2] .bgt-grid--last {
    padding-left: 0;
  }
}
.bge-contents .floor-title h2,
.c-content-main .floor-title h2 {
  font-size: 1.8rem;
  font-weight: bold;
  padding: 0;
  margin-bottom: 0;
  letter-spacing: 0.1em;
}
@media (--sm-lte) {
  .bge-contents .floor-title h2,
.c-content-main .floor-title h2 {
    font-size: 4.8vw;
    margin-top: 4.5333333333vw;
  }
}
.bge-contents .floor-title h2 span,
.c-content-main .floor-title h2 span {
  display: inline-block;
  font-weight: 500;
  font-size: 1.5rem;
  background: #0d212e;
  color: #fff;
  text-align: center;
  min-width: 47px;
  padding: 0 10px;
  margin-right: 10px;
}
@media (--sm-lte) {
  .bge-contents .floor-title h2 span,
.c-content-main .floor-title h2 span {
    font-size: 4vw;
    min-width: 12.5333333333vw;
    padding: 0 2.6666666667vw;
    margin-right: 2.6666666667vw;
    margin-bottom: 4px;
    display: table;
  }
}
.bge-contents .floor-title h2::before, .bge-contents .floor-title h2::after,
.c-content-main .floor-title h2::before,
.c-content-main .floor-title h2::after {
  display: none;
}
.bge-contents .floor-title .btn-link,
.c-content-main .floor-title .btn-link {
  text-align: center;
}
.bge-contents .floor-title .btn-link a,
.c-content-main .floor-title .btn-link a {
  min-width: 300px;
}
@media (--sm-lte) {
  .bge-contents .floor-title .btn-link a,
.c-content-main .floor-title .btn-link a {
    min-width: 0;
    width: 100%;
    max-width: 80vw;
  }
}
.bge-contents .flow-title,
.c-content-main .flow-title {
  margin-bottom: 14px;
}
@media (--sm-lte) {
  .bge-contents .flow-title,
.c-content-main .flow-title {
    margin-bottom: 3.7333333333vw;
  }
}
.bge-contents .flow-title h3,
.c-content-main .flow-title h3 {
  display: flex;
  align-items: center;
  margin: 0;
  background: #dde4e9;
  border: 0;
  font-size: 2.2rem;
  line-height: 1.6;
  font-weight: 500;
  padding: 10px 5px 9px 12px;
  letter-spacing: 0.1em;
}
@media (--sm-lte) {
  .bge-contents .flow-title h3,
.c-content-main .flow-title h3 {
    margin: 0;
    line-height: 1.35;
    font-size: 5.3333333333vw;
    padding: 3.2vw 1.3333333333vw 3.2vw 3.2vw;
  }
}
.bge-contents .flow-title h3 span,
.c-content-main .flow-title h3 span {
  width: 100px;
  flex: 0 0 auto;
  text-align: center;
  padding: 4px 0;
  letter-spacing: 0.05em;
  background: #00a2e6;
  color: #fff;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 0 15px 15px 0;
  margin: 0 12px 1px -12px;
}
@media (--sm-lte) {
  .bge-contents .flow-title h3 span,
.c-content-main .flow-title h3 span {
    width: 20vw;
    font-size: 2.9333333333vw;
    padding: 1.3333333333vw 0;
    border-radius: 0 4vw 4vw 0;
    margin: 2px 2.6666666667vw 0 -3.2vw;
  }
}
.bge-contents .flow-text,
.c-content-main .flow-text {
  padding: 0 32px;
  margin: 16px 0 14px;
}
@media (--sm-lte) {
  .bge-contents .flow-text,
.c-content-main .flow-text {
    padding: 0;
    font-size: 4.2666666667vw;
  }
}
.bge-contents .flow-text p,
.c-content-main .flow-text p {
  letter-spacing: 0.1em;
  line-height: 1.9;
}
.bge-contents .triangle,
.c-content-main .triangle {
  margin-bottom: 12px;
}
@media (--sm-lte) {
  .bge-contents .triangle,
.c-content-main .triangle {
    margin-bottom: 3.2vw;
  }
}
.bge-contents .triangle hr,
.c-content-main .triangle hr {
  display: block;
  width: 0;
  height: 0;
  background: none;
  border-style: solid;
  border-width: 14px 32px 0;
  border-color: #314958 transparent transparent;
}
@media (--sm-lte) {
  .bge-contents .triangle hr,
.c-content-main .triangle hr {
    border-width: 3.7333333333vw 8.5333333333vw 0;
    top: -6.9333333333vw;
  }
}
.bge-contents .flow-btn.bgb-button,
.c-content-main .flow-btn.bgb-button {
  margin: 25px 0 19px;
}
@media (--sm-lte) {
  .bge-contents .flow-btn.bgb-button,
.c-content-main .flow-btn.bgb-button {
    margin: 4.2666666667vw 0 5.0666666667vw;
  }
}
.bge-contents .flow-btn.bgb-button .bgt-btn,
.c-content-main .flow-btn.bgb-button .bgt-btn {
  min-width: 300px;
}
@media (--sm-lte) {
  .bge-contents .flow-btn.bgb-button .bgt-btn,
.c-content-main .flow-btn.bgb-button .bgt-btn {
    min-width: 0;
    width: 100%;
    max-width: 80vw;
  }
}
.bge-contents .image-link_last-no-link .bgt-grid:last-child a,
.c-content-main .image-link_last-no-link .bgt-grid:last-child a {
  pointer-events: none;
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2],
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] {
  display: flex;
}
@media (--sm-lte) {
  .bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2],
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] {
    display: block;
  }
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2]::after,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2]::after {
  content: none;
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid {
  padding: 20px 20px 40px 20px !important;
  font-feature-settings: "palt";
  float: none;
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:first-child,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:first-child {
  background: #edf3f8;
  position: relative;
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:first-child .syakai_kankyo-ttl,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:first-child .syakai_kankyo-ttl {
  background: #17456f;
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:first-child .syakai_kankyo-example-ttl,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:first-child .syakai_kankyo-example-ttl {
  color: #17456f;
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:first-child figcaption,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:first-child figcaption {
  color: #17456f;
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:first-child .syakai_kankyo-example-ttl::after,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:first-child .syakai_kankyo-example-ttl::after {
  background: #17456f;
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:last-child,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:last-child {
  background: #fdf0ed;
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:last-child .syakai_kankyo-ttl,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:last-child .syakai_kankyo-ttl {
  background: #97331b;
}
@media (--sm-gt) {
  .bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:last-child .syakai_kankyo-lead,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:last-child .syakai_kankyo-lead {
    visibility: hidden;
  }
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:last-child .syakai_kankyo-example-ttl,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:last-child .syakai_kankyo-example-ttl {
  color: #97331b;
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:last-child figcaption,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:last-child figcaption {
  color: #97331b;
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:last-child .syakai_kankyo-example-ttl::after,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .bgt-grid:last-child .syakai_kankyo-example-ttl::after {
  background: #97331b;
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-ttl,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-ttl {
  margin: -20px -20px 1em -20px;
  font-size: 2rem;
  line-height: 1;
  padding: 0.75em 10px;
  color: #fff;
  text-align: center;
  font-weight: 500;
}
@media (--sm-lte) {
  .bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-ttl,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-ttl {
    font-size: 4.8vw;
  }
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-ttl::after, .bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-ttl::before,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-ttl::after,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-ttl::before {
  content: none;
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-example-img,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-example-img {
  font-size: 0;
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-lead,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-lead {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.6666666667;
  letter-spacing: 0.06em;
  margin: 1.5em 0;
}
@media (--sm-lte) {
  .bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-lead,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-lead {
    font-size: 4.2666666667vw;
  }
}
@media (--sm-gt) {
  .bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-lead,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-lead {
    width: calc(200% + 40px);
    text-align: center;
  }
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-description,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-description {
  font-size: 1.4rem;
  line-height: 1.8571428571;
  letter-spacing: 0.06em;
  margin-top: 0.9285714286em;
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-example-ttl,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-example-ttl {
  font-size: 2rem;
  letter-spacing: 0.06em;
  margin: 0.9em 0 0.75em;
  position: relative;
  padding-left: 1.1em;
  font-weight: 500;
}
@media (--sm-lte) {
  .bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-example-ttl,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-example-ttl {
    font-size: 4.8vw;
  }
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-example-ttl::after,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-example-ttl::after {
  position: absolute;
  content: "";
  display: block;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 3px;
  height: 1.5em;
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-example-description,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-example-description {
  font-size: 1.4rem;
  line-height: 1.8571428571;
  letter-spacing: 0.06em;
  margin-top: 0;
}
@media (--sm-lte) {
  .bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-example-description,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] .syakai_kankyo-example-description {
    font-size: 3.7333333333vw;
  }
}
.bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] figure figcaption,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] figure figcaption {
  font-size: 1.8rem;
  margin: 0.7777777778em 0 0.3888888889em;
  padding: 0;
  font-weight: 500;
}
@media (--sm-lte) {
  .bge-contents .syakai_kankyo-block[data-bgb=wysiwyg2] figure figcaption,
.c-content-main .syakai_kankyo-block[data-bgb=wysiwyg2] figure figcaption {
    font-size: 4.2666666667vw;
  }
}
.bge-contents .kokusai-table th,
.bge-contents .kokusai-table td,
.c-content-main .kokusai-table th,
.c-content-main .kokusai-table td {
  text-align: center;
  letter-spacing: normal;
}
@media (--sm-gt) {
  .bge-contents .kokusai-table th,
.bge-contents .kokusai-table td,
.c-content-main .kokusai-table th,
.c-content-main .kokusai-table td {
    font-size: 1.4rem;
  }
}
.bge-contents .kokusai-table th.text-left,
.bge-contents .kokusai-table td.text-left,
.c-content-main .kokusai-table th.text-left,
.c-content-main .kokusai-table td.text-left {
  text-align: left;
}
.bge-contents .kokusai-table tr:first-child th:first-child,
.c-content-main .kokusai-table tr:first-child th:first-child {
  width: 10%;
  background: #5a6d7a;
  color: #fff;
}
.bge-contents .kokusai-table tr:first-child th:nth-child(2),
.c-content-main .kokusai-table tr:first-child th:nth-child(2) {
  width: 18%;
}
.bge-contents .kokusai-table tr:first-child th:nth-child(3),
.c-content-main .kokusai-table tr:first-child th:nth-child(3) {
  width: 54%;
}
.bge-contents .kokusai-table tr:first-child th:nth-child(4),
.c-content-main .kokusai-table tr:first-child th:nth-child(4) {
  width: 18%;
}
.bge-contents .kokusai-table tr:first-child th:not(:first-child),
.c-content-main .kokusai-table tr:first-child th:not(:first-child) {
  background: #0d212e;
  color: #fff;
}
[data-path$="/search"] .bge-contents,
[data-path$="/search"] .c-content-main {
  line-height: 2;
}
[data-path$="/search"] .bge-contents form,
[data-path$="/search"] .c-content-main form {
  background: #f3f3f3;
  padding: 30px;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents form,
[data-path$="/search"] .c-content-main form {
    padding: 5.3333333333vw 5%;
  }
}
[data-path$="/search"] .bge-contents form > div,
[data-path$="/search"] .c-content-main form > div {
  position: relative;
}
[data-path$="/search"] .bge-contents form input[type=text],
[data-path$="/search"] .c-content-main form input[type=text] {
  appearance: none;
  width: 100%;
  border-radius: 0;
  border: 3px solid #c8cfd4;
  font-size: 1.8rem;
  height: 65px;
  padding: 0 16px;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents form input[type=text],
[data-path$="/search"] .c-content-main form input[type=text] {
    height: 13.8666666667vw;
    border-width: 2px;
    font-size: 4.2666666667vw;
  }
}
[data-path$="/search"] .bge-contents form input[type=text]:focus,
[data-path$="/search"] .c-content-main form input[type=text]:focus {
  outline: none;
}
[data-path$="/search"] .bge-contents form input[type=submit],
[data-path$="/search"] .c-content-main form input[type=submit] {
  border-radius: 0;
  border: 0;
  width: 280px;
  display: block;
  padding: 14px 10px 17px 12px;
  letter-spacing: 0.1em;
  margin: 22px auto 0;
  font-family: inherit;
  background: #0d212e url("/img/icon-arrow-search.svg") no-repeat right 15px center;
  color: #fff;
  font-size: 1.7rem;
  cursor: pointer;
}
@media (--sm-gt) {
  [data-path$="/search"] .bge-contents form input[type=submit],
[data-path$="/search"] .c-content-main form input[type=submit] {
    transition: opacity 300ms;
  }
  [data-path$="/search"] .bge-contents form input[type=submit]:hover,
[data-path$="/search"] .c-content-main form input[type=submit]:hover {
    opacity: 0.6;
  }
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents form input[type=submit],
[data-path$="/search"] .c-content-main form input[type=submit] {
    font-size: 4.2666666667vw;
    width: 100%;
    margin-top: 5.3333333333vw;
    padding: 3.7333333333vw 2.6666666667vw 4.5333333333vw 3.2vw;
  }
}
[data-path$="/search"] .bge-contents form input[type=submit]:focus,
[data-path$="/search"] .c-content-main form input[type=submit]:focus {
  outline: none;
}
[data-path$="/search"] .bge-contents [id=proboOverlay],
[data-path$="/search"] .c-content-main [id=proboOverlay] {
  display: none !important;
}
[data-path$="/search"] .bge-contents ._pbox-refine-1,
[data-path$="/search"] .bge-contents ._pbox-refine-2,
[data-path$="/search"] .c-content-main ._pbox-refine-1,
[data-path$="/search"] .c-content-main ._pbox-refine-2 {
  display: flex;
  margin-top: 19px;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-refine-1,
[data-path$="/search"] .bge-contents ._pbox-refine-2,
[data-path$="/search"] .c-content-main ._pbox-refine-1,
[data-path$="/search"] .c-content-main ._pbox-refine-2 {
    margin-top: 5.0666666667vw;
    display: block;
  }
}
[data-path$="/search"] .bge-contents ._pbox-refine-1 ._title,
[data-path$="/search"] .bge-contents ._pbox-refine-2 ._title,
[data-path$="/search"] .c-content-main ._pbox-refine-1 ._title,
[data-path$="/search"] .c-content-main ._pbox-refine-2 ._title {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.02em;
  padding: 3px 29px 3px 0;
  border-right: 1px solid #c8cfd4;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-refine-1 ._title,
[data-path$="/search"] .bge-contents ._pbox-refine-2 ._title,
[data-path$="/search"] .c-content-main ._pbox-refine-1 ._title,
[data-path$="/search"] .c-content-main ._pbox-refine-2 ._title {
    font-size: 4vw;
    padding: 0;
    border: 0;
  }
}
[data-path$="/search"] .bge-contents ._pbox-refine-1 ._refs,
[data-path$="/search"] .bge-contents ._pbox-refine-2 ._refs,
[data-path$="/search"] .c-content-main ._pbox-refine-1 ._refs,
[data-path$="/search"] .c-content-main ._pbox-refine-2 ._refs {
  margin: 0 -20px 0 0;
  padding: 0 0 0 29px;
  display: flex;
  flex-wrap: wrap;
  border: 0;
  flex: 1;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-refine-1 ._refs,
[data-path$="/search"] .bge-contents ._pbox-refine-2 ._refs,
[data-path$="/search"] .c-content-main ._pbox-refine-1 ._refs,
[data-path$="/search"] .c-content-main ._pbox-refine-2 ._refs {
    margin: 0 -5.3333333333vw 0 0;
    padding: 0;
  }
}
[data-path$="/search"] .bge-contents ._pbox-refine-1 ._refs li,
[data-path$="/search"] .bge-contents ._pbox-refine-2 ._refs li,
[data-path$="/search"] .c-content-main ._pbox-refine-1 ._refs li,
[data-path$="/search"] .c-content-main ._pbox-refine-2 ._refs li {
  padding: 0;
  margin: 6px 29px 0 0;
  letter-spacing: 0;
  font-size: 1.5rem;
  word-break: keep-all;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-refine-1 ._refs li,
[data-path$="/search"] .bge-contents ._pbox-refine-2 ._refs li,
[data-path$="/search"] .c-content-main ._pbox-refine-1 ._refs li,
[data-path$="/search"] .c-content-main ._pbox-refine-2 ._refs li {
    margin: 1.6vw 7.7333333333vw 0 0;
    font-size: 4vw;
  }
}
[data-path$="/search"] .bge-contents ._pbox-refine-1 ._refs li::before,
[data-path$="/search"] .bge-contents ._pbox-refine-2 ._refs li::before,
[data-path$="/search"] .c-content-main ._pbox-refine-1 ._refs li::before,
[data-path$="/search"] .c-content-main ._pbox-refine-2 ._refs li::before {
  display: none;
}
[data-path$="/search"] .bge-contents ._pbox-refine-1 .check,
[data-path$="/search"] .bge-contents ._pbox-refine-2 .check,
[data-path$="/search"] .c-content-main ._pbox-refine-1 .check,
[data-path$="/search"] .c-content-main ._pbox-refine-2 .check {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
[data-path$="/search"] .bge-contents ._pbox-refine-1 .check + ._text,
[data-path$="/search"] .bge-contents ._pbox-refine-2 .check + ._text,
[data-path$="/search"] .c-content-main ._pbox-refine-1 .check + ._text,
[data-path$="/search"] .c-content-main ._pbox-refine-2 .check + ._text {
  appearance: none;
  display: inline-block;
  padding-left: 22px;
  position: relative;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-refine-1 .check + ._text,
[data-path$="/search"] .bge-contents ._pbox-refine-2 .check + ._text,
[data-path$="/search"] .c-content-main ._pbox-refine-1 .check + ._text,
[data-path$="/search"] .c-content-main ._pbox-refine-2 .check + ._text {
    padding-left: 5.8666666667vw;
  }
}
[data-path$="/search"] .bge-contents ._pbox-refine-1 .check + ._text::before,
[data-path$="/search"] .bge-contents ._pbox-refine-2 .check + ._text::before,
[data-path$="/search"] .c-content-main ._pbox-refine-1 .check + ._text::before,
[data-path$="/search"] .c-content-main ._pbox-refine-2 .check + ._text::before {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 0;
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  border-radius: 0;
  border: 1px solid #c8cfd4;
  background: #fff;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-refine-1 .check + ._text::before,
[data-path$="/search"] .bge-contents ._pbox-refine-2 .check + ._text::before,
[data-path$="/search"] .c-content-main ._pbox-refine-1 .check + ._text::before,
[data-path$="/search"] .c-content-main ._pbox-refine-2 .check + ._text::before {
    top: 1.3333333333vw;
    width: 4.2666666667vw;
    height: 4.2666666667vw;
    vertical-align: -0.5333333333vw;
  }
}
[data-path$="/search"] .bge-contents ._pbox-refine-1 .check:checked + ._text::before,
[data-path$="/search"] .bge-contents ._pbox-refine-2 .check:checked + ._text::before,
[data-path$="/search"] .c-content-main ._pbox-refine-1 .check:checked + ._text::before,
[data-path$="/search"] .c-content-main ._pbox-refine-2 .check:checked + ._text::before {
  background: #0d212e;
}
[data-path$="/search"] .bge-contents ._pbox-refine-1 .check:checked + ._text::after,
[data-path$="/search"] .bge-contents ._pbox-refine-2 .check:checked + ._text::after,
[data-path$="/search"] .c-content-main ._pbox-refine-1 .check:checked + ._text::after,
[data-path$="/search"] .c-content-main ._pbox-refine-2 .check:checked + ._text::after {
  content: "";
  display: block;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  width: 8px;
  height: 5px;
  transform: rotate(-45deg);
  position: absolute;
  left: 4px;
  top: 9.2px;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-refine-1 .check:checked + ._text::after,
[data-path$="/search"] .bge-contents ._pbox-refine-2 .check:checked + ._text::after,
[data-path$="/search"] .c-content-main ._pbox-refine-1 .check:checked + ._text::after,
[data-path$="/search"] .c-content-main ._pbox-refine-2 .check:checked + ._text::after {
    width: 2.1333333333vw;
    height: 1.3333333333vw;
    left: 1.0666666667vw;
    top: 2.4vw;
  }
}
[data-path$="/search"] .bge-contents ._pbox-refine-1 .check:focus,
[data-path$="/search"] .bge-contents ._pbox-refine-2 .check:focus,
[data-path$="/search"] .c-content-main ._pbox-refine-1 .check:focus,
[data-path$="/search"] .c-content-main ._pbox-refine-2 .check:focus {
  outline: none;
}
[data-path$="/search"] .bge-contents .poplink_suggest,
[data-path$="/search"] .bge-contents div.poplink.pc022,
[data-path$="/search"] .c-content-main .poplink_suggest,
[data-path$="/search"] .c-content-main div.poplink.pc022 {
  width: 100% !important;
}
[data-path$="/search"] .bge-contents .pbox-search-status,
[data-path$="/search"] .c-content-main .pbox-search-status {
  margin-top: 12px;
  text-align: right;
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: 0.05em;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents .pbox-search-status,
[data-path$="/search"] .c-content-main .pbox-search-status {
    margin-top: 3.2vw;
    font-size: 3.7333333333vw;
  }
}
[data-path$="/search"] .bge-contents .pbox-search-status span,
[data-path$="/search"] .c-content-main .pbox-search-status span {
  font-weight: bold;
}
[data-path$="/search"] .bge-contents .pbox-sort-selector,
[data-path$="/search"] .bge-contents .pbox-count-selector,
[data-path$="/search"] .c-content-main .pbox-sort-selector,
[data-path$="/search"] .c-content-main .pbox-count-selector {
  display: none !important;
}
[data-path$="/search"] .bge-contents ._pbox-pagenav,
[data-path$="/search"] .c-content-main ._pbox-pagenav {
  text-align: center;
  margin: 43px 0 50px;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-pagenav,
[data-path$="/search"] .c-content-main ._pbox-pagenav {
    margin: 8vw 0;
  }
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._navs,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._navs {
  display: flex;
  justify-content: center;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-pagenav ._navs,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._navs {
    padding-top: 9.0666666667vw;
    position: relative;
  }
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._navs a,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._navs a {
  text-decoration: none;
  color: #101010;
}
@media (--sm-gt) {
  [data-path$="/search"] .bge-contents ._pbox-pagenav ._navs a,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._navs a {
    transition: opacity 300ms;
  }
  [data-path$="/search"] .bge-contents ._pbox-pagenav ._navs a:hover,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._navs a:hover {
    opacity: 0.6;
  }
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._navs a:visited,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._navs a:visited {
  color: #101010 !important;
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._page,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._page {
  width: 37px;
  height: 38px;
  margin: 0 4px;
  line-height: 37px;
  display: block;
  font-size: 1.6rem;
  font-weight: bold;
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-pagenav ._page,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._page {
    width: 9.8666666667vw;
    height: 10.1333333333vw;
    margin: 0 0.8vw;
    line-height: 9.8666666667vw;
    font-size: 4vw;
  }
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._page a,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._page a {
  display: block;
  width: 100%;
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._page._cur,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._page._cur {
  background: #161616;
  color: #fff;
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._next,
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._next,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev {
  padding-top: 3px;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-pagenav ._next,
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._next,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev {
    position: absolute;
    top: 0;
    left: 0;
  }
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._next a,
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev a,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._next a,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev a {
  display: block;
  overflow: hidden;
  width: 60px;
  position: relative;
  text-indent: -999px;
  letter-spacing: 0.05em;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-pagenav ._next a,
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev a,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._next a,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev a {
    width: 13.6vw;
    letter-spacing: 0;
    font-size: 3.7333333333vw;
  }
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._next a::before,
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev a::before,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._next a::before,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev a::before {
  content: "NEXT";
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  text-indent: 0;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-pagenav ._next a::before,
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev a::before,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._next a::before,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev a::before {
    font-size: 3.7333333333vw;
  }
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._next a::after,
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev a::after,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._next a::after,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev a::after {
  content: "";
  display: block;
  position: absolute;
  top: 13px;
  right: 5px;
  width: 6px;
  height: 6px;
  border-top: 2px solid #101010;
  border-right: 2px solid #101010;
  transform: rotate(45deg);
  transition: 300ms;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-pagenav ._next a::after,
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev a::after,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._next a::after,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev a::after {
    width: 1.6vw;
    height: 1.6vw;
    top: 2.9333333333vw;
    right: 0.5333333333vw;
    border-top-width: 1px;
    border-right-width: 1px;
  }
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._next,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._next {
  margin-left: 35px;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-pagenav ._next,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._next {
    margin-left: 0;
    right: 0;
    left: auto;
  }
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev {
  margin-right: 35px;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-pagenav ._prev,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev {
    margin-right: 0;
  }
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev a::before,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev a::before {
  content: "BACK";
  right: 0;
  left: auto;
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev a::after,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev a::after {
  right: auto;
  left: 5px;
  transform: rotate(-135deg);
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-pagenav ._prev a::after,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev a::after {
    right: auto;
    left: 0.5333333333vw;
  }
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._next._noanc,
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev._noanc,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._next._noanc,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev._noanc {
  display: block;
  overflow: hidden;
  width: 60px;
  position: relative;
  top: 3px;
  text-indent: -999px;
  letter-spacing: 0.05em;
  opacity: 0.3;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-pagenav ._next._noanc,
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev._noanc,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._next._noanc,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev._noanc {
    position: absolute;
    top: 0.5333333333vw;
    right: 0;
    width: 13.6vw;
    letter-spacing: 0;
  }
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._next._noanc::before,
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev._noanc::before,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._next._noanc::before,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev._noanc::before {
  content: "NEXT";
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  text-indent: 0;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-pagenav ._next._noanc::before,
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev._noanc::before,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._next._noanc::before,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev._noanc::before {
    font-size: 3.7333333333vw;
  }
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._next._noanc::after,
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev._noanc::after,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._next._noanc::after,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev._noanc::after {
  content: "";
  display: block;
  position: absolute;
  top: 13px;
  right: 5px;
  width: 6px;
  height: 6px;
  border-top: 2px solid #101010;
  border-right: 2px solid #101010;
  transform: rotate(45deg);
  transition: 300ms;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-pagenav ._next._noanc::after,
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev._noanc::after,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._next._noanc::after,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev._noanc::after {
    width: 1.6vw;
    height: 1.6vw;
    top: 2.9333333333vw;
    right: 0.5333333333vw;
    border-top-width: 1px;
    border-right-width: 1px;
  }
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev._noanc::before,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev._noanc::before {
  content: "BACK";
  right: 0;
  left: auto;
}
[data-path$="/search"] .bge-contents ._pbox-pagenav ._prev._noanc::after,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev._noanc::after {
  right: auto;
  left: 5px;
  transform: rotate(-135deg);
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-pagenav ._prev._noanc::after,
[data-path$="/search"] .c-content-main ._pbox-pagenav ._prev._noanc::after {
    right: auto;
    left: 0.5333333333vw;
  }
}
[data-path$="/search"] .bge-contents ._record,
[data-path$="/search"] .c-content-main ._record {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #101010;
  padding: 0 0 23px;
  margin-bottom: 20px;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._record,
[data-path$="/search"] .c-content-main ._record {
    padding-bottom: 6.1333333333vw;
    margin-bottom: 5.3333333333vw;
  }
}
[data-path$="/search"] .bge-contents ._record::after,
[data-path$="/search"] .c-content-main ._record::after {
  display: none;
}
[data-path$="/search"] .bge-contents ._record ._id,
[data-path$="/search"] .c-content-main ._record ._id {
  order: 0;
  padding: 15px 0 15px 30px;
  color: #00a2e6;
  font-size: 2.2rem;
  font-family: "Lato", "Noto Sans JP", "メイリオ", Meiryo, sans-serif;
  font-weight: bold;
  background: #f3f3f3;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._record ._id,
[data-path$="/search"] .c-content-main ._record ._id {
    padding: 2.6666666667vw 0 2.6666666667vw 4vw;
    font-size: 4.8vw;
    line-height: 1.8;
  }
}
[data-path$="/search"] .bge-contents ._record ._id::after,
[data-path$="/search"] .c-content-main ._record ._id::after {
  content: ".";
}
[data-path$="/search"] .bge-contents ._record ._title,
[data-path$="/search"] .c-content-main ._record ._title {
  order: 1;
  letter-spacing: -0.01em;
  background: #f3f3f3;
  flex: 1;
  font-size: 2.2rem;
  font-weight: bold;
  margin: 0;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._record ._title,
[data-path$="/search"] .c-content-main ._record ._title {
    font-size: 4.8vw;
    line-height: 1.8;
  }
}
[data-path$="/search"] .bge-contents ._record ._title a,
[data-path$="/search"] .c-content-main ._record ._title a {
  text-decoration: none;
  display: block;
  padding: 12px 30px 15px 8px;
  color: #101010;
}
@media (--sm-gt) {
  [data-path$="/search"] .bge-contents ._record ._title a,
[data-path$="/search"] .c-content-main ._record ._title a {
    transition: opacity 300ms;
  }
  [data-path$="/search"] .bge-contents ._record ._title a:hover,
[data-path$="/search"] .c-content-main ._record ._title a:hover {
    opacity: 0.6;
  }
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._record ._title a,
[data-path$="/search"] .c-content-main ._record ._title a {
    padding: 2.6666666667vw 4vw 2.6666666667vw 1.3333333333vw;
  }
}
[data-path$="/search"] .bge-contents ._record ._title a:visited,
[data-path$="/search"] .c-content-main ._record ._title a:visited {
  color: #101010 !important;
}
[data-path$="/search"] .bge-contents ._record ._thumb,
[data-path$="/search"] .c-content-main ._record ._thumb {
  order: 3;
  width: 210px;
  margin-left: 30px;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._record ._thumb,
[data-path$="/search"] .c-content-main ._record ._thumb {
    width: 100%;
    margin-left: 0;
    padding: 0 4vw;
  }
}
[data-path$="/search"] .bge-contents ._record ._thumb img,
[data-path$="/search"] .c-content-main ._record ._thumb img {
  width: 100%;
  max-width: none;
  max-height: none;
  margin-right: 0;
  border: 0;
}
[data-path$="/search"] .bge-contents ._record ._nearby,
[data-path$="/search"] .c-content-main ._record ._nearby {
  order: 4;
  margin: -10px 0 0;
  letter-spacing: 0.055em;
  padding: 0 20px 0 30px;
  line-height: 1.9;
  font-size: inherit;
  width: 100%;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._record ._nearby,
[data-path$="/search"] .c-content-main ._record ._nearby {
    margin-top: 0;
    padding: 0 4vw;
    font-size: 3.7333333333vw;
    line-height: 1.8;
  }
}
[data-path$="/search"] .bge-contents ._record ._url,
[data-path$="/search"] .c-content-main ._record ._url {
  order: 2;
  width: 930px;
  visibility: hidden;
  height: 30px;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._record ._url,
[data-path$="/search"] .c-content-main ._record ._url {
    width: 100%;
    height: 5.3333333333vw;
  }
}
[data-path$="/search"] .bge-contents ._pbox-recommend,
[data-path$="/search"] .c-content-main ._pbox-recommend {
  border: 0;
}
[data-path$="/search"] .bge-contents ._pbox-recommend ._caption,
[data-path$="/search"] .c-content-main ._pbox-recommend ._caption {
  margin-bottom: 13px;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-recommend ._caption,
[data-path$="/search"] .c-content-main ._pbox-recommend ._caption {
    margin-bottom: 2.6666666667vw;
  }
}
[data-path$="/search"] .bge-contents ._pbox-recommend ._record,
[data-path$="/search"] .c-content-main ._pbox-recommend ._record {
  padding: 20px 20px 14px;
  display: grid;
  grid-template-columns: 170px calc(100% - 170px);
  grid-template-rows: auto 1fr;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-recommend ._record,
[data-path$="/search"] .c-content-main ._pbox-recommend ._record {
    padding: 5.3333333333vw;
    grid-template-columns: 29.3333333333vw calc(100% - 26.6666666667vw);
  }
}
[data-path$="/search"] .bge-contents ._pbox-recommend ._image,
[data-path$="/search"] .c-content-main ._pbox-recommend ._image {
  width: 150px;
  grid-column: 1/2;
  grid-row: 1/3;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-recommend ._image,
[data-path$="/search"] .c-content-main ._pbox-recommend ._image {
    width: 26.6666666667vw;
  }
}
[data-path$="/search"] .bge-contents ._pbox-recommend ._image img,
[data-path$="/search"] .c-content-main ._pbox-recommend ._image img {
  width: 100%;
  display: block;
  margin: 0;
}
[data-path$="/search"] .bge-contents ._pbox-recommend ._summary,
[data-path$="/search"] .c-content-main ._pbox-recommend ._summary {
  order: 2;
  line-height: 1.7;
  letter-spacing: 0.065em;
  grid-column: 2/3;
  grid-row: 2;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-recommend ._summary,
[data-path$="/search"] .c-content-main ._pbox-recommend ._summary {
    display: none;
  }
}
[data-path$="/search"] .bge-contents ._pbox-recommend ._title,
[data-path$="/search"] .c-content-main ._pbox-recommend ._title {
  background: none;
  margin: -9px 0 5px;
  grid-column: 2/3;
  grid-row: 1;
}
@media (--sm-lte) {
  [data-path$="/search"] .bge-contents ._pbox-recommend ._title,
[data-path$="/search"] .c-content-main ._pbox-recommend ._title {
    line-height: 1.45;
    margin: -0.5333333333vw 0 0;
    font-size: 4.2666666667vw;
  }
}
[data-path$="/search"] .bge-contents ._pbox-recommend ._title a,
[data-path$="/search"] .c-content-main ._pbox-recommend ._title a {
  font-feature-settings: normal;
  padding: 0;
  letter-spacing: 0.06em;
}
[data-path$="/search"] .bge-contents ._pbox-recommend ._url,
[data-path$="/search"] .c-content-main ._pbox-recommend ._url {
  display: none;
}
.bge-contents dl.mark-list,
.c-content-main dl.mark-list {
  display: flex;
  margin: 0;
}
.bge-contents dl.mark-list dd,
.c-content-main dl.mark-list dd {
  flex: 1;
  margin: 0;
}
.bge-contents .design-title,
.c-content-main .design-title {
  background-color: #ababab;
  text-align: center;
  margin: 0 0 1em;
  font-weight: bold;
  border-radius: 15px;
}
[data-path="/gakusei/bukatsu/fit_joshikai"] .bge-contents a,
[data-path="/gakusei/bukatsu/fit_joshikai"] .c-content-main a {
  color: #e60057;
}
[data-path="/gakusei/bukatsu/fit_joshikai"] .bge-contents h2::after,
[data-path="/gakusei/bukatsu/fit_joshikai"] .c-content-main h2::after {
  background: #e60057;
}
[data-path="/gakusei/bukatsu/fit_joshikai"] .bge-contents h3,
[data-path="/gakusei/bukatsu/fit_joshikai"] .c-content-main h3 {
  border-left-color: #e60057;
}
[data-path="/gakusei/bukatsu/fit_joshikai"] .bge-contents h4::after,
[data-path="/gakusei/bukatsu/fit_joshikai"] .c-content-main h4::after {
  background: #e60057;
}
[data-path="/gakusei/bukatsu/fit_joshikai"] .bge-contents h6::before,
[data-path="/gakusei/bukatsu/fit_joshikai"] .c-content-main h6::before {
  border-color: #e60057;
}
[data-path="/gakusei/bukatsu/fit_joshikai"] .bge-contents ul li::before,
[data-path="/gakusei/bukatsu/fit_joshikai"] .c-content-main ul li::before {
  background: #e60057;
}
[data-path="/gakusei/bukatsu/fit_joshikai"] .bge-contents ul li.link-list a,
[data-path="/gakusei/bukatsu/fit_joshikai"] .c-content-main ul li.link-list a {
  color: inherit;
}
[data-path="/gakusei/bukatsu/fit_joshikai"] .bge-contents ul li.link-list a:hover,
[data-path="/gakusei/bukatsu/fit_joshikai"] .c-content-main ul li.link-list a:hover {
  color: #e60057;
}
.bge-contents .original-site-btn[data-bgb],
.c-content-main .original-site-btn[data-bgb] {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -133px !important;
}
@media (--sm-gt) {
  [data-path="/gakubu/kougaku/"] .bge-contents .original-site-btn[data-bgb], [data-path="/gakubu/joho_kougaku/"] .bge-contents .original-site-btn[data-bgb], [data-path="/gakubu/syakai_kankyo/"] .bge-contents .original-site-btn[data-bgb], [data-path="/gakubu/jc/"] .bge-contents .original-site-btn[data-bgb],
[data-path="/gakubu/kougaku/"] .c-content-main .original-site-btn[data-bgb],
[data-path="/gakubu/joho_kougaku/"] .c-content-main .original-site-btn[data-bgb],
[data-path="/gakubu/syakai_kankyo/"] .c-content-main .original-site-btn[data-bgb],
[data-path="/gakubu/jc/"] .c-content-main .original-site-btn[data-bgb] {
    margin-top: -81px !important;
  }
}
@media (--sm-lte) {
  .bge-contents .original-site-btn[data-bgb],
.c-content-main .original-site-btn[data-bgb] {
    top: 0;
    right: 0;
    left: 0;
    margin: 0 !important;
    margin-top: 0vw !important;
    transform: translateY(-150%);
  }
}
@media (--sm-lte) {
  [data-path="/gakubu/kougaku/"] .bge-contents .department-image, [data-path="/gakubu/joho_kougaku/"] .bge-contents .department-image, [data-path="/gakubu/syakai_kankyo/"] .bge-contents .department-image, [data-path="/gakubu/jc/"] .bge-contents .department-image, [data-path="/gakubu/jc/joho_media/"] .bge-contents .department-image,
[data-path="/gakubu/kougaku/"] .c-content-main .department-image,
[data-path="/gakubu/joho_kougaku/"] .c-content-main .department-image,
[data-path="/gakubu/syakai_kankyo/"] .c-content-main .department-image,
[data-path="/gakubu/jc/"] .c-content-main .department-image,
[data-path="/gakubu/jc/joho_media/"] .c-content-main .department-image {
    margin-top: 26vw !important;
  }
  [data-path="/gakubu/kougaku/"] .bge-contents .department-image > div, [data-path="/gakubu/joho_kougaku/"] .bge-contents .department-image > div, [data-path="/gakubu/syakai_kankyo/"] .bge-contents .department-image > div, [data-path="/gakubu/jc/"] .bge-contents .department-image > div, [data-path="/gakubu/jc/joho_media/"] .bge-contents .department-image > div,
[data-path="/gakubu/kougaku/"] .c-content-main .department-image > div,
[data-path="/gakubu/joho_kougaku/"] .c-content-main .department-image > div,
[data-path="/gakubu/syakai_kankyo/"] .c-content-main .department-image > div,
[data-path="/gakubu/jc/"] .c-content-main .department-image > div,
[data-path="/gakubu/jc/joho_media/"] .c-content-main .department-image > div {
    margin-bottom: 0;
  }
}
@media (--sm-gt) {
  .bge-contents .juken-blog-block .bgt-link__box,
.c-content-main .juken-blog-block .bgt-link__box {
    display: flex;
  }
}
.bge-contents .juken-blog-block .bgt-box__image-container,
.c-content-main .juken-blog-block .bgt-box__image-container {
  margin-bottom: 0;
}
.bge-contents .juken-blog-block .bgt-box__image-container img,
.c-content-main .juken-blog-block .bgt-box__image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media (--sm-gt) {
  .bge-contents .juken-blog-block .bgt-box__image-container,
.c-content-main .juken-blog-block .bgt-box__image-container {
    width: 50%;
  }
}
.bge-contents .juken-blog-block .bgt-box__caption,
.c-content-main .juken-blog-block .bgt-box__caption {
  background: #f3f3f3;
  padding: 33px 40px;
  line-height: 1.6;
  font-size: 2.2rem;
  letter-spacing: 0.1em;
  font-weight: 500;
}
@media (--sm-lte) {
  .bge-contents .juken-blog-block .bgt-box__caption,
.c-content-main .juken-blog-block .bgt-box__caption {
    padding: 3.4666666667vw 5.3333333333vw;
  }
}
.bge-contents .juken-blog-block .bgt-box__caption span,
.c-content-main .juken-blog-block .bgt-box__caption span {
  font-weight: normal;
  font-size: 0.65em;
  display: block;
  margin-top: 0.5em;
}
@media (--sm-gt) {
  .bge-contents .juken-blog-block .bgt-box__caption,
.c-content-main .juken-blog-block .bgt-box__caption {
    width: 50%;
  }
}

@media (--sm-lte) {
  .c-content-main .pc_only,
.c-content-main [data-device=pc] {
    display: none;
  }
}
@media (--sm-gt) {
  .c-content-main .sp_only,
.c-content-main [data-device=sp] {
    display: none;
  }
}
@media print {
  .c-content-main .sp_only,
.c-content-main [data-device=sp] {
    display: none;
  }
}

.bge-contents p,
.c-content-main p {
  margin: 0;
}
.bge-contents a,
.c-content-main a {
  color: #00ae34;
}
.bge-contents h2,
.c-content-main h2 {
  font-size: 1.24rem;
  font-weight: 200;
  padding: 14px 0;
  color: #00781f;
  border-bottom: 1px solid #00ae34;
  font-family: "Noto Sans Japanese", sans-serif;
}
.bge-contents h2::before, .bge-contents h2::after,
.c-content-main h2::before,
.c-content-main h2::after {
  display: none;
}
.bge-contents h3,
.c-content-main h3 {
  font-size: 1.16rem;
  border-color: #00ae34;
}
.bge-contents h4,
.c-content-main h4 {
  font-size: 1.08rem;
}
.bge-contents h4::before,
.c-content-main h4::before {
  background: #00781f;
}
.bge-contents h4::after,
.c-content-main h4::after {
  background: #00ae34;
}
.bge-contents h5,
.c-content-main h5 {
  font-size: 1rem;
}
.bge-contents h6,
.c-content-main h6 {
  font-size: 0.92rem;
}
.bge-contents h6::before,
.c-content-main h6::before {
  width: 6px;
  height: 6px;
  border: 4px solid #00ae34;
  top: calc(1em - 7px);
}
.bge-contents ul.indent-none,
.c-content-main ul.indent-none {
  padding-left: 0;
}
.bge-contents ul > li,
.c-content-main ul > li {
  font-size: 1rem;
}
.bge-contents ul > li::before,
.c-content-main ul > li::before {
  top: 11px;
  background: #00ae34;
}
.bge-contents ol > li::before,
.bge-contents ol.list-ol-normal > li::before,
.c-content-main ol > li::before,
.c-content-main ol.list-ol-normal > li::before {
  color: #00ae34;
  font-size: 1.1rem;
  width: 1.4em;
  margin-top: -3px;
}
.bge-contents .bgt-btn,
.bge-contents .btn-link a,
.c-content-main .bgt-btn,
.c-content-main .btn-link a {
  font-size: 1rem;
  color: #00ae34;
  background: #fff;
  border: solid 2px #00ae34;
}
@media (--sm-lte) {
  .bge-contents .bgt-btn,
.bge-contents .btn-link a,
.c-content-main .bgt-btn,
.c-content-main .btn-link a {
    font-size: 4.5333333333vw;
  }
}
.bge-contents .bgt-btn::after,
.bge-contents .btn-link a::after,
.c-content-main .bgt-btn::after,
.c-content-main .btn-link a::after {
  width: 18px;
  height: 6px;
  border: none;
  background: url("/images/common/icon05.gif");
  background-size: cover;
  transform: none;
}
.bge-contents [data-bgt-button-kind=link] .bgt-btn > span,
.c-content-main [data-bgt-button-kind=link] .bgt-btn > span {
  color: #00ae34;
}
.bge-contents [data-bgt-button-kind=em] .bgt-btn,
.c-content-main [data-bgt-button-kind=em] .bgt-btn {
  background-color: #00ae34;
  border: none;
}
.bge-contents [data-bgt-button-kind=em] .bgt-btn::after,
.c-content-main [data-bgt-button-kind=em] .bgt-btn::after {
  width: 18px;
  height: 6px;
  background: url("/images/common/icon04.png");
  background-size: cover;
}
.bge-contents [data-bgt-button-kind=back] .bgt-btn,
.c-content-main [data-bgt-button-kind=back] .bgt-btn {
  background-color: #788e80;
  border: none;
}
.bge-contents [data-bgt-button-kind=back] .bgt-btn::before,
.c-content-main [data-bgt-button-kind=back] .bgt-btn::before {
  width: 18px;
  height: 6px;
  border: none;
  background: url("/images/common/icon04.png");
  background-size: cover;
  transform: scale(-1, 1) rotate(0);
}
.bge-contents [data-bgt-button-kind=external] .bgt-btn > span,
.c-content-main [data-bgt-button-kind=external] .bgt-btn > span {
  color: #00ae34;
}
.bge-contents [data-bgt-button-kind=external] .bgt-btn,
.bge-contents .btn.external a,
.c-content-main [data-bgt-button-kind=external] .bgt-btn,
.c-content-main .btn.external a {
  background-color: #fff;
  border: 2px solid #00ae34;
}
.bge-contents [data-bgt-button-kind=external] .bgt-btn::after,
.bge-contents .btn.external a::after,
.c-content-main [data-bgt-button-kind=external] .bgt-btn::after,
.c-content-main .btn.external a::after {
  background: url("/img/icon-external-btn-ses.png") no-repeat;
  background-size: cover;
}
.bge-contents .bgt-download-file__link,
.bge-contents .btn-download a,
.c-content-main .bgt-download-file__link,
.c-content-main .btn-download a {
  color: #00ae34;
  background: #fff;
  border: 2px solid #00ae34;
}
.bge-contents .bgt-download-file__link::after,
.bge-contents .btn-download a::after,
.c-content-main .bgt-download-file__link::after,
.c-content-main .btn-download a::after {
  background: url("/img/icon-pdf-green.svg") no-repeat;
}
.bge-contents [data-bgt=gallery] .bgt-gallery__img img,
.c-content-main [data-bgt=gallery] .bgt-gallery__img img {
  object-fit: cover;
  margin: 0;
}
.bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__prev, .bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__next,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__prev,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__next {
  background-color: #00ae34;
  padding: 12px;
}
.bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__prev::after, .bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__next::after,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__prev::after,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__next::after {
  width: 16px;
  height: 16px;
  border-width: 3px 3px 0 0;
}
.bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__prev,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__prev {
  transform: none;
}
.bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__prev::after,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__prev::after {
  transform-origin: center center;
  transform: rotate(-135deg) translate(-3px, 3px);
}
.bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__next,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__next {
  transform: none;
}
.bge-contents [data-bgt=gallery] .bgt-gallery-ctrl__next::after,
.c-content-main [data-bgt=gallery] .bgt-gallery-ctrl__next::after {
  transform-origin: center center;
  transform: rotate(45deg) translate(-3px, 3px);
}