@font-face {
	font-family: YuGothicM;
	src: local('Yu Gothic Medium');
}

@font-face {
	font-family: icons;
	font-style: normal;
	font-weight: normal;
	src: url('../font/icons.eot');
	src: url('../font/icons.eot?#iefix') format('embedded-opentype'), url('../font/icons.woff') format('woff'), url('../font/icons.ttf') format('truetype');
}

$font-family-primary: 'Noto Sans JP', 'メイリオ', Meiryo, sans-serif;
$font-family-secondary: 'Lato', $font-family-primary;
$font-family-heading: $font-family-secondary;
$font-family-icons: icons;

// SES
$font-family-ses-primary: 'Noto Sans Japanese', sans-serif;

@mixin icon {
	display: inline-block;
	font-family: $font-family-icons;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	speak: none;
	text-align: left;
	text-indent: 0;
	text-rendering: auto;
	text-transform: none;
	vertical-align: middle;
	white-space: nowrap;
}

$glyph-space: '\0020';
$glyph-email: '\E100';
$glyph-youtube: '\E111';
$glyph-phone: '\E140';
$glyph-search: '\E150';
$glyph-rss: '\E160';
$glyph-facebook: '\E200';
$glyph-instagram: '\E201';
$glyph-twitter: '\E202';
$glyph-line: '\E203';
$glyph-googleplus: '\E204';
$glyph-plus: '\E800';
$glyph-minus: '\E801';
$glyph-times: '\E802';
$glyph-arrow-right: '\E900';
$glyph-arrow-right-triangle: '\E901';
$glyph-arrow-right-narrow: '\E902';

// 🍔 BurgerEditor が利用できる場合に有効
@mixin bge-icon {
	@include icon;
	font-family: FontAwesome; // stylelint-disable-line font-family-no-missing-generic-family-keyword
}

$fa-external-link: '\f08e';
